import { SUCCESS_FAILED } from "@/constants";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Badge, Button, Popconfirm, Space, Spin, Typography } from "antd";
import Tooltip from "antd/es/tooltip";
import { useEffect, useState } from "react";
import { CompareItem } from "..";

type CompareSetItem = {
    compareServerName: string;
    compareServerUno: number;
    compareSymbol: string;
    createDate: string;
    setName: string;
};
type CompareSetGrouped = {
    setName: string;
    items: CompareItem[];
};

type SavedCompareSetsProps = {
    onAddCompareItem: (items: CompareItem[]) => void;
};
const SavedCompareSets = ({ onAddCompareItem }: SavedCompareSetsProps) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [compareSets, setCompareSets] = useState<CompareSetGrouped[]>([]);

    const {
        data: res,
        isLoading,
        error,
        isFetching,
        refetch,
    } = useQuery({
        queryKey: [APIs.RC_LP_PRICE_COMPARE.GET_COMPARE_SET],
        queryFn: () => plainAxiosInstance.get<CompareSetItem[]>(`${APIs.RC_LP_PRICE_COMPARE.GET_COMPARE_SET}`),
        staleTime: 60 * 1000 * 5,
    });

    useEffect(() => {
        const data = res?.data;
        if (!data) return;
        const grouped = data.reduce((acc: CompareSetGrouped[], item: CompareSetItem) => {
            const exist = acc.find(a => a.setName === item.setName);
            if (exist) {
                exist.items.push({
                    compareServer: item.compareServerUno,
                    compareServerName: item.compareServerName,
                    compareSymbol: item.compareSymbol,
                });
            } else {
                acc.push({
                    setName: item.setName,
                    items: [
                        {
                            compareServer: item.compareServerUno,
                            compareServerName: item.compareServerName,
                            compareSymbol: item.compareSymbol,
                        },
                    ],
                });
            }
            return acc;
        }, []);
        setCompareSets(grouped);
    }, [res?.data]);

    useEffect(() => {
        if (error) {
            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Saved Compare Sets", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
        }
    }, [error]);

    function handleDelete(setName: string, cb: () => void) {
        plainAxiosInstance
            .post(`${APIs.RC_LP_PRICE_COMPARE.DELETE_COMPARE_SET}/${setName}`)
            .then(() => {
                cb();
                setTimeout(() => refetch(), 1000);
                // refetch();
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Saved Compare Set", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
            });
    }

    return (
        <>
            <div className="saved-compare-sets">
                <Button
                    className="extra-item-btn"
                    {...(isEdit ? { type: "primary", icon: <SaveOutlined /> } : { type: "default", icon: <EditOutlined /> })}
                    onClick={() => setIsEdit(!isEdit)}
                >
                    {isEdit ? "Save" : "Edit"}
                </Button>
                <Spin spinning={isLoading || isFetching}>
                    <div className="content">
                        {compareSets.map(x => (
                            <SavedItem key={x.setName} group={x} isEdit={isEdit} onAddCompareItem={onAddCompareItem} handleDelete={handleDelete} />
                        ))}
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default SavedCompareSets;

type SavedItemProps = {
    group: CompareSetGrouped;
    onAddCompareItem: (items: CompareItem[]) => void;
    handleDelete: (setName: string, callback: () => void) => void;
    isEdit: boolean;
};
const SavedItem = ({ group, isEdit, onAddCompareItem, handleDelete }: SavedItemProps) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    function handleOk() {
        setConfirmLoading(true);
        handleDelete(group.setName, () => {
            setOpen(false);
            setConfirmLoading(false);
        });
    }

    return (
        <Popconfirm
            title="Confirm delete this set?"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={e => {
                e?.preventDefault();
                setOpen(false);
            }}
        >
            <div className="saved-compare-set-wrapper" onClick={() => onAddCompareItem(group.items)}>
                <Tooltip
                    placement="right"
                    color="#3d3d3d"
                    title={
                        <Space direction="vertical">
                            {group.items.map(item => (
                                <Typography.Text
                                    key={`${item.compareServer}-${item.compareServerName}-${item.compareSymbol}`}
                                    style={{ color: "#fff" }}
                                >
                                    {`${item.compareServerName}-${item.compareSymbol}`}
                                </Typography.Text>
                            ))}
                        </Space>
                    }
                >
                    <div className="content">
                        <PlusOutlined />
                        <Typography.Text>{group.setName}</Typography.Text>
                    </div>
                </Tooltip>
                <div className="actions">
                    {isEdit ? (
                        <Button
                            style={{ display: isEdit ? "block" : "none" }}
                            className="delete-btn"
                            shape="round"
                            danger
                            icon={<DeleteOutlined />}
                            size="small"
                            onClick={e => {
                                e.stopPropagation();
                                setOpen(true);
                            }}
                        />
                    ) : (
                        <Badge count={group.items.length} color="#004B57" />
                    )}
                </div>
            </div>
        </Popconfirm>
    );
};
