import BasicFilter from "@/components/BasicFilter";
import useRCStatusMonitorDaily from "@/hooks/useRCStatusMonitorDaily";
import useRCStatusMonitorStore from "@/store/useRCStatusMonitorStore";
import Loader from "../components/Loader";
import MessageCard from "../components/MessageCard";
import TabContent from "../components/TabContent";
import ServerStatus, { ServerStatusProps } from "./ServerStatus";
import ServerStatusFilterWeekend from "./ServerStatusFilterWeekend";
import ServerStatusVendor from "./ServerStatusVendor";
import { massageDailyData } from "./statusUtils";
import { useEffect } from "react";
import fireTruck from "@/assets/audios/rc/fireTruck.mp3";
import marioCoin from "@/assets/audios/rc/marioCoin.mp3";
import { useAudioPlayer } from "@/helpers/audioHelper";

const TabDaily = () => {
    const { playSound } = useAudioPlayer(fireTruck);
    const { playSound: playSound2 } = useAudioPlayer(marioCoin);

    const { rcStatusMonitorDaily, isLoading, isFetching } = useRCStatusMonitorDaily();
    const vendor = useRCStatusMonitorStore(state => state.vendor.daily);

    const dataDaily = rcStatusMonitorDaily ? massageDailyData(rcStatusMonitorDaily) : [];
    const filterDataDaily = vendor ? dataDaily?.filter((item: any) => item.vendor === vendor) : [];

    useEffect(() => {
        if (!isFetching) {
            const eCount = rcStatusMonitorDaily.filter((item: any) => item.status === 2).length;
            const wCount = rcStatusMonitorDaily.filter((item: any) => item.status === 1).length;

            if (eCount > 0) {
                playSound();
            }
            if (wCount > 0) {
                playSound2();
            }
        }
    }, [isFetching]);

    return (
        <TabContent>
            <BasicFilter show={false}>
                <ServerStatusFilterWeekend />
            </BasicFilter>

            {isLoading && rcStatusMonitorDaily.length === 0 ? (
                <Loader />
            ) : (
                <>
                    <ServerStatusVendor data={dataDaily as ServerStatusProps[]} vendorType="daily" />
                    {!vendor && (
                        <div style={{ padding: "0 1rem" }}>
                            <MessageCard type="info">Please select a group from above</MessageCard>
                        </div>
                    )}
                    {filterDataDaily?.map((item: any, index: number) => (
                        <ServerStatus key={index} data={item as ServerStatusProps} extra={{ showSuccess: true }} />
                    ))}
                </>
            )}
        </TabContent>
    );
};

export default TabDaily;
