import { Modal } from "antd";
import { ExecuteSymbolSwapUpdateModalCallbackKey } from "../../SymbolSwapUpload/components/executeSymbolSwapUpdateModal";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { useEffect, useMemo, useState } from "react";
import { ConfigDiffRecNotesData, NoteEntityProps2 } from "./type";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import moment from "moment";

export interface NoteEntitiesModalProps {
    noteEntity: NoteEntityProps2 | null;
    callback: (type: number, data: any) => void;
}

export enum NoteEntitiesModalCallbackKey {
    Close = 0,
}

export interface NoteEntitiesProps {
    KeyStr?: string;
    diffId: number;
    insertDate: string;
    note: string;
    pid: number;
    sid: number;
    validator: number;
}

const NoteEntitiesModal = (props: NoteEntitiesModalProps) => {
    const [data, setData] = useState<ConfigDiffRecNotesData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const currNoteEntity = useMemo(() => props.noteEntity, [props.noteEntity]);

    const currNoteTableTitle = useMemo(() => {
        if (currNoteEntity === null) return "";
        else {
            let pkVals = currNoteEntity?.pkVals;
            let tmpTitle = Object.keys(pkVals).map((key) => (pkVals[`${key}`])).toString();
            return `${currNoteEntity?.ConfigDate}${Object.keys(pkVals).length > 0 ? `, ${tmpTitle}` : ""}`;
        };
    }, [currNoteEntity]);

    const columns = [
        DTColProps.Small({
            title: "Config Date",
            dataIndex: "configDate",
            key: "configDate",
            sorter: (a: any, b: any) => moment(a.configDate).diff(moment(b.configDate)),
            options: {
                filter: {
                    type: ComponentType.date,
                    value: [],
                    dateFormat: "YYYY-MM-DD",
                    callback: (filterValue: any, rowData: ConfigDiffRecNotesData) => {
                        if (filterValue === "") return true;
                        else {
                            const _filterValue = moment(filterValue as moment.Moment).startOf("day").format("YYYY-MM-DD");
                            const thisConfigDate = moment(rowData["configDate"]).format("YYYY-MM-DD");
                            return _filterValue === thisConfigDate;
                        };
                    },
                },
            },
        }),
        {
            title: "Notes",
            dataIndex: "note",
            key: "note",
            sorter: (a: any, b: any) => a.note.localeCompare(b.note),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        DTColProps.Small({
            title: "Validator",
            dataIndex: "validator",
            key: "validator",
            sorter: (a: any, b: any) => a.validator - b.validator,
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
    ];

    useEffect(() => {
        if (currNoteEntity !== null) {
            setIsLoading(true);
            setData([]);
            plainAxiosInstance
                .post(`${APIs.RISK_TOOL.POST_LATEST_CONFIG_DIFF_RECORD_NOTES}?configDate=${encodeURIComponent(currNoteEntity?.ConfigDate)}&dbName=${encodeURIComponent(currNoteEntity?.dbName!)}&tbName=${encodeURIComponent(currNoteEntity?.tbName!)}`, {
                    ConfigDate: currNoteEntity.ConfigDate,
                    ...currNoteEntity.pkVals,
                })
                .then((res: any) => {
                    setData(res.data.length === 0
                        ? []
                        : res.data.map((x: ConfigDiffRecNotesData, i: number) => ({ ...x, KeyStr: i.toString() }))
                    );
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler(`Error occurred during create reason`, SUCCESS_FAILED.OTHERS_FAILED, err);
                        setData([]);
                    })
                )
                .finally(() => setIsLoading(false));
        };
        return () => { };
    }, [currNoteEntity]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"50vw"}
            title="Configuration Difference Notes"
            open={currNoteEntity !== null}
            okButtonProps={{ style: { display: "none" } }}
            cancelText="Close"
            onCancel={() => props.callback(ExecuteSymbolSwapUpdateModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <FlexiDataTable
                bordered
                rowKeyProperty="KeyStr"
                title={<h2>{currNoteTableTitle}</h2>}
                loading={isLoading}
                columns={columns}
                options={{
                    showHideColumns: false,
                    defaultCollapseFilterPanel: true,
                }}
                dataSource={data}
                pagination={{
                    hideOnSinglePage: true,
                }}
                callback={() => { }}
            />
        </Modal>
    );
};

export default NoteEntitiesModal;
