import { SUCCESS_FAILED } from "@/constants";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { DefaultIfEmpty } from "@/utils/object";
import { SendOutlined, DownOutlined } from "@ant-design/icons";
import { Space, Dropdown, Tooltip, Alert, Spin } from "antd";
import { formatDistanceToNow } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface ReportDropdownMenu2Props {}

interface ReportStatusListProps {
    createdDateUtc: string;
    description: string;
    id: number;
    key: string;
    value: string;
}

interface ReportStatusPropProps {
    [key: string]: ReportStatusItemProps;
}

interface ReportStatusItemProps {
    title: string;
    shortTitle: string;
    callback: () => void;
    lastUpdatedAt: string | undefined;
    isBtnLoading: boolean;
}

const ReportTypeKeys = ["MRHourlyReportStatus", "MRWeekendHourlyReportStatus", "MREodPnlReportStatus", "MRSocialTradingReportStatus"];

const initialItem: ReportStatusItemProps = {
    title: "",
    shortTitle: "",
    callback: () => console.log(`Function not implement.`),
    lastUpdatedAt: undefined,
    isBtnLoading: false,
};

const ReportDropdownMenu2 = (props: ReportDropdownMenu2Props) => {
    const reportStatusIntervalSeconds = 10;
    const [reportStatusList, setReportStatusList] = useState<ReportStatusListProps[]>([]);
    const timerInterval = useRef<any>(undefined);

    const setBtnLoadingKey = (key: string | string[]) =>
        setReportStatusList(prev => {
            const items = Array.isArray(key) ? key : [key];
            const updatedArray = [...prev];

            items.forEach(x => {
                const fIdx = updatedArray.findIndex(y => y.key === x);
                if (fIdx > -1) {
                    updatedArray[fIdx].value = JSON.stringify({ ...JSON.parse(updatedArray[fIdx].value), IsNotAllowedToGenReport: true });
                }
            });

            return updatedArray;
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sendHourlyReport = (key: string) => {
        setBtnLoadingKey(key);
        apiRequest(APIs.SEND_HOURLY_REPORT, {
            svrDtFrom: null,
            svrDtTo: null,
        })
            .then(() => {})
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Error: `, SUCCESS_FAILED.OTHERS_FAILED, err)));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sendWeekendHourlyReport = (key: string) => {
        setBtnLoadingKey(key);
        apiRequest(APIs.SEND_WEEKEND_HOURLY_REPORT, {
            svrDtFrom: null,
            svrDtTo: null,
        })
            .then(() => {})
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Error: `, SUCCESS_FAILED.OTHERS_FAILED, err)));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sendEODReport = (key: string) => {
        setBtnLoadingKey(key);
        apiRequest(APIs.SEND_EOD_REPORT, {
            svrDtFrom: null,
            svrDtTo: null,
        })
            .then(() => {})
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Error: `, SUCCESS_FAILED.OTHERS_FAILED, err)));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sendSocialTradingReport = (key: string) => {
        setBtnLoadingKey(key);
        apiRequest(APIs.SEND_SOCIAL_TRADING_REPORT, {
            svrDtFrom: null,
            svrDtTo: null,
        })
            .then(() => {})
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Error: `, SUCCESS_FAILED.OTHERS_FAILED, err)));
    };

    const reportStatusProp: ReportStatusPropProps = useMemo(() => {
        return reportStatusList.reduce((obj: any, item: any) => {
            let jsonVal = JSON.parse(item.value),
                returnVal = {
                    lastUpdatedAt: DefaultIfEmpty(jsonVal, "ReportLastGenUtc", undefined),
                    isBtnLoading: DefaultIfEmpty(jsonVal, "IsNotAllowedToGenReport", false),
                };

            switch (item.key) {
                case "MRHourlyReportStatus":
                    obj[item.key] = {
                        ...returnVal,
                        title: "Hourly Report",
                        shortTitle: "Hourly",
                        callback: sendHourlyReport.bind(undefined, item.key),
                    };
                    break;
                case "MRWeekendHourlyReportStatus":
                    obj[item.key] = {
                        ...returnVal,
                        title: "Weekend Hourly Report",
                        shortTitle: "Weekend Hourly",
                        callback: sendWeekendHourlyReport.bind(undefined, item.key),
                    };
                    break;
                case "MREodPnlReportStatus":
                    obj[item.key] = {
                        ...returnVal,
                        title: "End Of Day Report",
                        shortTitle: "EOD",
                        callback: sendEODReport.bind(undefined, item.key),
                    };
                    break;
                case "MRSocialTradingReportStatus":
                    obj[item.key] = {
                        ...returnVal,
                        title: "Social Trading Report",
                        shortTitle: "Social Trading",
                        callback: sendSocialTradingReport.bind(undefined, item.key),
                    };
                    break;
                default:
                    obj[item.key] = { ...initialItem, ...returnVal };
                    break;
            }
            return obj;
        }, {});
    }, [reportStatusList, sendEODReport, sendHourlyReport, sendSocialTradingReport, sendWeekendHourlyReport]);

    const items = useMemo(
        () =>
            ReportTypeKeys.map(x => {
                let rItem = DefaultIfEmpty(reportStatusProp, x, initialItem);
                return {
                    key: `key-${x}`,
                    label: (
                        <Space className="report-item">
                            <div className="report-info">
                                <div className="sending-text-title">{rItem.title}</div>
                                {rItem.lastUpdatedAt && (
                                    <div className="sending-text-desc">{formatDistanceToNow(new Date(rItem.lastUpdatedAt), { addSuffix: true })}</div>
                                )}
                            </div>
                            <SendOutlined style={{ marginLeft: 8 }} />
                        </Space>
                    ),
                    disabled: rItem.isBtnLoading,
                    onClick: rItem.callback,
                };
            }),
        [reportStatusProp]
    );

    const mainTemplateDisplay: React.ReactNode = useMemo(() => {
        let itemArr = ReportTypeKeys.map(x => DefaultIfEmpty(reportStatusProp, x, initialItem)),
            loadingItem: ReportStatusPropProps[] = itemArr.filter(x => x.isBtnLoading),
            reportRunBefore = itemArr.filter(x => x.lastUpdatedAt),
            latestItem = reportRunBefore
                .map(x => ({ title: x.shortTitle, time: new Date(x.lastUpdatedAt) }))
                .reduce((latest, current) => (current.time > latest.time ? current : latest), { title: "", time: new Date(-8640000000000000) });
        return loadingItem.length > 0 ? (
            <Alert
                message={
                    <Space size="large">
                        <Spin size="large" />
                        <div>
                            <div className="sending-text-title">{`Sending ${
                                loadingItem.length > 1
                                    ? `${loadingItem
                                          .slice(0, -1)
                                          .map(x => x.shortTitle)
                                          .join(", ")} and ${loadingItem[loadingItem.length - 1].shortTitle}`
                                    : loadingItem[0].shortTitle
                            } report...`}</div>
                            <div className="sending-text-desc">This may take a moment.</div>
                        </div>
                        <DownOutlined />
                    </Space>
                }
                type="warning"
                style={{ height: "100%" }}
            />
        ) : (
            <div className="send-hourly-report">
                <Space>
                    {reportRunBefore.length > 0 ? (
                        <div className="report-info">
                            <div className="sending-text-desc">Latest report sent: </div>
                            <div className="sending-text-title">
                                {latestItem.title} Report - {formatDistanceToNow(latestItem.time, { addSuffix: true })}
                            </div>
                        </div>
                    ) : (
                        <div className="report-info">Select Report</div>
                    )}
                    <DownOutlined />
                </Space>
            </div>
        );
    }, [reportStatusProp]);

    const checkReportStatus = () => {
        apiRequest(APIs.GET_SETTING_LIST, { keys: ReportTypeKeys })
            .then((res: ReportStatusListProps[]) => {
                if (res && res.length > 0) {
                    setReportStatusList(res);
                } else {
                    setReportStatusList([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("mr report status", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    useEffect(() => {
        checkReportStatus();
        timerInterval.current = setInterval(checkReportStatus, reportStatusIntervalSeconds * 1000);
        return () => timerInterval.current && clearInterval(timerInterval.current);
    }, []);

    return (
        <Dropdown menu={{ items }} trigger={["click"]} overlayClassName={"lp-monitor-summary-container-report-menu"}>
            <div className="send-report-dropdown" style={{ cursor: "pointer" }}>
                <Tooltip title="Click to select a report to send">{mainTemplateDisplay}</Tooltip>
            </div>
        </Dropdown>
    );
};

export default ReportDropdownMenu2;
