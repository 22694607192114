import { Segmented } from "antd";
import { useEffect, useState } from "react";
import FilterByServer from "./FilterByServer";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";
import { KeyValuePair } from "@/constants/type";
import FilterBySymbol from "./FilterBySymbol";
import SavedCompareSets from "./SavedCompareSets";
import { CompareItem } from "../index";
import { SortList } from "@/utils/array";

const Segments = ["Filter by Server", "Filter by Symbol", "Compare Sets"];

const SymbolFilters = ({ onAddCompareItem }: { onAddCompareItem: (items: CompareItem[]) => void }) => {
    const [currentTab, setCurrentTab] = useState<string>(Segments[1]);
    const [serversList, setServersList] = useState<KeyValuePair[]>([]);

    useEffect(() => {
        plainAxiosInstance
            .get(`${APIs.RC_LP_PRICE_COMPARE.GET_SERVERS}`)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setServersList(
                        SortList(
                            res.data.map((item: any) => ({
                                text: item.serverName,
                                value: item.serverUno,
                            })),
                            "text"
                        )
                    );
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("servers list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    }, []);

    return (
        <div className="symbol-select-filters">
            <Segmented value={currentTab} options={Segments} onChange={(activeKey: any) => setCurrentTab(activeKey)} />
            <div className="filter-content-panel">
                <div className={currentTab === Segments[0] ? "" : "hide"}>
                    <FilterByServer servers={serversList} onAddCompareItem={onAddCompareItem} />
                </div>
                <div className={currentTab === Segments[1] ? "" : "hide"}>
                    <FilterBySymbol servers={serversList} onAddCompareItem={onAddCompareItem} />
                </div>
                <div className={currentTab === Segments[2] ? "" : "hide"}>
                    <SavedCompareSets onAddCompareItem={onAddCompareItem} />
                </div>
            </div>
        </div>
    );
};

export default SymbolFilters;
