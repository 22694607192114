import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED, SYMBOL_SCOPE_TYPE } from "@/constants";
import { KeyValuePair } from "@/constants/type";
import { apiRequest, APIs } from "@/services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { Modal, Form, Row, Col } from "antd";
import { useEffect, useMemo } from "react";

interface ExportExcelModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    servers: KeyValuePair[];
    symbols: KeyValuePair[];
    cleanSymbols: KeyValuePair[];
    pricingCleanSymbols: KeyValuePair[];
    symbolAssetType: KeyValuePair[];
}

export enum ExportExcelModalCallbackKey {
    Close = 0,
}

const ExportExcelModal = (props: ExportExcelModalProps) => {
    const [executeForm] = Form.useForm();
    const currentType = Form.useWatch("type", executeForm);

    const exportToExcel = (values: any) => {
        apiRequest(APIs.EXPORT_SYMBOL, values, "POST", "arraybuffer")
            .then((res: any) => {
                const fileName = res.headers["x-filename"];
                const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); // or any other extension
                // Append to html link element page
                document.body.appendChild(link);
                // start download
                link.click();
                // Clean up and remove the link
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbols excel", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
            });
    };

    const cleanSymbolAndAssetTypeTemplate: React.ReactNode = useMemo(
        () =>
            currentType === SYMBOL_SCOPE_TYPE.PRODUCT ? (
                <>
                    <FormComponent
                        label="Clean Symbol"
                        name="cleanSymbols"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.cleanSymbols,
                            inputProps: {
                                mode: "multiple",
                            },
                        }}
                    />
                    <FormComponent
                        label="Symbol Asset Type"
                        name="symbolAssetTypeIds"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.symbolAssetType,
                            inputProps: {
                                mode: "multiple",
                            },
                        }}
                    />
                </>
            ) : currentType === SYMBOL_SCOPE_TYPE.PRICING ? (
                <>
                    <FormComponent
                        label="Clean Symbol"
                        name="cleanSymbolsPricing"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.pricingCleanSymbols,
                            inputProps: {
                                mode: "multiple",
                                style: {
                                    width: "100%",
                                },
                            },
                        }}
                    />
                    <FormComponent
                        label="Symbol Asset Type"
                        name="symbolAssetTypeIdsPricing"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.symbolAssetType,
                            inputProps: {
                                mode: "multiple",
                                style: {
                                    width: "100%",
                                },
                            },
                        }}
                    />
                </>
            ) : (
                <></>
            ),
        [currentType, props.cleanSymbols, props.pricingCleanSymbols, props.symbolAssetType]
    );

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldsValue({
                type: SYMBOL_SCOPE_TYPE.PRODUCT,
                serverIds: [],
                symbols: [],
                cleanSymbols: [],
                cleanSymbolsPricing: [],
                symbolAssetTypeIds: [],
                symbolAssetTypeIdsPricing: [],
            });
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            width={600}
            title="Export as Excel"
            open={props.isModalVisible}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => props.callback(ExportExcelModalCallbackKey.Close, null)}
            onOk={() => {
                executeForm
                    .validateFields()
                    .then(values => {
                        exportToExcel(values);
                        props.callback(ExportExcelModalCallbackKey.Close, null);
                    })
                    .catch(errorInfo => console.log(errorInfo));
            }}
        >
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={executeForm} layout="horizontal" initialValues={{}}>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Server"
                            name="serverIds"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.servers,
                                inputProps: {
                                    mode: "multiple",
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Symbol"
                            name="symbols"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.symbols,
                                inputProps: {
                                    mode: "multiple",
                                },
                            }}
                        />
                    </Col>
                </Row>
                <FormComponent
                    label=" "
                    name="type"
                    extra={{
                        type: ComponentType.radio,
                        value: [
                            { text: "Product", value: SYMBOL_SCOPE_TYPE.PRODUCT },
                            { text: "Pricing", value: SYMBOL_SCOPE_TYPE.PRICING },
                        ],
                        itemProps: {
                            colon: false,
                        },
                    }}
                />
                {cleanSymbolAndAssetTypeTemplate}
            </Form>
        </Modal>
    );
};

export default ExportExcelModal;
