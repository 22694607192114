import { useRCIssuesLogMainServers, useRCIssuesLogMT5Monitor } from "@/hooks/useRCIssuesLog";
import { Input, Space } from "antd";
import moment from "moment";
import { matchSorter } from "match-sorter";
import { useMemo, useState } from "react";
import MessageCard from "../../components/MessageCard";
import { DTColProps } from "@/utils/Common";
import { DefaultIfEmpty } from "@/utils/object";
import FlexiDataTable from "@/components/FlexiDataTable";

const STATE_DESC: { [key: string]: string } = {
    "0": "ORDER_STATE_STARTED",
    "1": "ORDER_STATE_PLACED",
    "7": "ORDER_STATE_REQUEST_ADD",
    "8": "ORDER_STATE_REQUEST_MODIFY",
    "9": "ORDER_STATE_REQUEST_CANCEL",
};

const IssueMT5OrderTypeMonitorTable = () => {
    const { rcIssuesLogMainServers, isLoading: isLoadingMainServers } = useRCIssuesLogMainServers();
    const { rcIssuesLogMT5Monitor, isLoading } = useRCIssuesLogMT5Monitor();
    const [searchText, setSearchText] = useState("");

    const result = useMemo(
        () =>
            matchSorter(rcIssuesLogMT5Monitor, searchText, {
                keys: ["serverUno", "orderId", "symbol", "type", "openTime", "volume", "state"],
            }),
        [rcIssuesLogMT5Monitor, searchText]
    );

    const columns2 = [
        DTColProps.Small({
            title: "Server",
            dataIndex: "serverUno",
            key: "serverUno",
            sorter: (a: any, b: any) => a.serverUno - b.serverUno,
            render: (serverUno: any) => {
                const server = rcIssuesLogMainServers?.find(x => String(x.oldServerId) === String(serverUno));
                return server ? server.serverName : serverUno;
            },
        }),
        {
            title: "Order ID",
            dataIndex: "orderId",
            key: "orderId",
            sorter: (a: any, b: any) => a.orderId - b.orderId,
        },
        {
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
        },
        DTColProps.XSmall(
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                sorter: (a: any, b: any) => a.type - b.type,
                render: (text: any, checkState: any) => {
                    const { type, state } = checkState;
                    if (state === "1") {
                        if (type === "0") {
                            return "Buy";
                        } else if (type === "1") {
                            return "Sell";
                        }
                    }
                    return "Any";
                },
            },
            ["text-center"]
        ),
        DTColProps.Small({
            title: "Open Time",
            dataIndex: "openTime",
            key: "openTime",
            sorter: (a: any, b: any) => a.openTime - b.openTime,
            render: (dateTime: any) => (dateTime ? moment(dateTime).format("YYYY-MM-DD HH:mm:ss") : ""),
        }),
        {
            title: "Volume",
            dataIndex: "volume",
            key: "volume",
            className: "text-right",
            sorter: (a: any, b: any) => a.volume - b.volume,
        },
        DTColProps.Middle({
            title: "State",
            dataIndex: "state",
            key: "state",
            sorter: (a: any, b: any) => a.state - b.state,
            render: (state: any) => DefaultIfEmpty(STATE_DESC, state, "UNKNOWN_STATE"),
        }),
    ];

    return (
        <div>
            <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
                {isLoading || isLoadingMainServers ? (
                    <MessageCard type="info">Loading...</MessageCard>
                ) : rcIssuesLogMT5Monitor?.length > 0 ? (
                    <MessageCard type="warning">{`Total ${rcIssuesLogMT5Monitor?.length || 0} records`}</MessageCard>
                ) : (
                    <MessageCard type="success">All Good</MessageCard>
                )}
                <Input placeholder="Search" onChange={e => setSearchText(e.target.value)} allowClear />
                <FlexiDataTable
                    bordered
                    rowKeyProperty="orderId"
                    title={false}
                    columns={columns2}
                    options={{
                        enableFilter: false,
                        showHideColumns: false,
                    }}
                    loading={isLoading || isLoadingMainServers}
                    dataSource={result || []}
                    pagination={{
                        defaultPageSize: 20,
                    }}
                />
            </Space>
        </div>
    );
};

export default IssueMT5OrderTypeMonitorTable;
