import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import { CALLBACK_KEY, SUCCESS_FAILED, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { ReasonProps } from "@/pages/AdminTools/RiskTools/GroupTransferTool";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { Form, Modal } from "antd";
import { useEffect, useState } from "react";

export interface GroupTransferSettingsPageProps {}

export enum GroupTransferSettingsPageCallbackKey {
    RefetchData = 1,
}

const GroupTransferSettingsPage = (props: GroupTransferSettingsPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reasonOpt, setReasonOpt] = useState<ReasonProps[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isCreate, setIsCreate] = useState<boolean>(false);
    const [settingForm] = Form.useForm();

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_GROUP_TRANSFER_TOOL_SETTINGS_EDIT);

    const columns = [
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        showHideColumns: false,
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                settingForm.setFieldsValue({ id: 0, reason: "" });
                setIsCreate(true);
                setIsModalVisible(true);
                break;
            case CALLBACK_KEY.DO_EDIT:
                settingForm.setFieldsValue(FormData);
                setIsCreate(false);
                setIsModalVisible(true);
                break;
            case CALLBACK_KEY.DO_DELETE:
                deleteReason(FormData);
                break;
            default:
                break;
        }
    };

    const updateReason = (values: ReasonProps) => {
        setIsLoading(true);
        plainAxiosInstance
            .post(APIs.RISK_TOOL.EDIT_GROUP_TRANSFER_TOOL_SETTING, values)
            .then((res: any) => {
                if (res.data > 0) {
                    ErrorMessageHandler("front desk reason", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    getReasonList();
                } else {
                    ErrorMessageHandler("front desk reason", SUCCESS_FAILED.FAILED_UPDATE_DATA);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("front desk reason", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const deleteReason = (values: ReasonProps) => {
        setIsLoading(true);
        plainAxiosInstance
            .post(APIs.RISK_TOOL.DELETE_GROUP_TRANSFER_TOOL_SETTING, values)
            .then((res: any) => {
                if (res.data > 0) {
                    ErrorMessageHandler("front desk reason", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                    getReasonList();
                } else {
                    ErrorMessageHandler("front desk reason", SUCCESS_FAILED.FAILED_DELETE_DATA);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("front desk reason", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const getReasonList = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_CONFIG)
            .then((res: any) => {
                if (!isEmptyOrNull(res.data)) {
                    setReasonOpt(res.data);
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getReasonList();
        return () => {};
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Group Transfer Tool Settings</span>
                </div>
            </div>
            <div className="reason-settings-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="id"
                    title={<span className="flexi-table-small-title-style">Front Desk Reason Listing</span>}
                    columns={columns}
                    dataSource={reasonOpt}
                    options={options}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <Modal
                style={{ top: 10 }}
                width={"40vw"}
                title={isCreate ? "Create Front Desk Reason" : "Edit Front Desk Reason"}
                open={isModalVisible}
                okText="Submit"
                onOk={() => {
                    settingForm
                        .validateFields()
                        .then((values: any) => {
                            updateReason(values);
                            setIsModalVisible(false);
                        })
                        .catch((err: any) => console.log(err));
                }}
                onCancel={() => setIsModalVisible(false)}
                maskClosable={false}
            >
                <Form form={settingForm} layout="horizontal" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
                    <FormComponent label="" name="id" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent
                        label="Reason"
                        name="reason"
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        }}
                    />
                </Form>
            </Modal>
        </>
    );
};

export default GroupTransferSettingsPage;
