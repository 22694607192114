import { useEffect, useState } from "react";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { CALLBACK_KEY, SUCCESS_FAILED } from "../../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "../../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../../helpers/authHelper";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "../../../../../utils/Common";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { APIs } from "../../../../../services/apis";
import { message, Modal } from "antd";
import { defaultIfEmptyOrNull } from "../../../../../utils/string";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export interface SwapChargingToolSymbolSwapProps {}

interface SwapChargingToolDataProps {
    cleanSymbol: string;
    swapLong: number;
    swapMode: string;
    swapModeId: number;
    swapShort: number;
}

const SwapChargingToolSymbolSwap = (props: SwapChargingToolSymbolSwapProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<SwapChargingToolDataProps[]>([]);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_SWAPCHARGINGTOOL_EDIT);

    const columns: any[] = [
        {
            title: "Clean Symbol",
            dataIndex: "cleanSymbol",
            key: "cleanSymbol",
        },
        {
            title: "MT5 SWAP MODE",
            dataIndex: "swapMode",
            key: "swapMode",
        },
        DTColProps.Currency({
            width: "16vw",
            title: "Swap Long",
            dataIndex: "swapLong",
            key: "swapLong",
        }),
        DTColProps.Currency({
            width: "16vw",
            title: "Swap Short",
            dataIndex: "swapShort",
            key: "swapShort",
        }),
    ];

    const handleSubmit = (file: any) => {
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
                isLt5M = file.size / 1024 / 1024 < 5;

            if (fileExtension[0] !== ".xlsx") {
                ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            } else if (!isLt5M) {
                ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to import?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("uploadFile", file);

                    plainAxiosInstance
                        .post(APIs.RISK_TOOL.GET_SWAP_CHARGING_TOOL_UPLOAD, formData)
                        .then(res => {
                            if (res.status === 200 && res.data === 0) {
                                ErrorMessageHandler(`File upload successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                            } else {
                                ErrorMessageHandler(`${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        })
                        .catch((error: any) => {
                            ErrorMessageHandler(`File upload failed: (${error.response.data.message}).`, SUCCESS_FAILED.OTHERS_FAILED);
                        })
                        .finally(() => getMappingData());
                },
                onCancel() {},
            });
        } catch (error) {
            ErrorMessageHandler(`Error during uploading file. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        ...(enableUpdate && {
            upload: {
                name: "file",
                multiple: false,
                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                showUploadList: false,
                onChange: (info: any) => {
                    if (info.file.status === "error") {
                        ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                },
                customRequest: ({ file, onSuccess }: any) =>
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0),
                beforeUpload: (file: any) => handleSubmit(file),
            },
            export: {
                text: "Download Template",
            },
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, formData: any) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                try {
                    plainAxiosInstance
                        .get(`${plainAxiosInstance.defaults.baseURL}${APIs.RISK_TOOL.GET_SWAP_CHARGING_TOOL_DOWNLOAD}`, {
                            headers: {
                                Accept: "application/octet-stream, */*",
                            },
                            responseType: "blob",
                        })
                        .then(response => {
                            const contentType = response.headers["content-type"];
                            const fileName = getFileNameFromResponseHeader(response, `SwapChargingToolMap.xlsx`);
                            if (
                                contentType === "application/octet-stream" ||
                                contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", fileName);
                                document.body.appendChild(link);
                                link.click();
                                // Clean up
                                window.URL.revokeObjectURL(url);
                            } else {
                                ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        })
                        .catch((error: any) =>
                            ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED)
                        );
                } catch (e: any) {
                    ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
                }

                break;
            default:
                break;
        }
    };

    const getMappingData = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_SWAP_CHARGING_TOOL_SYMBOL_SWAP)
            .then((res: any) => {
                setData(res.data as SwapChargingToolDataProps[]);
                setIsLoading(false);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol swap", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    useEffect(() => {
        getMappingData();
    }, []);

    return (
        <div className="swap-charging-tool-symbol-swap-container">
            <FlexiDataTable
                bordered
                rowKeyProperty="login"
                title=""
                columns={columns}
                options={options}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
            />
        </div>
    );
};

export default SwapChargingToolSymbolSwap;
