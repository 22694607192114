import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, SUCCESS_FAILED, CALLBACK_KEY } from "@/constants";
import { CustomPaginationProps, FlexiDataColumnProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { ExclamationCircleOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import DownloadModal from "./DownloadModal";
import SetReadOnlyModal from "./SetReadOnlyModal";
import { DefaultIfEmpty } from "@/utils/object";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";

type Server = {
    text: string;
    value: number;
};

type RowToCancelType = {
    server: string;
    login: string;
};

const ReadOnlyPage = () => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [datalist, setDatalist] = useState<any[]>([]);
    const [serverList, setServerList] = useState<Server[]>([]);
    const [filterParams, setFilterParams] = useState<any>({ login: "" });
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [openReadOnlyModal, setOpenReadOnlyModal] = useState<boolean>(false);
    const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
    const [selectedRowToCancel, setSelectedRowToCancel] = useState<RowToCancelType[]>([]);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_READ_ONLY_EDIT);

    const columns: FlexiDataColumnProps[] = useMemo(
        () => [
            DTColProps.Small({
                title: "Server",
                dataIndex: "server",
                key: "server",
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                        inputProps: {
                            placeholder: "Please input login, seperate with comma",
                        },
                        callback(filterValue: string, rowData: any) {
                            const logins = filterValue.split(",").map(x => x.trim());
                            if (logins.length > 0) {
                                return logins.includes(rowData.login.toString());
                            }
                            return false;
                        },
                    },
                },
            }),
            DTColProps.Small({
                title: "Pre-Status",
                dataIndex: "readOnlyPre",
                key: "readOnlyPre",
                render: (text: number) => {
                    switch (text) {
                        case 0:
                            return "Enable Trading";
                        case 1:
                            return "Read-Only";
                        default:
                            return `${text}`;
                    }
                },
            }),
            DTColProps.Small({
                title: "Post-Status",
                dataIndex: "readOnlyAfter",
                key: "readOnlyAfter",
                render: (text: number) => {
                    switch (text) {
                        case 0:
                            return "Enable Trading";
                        case 1:
                            return "Read-Only";
                        default:
                            return `${text}`;
                    }
                },
            }),
            DTColProps.Small({
                title: "Operator",
                dataIndex: "optName",
                key: "optName",
            }),
            DTColProps.Small({
                title: "Reason",
                dataIndex: "reason",
                key: "reason",
            }),
            DTColProps.Small({
                title: "Create Date",
                dataIndex: "lastCreateTimeStr",
                key: "lastCreateTimeStr",
            }),
        ],
        []
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            serverFiltering: true,
            enableRowSelection: enableUpdate,
            export: { text: "Download" },
            ...(enableUpdate
                ? {
                      extraButtons: [
                          ...[{ text: "Set ReadOnly", value: "setReadOnly", icon: <PlusOutlined /> }],
                          ...(selectedRowToCancel.length > 0
                              ? [{ text: "Cancel ReadOnly", value: "cancelReadOnly", icon: <DeleteOutlined />, extras: { danger: true } }]
                              : []),
                      ],
                  }
                : {}),
        }),
        [datalist, selectedRowToCancel]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type, cbData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setPagination(prev => ({ ...prev, current: cbData.pagination.current, pageSize: cbData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                setFilterParams(
                    Object.keys(cbData)
                        .filter(x => cbData[x] !== undefined && cbData[x].toString().length > 0)
                        .reduce((obj: any, x: string) => {
                            obj[x] = cbData[x];
                            return obj;
                        }, {})
                );
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                setOpenDownloadModal(true);
                break;
            case CALLBACK_KEY.OTHERS:
                switch (cbData) {
                    case "setReadOnly":
                        setOpenReadOnlyModal(true);
                        break;
                    case "cancelReadOnly":
                        onCancelReadOnly();
                        break;
                    default:
                        break;
                }
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowToCancel(
                    cbData.selectedRows && cbData.selectedRows.length > 0
                        ? cbData.selectedRows.map((row: any) => ({ server: row.server, login: row.login }))
                        : []
                );
                break;
            default:
                break;
        }
    };

    const onCancelReadOnly = useCallback(() => {
        if (selectedRowToCancel.length === 0) {
            ErrorMessageHandler("Please select any row to cancel ReadOnly", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        }

        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Are you sure you want to cancel ReadOnly for these rows?`,
            content: (
                <div>
                    {selectedRowToCancel.map((row: any, index: number) => (
                        <div key={`slt-ky-${index}`}>
                            <span>
                                {row.server} - {row.login}
                            </span>
                        </div>
                    ))}
                </div>
            ),
            width: "30%",
            onOk() {
                setIsLoading(true);
                const formData = new FormData();
                formData.append("data", JSON.stringify(selectedRowToCancel));

                plainAxiosInstance
                    .post(`${APIs.RC_READ_ONLY.POST_CANCEL_READ_ONLY}`, formData)
                    .then((res: any) => {
                        if (res.data > 0) {
                            ErrorMessageHandler("ReadOnly", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        } else if (res.data === 0) {
                            ErrorMessageHandler(
                                "Notice! This batch of data has been set to read-only or the account is wrong!",
                                SUCCESS_FAILED.OTHERS_FAILED
                            );
                        } else if (res.data === -1) {
                            ErrorMessageHandler("ReadOnly", SUCCESS_FAILED.FAILED_DELETE_DATA);
                        }
                        setRunRefetchDataList(true);
                        setSelectedRowToCancel([]);
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("ReadOnly", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => setIsLoading(false));
            },
            onCancel() {},
        });
    }, [selectedRowToCancel]);

    const getReadOnlyList = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .post(`${APIs.RC_READ_ONLY.GET_READ_ONLY}`, {
                limit: pagination.pageSize,
                offset: ((pagination.current ?? 1) - 1) * (pagination.pageSize ?? 10),
                search: DefaultIfEmpty(filterParams, "login", ""),
            })
            .then((res: any) => {
                if (res.data && res.data.rows && res.data.rows.length > 0) {
                    setDatalist(res.data.rows.map((x: any) => ({ ...x, key: `${x.server}|${x.login}` })));
                    setPagination(prev => ({ ...prev, total: res.data.total }));
                } else {
                    setDatalist([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("readOnly", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    }, [filterParams, pagination]);

    const getConfig = () => {
        plainAxiosInstance
            .get(`${APIs.RC_READ_ONLY.GET_READ_ONLY_SERVER}`)
            .then((res: any) => setServerList(res.data && res.data.length > 0 ? res.data.map((x: any) => ({ text: x.name, value: x.value })) : []))
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getReadOnlyList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();

        return () => {
            setSelectedRowToCancel([]);
        };
    }, []);

    return (
        <CardBox title={"Read Only"}>
            <FlexiDataTable
                bordered
                rowKeyProperty="key"
                title=""
                columns={columns}
                dataSource={datalist}
                options={options}
                callback={componentCallback}
                pagination={pagination}
                serverSide={true}
                loading={isLoading}
            />
            <SetReadOnlyModal
                serversOptions={serverList}
                onModalCancel={() => setOpenReadOnlyModal(false)}
                onModalOk={() => {
                    setOpenReadOnlyModal(false);
                    setPagination(prev => ({ ...prev, current: 1 }));
                    setRunRefetchDataList(true);
                }}
                open={openReadOnlyModal}
            />
            <DownloadModal open={openDownloadModal} onClose={() => setOpenDownloadModal(false)} />
        </CardBox>
    );
};

export default ReadOnlyPage;
