import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { KeyValuePair, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorMessageHandler, ErrorCatchValidator, DTColProps } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { Button, message, Modal } from "antd";
import { useState, useMemo, useCallback, useEffect } from "react";
import CreateEditServerFeatureAccountModal, { CreateEditServerFeatureAccountModalCallbackKey } from "./CreateEditServerFeatureAccountModal";
import { SortList, ToObjectWithKey } from "@/utils/array";
import { DefaultIfEmpty } from "@/utils/object";
import { LinkOutlined } from "@ant-design/icons";
import LoadingComponent from "@/components/Loading";

export interface ServerFeatureAccountPageProps {}

export interface ServerFeatureAccountProps {
    comment: null | string;
    featureId: string;
    id: string;
    login: string;
    password: string;
    serverId: string;
    updateTime: string;
    updateUser: number;
    updateUserStr: string;
}

const ServerFeatureAccountPage = (props: ServerFeatureAccountPageProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [editData, setEditData] = useState<any | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<ServerFeatureAccountProps[]>([]);
    const [serverOptions, setServerOptions] = useState<KeyValuePair[]>([]);
    const [featureOptions, setFeatureOptions] = useState<KeyValuePair[]>([]);
    const [isConnected, setIsConnected] = useState<string[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_FEATURE_SETTINGS_EDIT);

    const SFObj = useMemo(() => {
        return {
            server: ToObjectWithKey(serverOptions, "value", "text"),
            feature: ToObjectWithKey(featureOptions, "value", "text"),
            serverOpt: serverOptions,
            featureOpt: featureOptions,
        };
    }, [serverOptions, featureOptions]);

    const columns: any[] = useMemo(
        () => [
            DTColProps.Small({
                title: "Server",
                dataIndex: "serverId",
                key: "serverId",
                render: (text: any) => DefaultIfEmpty(SFObj.server, text, text),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: SFObj.serverOpt,
                    },
                },
            }),
            DTColProps.Middle({
                title: "Feature",
                dataIndex: "featureId",
                key: "featureId",
                render: (text: any) => DefaultIfEmpty(SFObj.feature, text, text),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: SFObj.featureOpt,
                        callback: (filterValue: string, record: ServerFeatureAccountProps) => filterValue === record.featureId,
                    },
                },
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            {
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
            },
            DTColProps.Middle({
                title: "Update User",
                dataIndex: "updateUserStr",
                key: "updateUserStr",
            }),
            DTColProps.Small({
                title: "Update Time",
                dataIndex: "updateTime",
                key: "updateTime",
            }),
        ],
        [SFObj]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            ...(enableUpdate && {
                add: true,
                edit: true,
                delete: true,
            }),
            separateActionButton: true,
            customExtraActionButton: (record: ServerFeatureAccountProps, callback: Function) => {
                return isConnected.includes(`${record.serverId}-${record.featureId}`) ? (
                    <LoadingComponent />
                ) : (
                    <Button
                        type="text"
                        icon={<LinkOutlined />}
                        onClick={e => {
                            e.preventDefault();
                            callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "connect", data: record });
                        }}
                    />
                );
            },
        }),
        [enableUpdate, isConnected]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsModalVisible(true);
                setEditData(undefined);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsModalVisible(true);
                setEditData(FormData);
                break;
            case CALLBACK_KEY.DO_DELETE:
                deleteServerFeatureAccount(FormData);
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "connect") {
                    tryConnect(FormData.data);
                }
                break;
            default:
                break;
        }
    };

    const tryConnect = (record: any) => {
        let keyString = `${record.serverId}-${record.featureId}`;
        setIsConnected(prev => [...prev, ...[keyString]]);
        plainAxiosInstance
            .get(`${APIs.RC_SERVER_FEATURE_ACCOUNT.GET_LIST}/connectTest?serverId=${record.serverId}&featureId=${record.featureId}`)
            .then((res: any) => {
                if (res.data === 0) {
                    ErrorMessageHandler(`Server Feature Account connected successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                } else {
                    ErrorMessageHandler(`Connection failed: ${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) => {
                if (error.response) {
                    if (error.response.status === 504) {
                        ErrorMessageHandler(`Connection failed: [ Time Out Failed ].`, SUCCESS_FAILED.OTHERS_FAILED);
                    } else if (error.response.status === 500) {
                        ErrorMessageHandler(`Connection failed: [ Interval Server Error ].`, SUCCESS_FAILED.OTHERS_FAILED);
                    } else {
                        ErrorMessageHandler(`Connection failed: ${error.response.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                } else {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler(`Connection failed: [ An unexpected error occurred ].`, SUCCESS_FAILED.OTHERS_FAILED)
                    );
                }
            })
            .finally(() => setIsConnected(prev => prev.filter(x => x !== keyString)));
    };

    const handleSubmit = useCallback(
        (values: any) => {
            if (isEmptyOrNull(editData)) {
                plainAxiosInstance
                    .post(APIs.RC_SERVER_FEATURE_ACCOUNT.GET_LIST, values)
                    .then((res: any) => {
                        if (res.data === 0) {
                            ErrorMessageHandler("server feature account", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        } else {
                            ErrorMessageHandler("server feature account", SUCCESS_FAILED.FAILED_CREATE_DATA, res);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("server feature account", SUCCESS_FAILED.FAILED_CREATE_DATA, err)
                        )
                    )
                    .finally(() => getServerFeatureAccountList());
            } else {
                plainAxiosInstance
                    .put(APIs.RC_SERVER_FEATURE_ACCOUNT.GET_LIST, values)
                    .then((res: any) => {
                        if (res.data === 0) {
                            ErrorMessageHandler("server feature account", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        } else {
                            ErrorMessageHandler("server feature account", SUCCESS_FAILED.FAILED_UPDATE_DATA, res);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("server feature account", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                        )
                    )
                    .finally(() => {
                        setEditData(undefined);
                        getServerFeatureAccountList();
                    });
            }
        },
        [editData, data]
    );

    const modalCallback = useCallback(
        (type: number, values: any) => {
            switch (type) {
                case CreateEditServerFeatureAccountModalCallbackKey.Close:
                    setIsModalVisible(false);
                    break;
                case CreateEditServerFeatureAccountModalCallbackKey.Submit:
                    if (
                        isEmptyOrNull(editData) &&
                        data.some((x: any) => x["serverId"] === values["serverId"] && x["featureId"] === values["featureId"])
                    ) {
                        ErrorMessageHandler(`Server Feature Account already exists.`, SUCCESS_FAILED.OTHERS_FAILED);
                        return;
                    }

                    handleSubmit(values);
                    setIsLoading(true);
                    setIsModalVisible(false);
                    break;
                default:
                    break;
            }
        },
        [data, editData, handleSubmit]
    );

    const deleteServerFeatureAccount = (record: any) => {
        Modal.confirm({
            title: `Are you sure want to delete server feature account?`,
            onOk: () => {
                setIsLoading(true);
                plainAxiosInstance
                    .post(`${APIs.RC_SERVER_FEATURE_ACCOUNT.GET_LIST}/delete`, [{ serverId: record["serverId"], featureId: record["featureId"] }])
                    .then((res: any) => {
                        if (res.data === 0) {
                            ErrorMessageHandler("server feature account", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        } else {
                            ErrorMessageHandler("server feature account", SUCCESS_FAILED.FAILED_DELETE_DATA, res);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("server feature account", SUCCESS_FAILED.FAILED_DELETE_DATA, err)
                        )
                    )
                    .finally(() => {
                        setIsLoading(false);
                        getServerFeatureAccountList();
                    });
            },
        });
    };

    const getServerFeatureAccountList = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RC_SERVER_FEATURE_ACCOUNT.GET_LIST)
            .then((res: any) => {
                setData(res.data && res.data.length > 0 ? res.data.map((x: any) => ({ ...x, id: `${x.login}-${x.serverId}-${x.featureId}` })) : []);
            })
            .finally(() => setIsLoading(false));
    };

    const getConfig = () => {
        Promise.all([plainAxiosInstance.get(APIs.RC_ROOT.GET_SERVERS), plainAxiosInstance.get(APIs.RC_ROOT.GET_FEATURE_SETTINGS)])
            .then((res: any) => {
                setServerOptions(
                    SortList(
                        res[0].data.map((x: any) => ({ text: x.serverName, value: x.serverId })),
                        "text"
                    )
                );
                setFeatureOptions(
                    SortList(
                        res[1].data.map((x: any) => ({ text: x.featureName, value: x.featureId })),
                        "text"
                    )
                );
            })
            .finally(() => getServerFeatureAccountList());
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <>
            <div className="server-feature-account-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <CreateEditServerFeatureAccountModal
                isModalVisible={isModalVisible}
                modalTitle={isEmptyOrNull(editData) ? "Create New Server Feature Account" : "Edit Server Feature Account"}
                callback={modalCallback}
                data={editData}
                servers={serverOptions}
                features={featureOptions}
                dataList={data}
            />
        </>
    );
};

export default ServerFeatureAccountPage;
