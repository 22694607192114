import { isEmptyOrNull } from "@/utils/string";
import useRCIssuesLog, { RCIssueLogType, useRCIssuesLogMutate } from "@/hooks/useRCIssuesLog";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Typography, Space, Button, notification, Modal, Tag } from "antd";
import moment from "moment";
import { useMemo, useState } from "react";
import MessageCard from "../components/MessageCard";
import { capsToTitleCase } from "../StatusMonitor/statusUtils";
import { filterAllColsClientTable } from "@/utils/array";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { DTColProps } from "@/utils/Common";
import { CALLBACK_KEY } from "@/constants";

const { TextArea } = Input;
const { Text } = Typography;

interface MassagedDataProps {
    massagedData: any[];
    massagedDataBySearchCateria: any[];
}

const SortingCallback = (a: any, b: any, key: string) => {
    // sort alphabetically and length
    if (`${a[key] || ""}`.toLowerCase() < `${b[key] || ""}`.toLowerCase()) {
        return -1;
    }
    if (`${a[key] || ""}`.toLowerCase() > `${b[key] || ""}`.toLowerCase()) {
        return 1;
    }
    return 0;
};

const CommonIssuesLogTable = ({ type }: { type: RCIssueLogType }) => {
    const { rcIssuesLog, isLoading, rcIssuesLogError } = useRCIssuesLog(type);
    const { checkIssueLog, doneIssueLog, updateCommentIssueLog } = useRCIssuesLogMutate({ type });

    const [currEditCommentRowID, setCurrEditCommentRowID] = useState<string[]>([]);
    const [textAreaValue, setTextAreaValue] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [openCheckModal, setOpenCheckModal] = useState(false);
    const [openDoneModal, setOpenDoneModal] = useState(false);

    const filteredDatas: MassagedDataProps = useMemo(() => {
        let tmpData = isEmptyOrNull(rcIssuesLog)
            ? []
            : rcIssuesLog.map((item: any, idx: number) => ({
                  ...item,
                  key: `${item.id}`,
              }));

        return {
            massagedData: tmpData,
            massagedDataBySearchCateria: !isEmptyOrNull(searchQuery) ? filterAllColsClientTable(tmpData, searchQuery) : tmpData,
        };
    }, [rcIssuesLog, searchQuery]);

    const canDone: boolean = useMemo(
        () => !filteredDatas.massagedData.some(x => selectedRowKeys.includes(x.key) && x.checkTime === undefined),
        [selectedRowKeys, filteredDatas]
    );

    const columns2 = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a: any, b: any) => SortingCallback(a, b, "server"),
        }),
        DTColProps.Small({
            title: "Time",
            dataIndex: "time",
            key: "time",
            sorter: (a: any, b: any) => SortingCallback(a, b, "time"),
        }),
        DTColProps.Small({
            title: "IP",
            dataIndex: "ip",
            key: "ip",
            sorter: (a: any, b: any) => SortingCallback(a, b, "ip"),
        }),
        {
            title: "Message",
            dataIndex: "msg",
            key: "msg",
            sorter: (a: any, b: any) => SortingCallback(a, b, "msg"),
        },
        DTColProps.Small({
            title: "Check User",
            dataIndex: "checkUser",
            key: "checkUser",
            sorter: (a: any, b: any) => SortingCallback(a, b, "checkUser"),
        }),
        DTColProps.Small({
            title: "Check Time",
            dataIndex: "checkTime",
            key: "checkTime",
            render: (dateTime: any) => (dateTime ? moment(dateTime).format("YYYY-MM-DD HH:mm:ss") : ""),
            sorter: (a: any, b: any) => SortingCallback(a, b, "checkTime"),
        }),
        DTColProps.Middle({
            title: "Comments",
            dataIndex: "comment",
            key: "comment",
            render: (text: any, data: any) => {
                return (
                    <Space size={1}>
                        <Button
                            type="link"
                            onClick={() => {
                                setCurrEditCommentRowID([data.id]);
                                setTextAreaValue(text);
                            }}
                            icon={<EditOutlined />}
                        />
                        {text}
                    </Space>
                );
            },
            sorter: (a: any, b: any) => SortingCallback(a, b, "comment"),
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            enableFilter: false,
            showHideColumns: false,
            enableRowSelection: true,
            hideRowSelectionsSummary: true,
            rowSelectionData: {
                rowSelectionType: "checkbox",
                selectedRowKeys: selectedRowKeys,
                options: {
                    fixed: "left",
                },
            },
        }),
        [selectedRowKeys]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowKeys(FormData.selectedRowKeys);
                break;
            default:
                break;
        }
    };

    const handleCheck = async () => {
        try {
            const resp = await checkIssueLog({ ids: selectedRowKeys });
            if (resp) {
                setSelectedRowKeys([]);
                setOpenCheckModal(false);
                notification.success({
                    message: "Success Checked",
                    description: `Selected Alarm are checked.`,
                });
            }
        } catch (error) {
            console.log("Error in checking alarm", error);
            notification.error({
                message: "Check failed",
                description: `Selected Alarm are not checked. Error: ${error}`,
            });
        }
    };

    const handleDone = async () => {
        try {
            const resp = await doneIssueLog({ ids: selectedRowKeys });
            if (resp) {
                setSelectedRowKeys([]);
                setOpenDoneModal(false);
                notification.success({
                    message: "Success marked as done",
                    description: `Selected Alarm are marked as done.`,
                });
            }
        } catch (error) {
            console.log("Error in marking alarm as done", error);
            notification.error({
                message: "Failed to mark as done",
                description: `Selected Alarm are not marked as done. Error: ${error}`,
            });
        }
    };

    const handleUpdateComment = async () => {
        try {
            const resp = await updateCommentIssueLog({
                ids: currEditCommentRowID,
                comment: textAreaValue,
            });

            if (resp.status === 200 && resp.data > 0) {
                setTextAreaValue("");
                setCurrEditCommentRowID([]);
                notification.success({
                    message: "Comment updated",
                    description: "Comment updated successfully",
                });
            }
        } catch (err) {
            console.log("Error in handleUpdateComment", err);
            notification.error({
                message: "Failed to update comment",
                description: `Failed to update comment. Error: ${err}`,
            });
        }
    };

    return (
        <>
            <div className="common-issues-log-table-container">
                <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
                    {isLoading ? (
                        <MessageCard type="info">Loading...</MessageCard>
                    ) : rcIssuesLogError ? (
                        <MessageCard type="error">{rcIssuesLogError}</MessageCard>
                    ) : (
                        <>
                            {filteredDatas.massagedData.length === 0 ? (
                                <MessageCard type="success">All good</MessageCard>
                            ) : (
                                <MessageCard type={"error"}>
                                    {filteredDatas.massagedData?.length} {capsToTitleCase(type)} Alarm
                                </MessageCard>
                            )}
                            <div className="search-panel">
                                <div className="left">
                                    <Input
                                        prefix={<SearchOutlined />}
                                        placeholder="Search Server, message"
                                        onChange={e => setSearchQuery(e.target.value)}
                                        allowClear
                                    />
                                </div>
                                <div className="right">
                                    <Button danger disabled={selectedRowKeys.length <= 0} onClick={() => setOpenCheckModal(true)}>
                                        Mark as Checked
                                    </Button>
                                    <Button danger disabled={selectedRowKeys.length <= 0 || !canDone} onClick={() => setOpenDoneModal(true)}>
                                        Mark as Done
                                    </Button>
                                    <Button danger disabled={selectedRowKeys.length <= 0} onClick={() => setCurrEditCommentRowID(selectedRowKeys)}>
                                        Mass Edit Comments
                                    </Button>
                                </div>
                            </div>
                            <FlexiDataTable
                                bordered
                                rowKeyProperty="key"
                                title={false}
                                columns={columns2}
                                options={options}
                                callback={componentCallback}
                                dataSource={filteredDatas.massagedDataBySearchCateria}
                                pagination={{
                                    defaultPageSize: 20,
                                    ...(type === "ACCOUNT_MONITOR" && {
                                        pageSizeOptions: [10, 20, 50, 100, 1000],
                                    }),
                                }}
                                loading={isLoading}
                            />
                        </>
                    )}
                </Space>
            </div>
            <Modal
                title={"Mark alarm as checked"}
                open={openCheckModal}
                onCancel={() => setOpenCheckModal(false)}
                onOk={() => handleCheck()}
                okText="Confirm mark as checked"
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to mark selected alarm as checked?</p>
                <div>
                    {selectedRowKeys.map(each => {
                        return <Tag key={each}>{each}</Tag>;
                    })}
                </div>
            </Modal>
            <Modal
                title={"Mark alarm as done"}
                open={openDoneModal}
                onCancel={() => setOpenDoneModal(false)}
                onOk={() => handleDone()}
                okText="Confirm mark as done"
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to mark selected alarm as done?</p>
                <div>
                    {selectedRowKeys.map(each => {
                        return <Tag key={each}>{each}</Tag>;
                    })}
                </div>
            </Modal>
            <Modal
                title={"Edit Comment"}
                open={currEditCommentRowID?.length >= 1}
                onCancel={() => {
                    setCurrEditCommentRowID([]);
                    setTextAreaValue("");
                }}
                onOk={() => handleUpdateComment()}
            >
                <Space direction="vertical" style={{ width: "100%" }} size={16}>
                    <div>
                        <Text strong>Current editing IDs:</Text>
                        <div>
                            {currEditCommentRowID.map(each => {
                                return <Tag key={each}>{each}</Tag>;
                            })}
                        </div>
                    </div>
                    <div>
                        <Text strong>Comments</Text>
                        <TextArea
                            value={textAreaValue}
                            onChange={e => {
                                setTextAreaValue(e.target.value);
                            }}
                            rows={4}
                            placeholder="Enter comment here"
                        />
                    </div>
                </Space>
            </Modal>
        </>
    );
};

export default CommonIssuesLogTable;
