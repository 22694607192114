import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ToObjectWithKey, ToOptionTypeList } from "@/utils/array";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import ServerArchivingToolUploadFileModal, { ServerArchivingToolUploadFileModalCallbackKey } from "./components/uploadFileModal";
import moment from "moment";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { DefaultIfEmpty } from "@/utils/object";

export interface ServerArchivingToolProps {}

interface ServerArchivingToolDataProps {
    key?: string;
    batchId: number;
    archiveDate: string;
    createUser: null | string;
    deletedTradeNum: number;
    errorMessage: null | string;
    login: number;
    pnl: number;
    serverUno: number;
    serverName: string;
    status: number;
    uploadDateTime: string;
}

const STATUS: { [key: number]: string } = {
    0: "Initial",
    1: "In Progress",
    2: "Archived",
    3: "Fail",
    4: "Error",
};

const ServerArchivingTool = () => {
    const intervalInSecond = 5;
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [data, setData] = useState<ServerArchivingToolDataProps[]>([]);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [filterParams, setFilterParams] = useState<any>({
        startTime: moment().format("YYYY-MM-DD 00:00:00"),
        endTime: moment().add(1, "day").format("YYYY-MM-DD 00:00:00"),
        statusList: Object.keys(STATUS).map(x => parseInt(x)),
    });
    const [currentBatchId, setCurrentBatchId] = useState<string | undefined>(undefined);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RC_SERVER_ARCHIVING_TOOL_EDIT);

    const serverObj = useMemo(() => ToObjectWithKey(servers, "value", "text"), [servers]);

    const enableRefresh = useMemo(
        () => data && data.length > 0 && data.some((x: ServerArchivingToolDataProps) => x.status === 0 || x.status === 1),
        [data]
    );

    const columns: any[] = [
        DTColProps.Small({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
        }),
        DTColProps.Small({
            title: "Login Id",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.Small({
            title: "Archive Date",
            dataIndex: "archiveDate",
            key: "archiveDate",
            options: {
                filter: {
                    type: ComponentType.date,
                    value: "",
                    dateFormat: "YYYY-MM-DD",
                },
            },
        }),
        DTColProps.Small(
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (text: number) => {
                    switch (text) {
                        case 2:
                            return <span className="text-color-success">{STATUS[text]}</span>;
                        case 0:
                        case 1:
                            return text === 1 ? <Badge status="processing" text={STATUS[text]} /> : STATUS[text];
                        case 3:
                        case 4:
                            return <span className="text-color-error">{STATUS[text]}</span>;
                        default:
                            return text;
                    }
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: ToOptionTypeList(STATUS),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            },
            ["text-center"]
        ),
        DTColProps.Middle({
            title: "Description",
            dataIndex: "errorMessage",
            key: "errorMessage",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.XSmall(
            {
                title: "Pre-Count",
                dataIndex: "preCount",
                key: "preCount",
                render: (text: number) => currencyRender(text),
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Deleted Trade(s)",
                dataIndex: "deletedTradeNum",
                key: "deletedTradeNum",
                render: (text: number) => currencyRender(text),
            },
            ["text-center"]
        ),
        DTColProps.SCurrency({
            title: "Balance of Archived Trades",
            dataIndex: "pnl",
            key: "pnl",
        }),
        DTColProps.Small({
            title: "Created By",
            dataIndex: "createUser",
            key: "createUser",
        }),
        DTColProps.Middle({
            width: "8vw",
            title: "Created At",
            dataIndex: "uploadDateTime",
            key: "uploadDateTime",
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: [],
                    rules: [{ required: true, message: REQUIRED_FIELD }],
                    inputProps: {
                        allowClear: false,
                        dateOnly: true,
                    },
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            separateActionButton: true,
            serverFiltering: true,
            extraButtons: [
                ...(enableUpdate ? [{ icon: <UploadOutlined />, text: "Upload", value: "uploadExcel" }] : []),
                { icon: <DownloadOutlined />, text: "Download Template", value: "downloadTemplate" },
            ],
            rowExtra: [{ text: "", icon: <DownloadOutlined />, value: "downloadTradeReport" }],
            ...(isEmptyOrNull(currentBatchId) && {
                export: {
                    text: "Export to Excel",
                },
            }),
            ...(enableRefresh && {
                refresh: {
                    timer: true,
                    refreshSecond: intervalInSecond,
                },
            }),
        }),
        [enableRefresh, currentBatchId]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type, formData: any) => {
        switch (type) {
            case CALLBACK_KEY.OTHERS:
                if (formData === "uploadExcel") {
                    setIsModalVisible(true);
                } else if (formData === "downloadTemplate") {
                    downloadExcelTemplate();
                }
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                setFilterParams(
                    Object.keys(formData)
                        .filter(x => !isEmptyOrNull(formData[x]))
                        .reduce((acc: any, x: string) => {
                            if (x === "uploadDateTime") {
                                acc["startTime"] = moment(formData[x][0]).format("YYYY-MM-DD 00:00:00");
                                acc["endTime"] = moment(formData[x][1]).add(1, "day").format("YYYY-MM-DD 00:00:00");
                            } else if (x === "archiveDate") {
                                acc[x] = moment(formData[x]).format("YYYY-MM-DD");
                            } else if (x === "status") {
                                acc["statusList"] = formData[x];
                            } else if (x === "errorMessage") {
                                acc["description"] = formData[x];
                            } else {
                                acc[x] = formData[x];
                            }
                            return acc;
                        }, {})
                );
                setCurrentBatchId(undefined);
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                exportToExcel();
                break;
            case CALLBACK_KEY.REFRESH:
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (formData.key === "downloadTradeReport") {
                    downloadTradeReport({ serverUno: formData.data.serverUno, login: formData.data.login, batchId: formData.data.batchId });
                }
                break;
            default:
                break;
        }
    };

    const downloadTradeReport = useCallback(
        (values: any) => {
            setIsLoading(true);
            plainAxiosInstance
                .get(`${APIs.RC_SERVER_ARCHIVING_TOOL.DOWNLOAD_TRADE_REPORT}?${new URLSearchParams(values).toString()}`, {
                    headers: {
                        Accept: "application/octet-stream,text/csv, */*",
                    },
                    responseType: "blob",
                })
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const fileName = getFileNameFromResponseHeader(
                        response,
                        `server_archiving_tool_trade_report_[${DefaultIfEmpty(serverObj, values.serverUno, values.serverUno)}, ${values.login}].csv`
                    );
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "text/csv;charset=UTF-8" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) => {
                    ErrorCatchValidator(error, () => ErrorMessageHandler("No trade report found.", SUCCESS_FAILED.OTHERS_FAILED));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [serverObj]
    );

    const downloadExcelTemplate = () => {
        plainAxiosInstance
            .get(APIs.RC_SERVER_ARCHIVING_TOOL.DOWNLOAD_EXCEL_TEMPLATE)
            .then(res => {
                if (res.status !== 200) throw new Error("Failed to download data");
                const contentHeader = res.headers["content-disposition"];
                const fileName = contentHeader ? contentHeader.split("filename=")[1] : `server_archiving_tool_upload_template.csv`;

                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                link.setAttribute("type", "hidden");

                document.body.appendChild(link);
                link.click();
                //clean up
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`server archiving template`, SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
            });
    };

    const exportToExcel = useCallback(() => {
        plainAxiosInstance
            .get(`${APIs.RC_SERVER_ARCHIVING_TOOL.EXPORT_TO_EXCEL}?${new URLSearchParams(filterParams).toString()}`, {
                headers: {
                    Accept: "application/octet-stream,text/csv, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                const contentType = response.headers["content-type"];
                const fileName = getFileNameFromResponseHeader(response, `server_archiving-tool-${moment().format("YYYYMMDD_HHmmss")}.csv`);
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "text/csv;charset=UTF-8" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                } else {
                    ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch(err => {
                ErrorMessageHandler(`Download error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
            });
    }, [filterParams]);

    const getDataList = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(
                isEmptyOrNull(currentBatchId)
                    ? `${APIs.RC_SERVER_ARCHIVING_TOOL.GET_DATA_LIST}?${new URLSearchParams(filterParams).toString()}`
                    : `${APIs.RC_SERVER_ARCHIVING_TOOL.GET_DATA_LIST}/${currentBatchId}`
            )
            .then(res => {
                setData(
                    res.data && res.data.length > 0
                        ? res.data.map((x: ServerArchivingToolDataProps) => ({ ...x, key: `${x.archiveDate}-${x.login}-${x.uploadDateTime}` }))
                        : []
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [filterParams, currentBatchId]);

    const getConfig = () => {
        plainAxiosInstance
            .get(APIs.RC_SERVER_ARCHIVING_TOOL.GET_SERVER)
            .then(res => {
                if (res.data) {
                    setServers(res.data.map((x: any) => ({ text: x.label, value: x.value })));
                }
            })
            .finally(() => setRunRefetchDataList(true));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getDataList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList, getDataList]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <>
            <div className="server-archiving-tool-container">
                <CardBox title={"Server Archiving Tool"}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="key"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={data}
                        callback={componentCallback}
                        loading={isLoading}
                        filterInitialValue={{
                            uploadDateTime: [moment(), moment()],
                            status: Object.keys(STATUS).map(x => parseInt(x)),
                        }}
                    />
                </CardBox>
            </div>
            <ServerArchivingToolUploadFileModal
                isModalVisible={isModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case ServerArchivingToolUploadFileModalCallbackKey.Close:
                            setIsModalVisible(false);
                            break;
                        case ServerArchivingToolUploadFileModalCallbackKey.Submitting:
                            setIsModalVisible(false);
                            setIsLoading(true);
                            break;
                        case ServerArchivingToolUploadFileModalCallbackKey.SubmitSuccess:
                            setCurrentBatchId(`${data}`);
                            setFilterParams({ batchId: data });
                            setRunRefetchDataList(true);
                            break;
                        case ServerArchivingToolUploadFileModalCallbackKey.SubmitFailed:
                            setIsLoading(false);
                            break;
                        default:
                            break;
                    }
                }}
                serverList={servers}
            />
        </>
    );
};

export default ServerArchivingTool;
