import TinyTag from "@/components/Common/Tag";
import FlexiDataTable from "@/components/FlexiDataTable";
import { InnerPageActionMode, ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { CreateEditInlineFormBased, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, MRGroupList } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { DTColProps, ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { useState, useEffect, useMemo } from "react";
import MRGroupConfigEditPage from "./editPage";

const MRGroupConfig = () => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [MRGroupList, setMRGroupList] = useState<MRGroupList[]>([]);
    const [refetch, setRefetch] = useState<number>(0);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_MR_GROUP_SETTINGS_EDIT);

    const columns = useMemo(
        () => [
            DTColProps.Large({
                title: "Market Group",
                dataIndex: "marketGroup",
                key: "marketGroup",
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            }),
            {
                title: "Market Brands",
                dataIndex: "mrBrand",
                key: "mrBrand",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                        callback: (filterValue: string, rowData: any) => {
                            const marketBrands = rowData.mrBrands.map((brand: any) => brand.marketBrand);
                            return marketBrands.some((brand: string) => brand.toLowerCase().includes(filterValue.toLowerCase()));
                        },
                    },
                },
                render: (mrBrand: any, record: MRGroupList) => {
                    let sortedArray = [...record.mrBrands];
                    sortedArray.sort((a, b) => a.marketBrand.toLowerCase().localeCompare(b.marketBrand.toLowerCase()));
                    return sortedArray.map((x, index: number) => <TinyTag key={`${x.marketBrand}-${index}`} text={x.marketBrand} />);
                },
            },
            DTColProps.Status({
                title: "Status",
                dataIndex: "isEnabled",
                key: "isEnabled",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [
                            { text: "Active", value: true },
                            { text: "Inactive", value: false },
                        ],
                    },
                },
            }),
        ],
        [MRGroupList]
    );

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, rowData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: rowData["marketGroupId"] });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                apiRequest(APIs.DELETE_MR_GROUP, { marketGroupId: rowData["marketGroupId"] })
                    .then(res => {
                        ErrorMessageHandler("MR Group configuration", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setTimeout(() => getMRGroupListing(), 300);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("MR Group configuration", SUCCESS_FAILED.FAILED_DELETE_DATA, err)
                        )
                    );
                break;
            default:
                break;
        }
    };

    const getMRGroupListing = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_MR_GROUP_LIST, {})
            .then((res: any) => {
                setMRGroupList(res && res.length > 0 ? res : []);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("MR group list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getMRGroupListing();
        return () => {};
    }, []);

    return (
        <>
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="marketGroupId"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={MRGroupList}
                        callback={componentCallback}
                        loading={isLoading}
                    />
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    <MRGroupConfigEditPage
                        {...formObject}
                        callback={(action: InnerPageActionMode, value: any) => {
                            switch (action) {
                                case InnerPageActionMode.BACK:
                                    setIsFirstOpt(prev => !prev);
                                    if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                        getMRGroupListing();
                                    }
                                    break;
                            }
                        }}
                        resetState={refetch}
                    />
                </div>
            </div>
        </>
    );
};

export default MRGroupConfig;
