import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { Form, message, Modal, Row, Col } from "antd";
import moment from "moment";
import { useMemo, useCallback, useEffect } from "react";

interface Step4DownloadModalProps {}

interface Step4DownloadModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    servers: KeyValuePair[];
    batchId: string;
}

export enum Step4DownloadModalCallbackKey {
    Close = 0,
}

const Step4DownloadModal = (props: Step4DownloadModalProps) => {
    const [executeForm] = Form.useForm();

    const filteredServers = useMemo(
        () => (props.servers && props.servers.length > 0 ? props.servers.filter(x => !isEmptyOrNull(x.value)) : []),
        [props.servers]
    );

    const confirmExecute = useCallback(
        (serverId: string) => {
            try {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_DOWNLOAD_SUPPLEMENTARY_DEDUCTION}?batchId=${props.batchId}&serverId=${serverId}&type=2`, {
                        headers: {
                            Accept: "application/octet-stream,text/csv, */*",
                        },
                        responseType: "blob",
                    })
                    .then(response => {
                        const contentType = response.headers["content-type"];
                        const fileName = getFileNameFromResponseHeader(response, `DownloadDeduction_${moment().format("YYYYMMDDHHmmss")}.csv`);

                        if (
                            contentType === "application/octet-stream" ||
                            contentType === "text/csv" ||
                            contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ) {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", fileName);
                            document.body.appendChild(link);
                            link.click();
                            // Clean up
                            window.URL.revokeObjectURL(url);
                        } else {
                            ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler(`Error occurred during download`, SUCCESS_FAILED.OTHERS_FAILED, err)
                        )
                    );
            } catch (e: any) {
                ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
            }
        },
        [props.batchId]
    );

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldsValue({ server: filteredServers.length > 0 ? filteredServers[0].value : "" });
        }
    }, [props.isModalVisible, filteredServers]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"25vw"}
            title={"Download CSV (Not Deducted)"}
            open={props.isModalVisible}
            okText="Download"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        confirmExecute(values.server);
                        props.callback(Step4DownloadModalCallbackKey.Close, null);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(Step4DownloadModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="vertical">
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Server"
                            name="server"
                            extra={{
                                type: ComponentType.dropdown,
                                value: filteredServers,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default Step4DownloadModal;
