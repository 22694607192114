import useRCMetaTraderMonitors, {
    useRCMetaTraderMonitorChecked,
    useRCMetaTraderMonitorDone,
    useRCMetaTraderMonitorComment,
} from "@/hooks/useRCMetaTraderMonitors";
import { EditOutlined } from "@ant-design/icons";
import { Input, Typography, notification, Space, Tooltip, Tag, Button, Row, Col, Table, Modal } from "antd";
import { useState } from "react";
import Loader from "../components/Loader";
import MessageCard from "../components/MessageCard";
import ServerTimeWidget from "../components/ServerTimeWidget";
import { colorMap } from "../StatusMonitor/statusUtils";

const MetaTraderMonitorTable = () => {
    const { rcMetaTraderMonitors, isLoading } = useRCMetaTraderMonitors();
    const { checkMonitor } = useRCMetaTraderMonitorChecked();
    const { doneMonitor } = useRCMetaTraderMonitorDone();
    const { adjustComment } = useRCMetaTraderMonitorComment();
    const { TextArea } = Input;
    const { Title } = Typography;
    const [textAreaValue, setTextAreaValue] = useState("");

    const [currEditCommentRowID, setCurrEditCommentRowID] = useState<number | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openDoneModal, setOpenDoneModal] = useState(false);
    const [doneTextAreaValue, setDoneTextAreaValue] = useState("");

    const handleUpdateComment = async () => {
        try {
            const resp = await adjustComment({
                sno: currEditCommentRowID as number,
                comment: textAreaValue,
            });

            console.log(resp);
            if (resp.status === 200 && resp.data === 1) {
                setTextAreaValue("");
                setCurrEditCommentRowID(null);
            }
        } catch (err) {
            console.log("Error in handleUpdateComment", err);
        }
    };

    const handleCheck = async () => {
        try {
            const resp = await checkMonitor(selectedRowKeys);
            if (resp) {
                setSelectedRowKeys([]);
                notification.success({
                    message: "Check Success",
                    description: `Selected Monitors are checked.`,
                });
            }
        } catch (error) {
            console.log("Error in handleCheck", error);
            notification.error({
                message: "Check Failed",
                description: `Selected Monitors are not checked. Error: ${error}`,
            });
        }
    };

    const handleDone = async () => {
        try {
            const resp = await doneMonitor({ sno: selectedRowKeys, doneLog: doneTextAreaValue });
            if (resp) {
                setDoneTextAreaValue("");
                setSelectedRowKeys([]);
                notification.success({
                    message: "Done Success",
                    description: `Selected Monitors are done.`,
                });
            }
        } catch (error) {
            console.log("Error in handleDone", error);
            notification.error({
                message: "Done Failed",
                description: `Selected Monitors are not done. Error: ${error}`,
            });
        }
    };

    const onSelectChange = (selectedKeys: any) => {
        setSelectedRowKeys(selectedKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // massage data with unique key
    const massagedData =
        rcMetaTraderMonitors?.map((item: any, index: number) => {
            return {
                ...item,
                idx: item.sno,
            };
        }) ?? [];

    const columns = [
        {
            title: "Type",
            dataIndex: "type",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.type?.toLowerCase() < b.type?.toLowerCase()) {
                    return -1;
                }
                if (a.type?.toLowerCase() > b.type?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.server?.toLowerCase() < b.server?.toLowerCase()) {
                    return -1;
                }
                if (a.server?.toLowerCase() > b.server?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
            render: (text: string, data: any) => {
                return (
                    <Space>
                        <span>{text}</span>
                        {data.checkTime && (
                            <Tooltip
                                title={
                                    <>
                                        Last Checked:
                                        <br />
                                        {data.checkTime}
                                    </>
                                }
                            >
                                <Tag color="green">Checked</Tag>
                            </Tooltip>
                        )}
                    </Space>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
                    return -1;
                }
                if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            // able to reorder
            sorter: (a: any, b: any) => a.status - b.status,
            render: (text: number, data: any) =>
                text === 0 ? <Tag color={colorMap.success}>connected</Tag> : <Tag color={colorMap.error}>disconnected</Tag>,
        },
        {
            title: "Start Time",
            dataIndex: "startTime",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.startTime?.toLowerCase() < b.startTime?.toLowerCase()) {
                    return -1;
                }
                if (a.startTime?.toLowerCase() > b.startTime?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "End Time",
            dataIndex: "endTime",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.endTime?.toLowerCase() < b.endTime?.toLowerCase()) {
                    return -1;
                }
                if (a.endTime?.toLowerCase() > b.endTime?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Check Time",
            dataIndex: "checkTime",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.checkTime?.toLowerCase() < b.checkTime?.toLowerCase()) {
                    return -1;
                }
                if (a.checkTime?.toLowerCase() > b.checkTime?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Comment",
            dataIndex: "comment",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.comment?.toLowerCase() < b.comment?.toLowerCase()) {
                    return -1;
                }
                if (a.comment?.toLowerCase() > b.comment?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
            render: (text: string, data: any) => {
                return (
                    <Space size={1}>
                        <Button
                            type="link"
                            onClick={() => {
                                setCurrEditCommentRowID(data.sno);
                                setTextAreaValue(text);
                            }}
                            icon={<EditOutlined />}
                        />
                        {text}
                    </Space>
                );
            },
        },
    ];

    return (
        <Space style={{ padding: "1rem", width: "100%" }} direction="vertical">
            {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}> */}
            <Row style={{ alignItems: "center" }}>
                <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 0 }}>
                    <Title level={4}>Meta Trader Server Monitor</Title>
                </Col>
                <Col style={{ marginLeft: "auto" }} xs={{ order: 0 }} md={{ order: 1 }}>
                    <ServerTimeWidget />
                </Col>
            </Row>
            {/* </div> */}
            <Space>
                <Button disabled={selectedRowKeys.length <= 0} onClick={() => handleCheck()}>
                    Checked
                </Button>
                <Button disabled={selectedRowKeys.length <= 0} onClick={() => setOpenDoneModal(true)}>
                    Done
                </Button>
            </Space>
            {!rcMetaTraderMonitors && isLoading ? (
                <Loader />
            ) : (
                <Space direction="vertical" style={{ width: "100%" }}>
                    {rcMetaTraderMonitors?.length === 0 ? (
                        <MessageCard type="success">All good</MessageCard>
                    ) : (
                        <MessageCard type="error">
                            {rcMetaTraderMonitors?.length} disconnected{rcMetaTraderMonitors?.length > 1 ? "s" : ""}
                        </MessageCard>
                    )}

                    <Table
                        columns={columns}
                        dataSource={massagedData}
                        rowKey={record => record.sno}
                        loading={!rcMetaTraderMonitors && isLoading}
                        pagination={false}
                        rowSelection={rowSelection}
                        size="small"
                        style={{ width: "100%" }}
                    />
                </Space>
            )}
            <Modal
                title={"Edit Comment"}
                open={currEditCommentRowID !== null}
                onCancel={() => {
                    setCurrEditCommentRowID(null);
                    setTextAreaValue("");
                }}
                onOk={() => handleUpdateComment()}
            >
                <TextArea
                    value={textAreaValue}
                    onChange={e => {
                        setTextAreaValue(e.target.value);
                    }}
                    rows={4}
                />
            </Modal>
            <Modal
                title={"Done"}
                open={openDoneModal}
                onCancel={() => {
                    setDoneTextAreaValue("");
                    setOpenDoneModal(false);
                }}
                onOk={() => handleDone()}
            >
                <TextArea
                    value={doneTextAreaValue}
                    onChange={e => {
                        setDoneTextAreaValue(e.target.value);
                    }}
                    placeholder="Enter Done Log here..."
                    rows={4}
                />
            </Modal>
        </Space>
    );
};

export default MetaTraderMonitorTable;
