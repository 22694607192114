import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Drawer, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Module, TreeItemProps } from "../../constants/type";
import { useLocation, useNavigate } from "react-router-dom";
import ViewUserManual from "../../pages/SiteAdminModule/Configuration/UserManualConfiguration/ViewUserManual";
import AuthHelper from "../../helpers/authHelper";
import { getTreeList } from "@/pages/CentralizedSettingsPanel/components/Administration/RolePermission/ViewRolePage";

const UserManualDrawer = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const [open, setOpen] = useState<boolean>(false);
    const [manualLoading, setManualLoading] = useState<boolean>(true);
    const [modules, setModules] = useState<TreeItemProps[]>([]);

    const getConfigInfo = () => {
        let auth = new AuthHelper();
        let sortedData: Module[] = auth.getAuthModules().filter((x) => `${x.type}` === "1");
        sortedData.sort((a: Module, b: Module) => a.order - b.order);
        setModules(getTreeList(sortedData));
        setManualLoading(false);
        // apiRequest(APIs.GET_MENU_LISTING, {})
        //     .then((data: any) => {
        //         let sortedData: Module[] = data as Module[];
        //         sortedData.sort((a: Module, b: Module) => a.order - b.order);
        //         setModules(getTreeList(sortedData));
        //         setManualLoading(false);
        //     })
        //     .catch((err) => {
        //         ErrorMessageHandler("role listing", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
        //         navigate("/siteadmin/rolepermission");
        //     });
    };

    useEffect(() => {
        getConfigInfo();
    }, []);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const goToUserManualListing = () => {
        navigate("/system/usermanual");
        setOpen(false);
    };

    return (
        <>
            <Drawer
                title="User Manual"
                placement="right"
                onClose={onClose}
                open={open}
                width={600}
                className="user-manual-drawer"
                extra={
                    <Button type="link" onClick={goToUserManualListing}>
                        See All
                    </Button>
                }
            >
                <ViewUserManual />
            </Drawer>
            <Tooltip title="User Manual" placement="bottom">
                <Button
                    shape="circle"
                    size="middle"
                    className="notification-button"
                    icon={<QuestionCircleOutlined className="notif-icon" />}
                    onClick={() => showDrawer()}
                />
            </Tooltip>
        </>
    );
};

export default UserManualDrawer;
