import { Form, Row, Col } from "antd";
import { useState, useEffect, useMemo, useCallback } from "react";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import { InnerPageActionMode, SUCCESS_FAILED, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CreateEditInlineFormBased, KeyValuePair, SymbolAssetTypesList } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";

interface CleanSymbolCreateEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
    symbolAssetTypeOptions: KeyValuePair[];
    symbolScope: number;
}

const initialValues = {
    cleanSymbol: "",
    symbolAssetTypeId: "",
};

const CleanSymbolCreateEditPage = (props: CleanSymbolCreateEditPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cleanSymbolForm] = Form.useForm();

    const symbolScopeProps = useMemo(() => ({ symbolScope: props.symbolScope }), [props.symbolScope]);

    const stateInfo = useMemo(() => {
        return {
            isAddAction: props.mode === InnerPageActionMode.CREATE_NEW,
            data: defaultIfEmptyOrNull(props.id, ""),
        };
    }, [props.mode, props.id]);

    const processBackAndRefetch = () => setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);

    const onSubmit = useCallback(
        (values: any) => {
            setIsLoading(true);
            if (stateInfo.isAddAction) {
                apiRequest(APIs.CREATE_CLEAN_SYMBOL, { ...values, ...symbolScopeProps })
                    .then(() => {
                        ErrorMessageHandler("New clean symbol", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new clean symbol", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                    )
                    .finally(() => setTimeout(() => setIsLoading(false), 500));
            } else {
                apiRequest(APIs.UPDATE_CLEAN_SYMBOL, { ...values, ...symbolScopeProps })
                    .then(() => {
                        ErrorMessageHandler("Existing clean symbol", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing clean symbol", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    )
                    .finally(() => setTimeout(() => setIsLoading(false), 500));
            }
        },
        [stateInfo, symbolScopeProps]
    );

    const getCleanSymbolList = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_CLEAN_SYMBOL_LISTING, { cleanSymbols: [stateInfo.data], ...symbolScopeProps })
            .then((data: any) => {
                if (data && data.length > 0) {
                    cleanSymbolForm.setFieldsValue(data[0]);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("clean symbol", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                processBackAndRefetch();
            })
            .finally(() => setIsLoading(false));
    }, [stateInfo, symbolScopeProps]);

    useEffect(() => {
        if (!stateInfo.isAddAction) {
            getCleanSymbolList();
        } else {
            cleanSymbolForm.setFieldsValue(initialValues);
        }
    }, [stateInfo, getCleanSymbolList, props.resetState]);

    return (
        <SitePageHeaderInline
            enableSubmit={!isLoading}
            title={stateInfo.isAddAction ? "Create New Clean Symbol" : "Edit Clean Symbol"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        cleanSymbolForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={cleanSymbolForm}
                    layout="horizontal"
                    initialValues={{}}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label="" name="isMainStream" extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Clean Symbol"
                                name="cleanSymbol"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    ...(!stateInfo.isAddAction && {
                                        inputProps: {
                                            readOnly: true,
                                        },
                                    }),
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Symbol Asset Type"
                                name="symbolAssetTypeId"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: props.symbolAssetTypeOptions,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
};

export default CleanSymbolCreateEditPage;
