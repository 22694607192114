import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useState } from "react";
import MRGroupConfig from "./MRGroupSettings";
import MRServerSettings from "./MRServerSettings";

interface MRGroupingSettingsPageProps {}

const MRGroupingSettingsPage = (props: MRGroupingSettingsPageProps) => {
    const [segmentVal, setSegmentVal] = useState<string>("MR Group");

    return (
        <div className="settings-panel-main-segment-panel-container">
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>MR Group Settings</span>
                </div>
            </div>
            <div className="top-panel">
                <Segmented
                    options={["MR Group", "MR Server Group"]}
                    value={segmentVal}
                    onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                />
            </div>
            <div className="container">
                {segmentVal === "MR Group" && <MRGroupConfig />}
                {segmentVal === "MR Server Group" && <MRServerSettings />}
            </div>
        </div>
    );
};

export default MRGroupingSettingsPage;
