import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import { Empty } from "antd";
import { replaceZeroToDomainName } from "../../utils/array";
import ReportingResultData from "../../assets/data/reportingResultData.json";
import ReportingTemplateData from "../../assets/data/reportingTemplateData.json";
import RoleAndPermissionData from "../../assets/data/roleAndPermissionData.json";
import UserManagementData from "../../assets/data/userManagementData.json";
import AppConfigurationData from "../../assets/data/appConfigurationData.json";
import AccountListData from "../../assets/data/accountListData.json";
import AccountWatchListData from "../../assets/data/accountWatchListData.json";
import RiskScoreConfigurationListData from "../../assets/data/riskScoreConfigurationListData.json";
import ClientListData from "../../assets/data/clientListData.json";
import ServerData from "../../assets/data/serverData.json";
import BrandData from "../../assets/data/brandData.json";
import ServerBrandMappingData from "../../assets/data/serverBrandMappingData.json";
import GroupPurposeData from "../../assets/data/groupPurposeData.json";
import TagManagementData from "../../assets/data/tagManagementData.json";
import HubSwitcherData from "../../assets/data/hubSwitcherData.json";
import ToxicClientCheckData from "../../assets/data/toxicClientCheckData.json";
import SyndicateTradingData from "../../assets/data/RcModules/TradingMonitor/syndicateTradingData.json";
import HftReportData from "../../assets/data/RcModules/TradingMonitor/hftReportData.json";
import ClientAnalysisData from "../../assets/data/RcModules/Analysis/clientAnalysisData.json";
import ReadOnlyData from "../../assets/data/RcModules/RiskTools/readOnlyData.json";
import HedgeToolData from "../../assets/data/RcModules/RiskTools/hedgeToolData.json";
import CidData from "../../assets/data/RcModules/RiskTools/cidData.json";
import ConfigDiffData from "../../assets/data/RcModules/RiskTools/configDiffData.json";
import ConfigDiffRecordData from "../../assets/data/RcModules/RiskTools/configDiffRecordData.json";
import BalanceUpdateData from "../../assets/data/RcModules/RiskTools/balanceUpdateData.json";
import SymbolSwapUploadData from "../../assets/data/RcModules/RiskTools/symbolSwapUploadData.json";
import DividendRecordData from "../../assets/data/RcModules/RiskTools/dividendRecordData.json";
import SwapChargingToolData from "../../assets/data/RcModules/RiskTools/swapChargingToolData.json";
import WeekendSwapsRecordData from "../../assets/data/RcModules/RiskTools/weekendSwapsRecordData.json";
import RollOverToolData from "../../assets/data/RcModules/RiskTools/rollOverToolData.json";
import MTClientSecurityData from "../../assets/data/RcModules/RiskTools/mtClientSecurityData.json";
import IssuesLogData from "../../assets/data/RcModules/SystemMonitor/issuesLogData.json";
import DataCenterData from "../../assets/data/RcModules/SystemMonitor/dataCenterData.json";
import RejectOrderData from "../../assets/data/RcModules/SystemMonitor/rejectOrderData.json";
import ServerPerformanceData from "../../assets/data/RcModules/SystemMonitor/serverPerformanceData.json";
import StatusMonitorData from "../../assets/data/RcModules/SystemMonitor/statusMonitorData.json";
import MetaTraderData from "../../assets/data/RcModules/SystemMonitor/metaTraderData.json";
import LinkedAccQueryData from "../../assets/data/RcModules/Reporting/linkedAccQueryData.json";
import LinkedAccRecordData from "../../assets/data/RcModules/Reporting/linkedAccRecData.json";
import CommissionRecordData from "../../assets/data/RcModules/Reporting/commissionRecData.json";
import NegativeEquityRecordData from "../../assets/data/RcModules/Reporting/negativeEquityRecData.json";
import AverageSpreadData from "../../assets/data/RcModules/Reporting/avgSpreadData.json";
import SettingsPanelData from "../../assets/data/settingsPanelData.json";

const md2json = require("md-2-json");

interface viewUserManualProps {
    modulePath?: string;
}

const ViewUserManual = (props: viewUserManualProps) => {
    let navigate = useNavigate();
    let location = useLocation();

    const [initialData, setInitialData] = useState<any>();

    useEffect(() => {
        return getInitialData(props.modulePath ? props.modulePath : location.pathname);
    }, [props.modulePath, location.pathname, initialData]);

    const massageData = (jsonData: any) => {
        const content = replaceZeroToDomainName(jsonData, "{0}", process.env.REACT_APP_AWS_S3_IMG_GALLERY || "");
        setInitialData(md2json.toMd(content));
    };

    const getInitialData = (pathname: string) => {
        switch (pathname) {
            case "/report/result":
                // console.log("come here")
                massageData(ReportingResultData.content);
                break;
            case "/report/template":
                massageData(ReportingTemplateData.content);
                break;
            case "/siteadmin/rolepermission":
                massageData(RoleAndPermissionData.content);
                break;
            case "/siteadmin/user":
                massageData(UserManagementData.content);
                break;
            case "/siteadmin/appconfig":
                massageData(AppConfigurationData.content);
                break;
            case "/analysis/accountprofile":
                massageData(AccountListData.content);
                break;
            case "/analysis/watchlist":
                massageData(AccountWatchListData.content);
                break;
            case "/siteadmin/riskscore/account":
                massageData(RiskScoreConfigurationListData.content);
                break;
            case "/analysis/clientlist":
                massageData(ClientListData.content);
                break;
            case "/siteadmin/serverbrand/server":
                massageData(ServerData.content);
                break;
            case "/siteadmin/serverbrand/brand":
                massageData(BrandData.content);
                break;
            case "/siteadmin/serverbrand/server-brand-mapping":
                massageData(ServerBrandMappingData.content);
                break;
            case "/siteadmin/serverbrand/group-purpose":
                massageData(GroupPurposeData.content);
                break;
            case "/siteadmin/tag":
                massageData(TagManagementData.content);
                break;
            case "/admintools/hubSwitcher":
                massageData(HubSwitcherData.content);
                break;
            case "/analysis/toxicClientCheck":
                massageData(ToxicClientCheckData.content);
                break;
            case "/realtime/syndicateTrading":
                massageData(SyndicateTradingData.content);
                break;
            case "/realtime/hftReport":
                massageData(HftReportData.content);
                break;
            case "/analysis/clientanalysis":
                massageData(ClientAnalysisData.content);
                break;
            case "/admintools/readOnly":
                massageData(ReadOnlyData.content);
                break;
            case "/admintools/hedgetool":
                massageData(HedgeToolData.content);
                break;
            case "/admintools/cid":
                massageData(CidData.content);
                break;
            case "/admintools/configdiff":
                massageData(ConfigDiffData.content);
                break;
            case "/admintools/configdiffrecord":
                massageData(ConfigDiffRecordData.content);
                break;
            case "/admintools/balanceupdate":
                massageData(BalanceUpdateData.content);
                break;
            case "/admintools/symbolswapupload":
                massageData(SymbolSwapUploadData.content);
                break;
            case "/admintools/dividendrecord":
                massageData(DividendRecordData.content);
                break;
            case "/admintools/swapchargingtool":
                massageData(SwapChargingToolData.content);
                break;
            case "/admintools/weekendswapsrecord":
                massageData(WeekendSwapsRecordData.content);
                break;
            case "/admintools/rollover":
                massageData(RollOverToolData.content);
                break;
            case "/admintools/mtClientSecurity":
                massageData(MTClientSecurityData.content);
                break;
            case "/systemmonitor/issueslog":
                massageData(IssuesLogData.content);
                break;
            case "/systemmonitor/datacenter":
                massageData(DataCenterData.content);
                break;
            case "/systemmonitor/rejectorder":
                massageData(RejectOrderData.content);
                break;
            case "/systemmonitor/serverperformance":
                massageData(ServerPerformanceData.content);
                break;
            case "/systemmonitor/statusmonitor":
                massageData(StatusMonitorData.content);
                break;
            case "/systemmonitor/metatrader":
                massageData(MetaTraderData.content);
                break;
            case "/report/linkedaccountquery":
                massageData(LinkedAccQueryData.content);
                break;
            case "/report/linkedaccountrecord":
                massageData(LinkedAccRecordData.content);
                break;
            case "/report/commissionrecord":
                massageData(CommissionRecordData.content);
                break;
            case "/report/negativeequityrecord":
                massageData(NegativeEquityRecordData.content);
                break;
            case "/report/averageSpread":
                massageData(AverageSpreadData.content);
                break;
            case "/settingspanel":
            case "/siteadmin/pricemonitor/pricealarmsettings":
            case "/siteadmin/pricemonitor/midpriceasettings":
            case "/siteadmin/pricemonitor/pricebackendopt":
            case "/siteadmin/brandserver/bridge":
            case "/siteadmin/brandserver/commissionconfig":
            case "/siteadmin/mrmonitor/mrgroupsettings":
                massageData(SettingsPanelData.content);
                break;
            default:
                setInitialData("");
                break;
        }
    };
    return <div data-color-mode="light" className="user-manual-md-container">{initialData === "" ? <Empty /> : <MDEditor.Markdown source={initialData} />}</div>;
};

export default ViewUserManual;
