import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, STATUS_TYPE, CALLBACK_KEY, SUCCESS_FAILED, InnerPageActionMode } from "@/constants";
import { KeyValuePair, Brands2, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, CreateEditInlineFormBased } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { useGetRoleMenusQuery } from "@/store/apis/role";
import { ToOptionTypeList } from "@/utils/array";
import { DTColProps, ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { objectToArray } from "@/utils/object";
import { useEffect, useMemo, useState } from "react";
import RoleCreateEditPage from "./RoleCreateEditPage";
import ViewRolePage from "./ViewRolePage";

const RolePermissionPage = () => {
    const { isLoading, data, refetch } = useGetRoleMenusQuery({ withModules: false });
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [refresh, setRefresh] = useState<number>(0);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_ROLE_AND_PERMISSION_EDIT);

    const brandList: KeyValuePair[] = useMemo(
        () =>
            objectToArray(
                (data?.data || []).reduce((bList: any, x: any) => {
                    if (x["brands"] && x["brands"].length > 0) {
                        x.brands.forEach((y: any) => {
                            if (bList[`${y.brandId}`] === undefined) {
                                bList[`${y.brandId}`] = { text: y.brandName, value: y.brandId };
                            }
                        });
                    }
                    return bList;
                }, {})
            ),
        [data]
    );

    const columns = useMemo(
        () => [
            DTColProps.XLarge({
                title: "Role Name",
                dataIndex: "role",
                key: "role",
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            }),

            {
                title: "Description",
                dataIndex: "description",
                key: "description",
            },

            DTColProps.Middle({
                title: "Brands",
                dataIndex: "brands",
                key: "brands",
                render: (brands: Brands2[]) => (brands.length > 0 ? brands.map(x => x.brandName).join(", ") : "-"),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: brandList,
                        callback: (filterValue: any, rowData: any) => rowData["brands"].some((x: any) => `${x.brandId}` === `${filterValue}`),
                    },
                },
            }),

            DTColProps.Status({
                title: "Status",
                dataIndex: "active",
                key: "active",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: ToOptionTypeList(STATUS_TYPE),
                    },
                },
            }),
        ],
        [brandList]
    );

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        // view: (record: any, option: any) => {
        //     let newProps = { ...option };
        //     newProps = <Link to={`/settingspanel/rolepermission/view/${record.roleId}`}>{newProps}</Link>;
        //     return newProps;
        // },
        view: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, data) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                setIsFirstOpt(false);
                setFormObject({ mode: InnerPageActionMode.OTHERS, id: data.roleId });
                setRefresh(prev => prev + 1);
                break;
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefresh(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: data.roleId });
                setRefresh(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_ROLE_MENU, { roleId: data.roleId })
                    .then(data => {
                        ErrorMessageHandler("The role", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setManualLoading(false);
                        refetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("role", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
                        setManualLoading(false);
                    });
                break;
        }
    };

    useEffect(() => {
        refetch();
        return () => {};
    }, []);

    return (
        <div>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Role & Permission</span>
                </div>
            </div>
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <FlexiDataTable
                        rowKeyProperty="roleId"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={data?.data || []}
                        callback={componentCallback}
                        loading={isLoading || manualLoading}
                    />
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    {formObject.mode === InnerPageActionMode.OTHERS ? (
                        <ViewRolePage
                            {...formObject}
                            callback={(action: InnerPageActionMode, value: any) => {
                                switch (action) {
                                    case InnerPageActionMode.BACK:
                                        setIsFirstOpt(prev => !prev);
                                        if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                            refetch();
                                        }
                                        break;
                                }
                            }}
                            resetState={refresh}
                        />
                    ) : (
                        <RoleCreateEditPage
                            {...formObject}
                            callback={(action: InnerPageActionMode, value: any) => {
                                switch (action) {
                                    case InnerPageActionMode.BACK:
                                        setIsFirstOpt(prev => !prev);
                                        if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                            refetch();
                                        }
                                        break;
                                }
                            }}
                            resetState={refresh}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RolePermissionPage;
