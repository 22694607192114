import React, { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED, TIMEZONE_FORMATS_ENUM, TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE, TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM, TOXIC_CLIENT_CHECK_ENTRY_STATUS, TOXIC_CLIENT_CHECK_IMPORT_AUDIT_LOG_IMPORT_SOURCE, TOXIC_CLIENT_CHECK_IMPORT_AUDIT_LOG_OVERALL_STATUS, TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM } from "../../../../constants";
import { AttributeProps, BrandsList, CrmBrandList, CustomPaginationProps, FlexiDataTableCallbackProps, RegulatorList, ToxicClientCheckCreateImportAuditLog, ToxicClientCheckCreateImportAuditLogDetails, ToxicClientLabelProps, ToxicClientSourceEventProps, UsersList } from "../../../../constants/type";
import { DateTimeUtil } from "../../../../utils/datetime";
import { SortList, ToObjectWithKey, ToOptionTypeList } from "../../../../utils/array";
import { objectToArray } from "../../../../utils/object";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Popconfirm, Popover, Tag, Tooltip } from "antd";
import { isEmptyOrNull } from "../../../../utils/string";
import moment from "moment";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";

const ImportEntryAuditLog = () => {
    const [data, setData] = useState<ToxicClientCheckCreateImportAuditLog[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sorting, setSorting] = useState<string>("");
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [servers, setServers] = useState<any>({});
    const [brands, setBrands] = useState<BrandsList[]>([]);
    const [regulators, setRegulators] = useState<RegulatorList[]>([]);
    const [users, setUsers] = useState<UsersList[]>([]);
    const [labels, setLabels] = useState<ToxicClientLabelProps[]>([]);
    const [crmBrands, setCrmBrands] = useState<CrmBrandList[]>([]);
    const [tcLabelAttrs, setTcLabelAttrs] = useState<AttributeProps[]>([]);
    const [tcSources, setTcSources] = useState<ToxicClientSourceEventProps[]>([]);
    const authHp = new AuthHelper();
    const enableDelete = authHp.isAuthorized(AuthKeys.TOXIC_CLIENT_CHECK_EDIT);

    const columns = useMemo(() => [
        {
            title: "Account ID",
            dataIndex: "accountId",
            key: "accountId",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [],
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input account id",
                        tokenSeparators: [",", "，", " "],
                    },
                },
                visible: false,
            },
        },
        {
            title: "Server",
            dataIndex: "server",
            key: "server",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: SortList(objectToArray(servers || []), "text"),
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input server name",
                    },
                },
                visible: false,
            },
        },
        {
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: brands.map((x: BrandsList) => ({
                        text: x.brand,
                        value: x.brand
                    })),
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input brand name",
                    },
                },
                visible: false,
            },
        },
        {
            title: "Client ID",
            dataIndex: "clientId",
            key: "clientId",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [],
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input client id",
                        tokenSeparators: [",", "，", " "],
                    },
                },
                visible: false,
            },
        },
        {
            title: "Client Name",
            dataIndex: "clientName",
            key: "clientName",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [],
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input client name",
                    },
                },
                visible: false,
            },
        },
        {
            title: "CRM Brand",
            dataIndex: "crmBrand",
            key: "crmBrand",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: crmBrands.map((x: CrmBrandList) => ({
                        text: x.brand,
                        value: x.brand
                    })),
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input crm brand name",
                    },
                },
                visible: false,
            },
        },
        {
            title: "Regulator",
            dataIndex: "regulator",
            key: "regulator",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: regulators.map((x: RegulatorList) => ({
                        text: x.name,
                        value: x.name
                    })),
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input regulator name",
                    },
                },
                visible: false,
            },
        },
        {
            title: "Issued Date (Server)",
            dataIndex: "issuedDate",
            key: "issuedDate",
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: [],
                    inputProps: {
                        dateOnly: true,
                    },
                },
                visible: false,
            },
        },
        {
            title: "Inspector",
            dataIndex: "inspectorValue",
            key: "inspectorValue",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: users.map((x) => ({
                        text: x.name + "  |  " + x.email,
                        value: x.email
                    })),
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input inspector email",
                    },
                },
                visible: false,
            },
        },
        {
            title: "Status",
            dataIndex: "statusValue",
            key: "statusValue",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: TOXIC_CLIENT_CHECK_ENTRY_STATUS[0], value: TOXIC_CLIENT_CHECK_ENTRY_STATUS[0] },
                        { text: TOXIC_CLIENT_CHECK_ENTRY_STATUS[1], value: TOXIC_CLIENT_CHECK_ENTRY_STATUS[1] },
                        { text: TOXIC_CLIENT_CHECK_ENTRY_STATUS[2], value: TOXIC_CLIENT_CHECK_ENTRY_STATUS[2] },
                    ],
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input status",
                    },
                },
                visible: false,
            },
        },
        {
            title: "Pickup Team",
            dataIndex: "pickupTeamValue",
            key: "pickupTeamValue",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM[1], value: TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM[1] },
                        { text: TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM[2], value: TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM[2] },
                    ],
                    inputProps: {
                        mode: "tags",
                    },
                },
                visible: false,
            },
        },
        {
            title: "Source",
            dataIndex: "toxicClientSource",
            key: "toxicClientSource",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: tcSources.map((x: ToxicClientSourceEventProps) => ({
                        text: x.sourceName,
                        value: x.sourceName
                    })),
                    inputProps: {
                        mode: "tags",
                    },
                },
                visible: false,
            },
        },
        {
            title: "CRM Mapping Mode",
            dataIndex: "crmMappingMode",
            key: "crmMappingMode",
            options: {
                visible: false,
                filter: {
                    type: ComponentType.dropdown,
                    value: ToOptionTypeList(TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE),
                    inputProps: { mode: "multiple" },
                },
            },
        },
        {
            title: "Toxic Client Label",
            dataIndex: "toxicClientLabel",
            key: "toxicClientLabel",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: labels.map((x: ToxicClientLabelProps) => ({
                        text: x.labelName,
                        value: x.labelName
                    })),
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input toxic client label name",
                    },
                },
                visible: false,
            },
        },
        {
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: users.map((x: UsersList) => ({
                        text: x.name + "  |  " + x.email,
                        value: x.id
                    })),
                    inputProps: {
                        mode: "multiple",
                    },
                },
                visible: false,
            },
        },
        {
            title: "Import Status",
            dataIndex: "importStatus",
            key: "importStatus",
            align: "center",
            width: "3.5vw",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Success", value: 1 },
                        { text: "Failed", value: 0 },
                    ],
                    inputProps: { mode: "multiple" },
                },
                visible: false,
            },
        },
        DTColProps.Small({
            title: "Import Source",
            dataIndex: "importSource",
            key: "importSource",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: ToOptionTypeList(TOXIC_CLIENT_CHECK_IMPORT_AUDIT_LOG_IMPORT_SOURCE),
                    inputProps: { mode: "multiple" },
                },
            },
            render: (importSource: number) => (
                <Tag>{TOXIC_CLIENT_CHECK_IMPORT_AUDIT_LOG_IMPORT_SOURCE[importSource]}</Tag>
            ),
        }),
        DTColProps.Small({
            title: "Import Overall Status",
            dataIndex: "importOverallStatus",
            key: "importOverallStatus",
            render: (importOverallStatus: number) => (
                <Tag color={`${importOverallStatus === 0
                    ? "error"
                    : importOverallStatus === 1
                        ? "success"
                        : "default"}`}
                >{TOXIC_CLIENT_CHECK_IMPORT_AUDIT_LOG_OVERALL_STATUS[importOverallStatus]}</Tag>
            ),
        }),
        DTColProps.XSmall({
            title: "Import Entry Count",
            dataIndex: "importEntryCount",
            key: "importEntryCount",
        },
            ["text-center"]
        ),
        DTColProps.XSmall({
            title: "Import Success Count",
            dataIndex: "importSuccessCount",
            key: "importSuccessCount",
        },
            ["text-center"]
        ),
        DTColProps.XSmall({
            title: "Import Failed Count",
            dataIndex: "importFailedCount",
            key: "importFailedCount",
        },
            ["text-center"]
        ),
        DTColProps.Small({
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            render: (createdBy: number) => {
                let thisOriUser: any = users.find((y: UsersList) => y.id === createdBy);
                return <div>{thisOriUser && thisOriUser.name}</div>;
            },
        }),
        DTColProps.Middle({
            title: "Created Date (Local)",
            dataIndex: "createdDateUtc",
            key: "createdDateUtc",
            options: {
                filter: {
                    type: ComponentType.daterange,
                    inputProps: {
                        showTime: { format: "HH:mm:ss" },
                    },
                },
            },
            render: (createdDateUtc: string) => DataTableColumnRender.DateTime(createdDateUtc),
        }),
        /**
         *  24/07/2024 - Xin
         *  temporarily hide delete action button due to role and permissions concerns
         *  - complicated process of updating the role and perm to accommodate delete role
         *  - need lots of time which we don't have right now, so put on hold first
         */
        // DTColProps.XXSmall({
        //     title: "",
        //     dataIndex: "isDeleted",
        //     key: "isDeleted",
        //     fixed: "right",
        //     render: (isDeleted: boolean, rowData: ToxicClientCheckCreateImportAuditLog) => {
        //         if (isDeleted) {
        //             let thisUser = users.find((x: UsersList) => x.id === rowData.deletedBy);
        //             return (
        //                 <Tooltip placement="topRight" overlayInnerStyle={{ width: 280 }} title={
        //                     <div className="deleted-tooltip">
        //                         <div>Removed by: {isEmptyOrNull(thisUser) ? "" : thisUser?.name}</div>
        //                         <div>Removed on: {isEmptyOrNull(rowData.deletedDateUtc) ? "" : DataTableColumnRender.DateTime(rowData.deletedDateUtc)}</div>
        //                     </div>
        //                 }>
        //                     <Tag color="volcano">Removed</Tag>
        //                 </Tooltip>
        //             );
        //         } else if (enableDelete) {
        //             return (
        //                 <Popconfirm
        //                     key={"cetmp-del"}
        //                     title="Confirm to delete?"
        //                     onConfirm={() => {
        //                         setIsLoading(true);
        //                         apiRequest(APIs.DELETE_TOXIC_CLIENT_ENTRY_IMPORT_BATCH, { importBatchId: rowData.importBatchId })
        //                             .then((res: any) => {
        //                                 ErrorMessageHandler("Toxic client entry import batch", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
        //                                 setRunRefetchDataList(true);
        //                             })
        //                             .catch((error: any) => {
        //                                 ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("toxic client entry import batch", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
        //                             })
        //                             .finally(() => setIsLoading(false));
        //                     }}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <DeleteOutlined style={{ color: "#f00f00", fontSize: "1.125rem" }} />
        //                 </Popconfirm>
        //             );
        //         } else return null;
        //     },
        // },
        //     ["text-center"]
        // ),
    ], [servers, brands, regulators, users, labels, crmBrands, tcLabelAttrs, tcSources]);

    const expandedColumns = useMemo(() => [
        DTColProps.XSmall({
            title: "Info",
            dataIndex: "accountId",
            key: "accountId",
            fixed: "left",
            render: (accountId: number, rowData: ToxicClientCheckCreateImportAuditLogDetails) => {
                return (
                    <div className="account-info-col">
                        <div className="account-item">
                            <span>Account ID</span><span>{isEmptyOrNull(accountId) ? "-" : accountId}</span>
                        </div>
                        <div className="account-item">
                            <span>Server</span><span>{isEmptyOrNull(rowData.server) ? "-" : rowData.server}</span>
                        </div>
                        <div className="account-item">
                            <span>Brand</span><span>{isEmptyOrNull(rowData.brand) ? "-" : rowData.brand}</span>
                        </div>
                        <div className={`tccl-mode-tag tccl-mode-tag-${TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[rowData.crmMappingMode]}`}>
                            {TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[rowData.crmMappingMode]}
                            <Tooltip
                                placement="right"
                                title={`${rowData.crmMappingMode === 0
                                    ? "Client data and brand mapping populated from pipeline"
                                    : "Self populated client data and brand mapping by users"}`}
                            >
                                <span><InfoCircleOutlined /></span>
                            </Tooltip>
                        </div>
                    </div>
                )
            },
        }),
        DTColProps.Small({
            title: "CRM Info",
            dataIndex: "clientId",
            key: "clientId",
            fixed: "left",
            render: (clientId: number, rowData: ToxicClientCheckCreateImportAuditLogDetails) => {
                return (
                    <div className="account-info-col">
                        <div className="account-item">
                            <span>Client ID</span><span>{isEmptyOrNull(clientId) ? "-" : clientId}</span>
                        </div>
                        <div className="account-item">
                            <span>Client Name</span><span>{isEmptyOrNull(rowData.clientName) ? "-" : rowData.clientName}</span>
                        </div>
                        <div className="account-item">
                            <span>CRM Brand</span><span>{isEmptyOrNull(rowData.crmBrand) ? "-" : rowData.crmBrand}</span>
                        </div>
                        <div className="account-item">
                            <span>Regulator</span><span>{isEmptyOrNull(rowData.regulator) ? "-" : rowData.regulator}</span>
                        </div>
                    </div>
                )
            },
        }),
        DTColProps.Small({
            title: "Inspector & Status",
            dataIndex: "inspectorValue",
            key: "inspectorValue",
            render: (inspectorValue: string, rowData: ToxicClientCheckCreateImportAuditLogDetails) => {
                let idx = users.findIndex((x) => x.email === inspectorValue);
                return (
                    <div className="inspector-col">
                        {idx > -1 ? (
                            <>
                                <div className="title">{users[idx].email}</div>
                                <div className="value">{users[idx].name}</div>
                            </>
                        ) : (
                            <div className="value">-</div>
                        )}
                        <div className="title">Issued on</div>
                        <div className="extra">{isEmptyOrNull(rowData.issuedDate) ? "-" : DataTableColumnRender.DateTime_ServerDateOnly(rowData.issuedDate)}</div>
                        {
                            rowData && rowData.toxicClientSource && <Tooltip title={`Source: ${rowData.toxicClientSource}`} placement="topLeft" >
                                <div className="source-tag">{rowData.toxicClientSource}</div>
                            </Tooltip>
                        }
                        <div className={`status-col-statusTag status-col-sts-${rowData.status}`}>{rowData.statusValue}</div>
                    </div>
                );
            },
        }),
        {
            title: "Illegal Profit",
            dataIndex: "illegalProfit",
            key: "illegalProfit",
            width: "9vw",
            render: (illegalProfit: any, rowData: ToxicClientCheckCreateImportAuditLogDetails) => {
                return (
                    <div className="tccl-grouped-info-col">
                        <div className="deductions-grouped-item">
                            <span>Illegal Profit :</span><span>{isEmptyOrNull(illegalProfit) ? "-" : illegalProfit}</span>
                        </div>
                        <div className="deductions-grouped-item">
                            <span>Suggested Deduction :</span><span>{isEmptyOrNull(rowData.suggestedDeduction) ? "-" : rowData.suggestedDeduction}</span>
                        </div>
                        <div className="deductions-grouped-item">
                            <span>Actual Deduction :</span><span>{isEmptyOrNull(rowData.actualDeduction) ? "-" : rowData.actualDeduction}</span>
                        </div>
                        <div className="deductions-grouped-item">
                            <span>Deduction Date (Server) :</span><span>{isEmptyOrNull(rowData.deductionDate) ? "-" : DataTableColumnRender.DateTime_ServerDateOnly(rowData.deductionDate)}</span>
                        </div>
                        <div className="deductions-grouped-item">
                            <span>Account Currency :</span><span>{isEmptyOrNull(rowData.accountCurrency) ? "-" : rowData.accountCurrency}</span>
                        </div>
                        <div className="deductions-grouped-item">
                            <span>USD Conversion Rate :</span><span>{isEmptyOrNull(rowData.accountCurrencyUsdConvRate) ? "-" : rowData.accountCurrencyUsdConvRate}</span>
                        </div>
                    </div>
                );
            },
        },
        DTColProps.Small({
            title: <div>Toxic Client Label
                <Tooltip title="Click onto the info button (if available) to view their respective attributes and values">
                    <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Tooltip>
            </div>,
            dataIndex: "toxicClientLabel",
            key: "toxicClientLabel",
            render: (toxicClientLabel: string, rowData: ToxicClientCheckCreateImportAuditLogDetails) => {
                let thisLabelObj = labels.find((x: ToxicClientLabelProps) => x.labelId === rowData.labelId);
                return (
                    <>
                        <div style={{ display: "flex", justifyContent: "center" }} key={rowData.labelId}>
                            <div
                                style={{
                                    backgroundColor: `${isEmptyOrNull(thisLabelObj) ? "#d9d9d9" : thisLabelObj?.labelColor}`,
                                    color: "black",
                                    padding: "0.7vh 0.651vw",
                                    wordWrap: "break-word",
                                    borderRadius: 5,
                                    fontSize: "0.7rem",
                                    fontWeight: "bold",
                                }}
                            >
                                {toxicClientLabel}
                            </div>
                        </div>
                        {(!isEmptyOrNull(rowData.labelAttrsWithValues) || !isEmptyOrNull(rowData.symbols)) && (
                            <Popover
                                trigger={["click"]}
                                placement="right"
                                content={(
                                    <div className="tcl-attribute-popover-content">
                                        <>
                                            {!isEmptyOrNull(rowData.symbols) && (
                                                <div className="tccl-grouped-info-col" key={"symbols"}>
                                                    <div className="label-attr-grouped-item">
                                                        <span>Symbols :</span><>&nbsp;</>
                                                        <span>{rowData.symbols.join(", ")}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {
                                                !isEmptyOrNull(rowData.labelAttrsWithValues) && rowData.labelAttrsWithValues.map((currAttr: any) => {
                                                    let thisAttr = tcLabelAttrs.find((x: AttributeProps) => x.attrId === currAttr.attrId);
                                                    let currAttrValue: string = "";
                                                    if (thisAttr) {
                                                        switch (thisAttr.uiElementType) {
                                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DropdownMenu:
                                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.Checkbox:
                                                                let thisValueArr = JSON.parse(currAttr.attrValue);
                                                                currAttrValue = thisValueArr.join(", ");
                                                                break;
                                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.ToggleSwitcher:
                                                                currAttrValue = currAttr.attrValue === "True" ? "Yes" : "No";
                                                                break;
                                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker:
                                                                currAttrValue = DataTableColumnRender.DateTime_ServerDateOnly(currAttr.attrValue);
                                                                break;
                                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DateTimePicker:
                                                                currAttrValue = thisAttr && thisAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Server
                                                                    ? DataTableColumnRender.DateTime_ServerTime(currAttr.attrValue)
                                                                    : thisAttr && thisAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Local
                                                                        ? DataTableColumnRender.DateTime(currAttr.attrValue)
                                                                        : moment.parseZone(currAttr.attrValue, "YYYY-MM-DDTHH:mm:ss").utc(true).format("YYYY-MM-DD HH:mm:ssZZ");
                                                                break;
                                                            default:
                                                                currAttrValue = currAttr.attrValue;
                                                                break;
                                                        };
                                                    } else return null;
                                                    return (
                                                        <div className="tccl-grouped-info-col" key={currAttr.attrId}>
                                                            <div className="label-attr-grouped-item">
                                                                <span>{currAttr.attrName} :</span><>&nbsp;</>
                                                                <span>{currAttrValue}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </>
                                    </div>
                                )}
                            >
                                <InfoCircleOutlined style={{ marginTop: 10, fontSize: "1.125rem" }} />
                            </Popover>
                        )}
                    </>
                )
            },
        },
            ["text-center"]
        ),
        {
            title: "Note",
            dataIndex: "note",
            key: "note",
            width: "9vw",
        },
        {
            title: "Import Status",
            dataIndex: "importStatus",
            key: "importStatus",
            align: "center",
            width: "3.5vw",
            render: (importStatus: number) => {
                if (importStatus === 1) {
                    return <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />;
                } else {
                    return <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />;
                }
            },
        },
        DTColProps.Small({
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            render: (remarks: string) => (
                <div className="import-audit-log-remarks-col">{remarks}</div>
            ),
        }),
    ], [users, labels, tcLabelAttrs]);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "createdDateUtc") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["createdDateUtcFrom"] = DateTimeUtil.GetUTC(FormData[x][0]);
                                fParams["createdDateUtcTo"] = DateTimeUtil.GetUTC(FormData[x][1]);
                            }
                        } else if (x === "issuedDate") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["issuedDateFrom"] = DateTimeUtil.GetUTC(FormData[x][0], "YYYY-MM-DD");
                                fParams["issuedDateTo"] = DateTimeUtil.GetUTC(FormData[x][1], "YYYY-MM-DD");
                            }
                        } else if (x === "accountId") {
                            let intAccIds: number[] = [];
                            FormData[x].forEach((y: string) => intAccIds.push(Number(y)));
                            fParams["accountIds"] = intAccIds;
                        } else if (x === "server") {
                            fParams["servers"] = FormData[x];
                        } else if (x === "brand") {
                            fParams["brands"] = FormData[x];
                        } else if (x === "clientId") {
                            let intClientIds: number[] = [];
                            FormData[x].forEach((y: string) => intClientIds.push(Number(y)));
                            fParams["clientIds"] = intClientIds;
                        } else if (x === "clientName") {
                            fParams["clientNames"] = FormData[x];
                        } else if (x === "crmBrand") {
                            fParams["crmBrands"] = FormData[x];
                        } else if (x === "regulator") {
                            fParams["regulators"] = FormData[x];
                        } else if (x === "statusValue") {
                            fParams["statuses"] = FormData[x];
                        } else if (x === "toxicClientLabel") {
                            fParams["toxicClientLabels"] = FormData[x];
                        } else if (x === "inspectorValue") {
                            fParams["inspectors"] = FormData[x];
                        } else if (x === "pickupTeamValue") {
                            fParams["pickupTeams"] = FormData[x];
                        } else if (x === "toxicClientSource") {
                            fParams["toxicClientSources"] = FormData[x];
                        } else if (x === "crmMappingMode") {
                            fParams["crmMappingModes"] = FormData[x];
                        } else if (x === "importStatus") {
                            fParams["importStatuses"] = FormData[x];
                        } else if (x === "importSource") {
                            fParams["importSources"] = FormData[x];
                        } else if (x === "pickupTeam") {
                            fParams["pickupTeams"] = FormData[x];
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            default:
                break;
        };
    };

    const getExpandedRowRender = (rowData: ToxicClientCheckCreateImportAuditLog) => {
        rowData.logDetails.sort((a, b) => a.importStatus - b.importStatus);
        return (
            <div className="tccl-expanded-row-container">
                <div className="tccl-expandable-table">
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="id"
                        title="Accounts"
                        columns={expandedColumns}
                        options={{
                            enableFilter: false,
                        }}
                        dataSource={rowData.logDetails}
                        callback={() => { }}
                        loading={false}
                        {...rowData.logDetails.length < 10 && { pagination: false }}
                    />
                </div>
            </div>
        );
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["server", "brand", "regulator", "user", "crmserver", "toxicclientlabelattr", "toxicclientlabel", "toxicclientsource"],
        })
            .then((data: any) => {
                if (data["servers"] && data["servers"].length > 0) {
                    let newServersList = ToObjectWithKey(data.servers.map((x: any) => ({ value: x["server"], text: x["server"] })), "value");
                    setServers(newServersList);
                } else setServers({});
                setBrands(data.brands);
                setRegulators(data.regulators);
                setUsers(data.users);
                setCrmBrands(data.crmBrands);
                setLabels(data.toxicClientLabels);
                setTcLabelAttrs(data.toxicClientLabelAttrs);
                setTcSources(data.toxicClientSources);
                setRunRefetchDataList(true);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to get filter config list: ", err)));
    };

    const getEntryImportAuditLog = () => {
        apiRequest(APIs.GET_TOXIC_CLIENT_ENTRY_IMPORT_AUDIT_LOGS, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data.result.length > 0) {
                    setData(data.result);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                };
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("toxic client check import audit logs", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setData([]);
                setPagination((prev) => ({ ...prev, total: 0 }));
            }))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            setRunRefetchDataList(false);
            getEntryImportAuditLog();
        }
        return () => { };
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className="toxic-client-check-create-import-audit-log-tab">
            <div className="single-page with-background">
                <div className="active">
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="importBatchId"
                        title=""
                        columns={columns}
                        options={{
                            serverFiltering: true,
                            defaultCollapseFilterPanel: true,
                            expandable: {
                                expandedRowRender: getExpandedRowRender,
                            },
                        }}
                        dataSource={data}
                        callback={componentCallback}
                        loading={isLoading}
                        serverSide={true}
                        pagination={pagination}
                    />
                </div>
            </div>
        </div>
    );
};

export default ImportEntryAuditLog;