import { useCallback, useEffect, useMemo, useState } from "react";
import useRCMetaTraderStore from "../../../store/useRCMetaTraderStore";
import { Button, Col, Row, Space, Tag, Typography } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { DownOutlined } from "@ant-design/icons";
import ResizeablePanel from "../../../components/Motion/ResizablePanel";
import { colorMap, colorMapRGB } from "../../SystemMonitor/StatusMonitor/statusUtils";
import PAAlarmServerGrid from "./PAAlarmServerGrid";
import { PriceAnalysisMassagedServerDetails, PriceAnalysisSettingGroup } from "../../../constants/type";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import useRCPriceAnalysisLatestList from "../../../hooks/useRCPriceAnalysisAlarmLatest";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { SUCCESS_FAILED } from "../../../constants";
import LoadingComponent from "../../../components/Loading";
import { FaRedo } from "react-icons/fa";
import soundFile from "../../../assets/audios/rc/price-stop.mp3";
import TimeDiff from "../../SystemMonitor/components/TimeDiff";
import { useAudioPlayer } from "@/helpers/audioHelper";

const PAAlarmServerGroup = () => {
    const { playSound } = useAudioPlayer(soundFile);
    const showSuccess = useRCMetaTraderStore(state => state.display.success);
    const showDanger = useRCMetaTraderStore(state => state.display.danger);
    const [expandedData, setExpandedData] = useState<string[]>([]);
    const [isLoadingNow, setIsLoadingNow] = useState<boolean>(true);
    const { Text, Title } = Typography;
    const [PASettingGroup, setPASettingGroup] = useState<PriceAnalysisSettingGroup[]>([]);
    const [filteredData, setFilteredData] = useState<PriceAnalysisMassagedServerDetails[]>([]);
    const [allGroupData, setAllGroupData] = useState<any[]>([]);
    const { rcPALatestList, refetchRcPALatestList, isLoading, dataUpdatedAt, isFetching } = useRCPriceAnalysisLatestList(43200);
    const [isMuted, setIsMuted] = useState(false);

    const handleIsMuted = useCallback((value: boolean) => {
        setIsMuted(value);
    }, []);

    const getPASettingGroup = useCallback(async () => {
        try {
            const response = await plainAxiosInstance.get(`${APIs.RC_PRICE_ANALYSIS.GET_PA_SETTINGS_GROUPS}`);
            setPASettingGroup(response.data || []);
        } catch (error) {
            ErrorCatchValidator(error, (err: any) =>
                ErrorMessageHandler("Price Analysis Alarm Settings Groups", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
            );
        } finally {
            setIsLoadingNow(false);
        }
    }, []);

    const getServersByGroupId = useCallback(async (groupId: number) => {
        try {
            const response = await plainAxiosInstance.get(`${APIs.RC_PRICE_ANALYSIS.GET_PA_SETTINGS_SERVERS}/${groupId}`);
            return response.data;
        } catch (error) {
            ErrorCatchValidator(error, (err: any) =>
                ErrorMessageHandler("Price Analysis Alarm Settings Servers", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
            );
            return null;
        }
    }, []);

    const fetchAllServerData = useCallback(async () => {
        setIsLoadingNow(true);
        const promises = PASettingGroup.map(async group => {
            const data = await getServersByGroupId(group.groupId);
            return { groupId: group.groupId, servers: data };
        });

        const results = await Promise.all(promises);
        setAllGroupData(results.filter(item => item.servers !== null));
        setIsLoadingNow(false);
    }, [PASettingGroup, getServersByGroupId]);

    useEffect(() => {
        getPASettingGroup();
    }, [getPASettingGroup]);

    useEffect(() => {
        if (PASettingGroup.length > 0) {
            fetchAllServerData();
        }
    }, [PASettingGroup, fetchAllServerData]);

    const uniqGroup = useMemo(() => Array.from(new Set(PASettingGroup.map(item => item.groupId))), [PASettingGroup]);

    const groupedServerInfoSettings = useMemo(() => {
        const groupedData: { [key: number]: any[] } = {};
        uniqGroup.forEach((groupId, index) => {
            groupedData[groupId] = allGroupData[index]?.servers || [];
        });
        return groupedData;
    }, [uniqGroup, allGroupData]);

    const groupData = useMemo(() => {
        return uniqGroup.map(groupId => {
            const rcPASettingServersTempData = groupedServerInfoSettings[groupId] || [];
            const rcPALatestListTempData = rcPALatestList?.filter(data => data.groupId === groupId);
            const matchedGroupServerName = PASettingGroup.find(data => data.groupId === groupId);

            let danger: any[] = [];
            let success: any[] = [];
            rcPALatestListTempData?.forEach(latestData => {
                const match = rcPASettingServersTempData.find(data => data.serverUno === latestData.baseServerUno);
                if (match) {
                    danger.push(latestData);
                }
            });
            rcPASettingServersTempData.forEach(data => {
                success.push(data);
            });

            return {
                groupId: groupId.toString(),
                groupServerName: matchedGroupServerName?.groupDn,
                data: [...danger, ...success],
            };
        });
    }, [uniqGroup, groupedServerInfoSettings, PASettingGroup, rcPALatestList]);

    useEffect(() => {
        setFilteredData(groupData);
    }, [groupData]);

    useEffect(() => {
        if (!isFetching) {
            if ((rcPALatestList?.length ?? 0) >= 1) {
                playSound();
            }
        }
    }, [isFetching]);

    const dangerServerSet = useMemo(() => {
        const set = new Set();
        filteredData.forEach(group => {
            group.data.forEach(item => {
                if (item.n !== undefined) {
                    set.add(item.otherServerUno);
                }
            });
        });
        return set;
    }, [filteredData]);

    if (filteredData.length === 0) return null;
    return (
        <div className="tab-alarm-monitor-wall-container">
            {isLoadingNow || isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="container-content">
                    <div className="title-bar">
                        <Title level={2}>Latest alarm data in 30 days.</Title>
                        <Space>
                            <TimeDiff timestamp={dataUpdatedAt} />
                            <Button
                                type="ghost"
                                style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
                                onClick={event => {
                                    event.preventDefault();
                                    refetchRcPALatestList();
                                }}
                                disabled={isLoading}
                                shape="circle"
                                icon={
                                    <motion.div animate={{ rotate: isLoading ? 180 : 0 }}>
                                        <FaRedo style={{ fontSize: "0.75rem" }} />
                                    </motion.div>
                                }
                            />
                        </Space>
                    </div>
                    <Row gutter={[8, 8]}>
                        {filteredData.map((group, index) => {
                            const hasError = group.data.some(item => item.n !== undefined);
                            const finalData = group.data.filter(server => {
                                if (server.groupId && showDanger) return true;
                                if (server.groupId && showSuccess) return true;
                                return false;
                            });

                            const alarms = finalData.filter(item => item.n !== undefined);
                            const servers = finalData.filter(item => item.serverUno !== undefined);

                            const groupedAlarms = alarms.reduce((acc, alarm) => {
                                if (!acc[alarm.otherServerUno]) {
                                    acc[alarm.otherServerUno] = [];
                                }
                                acc[alarm.otherServerUno].push(alarm);
                                return acc;
                            }, {});

                            const finalResult = servers.map(server => {
                                if (groupedAlarms[server.serverUno]) {
                                    return { ...server, alarm: groupedAlarms[server.serverUno] };
                                }
                                return server;
                            });

                            const combinedResult = [...finalResult];
                            const dangerCount = combinedResult.filter(server => "alarm" in server && server.alarm?.length > 0).length;
                            const successCount = combinedResult.length - dangerCount;

                            const isExpanded = expandedData.includes(group.groupId);
                            return (
                                <Col
                                    key={group.groupId + index}
                                    xs={{ span: 24 }}
                                    sm={{ span: isExpanded ? 24 : 12 }}
                                    md={{ span: isExpanded ? 24 : 8 }}
                                    lg={{ span: isExpanded ? 24 : 6 }}
                                >
                                    <div
                                        style={{
                                            border: "1px solid rgba(0,0,0,0.25)",
                                            borderRadius: "4px",
                                            padding: "0.5rem",
                                            background: hasError ? `rgba(${colorMapRGB.error}, 0.1)` : "none",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <div style={{ width: "100%", display: "flex", marginBottom: 4 }}>
                                            <Row style={{ flex: 1 }}>
                                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                                    <Title level={4}>{group.groupServerName}</Title>
                                                </Col>
                                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                                    <Space direction="horizontal" size={4} wrap>
                                                        {dangerCount >= 1 && (
                                                            <Tag color={colorMap.error} style={{ margin: 0 }}>
                                                                {dangerCount} Unresolved Server
                                                            </Tag>
                                                        )}
                                                        {successCount >= 1 && (
                                                            <Tag color={colorMap.success} style={{ margin: 0 }}>
                                                                {successCount} Resolved Server
                                                            </Tag>
                                                        )}
                                                    </Space>
                                                </Col>
                                            </Row>

                                            <Button
                                                type="text"
                                                shape="circle"
                                                size="small"
                                                icon={
                                                    <motion.div
                                                        animate={{ rotate: isExpanded ? 180 : 0 }}
                                                        onClick={() => {
                                                            setExpandedData(prev =>
                                                                prev.includes(group.groupId)
                                                                    ? prev.filter(item => item !== group.groupId)
                                                                    : [...prev, group.groupId]
                                                            );
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <DownOutlined />
                                                    </motion.div>
                                                }
                                            />
                                        </div>
                                        <ResizeablePanel>
                                            <AnimatePresence>
                                                {isExpanded && (
                                                    <motion.div key="full" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                                        <Row gutter={[8, 8]}>
                                                            {combinedResult.map((server, index) => (
                                                                <Col
                                                                    xs={{ span: 24 }}
                                                                    sm={{ span: 12 }}
                                                                    md={{ span: 6 }}
                                                                    lg={{ span: 6 }}
                                                                    xl={{ span: 4 }}
                                                                    key={index.toString()}
                                                                >
                                                                    <PAAlarmServerGrid
                                                                        server={{ alarm: server.alarm, serverDn: server.serverDn }}
                                                                        isLoading={isLoadingNow}
                                                                    />
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </ResizeablePanel>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            )}
        </div>
    );
};

export default PAAlarmServerGroup;
