import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { message, Modal } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";

export interface OutOfPriceSettingsPageProps {}

const OutOfPriceSettingsPage = (props: OutOfPriceSettingsPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_OUT_OF_PRICE_SETTINGS_EDIT);

    const columns: any[] = [
        DTColProps.Large({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
        }),
        {
            title: "Login",
            dataIndex: "login",
            key: "login",
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        showHideColumns: false,
        export: {
            text: "Download",
        },
        ...(enableUpdate && {
            upload: {
                uploadText: "Upload Whitelist",
                onChange: (info: any) => {
                    if (info.file.status === "error") {
                        ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                },
                customRequest: ({ file, onSuccess }: any) =>
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0),
                beforeUpload: (file: any) => handleSubmit(file),
            },
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                handleDownload();
                break;
            default:
                break;
        }
    };

    const handleDownload = () => {
        plainAxiosInstance
            .get(APIs.RC_OUT_OF_PRICE_ALARM.GET_DOWNLOAD_REPORT, {
                headers: {
                    Accept: "application/octet-stream, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                const fileName = getFileNameFromResponseHeader(response, `DealOopWhitelist_Setting${moment().format("YYYY-MM-DD_HH-mm-ss")}.xlsx`);
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    ErrorMessageHandler(`Time Threshold downloaded successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                } else {
                    ErrorMessageHandler(`Received non-file response. Error: ${response}.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch(err => {
                ErrorMessageHandler(`Download error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
            });
    };

    const handleSubmit = (file: any) => {
        let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
            isLt5M = file.size / 1024 / 1024 < 5;

        if (fileExtension[0] !== ".xlsx") {
            ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        } else if (!isLt5M) {
            ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        }

        if (file === undefined) {
            ErrorMessageHandler(`Please upload any file.`, SUCCESS_FAILED.OTHERS_FAILED);
            return;
        }

        Modal.confirm({
            title: "Are you sure you want to import whitelist?",
            onOk: () => {
                var formData = new FormData();
                formData.append("uploadFile", file);

                plainAxiosInstance
                    .post(APIs.RC_OUT_OF_PRICE_ALARM.POST_UPLOAD_SETTINGS, formData)
                    .then(res => {
                        if (res.status === 200 && res?.data !== 0) {
                            ErrorMessageHandler(`Failed to upload whitelist. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
                        } else {
                            ErrorMessageHandler(`Whitelist uploaded successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                            getWhitelist();
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler(`File upload failed. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED, err)
                        )
                    );
            },
        });
    };

    const getWhitelist = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_OUT_OF_PRICE_ALARM.GET_SETTINGS}`)
            .then((res: any) => {
                if (res.data) {
                    setData(res.data.map((x: any) => ({ ...x, key: `${x.serverUno}-${x.login}` })));
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("white list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getWhitelist();
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Out Of Price Settings</span>
                </div>
            </div>
            <FlexiDataTable
                bordered
                rowKeyProperty="key"
                title={false}
                columns={columns}
                options={options}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
            />
        </>
    );
};

export default OutOfPriceSettingsPage;
