import { useCallback, useEffect, useRef, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DataTableColumnRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED, WITHDRAWAL_AUDIT_APILOGS_ACTION_CODE, WITHDRAWAL_AUDIT_RESPONSE_STATUS_CODE } from "@/constants";
import { CustomPaginationProps, FlexiDataTableCallbackProps, WithdrawalAuditApiLogs } from "@/constants/type";
import { DateTimeUtil } from "@/utils/datetime";
import { CheckCircleOutlined, CloseCircleOutlined, ZoomInOutlined } from "@ant-design/icons";
import { apiRequest, APIs } from "@/services/apiConfig";
import { Modal, Tooltip, Typography } from "antd";

const APILogs = () => {
    const [data, setData] = useState<WithdrawalAuditApiLogs[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({});
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [sorting, setSorting] = useState<string>("");
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [currErrDetails, setCurrErrDetails] = useState<WithdrawalAuditApiLogs | null>(null);
    const scrollableLogsDivRef = useRef<any>(null);

    const columns = [
        DTColProps.Middle({
            title: "Request Time (Server)",
            dataIndex: "requestTimeUtc",
            key: "requestTimeUtc",
            fixed: "left",
            width: "10vw",
            sorter: true,
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: [],
                    inputProps: { showTime: true },
                },
            },
            render: (value: string, rowData: WithdrawalAuditApiLogs) => (
                <div className="wa-req-time-id-col">
                    <span>{value ? DataTableColumnRender.DateTime_ServerTime(value) : ""}</span>
                    <span>Transaction ID: {rowData.txId}</span>
                </div>
            ),
        }),
        {
            title: "Transaction ID",
            dataIndex: "txId",
            key: "txId",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
                visible: false,
            }
        },
        {
            title: "Show Successful Requests Only",
            dataIndex: "isShowSuccessfulRequestOnly",
            key: "isShowSuccessfulRequestOnly",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                    ],
                },
                visible: false,
            }
        },
        {
            title: "Show Requests with Error Only",
            dataIndex: "isShowRequestWithErrorOnly",
            key: "isShowRequestWithErrorOnly",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                    ],
                },
                visible: false,
            }
        },
        DTColProps.Small({
            title: "Action Code",
            dataIndex: "actionCode",
            key: "actionCode",
            render: (actionCode: number) => WITHDRAWAL_AUDIT_APILOGS_ACTION_CODE[actionCode],
        }),
        DTColProps.XSmall({
            title: "Http Method",
            dataIndex: "httpMethod",
            key: "httpMethod",
        }),
        DTColProps.Middle({
            title: "End Point",
            dataIndex: "endpoint",
            key: "endpoint",
            render: (endpoint: string) =>
                endpoint && endpoint.length > 0 ? (
                    <Tooltip title={endpoint} overlayInnerStyle={{ width: "20vw" }} placement="topLeft">
                        <Typography.Paragraph ellipsis={{ rows: 4 }} style={{ marginBottom: "0" }}>
                            {endpoint}
                        </Typography.Paragraph>
                    </Tooltip>
                ) : <></>,
        }),
        DTColProps.Middle({
            title: "Query Params",
            dataIndex: "queryParams",
            key: "queryParams",
            render: (qParams: any) => {
                return Object.keys(qParams).length > 0
                    ? (
                        <div className="waal-query-params">
                            {
                                Object.keys(qParams).length > 1
                                    ? (
                                        <Tooltip title={displayAllTooltip(qParams)} overlayInnerStyle={{ width: "20vw" }} placement="topLeft">
                                            <div>
                                                {Object.keys(qParams)
                                                    .slice(0, 1)
                                                    .map((key: string, i: number) => (
                                                        <div key={i}>
                                                            <b>{key}:</b>
                                                            <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ marginBottom: "0" }}>
                                                                {qParams[key]}
                                                            </Typography.Paragraph>
                                                        </div>
                                                    ))}
                                            </div>
                                        </Tooltip>
                                    )
                                    : Object.keys(qParams).map((key: string, i: number) => (
                                        <div key={i}>
                                            <b>{key}:</b> {qParams[key] && qParams[key].length > 0 ? (
                                                <Tooltip title={qParams[key]} overlayInnerStyle={{ width: "20vw" }} placement="topLeft">
                                                    <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ marginBottom: "0" }}>
                                                        {qParams[key]}
                                                    </Typography.Paragraph>
                                                </Tooltip>
                                            ) : <></>}
                                        </div>
                                    ))
                            }
                        </div>
                    )
                    : <></>;
            },
        }),
        DTColProps.Middle({
            title: "Request Headers",
            dataIndex: "requestHeaders",
            key: "requestHeaders",
            render: (reqH: any) => {
                return Object.keys(reqH).length > 0
                    ? (
                        <div className="waal-req-headers">
                            {
                                Object.keys(reqH).length > 3
                                    ? (
                                        <Tooltip title={displayAllTooltip(reqH)} overlayInnerStyle={{ width: "20vw" }} placement="topLeft">
                                            <div>
                                                {Object.keys(reqH)
                                                    .slice(0, 3)
                                                    .map((key: string, i: number) => (
                                                        <div key={i}>
                                                            <b>{key}:</b> {reqH[key]}
                                                        </div>
                                                    ))}
                                                <span>...</span>
                                            </div>
                                        </Tooltip>
                                    )
                                    : Object.keys(reqH).map((key: string, i: number) => (
                                        <div key={i}>
                                            <b>{key}:</b> {reqH[key]}
                                        </div>
                                    ))
                            }
                        </div>
                    )
                    : <></>;
            },
        }),
        DTColProps.Middle({
            title: "Request Body",
            dataIndex: "requestBody",
            key: "requestBody",
            render: (reqB: any) => {
                return Object.keys(reqB).length > 0
                    ? (
                        <div className="waal-req-body">
                            {
                                Object.keys(reqB).length > 3
                                    ? (
                                        <Tooltip title={displayAllTooltip(reqB)} overlayInnerStyle={{ width: "20vw" }} placement="topLeft">
                                            <div>
                                                {Object.keys(reqB)
                                                    .slice(0, 3)
                                                    .map((key: string, i: number) => (
                                                        <div key={i}>
                                                            <b>{key}:</b> {reqB[key]}
                                                        </div>
                                                    ))}
                                                <span>...</span>
                                            </div>
                                        </Tooltip>
                                    )
                                    : Object.keys(reqB).map((key: string, i: number) => (
                                        <div key={i}>
                                            <b>{key}:</b> {reqB[key]}
                                        </div>
                                    ))
                            }
                        </div>
                    )
                    : <></>;
            },
        }),
        DTColProps.XXSmall({
            title: "Response Status Code",
            dataIndex: "responseStatusCode",
            key: "responseStatusCode",
        }),
        DTColProps.Middle({
            title: "Response Body",
            dataIndex: "responseBody",
            key: "responseBody",
            width: "15vw",
            render: (resB: any) => {
                return Object.keys(resB).length > 0
                    ? (
                        <div className="waal-res-body">
                            {
                                Object.keys(resB).length > 3
                                    ? (
                                        <Tooltip title={displayAllTooltip(resB)} overlayInnerStyle={{ width: "20vw" }} placement="topLeft">
                                            <div>
                                                {Object.keys(resB)
                                                    .slice(0, 3)
                                                    .map((key: string, i: number) => (
                                                        <div key={i}>
                                                            <b>{key}:</b> {resB[key]}
                                                        </div>
                                                    ))}
                                                <span>...</span>
                                            </div>
                                        </Tooltip>
                                    )
                                    : Object.keys(resB).map((key: string, i: number) => (
                                        <div key={i}>
                                            <b>{key}:</b> {resB[key]}
                                        </div>
                                    ))
                            }
                        </div>
                    )
                    : <></>;
            },
        }),
        DTColProps.Middle({
            title: "Error Details",
            dataIndex: "errorDetails",
            key: "errorDetails",
            render: (errD: string, rowData: WithdrawalAuditApiLogs) => errD && errD.length > 0 ? (
                <div className="waal-err-details">
                    <Typography.Paragraph ellipsis={{ rows: 4 }} style={{ marginBottom: "0" }}>
                        {errD}
                    </Typography.Paragraph>
                    <Tooltip title="Click to view more">
                        <ZoomInOutlined className="more-btn" onClick={() => {
                            setCurrErrDetails(rowData);
                            scrollableLogsDivRef.current.scrollTo({ top: 0 });
                        }} />
                    </Tooltip>
                </div>
            ) : <></>,
        }),
        DTColProps.XXSmall(
            {
                title: "Is Success Executed",
                dataIndex: "isSuccessExecuted",
                key: "isSuccessExecuted",
                fixed: "right",
                render: (isSuccessExecuted: boolean) =>
                    isSuccessExecuted
                        ? <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                        : <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />,
            },
            ["text-center"]
        ),
    ];

    const displayAllTooltip = (data: any) => {
        return (
            <div>
                {Object.keys(data).map((key: string, i: number) => (
                    <div key={i}>
                        <b>{key}:</b> {data[key]}
                    </div>
                ))}
            </div>
        )
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "requestTimeUtc") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["requestTimeUtcFrom"] = DateTimeUtil.GetUTC(FormData[x][0]);
                                fParams["requestTimeUtcTo"] = DateTimeUtil.GetUTC(FormData[x][1]);
                            }
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;

            default:
                break;
        };
    };

    const getWithdrawalAuditApiLogs = useCallback(() => {
        apiRequest(APIs.GET_WITHDRAWAL_AUDIT_API_LOGS, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data === null || data.result.length === 0) {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                };
                setData(data.result);
                setPagination(prev => ({ ...prev, total: data.total }));
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("withdrawal audit api logs", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                });
            })
            .finally(() => setIsLoading(false));
    }, [sorting, pagination, filterParams]);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getWithdrawalAuditApiLogs();
            setRunRefetchDataList(false);
        };
        return () => { };
    }, [runRefetchDataList]);

    return (
        <div className="withdrawal-api-logs-tab">
            <div className="single-page with-background">
                <div className={`active`}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="id"
                        title={false}
                        columns={columns}
                        options={{
                            defaultCollapseFilterPanel: true,
                            serverFiltering: true,
                        }}
                        dataSource={data ?? []}
                        callback={componentCallback}
                        loading={isLoading}
                        serverSide={true}
                        pagination={pagination}
                    />
                    <Modal
                        width={"60vw"}
                        centered
                        destroyOnClose
                        title={`Error Details (Transaction ID: ${currErrDetails?.txId})`}
                        open={currErrDetails !== null}
                        onOk={() => setCurrErrDetails(null)}
                        onCancel={() => setCurrErrDetails(null)}
                        footer={false}
                    >
                        <div className="nice-scrollbar" style={{ maxHeight: "75vh", overflow: "auto" }} ref={scrollableLogsDivRef}>
                            <div style={{ fontFamily: "monospace", paddingRight: 10 }} dangerouslySetInnerHTML={{ __html: currErrDetails?.errorDetails?.replace(/\r?\n|\r/g, "<br>") ?? "" }} />
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default APILogs;