import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample response from the API
[
  {
    "brandId": "AT",
    "brandName": null,
    "serverId": "MT5AT",
    "serverUno": null,
    "supervision": "AT",
    "uniqueName": "MT5AT",
    "groupPattern": null,
    "comment": null,
    "servers": null
  },
  {
    "brandId": "ATOM8",
    "brandName": null,
    "serverId": "MT5AU",
    "serverUno": null,
    "supervision": "FCA",
    "uniqueName": "ATOM8MT5AU",
    "groupPattern": "VUK_Hedge",
    "comment": null,
    "servers": null
  },
  {
    "brandId": "ATOM8",
    "brandName": null,
    "serverId": "VGPUK",
    "serverUno": null,
    "supervision": "FCA",
    "uniqueName": "ATOM8VGPUK",
    "groupPattern": null,
    "comment": "",
    "servers": null
  },
  {
    "brandId": "Bybit",
    "brandName": null,
    "serverId": "MT5Bybit",
    "serverUno": null,
    "supervision": "SVG",
    "uniqueName": "MT5Bybit",
    "groupPattern": null,
    "comment": null,
    "servers": null
  },
  {
    "brandId": "Infinox",
    "brandName": null,
    "serverId": "BHS1",
    "serverUno": null,
    "supervision": "SCB",
    "uniqueName": "InfinoxBHS1",
    "groupPattern": null,
    "comment": "INF03",
    "servers": null
  },
  {
    "brandId": "Infinox",
    "brandName": null,
    "serverId": "BHS2",
    "serverUno": null,
    "supervision": "SCB",
    "uniqueName": "InfinoxBHS2",
    "groupPattern": null,
    "comment": "INF05",
    "servers": null
  },
]
*/

// const dummy = [
//     {
//         brandId: "AT",
//         brandName: null,
//         serverId: "MT5AT",
//         serverUno: null,
//         supervision: "AT",
//         uniqueName: "MT5AT",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "ATOM8",
//         brandName: null,
//         serverId: "MT5AU",
//         serverUno: null,
//         supervision: "FCA",
//         uniqueName: "ATOM8MT5AU",
//         groupPattern: "VUK_Hedge",
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "ATOM8",
//         brandName: null,
//         serverId: "VGPUK",
//         serverUno: null,
//         supervision: "FCA",
//         uniqueName: "ATOM8VGPUK",
//         groupPattern: null,
//         comment: "",
//         servers: null,
//     },
//     {
//         brandId: "Bybit",
//         brandName: null,
//         serverId: "MT5Bybit",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "MT5Bybit",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Infinox",
//         brandName: null,
//         serverId: "BHS1",
//         serverUno: null,
//         supervision: "SCB",
//         uniqueName: "InfinoxBHS1",
//         groupPattern: null,
//         comment: "INF03",
//         servers: null,
//     },
//     {
//         brandId: "Infinox",
//         brandName: null,
//         serverId: "BHS2",
//         serverUno: null,
//         supervision: "SCB",
//         uniqueName: "InfinoxBHS2",
//         groupPattern: null,
//         comment: "INF05",
//         servers: null,
//     },
//     {
//         brandId: "Infinox",
//         brandName: null,
//         serverId: "BHS3",
//         serverUno: null,
//         supervision: "SCB",
//         uniqueName: "InfinoxBHS3",
//         groupPattern: null,
//         comment: "INF04",
//         servers: null,
//     },
//     {
//         brandId: "Infinox",
//         brandName: null,
//         serverId: "INF06",
//         serverUno: null,
//         supervision: "SCB",
//         uniqueName: "InfinoxINF06",
//         groupPattern: null,
//         comment: "INF06",
//         servers: null,
//     },
//     {
//         brandId: "Infinox",
//         brandName: null,
//         serverId: "IUK",
//         serverUno: null,
//         supervision: "SCB",
//         uniqueName: "InfinoxIUK",
//         groupPattern: null,
//         comment: "INF02",
//         servers: null,
//     },
//     {
//         brandId: "Infinox",
//         brandName: null,
//         serverId: "M4",
//         serverUno: null,
//         supervision: "FSA",
//         uniqueName: "InfinoxM4",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Infinox",
//         brandName: null,
//         serverId: "MT5INF",
//         serverUno: null,
//         supervision: "SCB",
//         uniqueName: "InfinoxMT5INF",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Infinox",
//         brandName: null,
//         serverId: "MT5M4",
//         serverUno: null,
//         supervision: "FSA",
//         uniqueName: "InfinoxMT5M4",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "IV",
//         brandName: null,
//         serverId: "IV",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "IVIV",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "IV",
//         brandName: null,
//         serverId: "MT5ST",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "StarTraderMT5",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "IV",
//         brandName: null,
//         serverId: "ST2",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "StarTrader2",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "IV",
//         brandName: null,
//         serverId: "ST4",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "StarTrader4",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "KCM",
//         brandName: null,
//         serverId: "KCM",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "KCMKCM",
//         groupPattern: null,
//         comment: "全吃不額外過濾",
//         servers: null,
//     },
//     {
//         brandId: "Moneta",
//         brandName: null,
//         serverId: "AU2",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "MonetaAU2",
//         groupPattern: "MON",
//         comment: "2022-06月中上線",
//         servers: null,
//     },
//     {
//         brandId: "Moneta",
//         brandName: null,
//         serverId: "Moneta1",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "Moneta",
//         groupPattern: "MON",
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Moneta",
//         brandName: null,
//         serverId: "MT5AU",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "MonetaMT5AU",
//         groupPattern: "Moneta_Hedge",
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Moneta",
//         brandName: null,
//         serverId: "MT5Moneta",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "MonetaMT5Moneta",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Moneta",
//         brandName: null,
//         serverId: "MT5Moneta1",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "MT5Moneta1",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Moneta",
//         brandName: null,
//         serverId: "UK3",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "MonetaUK3",
//         groupPattern: "MON",
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Pacific",
//         brandName: null,
//         serverId: "MT5PUG",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "PacificMT5PUG",
//         groupPattern: null,
//         comment: "目前單獨 Server 先不額外過濾",
//         servers: null,
//     },
//     {
//         brandId: "Pacific",
//         brandName: null,
//         serverId: "PU3",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "PacificPU3",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Pacific",
//         brandName: null,
//         serverId: "PU4",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "PacificPU4",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Pacific",
//         brandName: null,
//         serverId: "PU5",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "PacificPU5",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Pacific",
//         brandName: null,
//         serverId: "PU6",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "PacificPU6",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Pacific",
//         brandName: null,
//         serverId: "PU7",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "PacificPU7",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Pacific",
//         brandName: null,
//         serverId: "PUG",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "PacificPUG",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Pacific",
//         brandName: null,
//         serverId: "PUG2",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "PacificPUG2",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Pacific",
//         brandName: null,
//         serverId: "PUGdemo",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "PacificPUGdemo",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "RF",
//         brandName: null,
//         serverId: "RF",
//         serverUno: null,
//         supervision: "RG",
//         uniqueName: "MT4RF",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "RF",
//         brandName: null,
//         serverId: "RFDemo",
//         serverUno: null,
//         supervision: "RG",
//         uniqueName: "RFDemo",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "STPrime",
//         brandName: null,
//         serverId: "MT5ST2",
//         serverUno: null,
//         supervision: "ASIC",
//         uniqueName: "MT5 Startrader Prime",
//         groupPattern: "",
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "STPrime",
//         brandName: null,
//         serverId: "ST3",
//         serverUno: null,
//         supervision: "ASIC",
//         uniqueName: "MT4 Startrader Prime",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Ultimas",
//         brandName: null,
//         serverId: "OPL",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "UltimasOPL",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Ultimas",
//         brandName: null,
//         serverId: "UM2",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "UltimasOPL2",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "AU1",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "VantageAU1",
//         groupPattern: null,
//         comment: "先全部吃不過濾",
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "AU2",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "VantageAU2",
//         groupPattern: null,
//         comment: "先全部吃不過濾",
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "AU3",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "VantageAU3",
//         groupPattern: null,
//         comment: "先全部吃不過濾",
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "AU4",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "VantageAU4",
//         groupPattern: null,
//         comment: "先全部吃不過濾",
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "AU5",
//         serverUno: null,
//         supervision: "VFSC,VFSC2",
//         uniqueName: "VantageAU5",
//         groupPattern: null,
//         comment: "",
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "AU6",
//         serverUno: null,
//         supervision: "VFSC,VFSC2",
//         uniqueName: "VantageAU6",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "AUdemo",
//         serverUno: null,
//         supervision: "ASIC,VFSC,VFSC2,SVG,",
//         uniqueName: "VantageAUDemo",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "MT5AU",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "VantageMT5AU",
//         groupPattern: "Vantage_Hedge,VIG_Hedge,VU_Hedge,Social",
//         comment: "MT5 都用 StartWith 作法，但 MT5AU 可能感覺先過濾掉其他的會比較好",
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "MT5UK",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "UKMT5",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "MT5UK2",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "UK2MT5",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UK1",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "VantageUK1",
//         groupPattern: null,
//         comment: "先全部吃不過濾",
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UK10",
//         serverUno: null,
//         supervision: "VFSC,VFSC2",
//         uniqueName: "VantageUK10",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UK11",
//         serverUno: null,
//         supervision: "VFSC,VFSC2",
//         uniqueName: "VantageUK11",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UK2",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "VantageUK2",
//         groupPattern: null,
//         comment: "先全部吃不過濾",
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UK3",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "VantageUK3",
//         groupPattern: null,
//         comment: "先全部吃不過濾",
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UK4",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "VantageUK4",
//         groupPattern: null,
//         comment: "先全部吃不過濾",
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UK5",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "VantageUK5",
//         groupPattern: null,
//         comment: "先全部吃不過濾",
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UK6",
//         serverUno: null,
//         supervision: "VFSC2",
//         uniqueName: "VantageUK6",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UK7",
//         serverUno: null,
//         supervision: "VFSC,VFSC2",
//         uniqueName: "VantageUK7",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UK8",
//         serverUno: null,
//         supervision: "VFSC,VFSC2",
//         uniqueName: "VantageUK8",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UK9",
//         serverUno: null,
//         supervision: "VFSC,VFSC2",
//         uniqueName: "VantageUK9",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "Vantage",
//         brandName: null,
//         serverId: "UKdemo",
//         serverUno: null,
//         supervision: "VFSC,VFSC2,SVG,",
//         uniqueName: "VantageUKDemo",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VantageJPN",
//         brandName: null,
//         serverId: "MT5VJP",
//         serverUno: null,
//         supervision: "VFSC",
//         uniqueName: "VantageMT5VJP",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VantageJPN",
//         brandName: null,
//         serverId: "VJP",
//         serverUno: null,
//         supervision: "VFSC",
//         uniqueName: "VantageVJP",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VIDA",
//         brandName: null,
//         serverId: "MT5VIDA",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "MT5VIDA",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VIDA",
//         brandName: null,
//         serverId: "VIDA08",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "VIDA08",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VT",
//         brandName: null,
//         serverId: "MT5AU",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "VTMT5AU",
//         groupPattern: "VT_Hedge,VTC_Hedge",
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VT",
//         brandName: null,
//         serverId: "MT5VT",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "MT5VT",
//         groupPattern: "VT_Hedge",
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VT",
//         brandName: null,
//         serverId: "VT1",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "VTVT1",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VT",
//         brandName: null,
//         serverId: "VT2",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "VTVT2",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VT",
//         brandName: null,
//         serverId: "VT3",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "VTVT3",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VT",
//         brandName: null,
//         serverId: "VT4",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "VTVT4",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VT",
//         brandName: null,
//         serverId: "VT5",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "VTVT5",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VT",
//         brandName: null,
//         serverId: "VT6",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "VTVT6",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VT",
//         brandName: null,
//         serverId: "VT7",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "VTVT7",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VT",
//         brandName: null,
//         serverId: "VT8",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "VTVT8",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
//     {
//         brandId: "VT",
//         brandName: null,
//         serverId: "VT9",
//         serverUno: null,
//         supervision: "SVG",
//         uniqueName: "VTVT9",
//         groupPattern: null,
//         comment: null,
//         servers: null,
//     },
// ];

interface RCServerBrands {
    brandId: string;
    brandName: string | null;
    serverId: string;
    serverUno: string | null;
    supervision: string;
    uniqueName: string;
    groupPattern: string | null;
    comment: string | null;
    servers: any | null;
}

// add js doc, says this is for matching RC server to full brand name

/**
 * This hook is used to get the list of RC Servers's Brand (full name) and the server details
 */
const useRCServerBrands = (): {
    rcServerBrands: RCServerBrands[] | null;
    rcServerBrandsError: string | null;
    refetchRcServerBrands: () => void;
    isFetching: boolean;
    isLoading: boolean;
} => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcServerBrands"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ROOT.GET_SERVER_BRANDS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000 * 5, //(60 x 1000ms x 5 = 5 minutes)
    });

    const rcServerBrands: RCServerBrands[] | null = data?.data?.status ? null : data?.data || null;
    // const rcServerBrands: RCServerBrands[] | null = dummy;
    // @ts-ignore
    const rcServerBrandsError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcServerBrands,
        rcServerBrandsError,
        refetchRcServerBrands: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCServerBrands;
