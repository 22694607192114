import React, { useState, useCallback } from "react";
import useRCPriceAlarm, { useRCPriceAlarmMiscStop } from "../../../hooks/useRCPriceAlarm";
import PriceAlarmTable from "./PriceAlarmTable";
import { Divider } from "antd";
import FloatingTitleBox from "../../SystemMonitor/components/FloatingTitleBox";
import useRCPriceAlarmStore from "../../../store/useRCPriceAlarmStore";
import PriceAlarmMenu from "./PriceAlarmMenu";
import ServerStopTable from "./ServerStopTable";
import MiscStopTable from "./MiscStopTable";
import PriceMonitorBreadCrumbs from "../components/PriceMonitorBreadCrumbs";

const PriceAlarm = () => {
    return (
        <div style={{ marginBottom: "3rem" }}>
            <PriceMonitorBreadCrumbs currMenu="Price Alarm" />
            <PriceAlarmMenu />
            <div
                style={{
                    position: "relative",
                    zIndex: 1,
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "1rem",
                }}
            >
                <section id="PRICE_ALARM">
                    <PriceAlarmMonitor />
                </section>
                <Divider style={{ margin: "0.5rem 0" }} />
                <section id="SERVER_STOP">
                    <ServerStopMonitor />
                </section>
                <Divider style={{ margin: "0.5rem 0" }} />
                <section id="LP_PRICE_ALARM">
                    <LPPriceAlarmMonitor />
                </section>
                <Divider style={{ margin: "0.5rem 0" }} />
                <section id="CONNECTOR">
                    <ConnectorStopMonitor />
                </section>
                <Divider style={{ margin: "0.5rem 0" }} />
                <section id="CALCULATE_TOOL">
                    <CalculateToolStopMonitor />
                </section>
            </div>
        </div>
    );
};

const PriceAlarmMonitor = () => {
    const { rcPriceAlarmSummary, isLoading: isLoadingPriceAlarm, rcPriceAlarmError } = useRCPriceAlarm();
    const updateInView = useRCPriceAlarmStore(state => state.updateInView);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const onExpand = useCallback((expanded: boolean, record: any) => {
        setExpandedRowKeys(keys => {
            const key = `${record.brand}-${record.group}`;
            if (expanded) {
                return [...keys, key];
            } else {
                return keys.filter(k => k !== key);
            }
        });
    }, []);

    return (
        <FloatingTitleBox
            title="Price Alarm"
            inViewCallback={(isInView: boolean) => {
                updateInView({ PRICE_ALARM: isInView });
            }}
        >
            <div style={{ padding: "1rem" }}>
                <PriceAlarmTable
                    data={rcPriceAlarmSummary}
                    isLoading={isLoadingPriceAlarm}
                    errorText={rcPriceAlarmError}
                    expandedRowKeys={expandedRowKeys}
                    onExpand={onExpand}
                    priceAlarmColor={"bg-red-800"}
                />
            </div>
        </FloatingTitleBox>
    );
};

const LPPriceAlarmMonitor = () => {
    const { rcPriceAlarmSummaryLP, isLoading: isLoadingPriceAlarm, rcPriceAlarmError } = useRCPriceAlarm();
    const updateInView = useRCPriceAlarmStore(state => state.updateInView);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const onExpand = useCallback((expanded: boolean, record: any) => {
        setExpandedRowKeys(keys => {
            const key = `${record.brand}-${record.group}`;
            if (expanded) {
                return [...keys, key];
            } else {
                return keys.filter(k => k !== key);
            }
        });
    }, []);

    return (
        <FloatingTitleBox
            title="LP Price Alarm"
            inViewCallback={(isInView: boolean) => {
                updateInView({ LP_PRICE_ALARM: isInView });
            }}
        >
            <div style={{ padding: "1rem" }}>
                <PriceAlarmTable
                    data={rcPriceAlarmSummaryLP}
                    isLoading={isLoadingPriceAlarm}
                    errorText={rcPriceAlarmError}
                    expandedRowKeys={expandedRowKeys}
                    onExpand={onExpand}
                    priceAlarmColor={"bg-yellow-700"}
                />
            </div>
        </FloatingTitleBox>
    );
};

const ServerStopMonitor = () => {
    const updateInView = useRCPriceAlarmStore(state => state.updateInView);
    return (
        <FloatingTitleBox
            title="Server Stop"
            inViewCallback={(isInView: boolean) => {
                updateInView({ SERVER_STOP: isInView });
            }}
        >
            <div style={{ padding: "1rem" }}>
                <ServerStopTable />
            </div>
        </FloatingTitleBox>
    );
};

const ConnectorStopMonitor = () => {
    const updateInView = useRCPriceAlarmStore(state => state.updateInView);
    const { isLoading, rcPriceAlarmMiscStopConnector, rcPriceAlarmMiscStopError } = useRCPriceAlarmMiscStop();
    return (
        <FloatingTitleBox
            title="Price Connector Stop"
            inViewCallback={(isInView: boolean) => {
                updateInView({ CONNECTOR: isInView });
            }}
        >
            <div style={{ padding: "1rem" }}>
                <MiscStopTable data={rcPriceAlarmMiscStopConnector} isLoading={isLoading} errorText={rcPriceAlarmMiscStopError} />
            </div>
        </FloatingTitleBox>
    );
};

const CalculateToolStopMonitor = () => {
    const updateInView = useRCPriceAlarmStore(state => state.updateInView);
    const { isLoading, rcPriceAlarmMiscStopCalculateTool, rcPriceAlarmMiscStopError } = useRCPriceAlarmMiscStop();
    return (
        <FloatingTitleBox
            title="Price Calculate Tool Stop"
            inViewCallback={(isInView: boolean) => {
                updateInView({ CALCULATE_TOOL: isInView });
            }}
        >
            <div style={{ padding: "1rem" }}>
                <MiscStopTable data={rcPriceAlarmMiscStopCalculateTool} isLoading={isLoading} errorText={rcPriceAlarmMiscStopError} />
            </div>
        </FloatingTitleBox>
    );
};

export default PriceAlarm;
