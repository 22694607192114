import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";
import { SUCCESS_FAILED, ComponentType, InnerPageActionMode } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CreateEditInlineFormBased } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { Form, Row, Col } from "antd";
import { useState, useEffect, useMemo, useCallback } from "react";

export interface SymbolMapCreateEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
    cleanSymbols: string[];
    symbolScope: number;
}

const initialValues = {
    symbol: "",
    cleanSymbol: "",
    suffix: "",
    isTestSymbol: false,
};

const SymbolMapCreateEditPage = (props: SymbolMapCreateEditPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [symbolForm] = Form.useForm();

    const symbolScopeProps = useMemo(() => ({ symbolScope: props.symbolScope }), [props.symbolScope]);

    const stateInfo = useMemo(() => {
        return {
            isAddAction: props.mode === InnerPageActionMode.CREATE_NEW,
            data: defaultIfEmptyOrNull(props.id, ""),
        };
    }, [props.mode, props.id]);

    const processBackAndRefetch = () => setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);

    const onSubmit = useCallback(
        (values: any) => {
            setIsLoading(true);
            if (stateInfo.isAddAction) {
                apiRequest(APIs.UPDATE_MT_SYMBOL, { ...values, ...symbolScopeProps })
                    .then(() => {
                        ErrorMessageHandler("New symbol", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new symbol", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                    )
                    .finally(() => setTimeout(() => setIsLoading(false), 500));
            } else {
                apiRequest(APIs.UPDATE_MT_SYMBOL, { ...values, ...symbolScopeProps })
                    .then(() => {
                        ErrorMessageHandler("Existing symbol", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing symbol", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    )
                    .finally(() => setTimeout(() => setIsLoading(false), 500));
            }
        },
        [stateInfo, symbolScopeProps]
    );

    const getSymbolInfo = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_MT_SYMBOL_LIST, { symbols: [stateInfo.data], ...symbolScopeProps })
            .then((data: any) => {
                if (data) {
                    symbolForm.setFieldsValue(data[0]);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                processBackAndRefetch();
            })
            .finally(() => setIsLoading(false));
    }, [stateInfo, symbolScopeProps]);

    useEffect(() => {
        if (!stateInfo.isAddAction) {
            getSymbolInfo();
        } else {
            symbolForm.setFieldsValue(initialValues);
        }
        return () => {};
    }, [stateInfo, getSymbolInfo, props.resetState]);

    return (
        <SitePageHeaderInline
            enableSubmit={!isLoading}
            title={stateInfo.isAddAction ? "Create New Symbol" : "Edit Symbol"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        symbolForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={symbolForm} layout="horizontal" initialValues={{}} onFinish={onSubmit}>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Symbol"
                                name="symbol"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    ...(!stateInfo.isAddAction && {
                                        inputProps: {
                                            readOnly: true,
                                        },
                                    }),
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Clean Symbol"
                                name="cleanSymbol"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: props.cleanSymbols.map(x => ({ text: x, value: x })),
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Suffix"
                                name="suffix"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label=" "
                                name="isTestSymbol"
                                extra={{
                                    type: ComponentType.checkbox,
                                    value: "Is Text Symbol",
                                    itemProps: {
                                        colon: false,
                                        style: {
                                            marginBottom: "0px",
                                        },
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
};

export default SymbolMapCreateEditPage;
