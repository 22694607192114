import { Space, Table } from "antd";
import React from "react";
import type { IRCPriceAlarmMiscStop } from "../../../hooks/useRCPriceAlarm";

import MessageCard from "../../SystemMonitor/components/MessageCard";
const columns = [
    {
        title: "Server",
        dataIndex: "appName",
        width: 200,
    },
    {
        title: "Occur Time",
        dataIndex: "sendTimeStr",
        width: 200,
    },
    {
        title: "Duration",
        dataIndex: "showStopTime",
        width: 200,
    },
];
const MiscStopTable = ({ data, isLoading, errorText }: { data: IRCPriceAlarmMiscStop[]; isLoading: boolean; errorText: string | null }) => {
    return (
        <div>
            {isLoading ? (
                <MessageCard type="info">Loading...</MessageCard>
            ) : errorText ? (
                <MessageCard type="error">{errorText}</MessageCard>
            ) : (
                <Space direction="vertical" style={{ width: "100%" }}>
                    {data?.length === 0 ? (
                        <MessageCard type="success">All good</MessageCard>
                    ) : (
                        <MessageCard type="error">{data.length} error</MessageCard>
                    )}
                    <Table bordered columns={columns} dataSource={data} rowKey="appName" pagination={false} size="small" />
                </Space>
            )}
        </div>
    );
};

export default MiscStopTable;
