import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
import { massageDailyData } from "./../pages/SystemMonitor/StatusMonitor/statusUtils";
const useRCStatusMonitorDaily = () => {
    //convert request to form data
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcStatusMonitorDaily"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_SYSTEM_MONITOR.GET_STATUS_MONITOR_DAILY}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        refetchInterval: 30 * 1000,
    });

    const rcStatusMonitorDaily = data?.data?.status ? [] : data?.data || [];
    const massagedData = rcStatusMonitorDaily ? massageDailyData(rcStatusMonitorDaily) : [];

    const totalErrorCount = massagedData.reduce((acc: number, item: any) => acc + item.errorCount, 0) ?? 0;
    const totalWarningCount = massagedData.reduce((acc: number, item: any) => acc + item.warningCount, 0) ?? 0;
    // @ts-ignore
    const rcStatusMonitorDailyError = data?.data?.message || error?.message || error || null;

    return {
        rcStatusMonitorDaily,
        rcStatusMonitorDailyTotalErrorCount: totalErrorCount,
        rcStatusMonitorDailyTotalWarningCount: totalWarningCount,
        rcStatusMonitorDailyError,
        refetchRcStatusMonitorDaily: refetch,
        isLoading,
        isFetching,
    };
};

export const useRCStatusMonitorRerun = () => {
    // get api, and invalid rcStatusMonitorDaily cache
    // const token = getToken();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, error } = useMutation(
        async ({ serverName, scheduleName }: { serverName: string; scheduleName: string | undefined }) => {
            if (!serverName || !scheduleName) {
                return Promise.reject("serverName or scheduleName is empty");
            }
            return plainAxiosInstance.get(
                `${APIs.RC_SYSTEM_MONITOR.GET_STATUS_MONITOR_RERUN}?serverName=${serverName}&scheduleName=${scheduleName}`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcStatusMonitorDaily"]);
                return res;
            },
            onError: error => {
                console.error(`Error on rerun:`, error);
                return error;
            },
        }
    );

    return {
        rerun: mutateAsync,
        isLoading,
        error,
    };
};

export default useRCStatusMonitorDaily;
