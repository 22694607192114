import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { Modal, Form, Row, Col } from "antd";
import { useEffect } from "react";

interface DownloadTemplateModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    servers: KeyValuePair[];
    symbols: KeyValuePair[];
    cleanSymbols: KeyValuePair[];
    symbolAssetType: KeyValuePair[];
}

export enum DownloadTemplateModalCallbackKey {
    Close = 0,
}

const DownloadTemplateModal = (props: DownloadTemplateModalProps) => {
    const [executeForm] = Form.useForm();

    const downloadTemplate = (values: any) => {
        apiRequest(APIs.DOWNLOAD_SYMBOL_TEMPLATE, values, "POST", "arraybuffer")
            .then((res: any) => {
                const fileName = res.headers["x-filename"];
                const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); // or any other extension
                // Append to html link element page
                document.body.appendChild(link);
                // start download
                link.click();
                // Clean up and remove the link
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol template", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
            });
    };

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldsValue({ downloadMode: "", serverIds: [], symbols: [], cleanSymbols: [], symbolAssetTypeIds: [] });
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            width={600}
            title="Download Template"
            open={props.isModalVisible}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => props.callback(DownloadTemplateModalCallbackKey.Close, null)}
            onOk={() => {
                executeForm
                    .validateFields()
                    .then(values => {
                        downloadTemplate(values);
                        props.callback(DownloadTemplateModalCallbackKey.Close, null);
                    })
                    .catch(errorInfo => console.log(errorInfo));
            }}
        >
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={executeForm} layout="horizontal" initialValues={{}}>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Mode"
                            name="downloadMode"
                            extra={{
                                type: ComponentType.dropdown,
                                value: [
                                    {
                                        text: "With Existing Symbols",
                                        value: 2,
                                    },
                                    {
                                        text: "Blank Template",
                                        value: 1,
                                    },
                                ],
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Server"
                            name="serverIds"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.servers,
                                inputProps: {
                                    mode: "multiple",
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Symbol"
                            name="symbols"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.symbols,
                                inputProps: {
                                    mode: "multiple",
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Clean Symbol"
                            name="cleanSymbols"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.cleanSymbols,
                                inputProps: {
                                    mode: "multiple",
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Symbol Asset Type"
                            name="symbolAssetTypeIds"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.symbolAssetType,
                                inputProps: {
                                    mode: "multiple",
                                },
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default DownloadTemplateModal;
