import { useEffect, useMemo, useRef, useState } from "react";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import {
    ClientAnalysisCandlestickChartDataValues,
    ClientAnalysisSymbolListByServer,
    ClientAnalysisTicketDetails,
    FlexiDataTableCallbackProps,
    KeyValuePair,
} from "@/constants/type";
import { Button, Col, Divider, Form, Radio, Row } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import LoadingComponent from "@/components/Loading";
import { init, dispose } from "klinecharts";
import moment from "moment";

interface KlineTabProps {
    params: any;
    isInitialLoading: boolean;
}

const KlineTab = (props: KlineTabProps) => {
    const [wholeData, setWholeData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(props.isInitialLoading);
    const [symbols, setSymbols] = useState<ClientAnalysisSymbolListByServer[]>([]);
    const [filterForm] = Form.useForm();
    const [isTicketDetailLoading, setIsTicketDetailLoading] = useState<boolean>(false);
    const [ticketData, setTicketData] = useState<ClientAnalysisTicketDetails[]>([]);
    const [tickets, setTickets] = useState<KeyValuePair[]>([]); // for filter options
    const [klineData, setKlineData] = useState<ClientAnalysisCandlestickChartDataValues[]>([]);
    const [hiddenChart, setHiddenChart] = useState<boolean>(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

    const klineChartRef = useRef<any>(null);
    const size = useWindowSize();

    const params = useMemo(() => (Object.values(props.params).some(item => (item as string) !== "") ? props.params : undefined), [props.params]);

    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
            width: 0,
            height: 0,
        });

        useEffect(() => {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            // Add event listener
            window.addEventListener("resize", handleResize);
            // Call handler right away so state gets updated with initial window size
            handleResize();
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
    }

    const columns = [
        DTColProps.XXSmall({
            title: "Ticket",
            dataIndex: "ticket",
            key: "ticket",
            // fixed: "left",
            width: "2vw",
            sorter: (a: any, b: any) => a.ticket - b.ticket,
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: tickets,
                },
            },
        }),
        DTColProps.XXSmall({
            title: "Entry",
            dataIndex: "dealMethod",
            key: "dealMethod",
            width: "2vw",
            sorter: (a: any, b: any) => a.dealMethod - b.dealMethod,
        }),
        DTColProps.XXSmall({
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: "2vw",
            sorter: (a: any, b: any) => a.ticket - b.ticket,
            render: (action: number) => (action === 0 ? "Buy" : "Sell"),
        }),
        DTColProps.XXSmall({
            title: "Price",
            dataIndex: "price",
            key: "price",
            width: "2vw",
            sorter: (a: any, b: any) => a.price - b.price,
        }),
        DTColProps.XXSmall({
            title: "Volume",
            dataIndex: "volume",
            key: "volume",
            width: "2vw",
            sorter: (a: any, b: any) => a.volume - b.volume,
        }),
        DTColProps.XXSmall({
            title: "PNL",
            dataIndex: "pnl",
            key: "pnl",
            width: "2vw",
            sorter: (a: any, b: any) => a.pnl - b.pnl,
            render: (pnl: number) => currencyRender(pnl, 5),
        }),
        DTColProps.XXSmall({
            title: "Time",
            dataIndex: "tradeTimeStr",
            key: "tradeTimeStr",
            sorter: (a: any, b: any) => a.tradeTimeStr - b.ticketradeTimeStrt,
        }),
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                onSelectChange(FormData.selectedRowKeys, FormData.selectedRows);
                setSelectedRowKeys(FormData.selectedRowKeys);
                break;
            default:
                break;
        }
    };

    const onSelectChange = async (key: string, row: any) => {
        if (row !== null) {
            let buyMarks = await getBuyMarksByData(wholeData);
            let buyPointMarks = await getBuyPointMarksByData(wholeData, row[0].ticket);
            let sellMarks = await getSellMarksByData(wholeData);
            let sellPointMarks = await getSellPointMarksByData(wholeData, row[0].ticket);
            await getCandlestickChart(null, buyMarks, sellMarks, buyPointMarks, sellPointMarks);
        }
    };

    // open new tab to show kline image
    const getKlinePicture = () => {
        let popup: any = window.open();
        popup.document.write('<img src="' + klineChartRef.current.getConvertPictureUrl(true, "png") + '" />');
        popup.document.location = "#";
    };

    const onTechnicalIndicatorChange = (e: any) => {
        if (klineChartRef.current !== null && klineChartRef.current !== undefined) {
            klineChartRef.current.createTechnicalIndicator(e.target.value, false, {
                id: "ext_technical_indicator",
            });
        }
    };

    const onFinished = async (values: any) => {
        let markAllData = await getCandlestickMarkByUser(values);
        if (markAllData !== null) {
            setWholeData(markAllData);
            let buyMarks = await getBuyMarksByData(markAllData);
            let buyPointMarks = await getBuyPointMarksByData(markAllData, null);
            let sellMarks = await getSellMarksByData(markAllData);
            let sellPointMarks = await getSellPointMarksByData(markAllData, null);
            await getCandlestickChart(values, buyMarks, sellMarks, buyPointMarks, sellPointMarks);
            getCandlestickUserTrades(values);
        }
    };

    const getCandlestickUserTrades = (obj: any) => {
        setIsTicketDetailLoading(true);
        const formData = new FormData();
        formData.append("oldServer", props.params.server);
        let symbolAry = obj.symbol.split("|");
        formData.append("symbol", symbolAry[0]);
        formData.append("login", props.params.login);
        formData.append("period", obj.period);
        formData.append("startDatetime", obj.rangeTime[0].format("YYYY-MM-DD HH:mm"));
        formData.append("endDatetime", obj.rangeTime[1].format("YYYY-MM-DD HH:mm"));
        plainAxiosInstance
            .post(`${APIs.RC_CLIENT_ANALYSIS.POST_CANDLESTICK_USER_TRADES}`, formData)
            .then((res: any) => {
                if (res.data) {
                    let newTicketData = res.data.map((x: any, i: number) => ({
                        key: x.ticket + "|" + x.tradeTime + "|" + i,
                        ...x,
                    }));
                    setTicketData(newTicketData);
                    let tickets = res.data.map((x: any) => ({
                        text: x.ticket,
                        value: x.ticket,
                    }));
                    setTickets(tickets);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("user trades", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setSymbols([]);
                })
            )
            .finally(() => setIsTicketDetailLoading(false));
    };

    const getBuyMarksByData = async (markAllData: any) => {
        return markAllData.buys.map((item: any) => {
            return {
                point: { timestamp: item.timestamp },
                drawExtend: (params: any) => {
                    const { ctx, coordinate, isActive } = params;
                    if (isActive) {
                        annotationBuyDrawExtend(
                            ctx,
                            coordinate,
                            item.trades.map((trade: any) => {
                                return trade.ticket + " " + trade.dealMethod + (trade.action === 0 ? " (Buy) " : " (Sell) ") + trade.price;
                            })
                        );
                    }
                },
                styles: {
                    position: "bottom",
                    symbol: {
                        type: "custom",
                    },
                },
                checkEventCoordinateOnCustomSymbol: ({
                    eventCoordinate,
                    coordinate,
                    size,
                }: {
                    eventCoordinate: { x: number; y: number };
                    coordinate: { x: number; y: number };
                    size: number;
                }) => {
                    return (
                        eventCoordinate.x > coordinate.x - 4 &&
                        eventCoordinate.x < coordinate.x + 4 &&
                        eventCoordinate.y > coordinate.y - 768 &&
                        eventCoordinate.y < coordinate.y + 768
                    );
                },
                drawCustomSymbol: ({
                    ctx,
                    point,
                    coordinate,
                    isActive,
                }: {
                    ctx: CanvasRenderingContext2D;
                    point: any;
                    coordinate: any;
                    isActive: boolean;
                }) => {
                    let color;
                    let size;
                    if (isActive) {
                        color = "#66f466";
                        size = 100;
                    } else {
                        color = "#7ce97c";
                        size = 100;
                    }
                    drawBuyMark(ctx, point, coordinate, color, size);
                },
            };
        });
    };

    const getBuyPointMarksByData = async (markAllData: any, selectedTicket: any) => {
        return markAllData.buys.map((item: any) => {
            return item.trades.map((trade: any) => {
                return {
                    point: { timestamp: item.timestamp, value: trade.price },
                    styles: {
                        position: "point",
                        symbol: {
                            type: selectedTicket !== null && trade.ticket === selectedTicket ? "triangle" : "circle",
                            size: selectedTicket !== null && trade.ticket === selectedTicket ? 20 : 5,
                            color: selectedTicket !== null && trade.ticket === selectedTicket ? "rgba(245, 217, 39, 1)" : "rgb(204,255,204,1)",
                            activeSize: selectedTicket !== null && trade.ticket === selectedTicket ? 20 : 5,
                            activeColor: selectedTicket !== null && trade.ticket === selectedTicket ? "rgba(245, 217, 39, 1)" : "rgb(204,255,204,1)",
                        },
                    },
                };
            });
        });
    };

    const getSellMarksByData = async (markAllData: any) => {
        return markAllData.sells.map((item: any) => {
            return {
                point: { timestamp: item.timestamp },
                drawExtend: (params: any) => {
                    const { ctx, coordinate, isActive } = params;
                    if (isActive) {
                        annotationSellDrawExtend(
                            ctx,
                            coordinate,
                            item.trades.map((trade: any) => {
                                return trade.ticket + " " + trade.dealMethod + (trade.action == 0 ? " (Buy) " : " (Sell) ") + trade.price;
                            })
                        );
                    }
                },
                styles: {
                    position: "top",
                    symbol: {
                        type: "custom",
                    },
                },
                checkEventCoordinateOnCustomSymbol: ({
                    eventCoordinate,
                    coordinate,
                    size,
                }: {
                    eventCoordinate: { x: number; y: number };
                    coordinate: { x: number; y: number };
                    size: number;
                }) => {
                    return (
                        eventCoordinate.x > coordinate.x - 4 &&
                        eventCoordinate.x < coordinate.x + 4 &&
                        eventCoordinate.y > coordinate.y - 768 &&
                        eventCoordinate.y < coordinate.y + 768
                    );
                },
                drawCustomSymbol: ({
                    ctx,
                    point,
                    coordinate,
                    isActive,
                }: {
                    ctx: CanvasRenderingContext2D;
                    point: any;
                    coordinate: any;
                    isActive: boolean;
                }) => {
                    let color;
                    let size;
                    if (isActive) {
                        color = "rgb(255,102,102,0.8)";
                        size = 100;
                    } else {
                        color = "rgb(255,153,153,0.8)";
                        size = 100;
                    }
                    drawSellMark(ctx, point, coordinate, color, size);
                },
            };
        });
    };

    const getSellPointMarksByData = async (markAllData: any, selectedTicket: any) => {
        return markAllData.sells.map((item: any) => {
            return item.trades.map((trade: any) => {
                return {
                    point: { timestamp: item.timestamp, value: trade.price },
                    styles: {
                        position: "point",
                        symbol: {
                            type: selectedTicket !== null && trade.ticket === selectedTicket ? "triangle" : "circle",
                            size: selectedTicket !== null && trade.ticket === selectedTicket ? 20 : 5,
                            color: selectedTicket !== null && trade.ticket === selectedTicket ? "rgba(245, 217, 39, 1)" : "rgb(255,153,153,1)",
                            activeSize: selectedTicket !== null && trade.ticket === selectedTicket ? 20 : 5,
                            activeColor: selectedTicket !== null && trade.ticket === selectedTicket ? "rgba(245, 217, 39, 1)" : "rgb(255,153,153,1)",
                        },
                    },
                };
            });
        });
    };

    const getCandlestickChart = async (
        obj: { symbol: string; period: string | Blob; rangeTime: { format: (arg0: string) => string | Blob }[] } | null,
        buyMarks: any,
        sellMarks: any,
        buyPointMarks: any,
        sellPointMarks: any
    ) => {
        if (obj !== null) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("oldServer", props.params.server);
            let symbolAry = obj.symbol.split("|");
            formData.append("symbol", symbolAry[0]);
            formData.append("digits", symbolAry[1]);
            formData.append("period", obj.period);
            formData.append("startDatetime", obj.rangeTime[0].format("YYYY-MM-DD HH:mm"));
            formData.append("endDatetime", obj.rangeTime[1].format("YYYY-MM-DD HH:mm"));
            return plainAxiosInstance
                .post(`${APIs.RC_CLIENT_ANALYSIS.POST_CANDLESTICK_CHART}`, formData)
                .then((res: any) => {
                    setKlineData(res.data.value);
                    drawKlineChart(res.data.value, buyMarks, sellMarks, buyPointMarks, sellPointMarks);
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler(`Failed to load candlestick data from manager API`, SUCCESS_FAILED.OTHERS_FAILED, err);
                        setKlineData([]);
                    })
                )
                .finally(() => {
                    setIsLoading(false);
                    setHiddenChart(false);
                });
        } else {
            drawKlineChart(klineData, buyMarks, sellMarks, buyPointMarks, sellPointMarks);
        }
    };

    const textColorDark = "#929AA5";
    const gridColorDark = "#292929";
    const axisLineColorDark = "#333333";
    const crossTextBackgroundColorDark = "#373a40";

    const getThemeOptions = () => {
        const textColor = textColorDark;
        const gridColor = gridColorDark;
        const axisLineColor = axisLineColorDark;
        const crossTextBackgroundColor = crossTextBackgroundColorDark;
        return {
            grid: {
                horizontal: {
                    color: gridColor,
                },
                vertical: {
                    color: gridColor,
                },
            },
            candle: {
                priceMark: {
                    high: {
                        color: textColor,
                    },
                    low: {
                        color: textColor,
                    },
                },
                tooltip: {
                    text: {
                        color: textColor,
                    },
                },
            },
            technicalIndicator: {
                tooltip: {
                    text: {
                        color: textColor,
                    },
                },
            },
            xAxis: {
                axisLine: {
                    color: axisLineColor,
                },
                tickLine: {
                    color: axisLineColor,
                },
                tickText: {
                    color: textColor,
                },
            },
            yAxis: {
                axisLine: {
                    color: axisLineColor,
                },
                tickLine: {
                    color: axisLineColor,
                },
                tickText: {
                    color: textColor,
                },
            },
            separator: {
                color: axisLineColor,
            },
            crosshair: {
                horizontal: {
                    line: {
                        color: "rgb(255,255,255,0.1)",
                    },
                    text: {
                        backgroundColor: crossTextBackgroundColor,
                    },
                },
                vertical: {
                    line: {
                        color: "rgb(255,255,255,0.1)",
                    },
                    text: {
                        backgroundColor: crossTextBackgroundColor,
                    },
                },
            },
        };
    };

    const getLanguageOptions = () => {
        return {
            candle: {
                tooltip: {
                    labels: ["T : ", "O : ", "C : ", "H : ", "L : ", "V : "],
                },
            },
        };
    };

    // Draw Kline
    const drawKlineChart = (
        wholeData: ClientAnalysisCandlestickChartDataValues[],
        buyMarks: any,
        sellMarks: any,
        buyPointMarks: any,
        sellPointMarks: any
    ) => {
        if (isEmptyOrNull(klineChartRef.current)) {
            klineChartRef.current = init("technical-indicator-k-line");
        }
        //klineChartRef.current = init("technical-indicator-k-line");
        klineChartRef.current.applyNewData(wholeData); // when using applyNewData, no need to call clearData
        klineChartRef.current.removeAnnotation();
        klineChartRef.current.createAnnotation(buyMarks, "candle_pane");
        klineChartRef.current.createAnnotation(sellMarks, "candle_pane");

        let buyPoints = getPointMarksByData(buyPointMarks);
        let sellPoints = getPointMarksByData(sellPointMarks);
        klineChartRef.current.createAnnotation(buyPoints, "candle_pane");
        klineChartRef.current.createAnnotation(sellPoints, "candle_pane");

        klineChartRef.current.createTechnicalIndicator("MA", false, {
            id: "candle_pane",
        });
        klineChartRef.current.createTechnicalIndicator("VOL", true, {
            id: "ext_technical_indicator",
        });
        klineChartRef.current.setStyleOptions(getThemeOptions());
        klineChartRef.current.setStyleOptions(getLanguageOptions());
        klineChartRef.current.setPriceVolumePrecision(5, 0);
        klineChartRef.current.setDataSpace(500);
        klineChartRef.current.setTimezone("GMT+0");
        klineChartRef.current.resize();
        //setKlineObj(klineChartRef.current);
    };

    const getPointMarksByData = (pointMarksMap: any) => {
        let result: any[] = [];
        pointMarksMap.forEach((pointMarks: any) => {
            pointMarks.forEach((item: any) => {
                result.push(item);
            });
        });
        return result;
    };

    const getCandlestickMarkByUser = async (obj: any): Promise<any> => {
        const formData = new FormData();
        formData.append("oldServer", props.params.server);
        let symbolAry = obj.symbol.split("|");
        formData.append("symbol", symbolAry[0]);
        formData.append("login", props.params.login);
        formData.append("period", obj.period);
        formData.append("startDatetime", obj.rangeTime[0].format("YYYY-MM-DD HH:mm"));
        formData.append("endDatetime", obj.rangeTime[1].format("YYYY-MM-DD HH:mm"));
        return plainAxiosInstance
            .post(`${APIs.RC_CLIENT_ANALYSIS.POST_CANDLESTICK_MARK_BY_USER}`, formData)
            .then((res: any) => {
                if (res.data) {
                    return res.data;
                } else return null;
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler(`Failed to load candlestick marks by user trade`, SUCCESS_FAILED.OTHERS_FAILED, err)
                )
            );
    };

    const koHalfWidth = 10;
    const koBottomWidth = 3;
    const koHeight = 20;

    const drawSellMark = (ctx: any, point: any, coordinate: any, color: string, size: number) => {
        const x = coordinate.x;
        const y = coordinate.y;

        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x + koHalfWidth, y - koHalfWidth);
        ctx.lineTo(x + koBottomWidth, y - koHalfWidth);
        ctx.lineTo(x + koBottomWidth, y - koHeight);
        ctx.lineTo(x - koBottomWidth, y - koHeight);
        ctx.lineTo(x - koBottomWidth, y - koHalfWidth);
        ctx.lineTo(x - koHalfWidth, y - koHalfWidth);
        ctx.closePath();
        ctx.fill();
    };

    const drawBuyMark = (ctx: any, point: any, coordinate: any, color: string, size: number) => {
        const x = coordinate.x;
        const y = isEmptyOrNull(klineChartRef.current) ? 620 : klineChartRef.current.getHeight().candle_pane - 20;

        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x + koHalfWidth, y + koHalfWidth);
        ctx.lineTo(x + koBottomWidth, y + koHalfWidth);
        ctx.lineTo(x + koBottomWidth, y + koHeight);
        ctx.lineTo(x - koBottomWidth, y + koHeight);
        ctx.lineTo(x - koBottomWidth, y + koHalfWidth);
        ctx.lineTo(x - koHalfWidth, y + koHalfWidth);
        ctx.closePath();
        ctx.fill();
    };

    const annotationSellDrawExtend = (ctx: any, coordinate: any, text: string) => {
        ctx.fillStyle = "#000000"; // Set text color to black
        const textWidth = ctx.measureText(text).width / text.length; // Use ctx.measureText for accurate width calculation
        const startX = coordinate.x;
        let startY = coordinate.y + 2;

        // Draw the rectangle with rounded corners (background)
        const rectX = startX - textWidth / 2 - 6; // Center the rectangle based on text width
        const rectY = startY;
        const rectWidth = textWidth + 12; // Add padding around the text
        const rectHeight = 20 * text.length; // Height of the rectangle (constant for consistency)
        const r = 2; // Corner radius for rounded corners

        // Set background color
        ctx.fillStyle = "#999999";
        ctx.beginPath();
        ctx.moveTo(rectX + r, rectY);
        ctx.arcTo(rectX + rectWidth, rectY, rectX + rectWidth, rectY + rectHeight, r);
        ctx.arcTo(rectX + rectWidth, rectY + rectHeight, rectX, rectY + rectHeight, r);
        ctx.arcTo(rectX, rectY + rectHeight, rectX, rectY, r);
        ctx.arcTo(rectX, rectY, rectX + rectWidth, rectY, r);
        ctx.closePath();
        ctx.fill();

        // Draw the text centered inside the background
        ctx.fillStyle = "#000000"; // Text color (black)
        ctx.textBaseline = "middle"; // Center vertically
        ctx.textAlign = "center"; // Center horizontally
        for (let i = 0; i < text.length; i++) {
            ctx.fillText(text[i], startX, 10 + startY + 20 * i);
        }
    };

    const annotationBuyDrawExtend = (ctx: any, coordinate: any, text: string) => {
        ctx.fillStyle = "#000000"; // Text color (black)
        const textWidth = ctx.measureText(text).width / text.length; // Measure the width of the text
        const startX = coordinate.x;
        let startY = coordinate.y - 40;

        // Draw the rectangle with rounded corners (background)
        const rectX = startX - textWidth / 2 - 6; // Center the rectangle based on text width
        const rectY = startY;
        const rectWidth = textWidth + 12; // Add padding around the text
        const rectHeight = -20 * text.length; // Fixed height for consistency
        const r = 2; // Corner radius for rounded corners

        // Set background color
        ctx.fillStyle = "#999999";
        ctx.beginPath();
        ctx.moveTo(rectX + r, rectY);
        ctx.arcTo(rectX + rectWidth, rectY, rectX + rectWidth, rectY + rectHeight, r);
        ctx.arcTo(rectX + rectWidth, rectY + rectHeight, rectX, rectY + rectHeight, r);
        ctx.arcTo(rectX, rectY + rectHeight, rectX, rectY, r);
        ctx.arcTo(rectX, rectY, rectX + rectWidth, rectY, r);
        ctx.closePath();
        ctx.fill();

        // Draw the text centered inside the background
        ctx.fillStyle = "#000000"; // Text color (white)
        ctx.textBaseline = "middle"; // Center vertically
        ctx.textAlign = "center"; // Center horizontally
        for (let i = 0; i < text.length; i++) {
            ctx.fillText(text[i], startX, -10 + startY - 20 * i);
        }
    };

    const getServerSymbols = () => {
        setIsLoading(true);
        const formData = new FormData();
        if (params && !isEmptyOrNull(params)) {
            formData.append("oldServer", params.server);
            plainAxiosInstance
                .post(`${APIs.RC_CLIENT_ANALYSIS.POST_SYMBOLS_BY_SERVER}`, formData)
                .then((res: any) => {
                    setSymbols(
                        res.data.length > 0
                            ? res.data.map((x: ClientAnalysisSymbolListByServer) => ({
                                  text: x.symbol,
                                  value: x.symbol + "|" + x.digits,
                              }))
                            : []
                    );
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("symbols list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                        setSymbols([]);
                    })
                )
                .finally(() => setIsLoading(false));
        } else setIsLoading(false);
    };

    useEffect(() => {
        getServerSymbols();
        return () => {
            dispose("technical-indicator-k-line");
        };
    }, []);

    // listen windows size change to fix klineChart rwd
    useEffect(() => {
        if (klineChartRef.current !== null && klineChartRef.current !== undefined) {
            klineChartRef.current.resize();
        }
    }, [size]);

    return (
        <div className="kline-tab-container">
            <div className="top-search-panel">
                <Form form={filterForm} layout="vertical" initialValues={{}} onFinish={onFinished}>
                    <Row gutter={24} style={{ width: "100%" }}>
                        <Col xl={8} lg={12} md={12} sm={24}>
                            <FormComponent
                                label="Symbol"
                                name="symbol"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: symbols,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                        <Col xl={8} lg={12} md={12} sm={24}>
                            <Form.Item
                                className="form-radio"
                                label={"Period"}
                                name={"period"}
                                rules={[{ required: true, message: REQUIRED_FIELD }]}
                                initialValue={!params.server.startsWith("MT5") ? "5" : "1"}
                            >
                                <Radio.Group>
                                    <Radio.Button value="1">1m</Radio.Button>
                                    {!params.server.startsWith("MT5") ? (
                                        <>
                                            <Radio.Button value="5">5m</Radio.Button>
                                            <Radio.Button value="15">15m</Radio.Button>
                                            <Radio.Button value="30">30m</Radio.Button>
                                            <Radio.Button value="60">1H</Radio.Button>
                                            <Radio.Button value="240">4H</Radio.Button>
                                            <Radio.Button value="1440">1D</Radio.Button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xl={8} lg={12} md={12} sm={24}>
                            <FormComponent
                                label="Date Range"
                                name="rangeTime"
                                extra={{
                                    type: ComponentType.daterange,
                                    value: [],
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: {
                                        showTime: true,
                                        format: "YYYY-MM-DD HH:mm",
                                        ranges: {
                                            Today: [moment().startOf("day"), moment()],
                                            "This Week": [moment().startOf("week"), moment()],
                                            "This Month": [moment().startOf("month"), moment()],
                                        },
                                    },
                                }}
                            />
                        </Col>
                        <Divider orientation="left" className="divider-nogap"></Divider>
                        <Row style={{ width: "100%" }}>
                            <Col span={24} key="flt-f-c-btns" className="filter-btns">
                                <Button
                                    type="default"
                                    onClick={e => {
                                        e.preventDefault();
                                        filterForm.resetFields();
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button type="primary" htmlType="submit" loading={isLoading}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                </Form>
            </div>
            <Row className="k-line-chart-row">
                <Col span={16} id="technical-indicator-k-line" style={{ height: "700px" }} className="k-line-chart">
                    <Col className="k-line-loading" hidden={!isLoading}>
                        <div className="loading-container">
                            <LoadingComponent tip="Loading..." />
                        </div>
                    </Col>
                </Col>
                <Col span={8} className="right-panel">
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="key"
                        title={false}
                        columns={columns}
                        options={{
                            showHideColumns: false,
                            defaultCollapseFilterPanel: true,
                            enableRowSelection: true,
                            rowSelectionData: {
                                rowSelectionType: "radio",
                                selectedRowKeys: selectedRowKeys,
                            },
                        }}
                        dataSource={ticketData ?? []}
                        callback={componentCallback}
                        loading={isTicketDetailLoading}
                        scroll={{ y: 610 }}
                        pagination={false}
                    />
                </Col>
                <Col span={24} hidden={hiddenChart}>
                    <div className="bottom-extra-buttons">
                        <Radio.Group buttonStyle="solid" onChange={onTechnicalIndicatorChange} defaultValue="VOL">
                            <Radio.Button value="VOL">VOL</Radio.Button>
                            <Radio.Button value="MACD">MACD</Radio.Button>
                            <Radio.Button value="KDJ">KDJ</Radio.Button>
                        </Radio.Group>
                        <Button className="view-chart-btn" icon={<CameraOutlined />} onClick={getKlinePicture} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default KlineTab;
