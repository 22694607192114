import React, { useRef, useState } from "react";
import { Column, Line } from "@ant-design/plots";
import { Alert, Typography, Space, Tag, Tooltip, Button } from "antd";
import { APIs } from "../../../services/apis";
import { motion } from "framer-motion";
import { isEqual } from "lodash";
import useRCSpreadReportStore from "../../../store/useRCSpreadReportStore";
import useRCSpreadCompare from "../../../hooks/useRCSpreadCompare";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { getToken } from "../../../services/localStorage";
import moment from "moment";
import { getFileNameFromResponseHeader } from "@/utils/Common";
const SpreadAnalysisRecords: React.FC = () => {
    const { Title } = Typography;
    const queryRecords = useRCSpreadReportStore(state => state.queryRecords);
    const spreadReportQuery = useRCSpreadReportStore(state => state.spreadReportQuery);
    const chartType = useRCSpreadReportStore(state => state.chartType);
    const [applyDummy, setApplyDummy] = useState(false);
    const { spreadCompare, spreadCompareError, refetchSpreadCompare, isLoading } = useRCSpreadCompare(
        !spreadReportQuery
            ? { symbol: null, start: "", end: "", groups: [], useDummy: 1 }
            : {
                  ...spreadReportQuery,
                  useDummy: applyDummy ? 1 : null,
                  // symbol: "XAUUSD",
                  // start: "2024-04-03",
                  // end: "2024-04-03",
                  // groups: ["MT5_FCA_ECN", "MT5_FCA_INST_ECN", "Exness-MT5-STP", "LP_AU_OKCOIN"],
              }
    );

    const config = {
        data: [...spreadCompare],
        isGroup: true,
        xField: "time",
        yField: "spread",
        seriesField: "name",
        // 分组柱状图 组内柱子间的间距 (像素级别)
        dodgePadding: 2,
        // 分组柱状图 组间的间距 (像素级别)
        intervalPadding: 20,
        label: {
            // 可手动配置 label 数据标签位置
            position: "middle",
            // 'top', 'middle', 'bottom'
            // 可配置附加的布局方法
            layout: [
                // 柱形图数据标签位置自动调整
                {
                    type: "interval-adjust-position",
                }, // 数据标签防遮挡
                {
                    type: "interval-hide-overlap",
                }, // 数据标签文颜色自动调整
                {
                    type: "adjust-color",
                },
            ],
        },
        slider: {
            start: 0,
            end: 0.05,
        },
    };

    const configLine = {
        data: [...spreadCompare],
        xField: "time",
        yField: "spread",
        seriesField: "name",
        slider: {
            start: 0,
            end: 1,
        },
    };

    // console.log("process?.env?.REACT_APP_ENV ", process?.env?.REACT_APP_ENV);
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {/* toggle use dummy data */}
            {/* <Button
                type="dashed"
                onClick={() => {
                    setApplyDummy(prev => !prev);
                }}
            >
                Dummy Data ({applyDummy ? "ON" : "OFF"})
            </Button> */}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Title level={5}>Analysis</Title>
            </div>

            {queryRecords.length >= 1 && (
                <DraggableContainer>
                    {queryRecords.map((record, index) => {
                        const isHighlight = isEqual(record, spreadReportQuery);
                        return (
                            <RecordCard
                                key={record.start + record.end + record.groups.join("") + record.symbol + index + isHighlight}
                                isHighlight={isHighlight}
                                record={record}
                            />
                        );
                    })}
                </DraggableContainer>
            )}

            {!spreadReportQuery ? (
                <Alert message="Please generate a report first" type="warning" />
            ) : spreadCompare ? (
                <div id="chartContainer">
                    <div style={{ width: "100%", backgroundColor: "rgba(240,242,245,0.5)", padding: "1rem", borderRadius: "5px" }}>
                        {spreadCompareError && (
                            <Alert
                                message="Failed to load data"
                                description={"Error Message : " + spreadCompareError}
                                type="error"
                                action={
                                    <Button size="small" type="text" onClick={() => refetchSpreadCompare()}>
                                        Retry
                                    </Button>
                                }
                            />
                        )}
                        {chartType === "line" ? (
                            /* @ts-ignore  */
                            <Line {...configLine} />
                        ) : chartType === "bar" ? (
                            /* @ts-ignore  */
                            <Column {...config} />
                        ) : null}
                    </div>
                </div>
            ) : isLoading ? (
                "Loading..."
            ) : (
                "no data"
            )}
        </Space>
    );
};

const DraggableContainer = ({ children }: { children: any }) => {
    const ref = useRef(null);
    return (
        <div className="container" ref={ref} style={{ overflow: "hidden" }}>
            <motion.div
                className="scroller"
                drag="x"
                dragConstraints={ref}
                onMeasureDragConstraints={console.log}
                style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    minWidth: "100%",
                    // fit the width of the children
                    width: "fit-content",
                }}
            >
                {children}
            </motion.div>
        </div>
    );
};

const RecordCard = ({ isHighlight = false, record }: { isHighlight: boolean; record: any }) => {
    // const { manuallyUpdateSpreadReportQuery, removeQueryRecord } = useRCSpreadReportStore();
    const manuallyUpdateSpreadReportQuery = useRCSpreadReportStore(state => state.manuallyUpdateSpreadReportQuery);
    const removeQueryRecord = useRCSpreadReportStore(state => state.removeQueryRecord);

    const { Title, Text } = Typography;
    const color = { color: isHighlight ? "#fff" : "#000" };
    const token = getToken();
    const handleDownload = () => {
        const req = { symbol: record.symbol, groups: record.groups.join(","), startDate: record.start, endDate: record.end };
        axios
            .post(
                `${process.env.REACT_APP_API_URL}${APIs.RC_SPREAD.POST_SPREAD_COMPARE_DOWNLOAD}`,
                { ...req },
                {
                    headers: {
                        Accept: "application/octet-stream, */*",
                        // "Content-type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: "blob",
                }
            )
            .then(response => {
                const contentType = response.headers["content-type"];
                const fileName = getFileNameFromResponseHeader(
                    response,
                    `symbol_analysis_report_${record.symbol}_${record.start}_${record.end}.xlsx`
                );
                if (contentType === "application/octet-stream") {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                } else {
                    // Handle the response as non-file data
                    console.log("Received non-file response:", response);
                    // For example, process JSON data
                    // const jsonData = JSON.parse(response.data);
                    // console.log('JSON data:', jsonData);
                }
            })
            .catch(err => {
                console.log("download error", err);
            });
    };

    const recordStart = // check if it's time stamp with T, convert it to YYYY-MM-DD
        record?.start?.includes("T") ? moment(record.start).format("YYYY-MM-DD") : record?.start ?? null;
    const recordEnd = // check if it's time stamp with T, convert it to YYYY-MM-DD
        record?.end?.includes("T") ? moment(record.end).format("YYYY-MM-DD") : record?.end ?? null;

    return (
        <motion.div
            initial={{ scale: 0.98 }}
            animate={{ scale: 0.98 }}
            style={{
                scale: 0.98,
                flex: "0 0 auto",
                width: "200px",
                height: "200px",
                padding: "0.5rem",
                marginRight: "10px",
                backgroundColor: isHighlight ? "#00615D" : "#f0f0f0",
                color: isHighlight ? "#fff" : "#000",
                // border: isHighlight ? "1px solid #ccc" : "1px solid transparent",
                borderRadius: "4px",
                overflowY: "auto",
                cursor: "pointer",
            }}
            whileHover={{ scale: 1 }}
            onClick={() => {
                manuallyUpdateSpreadReportQuery(record);
            }}
        >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Title level={5} style={{ ...color, marginBottom: 0 }}>
                    {record?.symbol}
                </Title>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.25rem" }}>
                    <Tooltip title="download">
                        <Button
                            size="small"
                            type="default"
                            shape="default"
                            icon={<DownloadOutlined />}
                            onClick={e => {
                                e.stopPropagation();
                                handleDownload();
                            }}
                        />
                    </Tooltip>
                    {!isHighlight && (
                        <Tooltip title="remove">
                            <Button
                                size="small"
                                type="default"
                                shape="default"
                                icon={<DeleteOutlined />}
                                disabled={isHighlight}
                                onClick={e => {
                                    e.stopPropagation();
                                    removeQueryRecord(record);
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
            <Text style={{ ...color }}>
                <strong>Start:</strong> {recordStart}
            </Text>
            <br />
            <Text style={{ ...color }}>
                <strong>End:</strong> {recordEnd}
            </Text>
            <br />
            <div style={{ display: "flex", gap: "2px", flexWrap: "wrap" }}>
                {["2", "4", "1"].map((group: string) => {
                    return (
                        record?.originalGroups?.[group]?.map((each: string, index: number) => {
                            const tagColor: { [key: string]: string } = {
                                "1": "blue",
                                "2": "green",
                                "4": "red",
                            };
                            const groupNaming: { [key: string]: string } = {
                                "1": "Liquidity Provider",
                                "2": "Our Servers",
                                "4": "Outsider Brokers",
                            };
                            const prefix: { [key: string]: string } = {
                                "1": "LP",
                                "2": "OS",
                                "4": "OB",
                            };

                            return (
                                <Tooltip title={groupNaming[group]} key={each + index}>
                                    <Tag key={each + index} color={tagColor[group]} style={{ margin: 0 }}>
                                        <strong style={{ color: tagColor[group], padding: "0 2px" }}>{prefix[group]}</strong> {each}
                                    </Tag>
                                </Tooltip>
                            );
                        }) ?? []
                    ); // Use an empty array if the group doesn't exist
                })}
            </div>
        </motion.div>
    );
};

// export default SpreadAnalysisRecords;
export default React.memo(SpreadAnalysisRecords);
