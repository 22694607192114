import { useCallback, useEffect, useMemo, useState } from "react";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "../../../../components/PageHeader/inlineIndex";
import {
    AttributeProps,
    BrandsList,
    CleanSymbolsList,
    CreateEditInlineFormBased,
    CrmBrandList,
    KeyValuePair,
    RegulatorList,
    ToxicClientCheckAccountDetails,
    ToxicClientCheckAttrDetails,
    ToxicClientCheckEntryFormProps,
    ToxicClientCheckEntryLabelAttrValues,
    ToxicClientLabelProps,
    TccCommonFilterSourceList,
    UsersList,
} from "../../../../constants/type";
import {
    ComponentType,
    InnerPageActionMode,
    SUCCESS_FAILED,
    TIMEZONE_FORMATS,
    TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM,
    TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE,
    TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM,
    TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM_ENUM,
    TOXIC_CLIENT_CHECK_ENTRY_STATUS,
    TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM,
} from "../../../../constants";
import LoadingComponent from "../../../../components/Loading";
import { Button, Col, Form, message, Modal, Popconfirm, Row } from "antd";
import { FormComponent } from "../../../../components/FormComponent";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { ToOptionTypeList } from "../../../../utils/array";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { isEmptyOrNull } from "../../../../utils/string";
import { cloneDeep } from "lodash";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import moment from "moment";
import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";

interface ToxicClientCheckEntryCreateEditProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
    servers: any[];
    brands: BrandsList[];
    crmBrands: CrmBrandList[];
    inspectors: UsersList[];
    regulators: RegulatorList[];
    tcLabelProps: ToxicClientLabelProps[];
    backActiveSegment?: string;
    filterParams?: any;
    cleanSymbols: CleanSymbolsList[];
    tcSources: TccCommonFilterSourceList[];
}

interface TCCAccountDetailsExtra extends ToxicClientCheckAccountDetails {
    serverName?: string;
    brandName?: string;
    crmMappingModeName: string;
    crmBrandName?: string;
}

const devVantageBrands = [
    { brand: "Vantage", id: 7 },
    { brand: "Vantage APAC", id: 16 },
];
const prodVantageBrands = [
    { brand: "Vantage", id: 7 },
    { brand: "Vantage APAC", id: 16 },
];

const ToxicClientCheckEntryCreateEdit = (props: ToxicClientCheckEntryCreateEditProps) => {
    const isAddAction = useMemo(() => props.mode === InnerPageActionMode.CREATE_NEW, [props.mode]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tccEntryForm] = Form.useForm();
    const crmMappingMode = Form.useWatch("crmMappingMode", tccEntryForm);
    const currTcLabelId = Form.useWatch("labelId", tccEntryForm);
    const [fParams, setFParams] = useState<any>(undefined);
    const [currEntryInfo, setCurrEntryInfo] = useState<TCCAccountDetailsExtra | undefined>(undefined);
    const currPickupTeam = Form.useWatch("pickupTeam", tccEntryForm);
    const [tcLabels, setTcLabels] = useState<ToxicClientLabelProps[]>([]);
    const [tcSources, setTcSources] = useState<TccCommonFilterSourceList[]>([]);

    // for brand confirmation modal
    const [finalBrandForm] = Form.useForm();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [payloadData, setPayloadData] = useState<any>(null);
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

    const isSymbolRequiredFlag = useMemo(() => {
        let thisLabelObj = props.tcLabelProps.find((x: ToxicClientLabelProps) => x.labelId === currTcLabelId);
        if (thisLabelObj !== undefined) return thisLabelObj.isSymbolRequired;
        else return false;
    }, [currTcLabelId, props.tcLabelProps]);

    const displayAttrConfigForm = (tcLabelId: number) => {
        let thisLabelObj = props.tcLabelProps.find((x: ToxicClientLabelProps) => x.labelId === tcLabelId);
        if (thisLabelObj !== undefined) {
            if (thisLabelObj.labelAttrs.length > 0) {
                return thisLabelObj.labelAttrs.map((x: AttributeProps) => {
                    switch (x.uiElementType) {
                        case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.TextArea:
                            return (
                                <Row key={`tccle-${thisLabelObj && thisLabelObj.labelId}-${x.attrId}`}>
                                    <Col span={22}>
                                        <FormComponent
                                            label={x.attrName}
                                            name={`tccle-${x.attrId}`}
                                            extra={{
                                                type: ComponentType.textarea,
                                                value: "",
                                                rules: [{ required: x.isMandatory, message: REQUIRED_FIELD }],
                                                disabled: x.isAttrActive === false,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.IntegerNumericField:
                            return (
                                <Row key={`tccle-${thisLabelObj && thisLabelObj.labelId}-${x.attrId}`}>
                                    <Col span={22}>
                                        <FormComponent
                                            label={x.attrName}
                                            name={`tccle-${x.attrId}`}
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                                rules: [{ required: x.isMandatory, message: REQUIRED_FIELD }],
                                                disabled: x.isAttrActive === false,
                                                inputProps: {
                                                    style: { width: "100%" },
                                                    precision: 0,
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DecimalNumericField:
                            return (
                                <Row key={`tccle-${thisLabelObj && thisLabelObj.labelId}-${x.attrId}`}>
                                    <Col span={22}>
                                        <FormComponent
                                            label={x.attrName}
                                            name={`tccle-${x.attrId}`}
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                                rules: [{ required: x.isMandatory, message: REQUIRED_FIELD }],
                                                disabled: x.isAttrActive === false,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DropdownMenu:
                            return (
                                <Row key={`tccle-${thisLabelObj && thisLabelObj.labelId}-${x.attrId}`}>
                                    <Col span={22}>
                                        <FormComponent
                                            label={x.attrName}
                                            name={`tccle-${x.attrId}-dropdown-${x.isMultiselectable ? "multi" : "single"}`}
                                            extra={{
                                                type: ComponentType.dropdown,
                                                value: x.options?.map((y: string) => ({ text: y, value: y })),
                                                rules: [{ required: x.isMandatory, message: REQUIRED_FIELD }],
                                                disabled: x.isAttrActive === false,
                                                inputProps: {
                                                    mode: `${x.isMultiselectable === true ? "multiple" : ""}`,
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.Checkbox:
                            return (
                                <Row key={`tccle-${thisLabelObj && thisLabelObj.labelId}-${x.attrId}`}>
                                    <Col span={22}>
                                        <FormComponent
                                            label={x.attrName}
                                            name={`tccle-${x.attrId}`}
                                            extra={{
                                                type: ComponentType.checkboxgroup,
                                                value: x.options?.map(y => ({ text: y, value: y })),
                                                rules: [{ required: x.isMandatory, message: REQUIRED_FIELD }],
                                                disabled: x.isAttrActive === false,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.RadioButton:
                            return (
                                <Row key={`tccle-${thisLabelObj && thisLabelObj.labelId}-${x.attrId}`}>
                                    <Col span={22}>
                                        <FormComponent
                                            label={x.attrName}
                                            name={`tccle-${x.attrId}`}
                                            extra={{
                                                type: ComponentType.radio,
                                                value: x.options?.map(y => ({ text: y, value: y })),
                                                rules: [{ required: x.isMandatory, message: REQUIRED_FIELD }],
                                                disabled: x.isAttrActive === false,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.ToggleSwitcher:
                            return (
                                <Row key={`tccle-${thisLabelObj && thisLabelObj.labelId}-${x.attrId}`}>
                                    <Col span={22}>
                                        <FormComponent
                                            label={x.attrName}
                                            name={`tccle-${x.attrId}-switch`}
                                            extra={{
                                                type: ComponentType.switch,
                                                value: ["No", "Yes"],
                                                disabled: x.isAttrActive === false,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker:
                        case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DateTimePicker:
                            return (
                                <Row key={`tccle-${thisLabelObj && thisLabelObj.labelId}-${x.attrId}`}>
                                    <Col span={22}>
                                        <FormComponent
                                            label={`${x.attrName} (${TIMEZONE_FORMATS[x.timeZoneFormat as number]})`}
                                            name={`tccle-${x.attrId}-${
                                                x.uiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker ? "date" : "datetime"
                                            }`}
                                            extra={{
                                                type: ComponentType.date,
                                                value: "",
                                                dateFormat: `${
                                                    x.uiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker
                                                        ? "YYYY-MM-DD"
                                                        : "YYYY-MM-DD HH:mm:ss"
                                                }`,
                                                rules: [{ required: x.isMandatory, message: REQUIRED_FIELD }],
                                                disabled: x.isAttrActive === false,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.TimePicker:
                            return (
                                <Row key={`tccle-${thisLabelObj && thisLabelObj.labelId}-${x.attrId}`}>
                                    <Col span={22}>
                                        <FormComponent
                                            label={x.attrName}
                                            name={`tccle-${x.attrId}-time`}
                                            extra={{
                                                type: ComponentType.time,
                                                value: "",
                                                rules: [{ required: x.isMandatory, message: REQUIRED_FIELD }],
                                                disabled: x.isAttrActive === false,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.TextField:
                        default:
                            return (
                                <Row key={`tccle-${thisLabelObj && thisLabelObj.labelId}-${x.attrId}`}>
                                    <Col span={22}>
                                        <FormComponent
                                            label={x.attrName}
                                            name={`tccle-${x.attrId}`}
                                            extra={{
                                                type: ComponentType.text,
                                                value: "",
                                                rules: [{ required: x.isMandatory, message: REQUIRED_FIELD }],
                                                disabled: x.isAttrActive === false,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                    }
                });
            }
        } else
            return (
                <>
                    <FormComponent label="" name="isSymbolRequired" extra={{ type: "hidden", value: "" }} />
                </>
            );
    };

    const onFormSubmit = useCallback(
        (values: any) => {
            let clonedValues: ToxicClientCheckEntryFormProps = cloneDeep(values);
            let labelAttrsWithValues: ToxicClientCheckEntryLabelAttrValues[] = [];
            let newObjWithoutNumKeys: { [key: string]: any } = {};
            Object.keys(clonedValues).forEach((currKey: any) => {
                let currKeyArr: any[] = currKey.split("-");
                if (currKeyArr[0].includes("tccle")) {
                    if (currKeyArr.length > 2) {
                        switch (currKeyArr[2]) {
                            case "dropdown":
                                if (clonedValues[currKey as keyof typeof clonedValues] !== undefined) {
                                    labelAttrsWithValues.push({
                                        attrId: Number(currKeyArr[1]),
                                        attrValue: Array.isArray(clonedValues[currKey as keyof typeof clonedValues])
                                            ? clonedValues[currKey as keyof typeof clonedValues]
                                            : [clonedValues[currKey as keyof typeof clonedValues]],
                                    });
                                }
                                break;
                            case "switch":
                                labelAttrsWithValues.push({
                                    attrId: Number(currKeyArr[1]),
                                    attrValue:
                                        clonedValues[currKey as keyof typeof clonedValues] === undefined
                                            ? false
                                            : clonedValues[currKey as keyof typeof clonedValues],
                                });
                                break;
                            case "date":
                                let dateValue = clonedValues[currKey as keyof typeof clonedValues];
                                if (clonedValues[currKey as keyof typeof clonedValues] !== undefined) {
                                    labelAttrsWithValues.push({
                                        attrId: Number(currKeyArr[1]),
                                        attrValue: (dateValue as unknown as moment.Moment).format("YYYY-MM-DD"),
                                    });
                                }
                                break;
                            case "time":
                                let timeValue = clonedValues[currKey as keyof typeof clonedValues];
                                if (clonedValues[currKey as keyof typeof clonedValues] !== undefined) {
                                    labelAttrsWithValues.push({
                                        attrId: Number(currKeyArr[1]),
                                        attrValue: (timeValue as unknown as moment.Moment).format("HH:mm:ss"),
                                    });
                                }
                                break;
                            case "datetime":
                                let datetimeValue = clonedValues[currKey as keyof typeof clonedValues];
                                if (clonedValues[currKey as keyof typeof clonedValues] !== undefined) {
                                    labelAttrsWithValues.push({
                                        attrId: Number(currKeyArr[1]),
                                        attrValue: (datetimeValue as unknown as moment.Moment).format("YYYY-MM-DDTHH:mm:ss"),
                                    });
                                }
                                break;
                        }
                    } else {
                        if (clonedValues[currKey as keyof typeof clonedValues] !== undefined) {
                            labelAttrsWithValues.push({
                                attrId: Number(currKeyArr[1]),
                                attrValue: clonedValues[currKey as keyof typeof clonedValues],
                            });
                        }
                    }
                } else {
                    if (currKeyArr[0].includes("crmMappingMode")) {
                        if (typeof clonedValues.crmMappingMode === "string") {
                            newObjWithoutNumKeys[currKey] = clonedValues.crmMappingMode === "Manual" ? 1 : 0;
                        } else if (typeof clonedValues.crmMappingMode === "boolean") {
                            newObjWithoutNumKeys[currKey] = clonedValues.crmMappingMode === true ? 1 : 0;
                        }
                    } else {
                        if (currKey === "deductionDate") {
                            newObjWithoutNumKeys[currKey] = isEmptyOrNull(clonedValues.deductionDate)
                                ? null
                                : (clonedValues.deductionDate as unknown as moment.Moment).format("YYYY-MM-DD");
                        } else if (currKey === "issuedDate") {
                            newObjWithoutNumKeys[currKey] = isEmptyOrNull(clonedValues.issuedDate)
                                ? null
                                : (clonedValues.issuedDate as unknown as moment.Moment).format("YYYY-MM-DD");
                        } else {
                            newObjWithoutNumKeys[currKey] = isEmptyOrNull(clonedValues[currKeyArr[0] as keyof typeof clonedValues])
                                ? null
                                : clonedValues[currKeyArr[0] as keyof typeof clonedValues];
                        }
                    }
                }
            });
            let finalFormValues = {
                ...newObjWithoutNumKeys,
                labelAttrsWithValues,
            };

            setIsLoading(true);
            if (isAddAction) {
                apiRequest(APIs.CREATE_TOXIC_CLIENT_CHECK_ENTRY, finalFormValues)
                    .then(res => {
                        ErrorMessageHandler("Toxic client check entry", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        setTimeout(() => {
                            tccEntryForm.resetFields();
                            props.callback &&
                                props.callback(InnerPageActionMode.BACK, {
                                    refreshMainList: true,
                                    reloadSegment: props.backActiveSegment,
                                    fParams: fParams,
                                });
                        }, 400);
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => {
                            if (err.status === -3) {
                                Modal.error({
                                    title: "Warning!",
                                    content: err.message,
                                    icon: <WarningOutlined />,
                                });
                            } else if (err.status === -4) {
                                setPayloadData(finalFormValues);
                                setIsModalOpen(true);
                            } else ErrorMessageHandler("toxic client check entry", SUCCESS_FAILED.FAILED_CREATE_DATA, err);
                        });
                    })
                    .finally(() => setIsLoading(false));
            } else {
                apiRequest(APIs.UPDATE_TOXIC_CLIENT_CHECK_ENTRY, finalFormValues)
                    .then(res => {
                        ErrorMessageHandler("Toxic client check entry", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        setTimeout(() => {
                            tccEntryForm.resetFields();
                            props.callback &&
                                props.callback(InnerPageActionMode.BACK, {
                                    refreshMainList: true,
                                    reloadSegment: props.backActiveSegment,
                                    fParams: fParams,
                                });
                        }, 400);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => {
                            if (err.status === -3) {
                                Modal.error({
                                    title: "Warning!",
                                    content: err.message,
                                    icon: <WarningOutlined />,
                                });
                            } else ErrorMessageHandler("toxic client check entry", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                        })
                    )
                    .finally(() => setIsLoading(false));
            }
        },
        [isAddAction, fParams]
    );

    const fillForm = useCallback((data?: ToxicClientCheckAccountDetails, tcLabelProps?: ToxicClientLabelProps[]) => {
        if (isEmptyOrNull(data)) {
            let temp = {
                crmMappingMode: false,
            };
            tccEntryForm.setFieldsValue(temp);
        } else {
            let currentData = data as ToxicClientCheckAccountDetails,
                temp: any = {
                    accountId: currentData.accountId,
                    entryId: currentData.entryId,
                    serverId: currentData.serverId,
                    inspector: currentData.inspector,
                    pickupTeam: currentData.pickupTeam,
                    status: currentData.status,
                    illegalProfit: currentData.illegalProfit,
                    suggestedDeduction: currentData.suggestedDeduction,
                    actualDeduction: currentData.actualDeduction,
                    deductionDate: currentData.deductionDate ? moment(currentData.deductionDate) : null,
                    labelId: currentData.labelId,
                    symbols: currentData.symbols === null ? [] : currentData.symbols,
                    note: currentData.note,
                    crmMappingMode: currentData.crmMappingMode === 0 ? "Auto" : "Manual",
                    brandId: currentData.brandId,
                    crmBrandId: currentData.crmBrandId,
                    clientName: currentData.clientName,
                    clientId: currentData.clientId,
                    regulatorId: currentData.regulatorId === 0 ? null : currentData.regulatorId,
                    issuedDate: currentData.issuedDate ? moment(currentData.issuedDate) : null,
                    accountCurrency: currentData.accountCurrency,
                    sourceId: currentData.sourceId,
                };
            let currLabelProps = tcLabelProps && tcLabelProps.find((l: ToxicClientLabelProps) => l.labelId === currentData.labelId);
            currentData.labelAttrDetails.forEach((x: ToxicClientCheckAttrDetails) => {
                switch (x.uiElementType) {
                    case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DropdownMenu:
                        if (currLabelProps !== undefined) {
                            let currAttrIdx = currLabelProps.labelAttrs.findIndex((attr: AttributeProps) => attr.attrId === x.attrId);
                            if (currAttrIdx < 0) temp[`tccle-${x.attrId}-dropdown`] = null;
                            else {
                                let currAttr = currLabelProps.labelAttrs[currAttrIdx];
                                temp[`tccle-${x.attrId}-dropdown-${currAttr.isMultiselectable ? "multi" : "single"}`] = currAttr.isMultiselectable
                                    ? x.attrValue
                                    : x.attrValue[0];
                            }
                        } else {
                            temp[`tccle-${x.attrId}-dropdown`] = null;
                        }
                        break;
                    case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.ToggleSwitcher:
                        temp[`tccle-${x.attrId}-switch`] = x.attrValue;
                        break;
                    case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker:
                        temp[`tccle-${x.attrId}-date`] = x.attrValue ? moment(x.attrValue) : null;
                        break;
                    case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.TimePicker:
                        temp[`tccle-${x.attrId}-time`] = x.attrValue ? moment(x.attrValue, "HH:mm:ss") : null;
                        break;
                    case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DateTimePicker:
                        temp[`tccle-${x.attrId}-datetime`] = x.attrValue ? moment(x.attrValue) : null;
                        break;
                    default:
                        temp[`tccle-${x.attrId}`] = x.attrValue;
                        break;
                }
            });
            tccEntryForm.setFieldsValue(temp);
        }
    }, []);

    const handleAccCurrencyBlur = (value: string) => {
        if (isEmptyOrNull(value)) return;
        else {
            tccEntryForm.setFieldsValue({ accountCurrency: value.toUpperCase() });
        }
    };

    const getToxicClientCheckEntryDetails = (id?: number | string | undefined) => {
        if (isEmptyOrNull(id)) {
            fillForm(undefined);
            setTcLabels(props.tcLabelProps.filter((x: ToxicClientLabelProps) => x.isLabelActive === true));
            setTcSources(props.tcSources.filter((x: TccCommonFilterSourceList) => x.isActive === true));
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        apiRequest(APIs.GET_TOXIC_CLIENT_CHECK_LIST, { checkListGroupingMode: 1, entryId: id })
            .then((res: ToxicClientCheckAccountDetails) => {
                let thisServer = props.servers.find((x: KeyValuePair) => x.value === res.serverId);
                let thisBrand = props.brands.find((x: BrandsList) => x.id === res.brandId);
                let thisCrmBrand = props.crmBrands.find((x: CrmBrandList) => x.id === res.crmBrandId);
                let newData = {
                    ...res,
                    serverName: isEmptyOrNull(thisServer) ? "" : thisServer?.text,
                    brandName: isEmptyOrNull(thisBrand) ? "" : thisBrand?.brand,
                    crmMappingModeName: res.crmMappingMode === 0 ? "Auto" : "Manual",
                    crmBrandName: isEmptyOrNull(thisCrmBrand) ? "" : thisCrmBrand?.brand,
                };
                setCurrEntryInfo(newData);
                fillForm(res, props.tcLabelProps);
                setTcLabels(props.tcLabelProps.filter((x: ToxicClientLabelProps) => x.isLabelActive || x.labelId === res.labelId));
                setTcSources(props.tcSources.filter((x: TccCommonFilterSourceList) => x.isActive || x.sourceId === res.sourceId));
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("toxic client check entry", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                props.callback && props.callback(InnerPageActionMode.BACK);
            })
            .finally(() => setIsLoading(false));
    };

    const handleFieldsChange = () => {
        let finalbrandId = finalBrandForm.getFieldValue("confirmedBrandId");
        if (finalbrandId > 0) setIsFormValid(true);
        else setIsFormValid(false);
    };

    const handleOk = () => {
        finalBrandForm.validateFields().then(res => {
            setIsButtonLoading(true);
            apiRequest(APIs.CREATE_TOXIC_CLIENT_CHECK_ENTRY, { ...payloadData, brandId: res.confirmedBrandId })
                .then(data => {
                    ErrorMessageHandler("Toxic client check entry", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    setTimeout(() => {
                        finalBrandForm.resetFields();
                        setIsModalOpen(false);
                        props.callback &&
                            props.callback(InnerPageActionMode.BACK, {
                                refreshMainList: true,
                                reloadSegment: props.backActiveSegment,
                                fParams: fParams,
                            });
                    }, 400);
                })
                .catch(error => {
                    ErrorCatchValidator(error, (err: any) => {
                        if (err.status === -3) {
                            Modal.error({
                                title: "Warning!",
                                content: err.message,
                                icon: <WarningOutlined />,
                                onOk: () => {
                                    Modal.destroyAll();
                                    setIsModalOpen(false);
                                    setIsFormValid(false);
                                    finalBrandForm.resetFields();
                                },
                            });
                        } else ErrorMessageHandler("toxic client check entry", SUCCESS_FAILED.FAILED_CREATE_DATA, err);
                    });
                })
                .finally(() => setIsButtonLoading(false));
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        finalBrandForm.resetFields();
        setIsFormValid(false);
    };

    useEffect(() => {
        getToxicClientCheckEntryDetails(props.id);
        setFParams(props.filterParams);

        return () => {};
    }, [props.resetState]);

    return (
        <SitePageHeaderInline
            title={isAddAction ? "Create New Toxic Client Check Entry" : "Edit Toxic Client Check Entry"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        tccEntryForm.resetFields();
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    default:
                        break;
                }
            }}
            extraProps={{
                extra: [
                    <Button
                        key={"regular-submit"}
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                            tccEntryForm
                                .validateFields()
                                .then(res => onFormSubmit(res))
                                .catch(err => {
                                    ErrorMessageHandler(`Failed to submit form. Please check the form for errors.`, SUCCESS_FAILED.OTHERS_FAILED);
                                });
                        }}
                    >
                        Submit
                    </Button>,
                    <Button
                        key={"cancel"}
                        onClick={() => {
                            tccEntryForm.resetFields();
                            props.callback && props.callback(InnerPageActionMode.BACK);
                        }}
                    >
                        Cancel
                    </Button>,
                    isAddAction
                        ? []
                        : [
                              <Popconfirm
                                  key={"cetmp-del"}
                                  title="Confirm to delete?"
                                  onConfirm={() => {
                                      setIsLoading(true);
                                      apiRequest(APIs.DELETE_TOXIC_CLIENT_CHECK_ENTRY, { entryId: props.id })
                                          .then((res: any) => {
                                              ErrorMessageHandler("Toxic client check entry", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                                              setTimeout(
                                                  () =>
                                                      props.callback &&
                                                      props.callback(InnerPageActionMode.BACK, {
                                                          refreshMainList: true,
                                                          reloadSegment: props.backActiveSegment,
                                                          fParams: fParams,
                                                      }),
                                                  400
                                              );
                                          })
                                          .catch((error: any) => {
                                              ErrorCatchValidator(error, (err: any) =>
                                                  ErrorMessageHandler("toxic client check entry", SUCCESS_FAILED.FAILED_DELETE_DATA, err)
                                              );
                                          })
                                          .finally(() => setIsLoading(false));
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                              >
                                  <Button type="primary" danger>
                                      Delete Toxic Client Check Entry
                                  </Button>
                              </Popconfirm>,
                          ],
                ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <>
                    <div className="toxic-client-check-entry-create-edit-form">
                        <Form
                            form={tccEntryForm}
                            layout="horizontal"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{}}
                            onFinish={onFormSubmit}
                        >
                            {!isAddAction && (
                                <>
                                    <div className="top">
                                        <div className="unedittable-container">
                                            <div className="left">
                                                <div className="item">
                                                    <span>{currEntryInfo?.accountId}</span>
                                                    <span>Account ID</span>
                                                </div>
                                                <div className="item">
                                                    <span>{currEntryInfo?.serverName}</span>
                                                    <span>Server</span>
                                                </div>
                                                <div className="item">
                                                    <span>{currEntryInfo?.brandName}</span>
                                                    <span>Brand</span>
                                                </div>
                                                <div className="item">
                                                    <span>{currEntryInfo?.crmMappingModeName}</span>
                                                    <span>CRM Mapping Mode</span>
                                                </div>
                                                <div className="item">
                                                    <span>{currEntryInfo?.clientId}</span>
                                                    <span>Client ID</span>
                                                </div>
                                                <div className="item">
                                                    <span>{currEntryInfo?.crmBrandName}</span>
                                                    <span>CRM Brand</span>
                                                </div>
                                            </div>
                                            <div className="right">
                                                <Col span={20}>
                                                    <FormComponent
                                                        label="Status"
                                                        name="status"
                                                        extra={{
                                                            type: ComponentType.dropdown,
                                                            value: ToOptionTypeList(TOXIC_CLIENT_CHECK_ENTRY_STATUS),
                                                            rules: [{ required: true, message: REQUIRED_FIELD }],
                                                        }}
                                                    />
                                                </Col>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="form-container">
                                <div className="left-panel">
                                    <div className="basic-info-config">
                                        <div className="config-title-div">
                                            <span>General Info</span>
                                        </div>
                                        {isAddAction ? (
                                            <>
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="Account ID"
                                                            name="accountId"
                                                            extra={{
                                                                type: ComponentType.number,
                                                                value: "",
                                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                                inputProps: {
                                                                    style: { width: "100%" },
                                                                    precision: 0,
                                                                },
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="Server"
                                                            name="serverId"
                                                            extra={{
                                                                type: ComponentType.dropdown,
                                                                value: props.servers,
                                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                                disabled: !isAddAction,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="CRM Mapping Mode"
                                                            name="crmMappingMode"
                                                            extra={{
                                                                type: ComponentType.switch,
                                                                value: ["Auto", "Manual"],
                                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            <>
                                                <FormComponent label="" name="entryId" extra={{ type: "hidden", value: "" }} />
                                                <FormComponent label="" name="crmMappingMode" extra={{ type: "hidden", value: "" }} />
                                                <FormComponent label="" name="serverId" extra={{ type: "hidden", value: "" }} />
                                                <FormComponent label="" name="brandId" extra={{ type: "hidden", value: "" }} />
                                                <FormComponent label="" name="accountId" extra={{ type: "hidden", value: "" }} />
                                                <FormComponent label="" name="crmBrandId" extra={{ type: "hidden", value: "" }} />
                                                <FormComponent label="" name="clientId" extra={{ type: "hidden", value: "" }} />
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="Account Currency"
                                                            name="accountCurrency"
                                                            extra={{
                                                                type: ComponentType.text,
                                                                value: "",
                                                                disabled:
                                                                    crmMappingMode ===
                                                                    TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[
                                                                        TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM.Auto
                                                                    ],
                                                                inputProps: {
                                                                    onBlur: (e: any) => handleAccCurrencyBlur(e.target.value),
                                                                },
                                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                                itemProps: {
                                                                    tooltip:
                                                                        "If the account currency is unknown, please enter USD as the default value.",
                                                                },
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="Client Name"
                                                            name="clientName"
                                                            extra={{
                                                                type: ComponentType.text,
                                                                value: "",
                                                                disabled:
                                                                    crmMappingMode ===
                                                                    TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[
                                                                        TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM.Auto
                                                                    ],
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="Regulator"
                                                            name="regulatorId"
                                                            extra={{
                                                                type: ComponentType.dropdown,
                                                                value: props.regulators.map((x: RegulatorList) => ({
                                                                    text: x.name,
                                                                    value: x.id,
                                                                })),
                                                                disabled:
                                                                    crmMappingMode ===
                                                                    TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[
                                                                        TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM.Auto
                                                                    ],
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        {isAddAction && crmMappingMode === true ? (
                                            <>
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="Brand"
                                                            name="brandId"
                                                            extra={{
                                                                type: ComponentType.dropdown,
                                                                value: props.brands.map((x: BrandsList) => ({
                                                                    text: x.brand,
                                                                    value: x.id,
                                                                })),
                                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="Account Currency"
                                                            name="accountCurrency"
                                                            extra={{
                                                                type: ComponentType.text,
                                                                value: "",
                                                                inputProps: {
                                                                    onBlur: (e: any) => handleAccCurrencyBlur(e.target.value),
                                                                },
                                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                                itemProps: {
                                                                    tooltip:
                                                                        "If the account currency is unknown, please enter USD as the default value.",
                                                                },
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="Client ID"
                                                            name="clientId"
                                                            extra={{
                                                                type: ComponentType.number,
                                                                value: "",
                                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                                inputProps: {
                                                                    style: { width: "100%" },
                                                                    precision: 0,
                                                                },
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="CRM Brand"
                                                            name="crmBrandId"
                                                            extra={{
                                                                type: ComponentType.dropdown,
                                                                value: props.crmBrands.map((x: CrmBrandList) => ({
                                                                    text: x.brand,
                                                                    value: x.id,
                                                                })),
                                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="Client Name"
                                                            name="clientName"
                                                            extra={{
                                                                type: ComponentType.text,
                                                                value: "",
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={22}>
                                                        <FormComponent
                                                            label="Regulator"
                                                            name="regulatorId"
                                                            extra={{
                                                                type: ComponentType.dropdown,
                                                                value: props.regulators.map((x: RegulatorList) => ({
                                                                    text: x.name,
                                                                    value: x.id,
                                                                })),
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <Row>
                                            <Col span={22}>
                                                <FormComponent
                                                    label="Issued Date (Server)"
                                                    name="issuedDate"
                                                    extra={{
                                                        type: ComponentType.date,
                                                        value: "",
                                                        dateFormat: "YYYY-MM-DD",
                                                        itemProps: {
                                                            tooltip: "If no value is provided, today's server date will be used by default.",
                                                        },
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={22}>
                                                <FormComponent
                                                    label="Inspector"
                                                    name="inspector"
                                                    extra={{
                                                        type: ComponentType.dropdown,
                                                        value: props.inspectors.map((x: UsersList) => ({
                                                            text: x.name + "  |  " + x.email,
                                                            value: x.id,
                                                        })),
                                                        itemProps: {
                                                            tooltip:
                                                                "If no value is provided, the person creating this entry will be assigned as the default inspector.",
                                                        },
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={22}>
                                                <FormComponent
                                                    label="Pickup Team"
                                                    name="pickupTeam"
                                                    extra={{
                                                        type: ComponentType.dropdown,
                                                        value: ToOptionTypeList(TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM),
                                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={22}>
                                                <FormComponent
                                                    label="Source"
                                                    name="sourceId"
                                                    extra={{
                                                        type: ComponentType.dropdown,
                                                        value: tcSources.map((x: TccCommonFilterSourceList) => ({
                                                            text: x.sourceName,
                                                            value: x.sourceId,
                                                        })),
                                                        rules:
                                                            currPickupTeam === TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM_ENUM.Risk
                                                                ? [{ required: true, message: REQUIRED_FIELD }]
                                                                : [],
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        {isAddAction ? (
                                            <Row>
                                                <Col span={22}>
                                                    <FormComponent
                                                        label="Status"
                                                        name="status"
                                                        extra={{
                                                            type: ComponentType.dropdown,
                                                            value: ToOptionTypeList(TOXIC_CLIENT_CHECK_ENTRY_STATUS),
                                                            rules: [{ required: true, message: REQUIRED_FIELD }],
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        ) : (
                                            <></>
                                        )}
                                        <Row>
                                            <Col span={22}>
                                                <FormComponent
                                                    label="Note"
                                                    name="note"
                                                    extra={{
                                                        type: ComponentType.textarea,
                                                        value: "",
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="right-panel">
                                    <div className="label-info-config">
                                        <div className="config-title-div">
                                            <span>Toxic Client Label</span>
                                        </div>
                                        <Row className="tc-label">
                                            <Col span={22}>
                                                <FormComponent
                                                    label="Toxic Client Label"
                                                    name="labelId"
                                                    extra={{
                                                        type: ComponentType.dropdown,
                                                        value: tcLabels.map((x: ToxicClientLabelProps) => ({
                                                            text: x.labelName,
                                                            value: x.labelId,
                                                        })),
                                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={22}>
                                                <FormComponent
                                                    label="Symbols"
                                                    name="symbols"
                                                    extra={{
                                                        type: ComponentType.dropdown,
                                                        value: props.cleanSymbols.map((x: CleanSymbolsList) => ({
                                                            text: x.name,
                                                            value: x.name,
                                                        })),
                                                        rules: [{ required: isSymbolRequiredFlag, message: REQUIRED_FIELD }],
                                                        inputProps: {
                                                            mode: "tags",
                                                            placeholder: "Please input symbols",
                                                        },
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        {currTcLabelId > 0 && displayAttrConfigForm(currTcLabelId)}
                                    </div>
                                    <div className="deductions-info-config">
                                        <div className="config-title-div">
                                            <span>Illegal Profit</span>
                                        </div>
                                        <Row>
                                            <Col span={22}>
                                                <FormComponent
                                                    label="Illegal Profit"
                                                    name="illegalProfit"
                                                    extra={{
                                                        type: ComponentType.number,
                                                        value: "",
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={22}>
                                                <FormComponent
                                                    label="Suggested Deduction"
                                                    name="suggestedDeduction"
                                                    extra={{
                                                        type: ComponentType.number,
                                                        value: "",
                                                        rules: [
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    let currLabel = props.tcLabelProps.find(
                                                                        (x: ToxicClientLabelProps) => x.labelId === currTcLabelId
                                                                    );
                                                                    if (!isEmptyOrNull(currLabel)) {
                                                                        if (currLabel?.isAllowSdOverIp === false) {
                                                                            if (Math.sign(getFieldValue("illegalProfit")) === -1) {
                                                                                if (value !== 0 && value !== null) {
                                                                                    return Promise.reject(
                                                                                        new Error("Suggested deduction must be either 0 or none.")
                                                                                    );
                                                                                }
                                                                            } else if (value > getFieldValue("illegalProfit")) {
                                                                                return Promise.reject(
                                                                                    new Error(
                                                                                        "Suggested deduction cannot be higher than illegal profit."
                                                                                    )
                                                                                );
                                                                            }
                                                                        }
                                                                        return Promise.resolve();
                                                                    }
                                                                },
                                                            }),
                                                        ],
                                                        itemProps: {
                                                            dependencies: ["illegalProfit", "labelId"],
                                                        },
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={22}>
                                                <FormComponent
                                                    label="Actual Deduction"
                                                    name="actualDeduction"
                                                    extra={{
                                                        type: ComponentType.number,
                                                        value: "",
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={22}>
                                                <FormComponent
                                                    label="Deduction Date (Server)"
                                                    name="deductionDate"
                                                    extra={{
                                                        type: ComponentType.date,
                                                        value: "",
                                                        dateFormat: "YYYY-MM-DD",
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <Modal
                        title={
                            <span style={{ fontSize: 17 }}>
                                <ExclamationCircleOutlined style={{ color: "#d89614", marginRight: 7, fontSize: 20 }} /> Brand Confirmation
                            </span>
                        }
                        width={600}
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="back" onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                htmlType="submit"
                                loading={isButtonLoading}
                                onClick={handleOk}
                                disabled={!isFormValid}
                            >
                                Submit
                            </Button>,
                        ]}
                    >
                        <Form form={finalBrandForm} onFieldsChange={handleFieldsChange}>
                            <h4>Please confirm if the brand falls under Vantage or Vantage APAC.</h4>
                            <FormComponent
                                label=""
                                name="confirmedBrandId"
                                extra={{
                                    type: ComponentType.radio,
                                    value:
                                        process.env.REACT_APP_ENV === "dev"
                                            ? devVantageBrands.map((x: BrandsList) => ({ text: x.brand, value: x.id }))
                                            : process.env.REACT_APP_ENV === "prod"
                                            ? prodVantageBrands.map((x: BrandsList) => ({ text: x.brand, value: x.id }))
                                            : devVantageBrands.map((x: BrandsList) => ({ text: x.brand, value: x.id })),
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Form>
                    </Modal>
                </>
            )}
        </SitePageHeaderInline>
    );
};

export default ToxicClientCheckEntryCreateEdit;
