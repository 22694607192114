import { useEffect, useMemo, useState } from "react";
import { Tabs } from "antd";
import WithdrawalReqList from "./WithdrawalReqList";
import HistoricalList from "./HistoricalList";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator } from "@/utils/Common";
import { ServersList, BrandsList, ToxicClientLabelsList, RegulatorList, CrmBrandList } from "@/constants/type";
import LoadingComponent from "@/components/Loading";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import APILogs from "./APILogs";

interface WithdrawalAuditCommonFilterPops {
    servers: ServersList[];
    brands: BrandsList[];
    tcLabels: ToxicClientLabelsList[];
    regulators: RegulatorList[];
    crmBrands: CrmBrandList[];
};

const WithdrawalAudit = () => {
    const authHp = new AuthHelper();
    const enableView = authHp.isAuthorized(AuthKeys.WITHDRAWAL_AUDIT_VIEW_API_LOGS);

    const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);
    const [cfData, setCfData] = useState<WithdrawalAuditCommonFilterPops>({
        servers: [],
        brands: [],
        tcLabels: [],
        regulators: [],
        crmBrands: [],
    });
    const [currTab, setCurrTab] = useState<string | undefined>(undefined);

    // Ensure user can change tab by URL params
    useEffect(() => {
        const currTabParam = new URLSearchParams(window.location.search).get("tab");
        if (currTabParam) {
            setCurrTab(currTabParam);
        } else {
            setCurrTab("request");
        }
    }, []);

    // Handle popstate event
    useEffect(() => {
        const handlePopState = () => {
            const currTabParam = new URLSearchParams(window.location.search).get("tab");
            if (currTabParam) {
                setCurrTab(currTabParam);
            } else {
                setCurrTab("request");
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };

    const tabItems = useMemo(() => {
        let defaultList = [
            {
                label: `Withdrawal`,
                key: "request",
                children: <WithdrawalReqList
                    servers={cfData.servers}
                    brands={cfData.brands}
                    tcLabels={cfData.tcLabels}
                    isFilterLoaded={isFilterLoaded}
                    regulators={cfData.regulators}
                    crmBrands={cfData.crmBrands}
                />,
            },
            {
                label: `Historical List`,
                key: "historical",
                children: <HistoricalList
                    servers={cfData.servers}
                    brands={cfData.brands}
                    tcLabels={cfData.tcLabels}
                    isFilterLoaded={isFilterLoaded}
                    regulators={cfData.regulators}
                    crmBrands={cfData.crmBrands}
                />,
            },
        ];
        if (enableView) {
            defaultList.push(
                {
                    label: `API Logs`,
                    key: "logs",
                    children: <APILogs />,
                },
            );
        };

        return defaultList;
    }, [cfData, isFilterLoaded, enableView]);

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server", "brand", "toxicclientlabel", "regulator", "crmserver"] })
            .then((data: any) => {
                setCfData({
                    servers: data.servers,
                    brands: data.brands,
                    tcLabels: data.toxicClientLabels,
                    regulators: data.regulators,
                    crmBrands: data.crmBrands,
                });
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to load filter options: ", err));
            })
            .finally(() => setIsFilterLoaded(true));
        return () => { };
    }, []);

    return (
        <>
            <LoadingComponent tip="Loading filters..." spinning={!isFilterLoaded}>
                <Tabs
                    type="card"
                    activeKey={currTab}
                    destroyInactiveTabPane
                    tabBarStyle={{ marginBottom: "0" }}
                    items={tabItems}
                    onChange={handleChangeTab}
                />
            </LoadingComponent>
        </>
    );
};

export default WithdrawalAudit;