import { useEffect, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { CALLBACK_KEY, ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { FlexiDataTableCallbackProps, ToxicClientSourceEventProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { apiRequest } from "@/services/apiConfig";
import CreateEditPage from "./CreateEditPage";

const SourceConfiguration = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_TOXIC_CLIENT_SETTINGS_EDIT);

    const [data, setData] = useState<ToxicClientSourceEventProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [refetch, setRefetch] = useState<number>(0);
    const [formObject, setFormObject] = useState<any>({ mode: InnerPageActionMode.CREATE_NEW });

    const columns: any[] = [
        DTColProps.Middle({
            title: "Source",
            dataIndex: "sourceName",
            key: "sourceName",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.Middle({
            title: "Description",
            dataIndex: "sourceDescription",
            key: "sourceDescription",
        }),
        DTColProps.Status({
            title: "Status",
            dataIndex: "isSourceActive",
            key: "isSourceActive",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Active", value: true },
                        { text: "Inactive", value: false },
                    ],
                },
            },
        }),
        DTColProps.XSmall({
            title: "Source Entry Count",
            dataIndex: "sourceEntryCount",
            key: "sourceEntryCount",
            sorter: (a: ToxicClientSourceEventProps, b: ToxicClientSourceEventProps) => (a.sourceEntryCount > b.sourceEntryCount ? 1 : -1),
        }),
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, rowData: ToxicClientSourceEventProps) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: rowData["sourceId"] });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                apiRequest(APIs.DELETE_TOXIC_CLIENT_SOURCE, { sourceId: rowData["sourceId"] })
                    .then((res: any) => {
                        ErrorMessageHandler(`Toxic client source [ ${rowData.sourceName} ]`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setTimeout(() => getSourceEventList(), 300);
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("toxic client source", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
                    })
                    .finally(() => setIsLoading(false));
                break;
            default:
                break;
        }
    };

    const getSourceEventList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_TOXIC_CLIENT_SOURCE_CONFIG_LIST, {})
            .then((res: any) => {
                if (res.length > 0) setData(res);
                else setData([]);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("toxic client source list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                })
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getSourceEventList();
        return () => {};
    }, []);

    return (
        <div className="single-page">
            <div className={`${isFirstOpt ? "active" : ""}`}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="sourceId"
                    title=""
                    columns={columns}
                    options={{
                        separateActionButton: true,
                        add: enableUpdate,
                        edit: enableUpdate,
                        delete: enableUpdate
                            ? (record: ToxicClientSourceEventProps, option: any) => (record.sourceEntryCount > 0 ? undefined : option)
                            : false,
                    }}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <div className={`${isFirstOpt ? "" : "active"}`}>
                <CreateEditPage
                    {...formObject}
                    callback={(action: InnerPageActionMode, value: any) => {
                        switch (action) {
                            case InnerPageActionMode.BACK:
                                setIsFirstOpt(prev => !prev);
                                if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                    getSourceEventList();
                                }
                                break;
                        }
                    }}
                    resetState={refetch}
                />
            </div>
        </div>
    );
};

export default SourceConfiguration;
