import { useCallback, useEffect, useMemo, useState } from "react";
import { ComponentType, CALLBACK_KEY, WITHDRAWAL_AUDIT_RISK_TYPE_LABELS, WITHDRAWAL_AUDIT_STATUS_LABEL, SUCCESS_FAILED, WITHDRAWAL_AUDIT_STATUS, WITHDRAWAL_AUDIT_BLOCK_TYPES, WITHDRAWAL_AUDIT_BLOCK_TYPES_ENUM } from "@/constants";
import { BrandsList, ServersList, ToxicClientLabelsList, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, WithdrawalAuditData, CustomPaginationProps, RegulatorList, CrmBrandList, CrmBlockTypes } from "@/constants/type";
import { currencyRender, DataTableColumnRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { HistoryOutlined } from "@ant-design/icons";
import { Tooltip, Button } from "antd";
import CRMBlockTypeInfo from "../../ProblematicClients/CRMBlockTypeInfo";
import FlexiDataTable from "@/components/FlexiDataTable";
import ViewHistoricalLogModal from "../WithdrawalReqList/ViewHistoricalLogModal";
import { ToOptionTypeList } from "@/utils/array";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { DateTimeUtil } from "@/utils/datetime";
import { isEmptyOrNull } from "@/utils/string";

interface HistoricalistProps {
    servers: ServersList[];
    brands: BrandsList[];
    tcLabels: ToxicClientLabelsList[];
    isFilterLoaded: boolean;
    regulators: RegulatorList[];
    crmBrands: CrmBrandList[];
};

const HistoricalList = (props: HistoricalistProps) => {
    const [isLogModalOpen, setIsLogModalOpen] = useState<boolean>(false);
    const [data, setData] = useState<WithdrawalAuditData[]>([]);
    const [logsData, setLogsData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({});
    const [sorting, setSorting] = useState<string>("");
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [isLogsLoading, setIsLogsLoading] = useState<boolean>(false);

    const waBlockTypes = Object.keys(WITHDRAWAL_AUDIT_BLOCK_TYPES_ENUM)
        .filter(key => isNaN(Number(key))) // to keep only the string keys from the enum
        .map((currStrKey: string) => {
            const enumValue = WITHDRAWAL_AUDIT_BLOCK_TYPES_ENUM[currStrKey as keyof typeof WITHDRAWAL_AUDIT_BLOCK_TYPES_ENUM];
            return {
                blockType: currStrKey,
                blockTypeDesc: WITHDRAWAL_AUDIT_BLOCK_TYPES[enumValue],
                blacklistType: "",
                blacklistTypeDesc: "",
            }
        })
        .sort((a: CrmBlockTypes, b: CrmBlockTypes) => a.blockType.localeCompare(b.blockType));

    const columns = useMemo(() => [
        DTColProps.Middle({
            title: "Request Time (Server)",
            dataIndex: "requestTimeUtc",
            key: "requestTimeUtc",
            fixed: "left",
            width: "10vw",
            sorter: true,
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: [],
                    inputProps: { showTime: true },
                },
            },
            render: (value: string, rowData: WithdrawalAuditData) => (
                <div className="wa-req-time-id-col">
                    <span>{value ? DataTableColumnRender.DateTime_ServerTime(value) : ""}</span>
                    <span>Transaction ID: {rowData.txId}</span>
                </div>
            ),
        }),
        {
            title: "Transaction ID",
            dataIndex: "txId",
            key: "txId",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
                visible: false,
            },
        },
        {
            title: "Risk Type",
            dataIndex: "riskType",
            key: "riskType",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: ToOptionTypeList(WITHDRAWAL_AUDIT_RISK_TYPE_LABELS),
                    inputProps: { mode: "multiple" },
                },
                visible: false,
            },
        },
        DTColProps.Small({
            title: "Client Info",
            dataIndex: "clientId",
            key: "clientId",
            fixed: "left",
            width: "9vw",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [],
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please insert client id",
                        tokenSeparators: [",", "，", " "],
                    },
                    text: "Client ID",
                },
            },
            render: (clientId: number, rowData: WithdrawalAuditData) => {
                return (
                    <div className="acc-cli-info-col">
                        <div className="acc-cli-item">
                            <span>Client ID :</span>
                            <span>{clientId}</span>
                        </div>
                        <div className="acc-cli-item">
                            <span>CRM Brand :</span>
                            <span>{isEmptyOrNull(rowData.crmBrand) ? "-" : rowData.crmBrand}</span>
                        </div>
                        <div className="acc-cli-item">
                            <span>Regulator :</span>
                            <span>{isEmptyOrNull(rowData.regulator) ? "-" : rowData.regulator}</span>
                        </div>
                    </div>
                );
            },
        }),
        {
            title: "CRM Brand",
            dataIndex: "crmBrandId",
            key: "crmBrandId",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: props.crmBrands.map((x: CrmBrandList) => ({ text: x.brand, value: x.id })),
                    inputProps: { mode: "multiple" },
                },
                visible: false,
            },
        },
        {
            title: "Regulator",
            dataIndex: "regulatorId",
            key: "regulatorId",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: props.regulators.map((x: RegulatorList) => ({ text: x.name, value: x.id })),
                    inputProps: { mode: "multiple" },
                },
                visible: false,
            },
        },
        DTColProps.Small({
            title: "Account Info",
            dataIndex: "accountId",
            key: "accountId",
            fixed: "left",
            width: "9vw",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [],
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please insert account id",
                        tokenSeparators: [",", "，", " "],
                    },
                    text: "Account ID",
                },
            },
            render: (accountId: number, rowData: WithdrawalAuditData) => {
                return (
                    <div className="acc-cli-info-col">
                        <div className="acc-cli-item">
                            <span>Account ID :</span>
                            <span>{accountId}</span>
                        </div>
                        <div className="acc-cli-item">
                            <span>Server :</span>
                            <span>{isEmptyOrNull(rowData.server) ? "-" : rowData.server}</span>
                        </div>
                        <div className="acc-cli-item">
                            <span>Brand :</span>
                            <span>{isEmptyOrNull(rowData.brand) ? "-" : rowData.brand}</span>
                        </div>
                    </div>
                );
            },
        }),
        {
            title: "Brand",
            dataIndex: "brandId",
            key: "brandId",
            fixed: "left",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: props.brands.map((x: BrandsList) => ({ text: x.brand, value: x.id })),
                    inputProps: { mode: "multiple" },
                },
                visible: false,
            },
        },
        {
            title: "Server",
            dataIndex: "serverId",
            key: "serverId",
            fixed: "left",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: props.servers.map((x: ServersList) => ({ text: x.server, value: x.id })),
                    inputProps: { mode: "multiple" },
                },
                visible: false,
            },
        },
        DTColProps.Small({
            title: "Amount",
            dataIndex: "amountInUsd",
            key: "amountInUsd",
            sorter: (a: any, b: any) => a.amount - b.amount,
            render: (amount: number, rowData: WithdrawalAuditData) => (
                <div className="wa-amount-col">
                    <span className="base-amount-currency">{currencyRender(amount)} {rowData.baseCurrency}</span>
                    <span className="usd-amount-currency">{currencyRender(rowData.amountInUsd)} USD</span>
                </div>
            ),
            options: {
                filter: {
                    type: ComponentType.numberrange2,
                    value: "",
                    text: "Amount (USD)",
                },
            },
        },
            ["text-right"]
        ),
        DTColProps.Small({
            title: "Withdrawal Channel",
            dataIndex: "withdrawalChannel",
            key: "withdrawalChannel",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: <CRMBlockTypeInfo title="Block Type" crmBlockTypes={waBlockTypes} />,
            realTitle: "Block Type",
            dataIndex: "blockTypes",
            key: "blockTypes",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: Object.keys(WITHDRAWAL_AUDIT_BLOCK_TYPES_ENUM)
                        .filter((currKey: string) => isNaN(Number(currKey))) // to get the string keys of the enum
                        .map((key: string) => ({
                            text: key,
                            value: WITHDRAWAL_AUDIT_BLOCK_TYPES_ENUM[key as keyof typeof WITHDRAWAL_AUDIT_BLOCK_TYPES_ENUM],
                        })),
                    inputProps: {
                        mode: "multiple",
                        placeholder: "Please select block type",
                    },
                },
            },
            render: (blockTypes: string[]) => (
                <div className="wa-blocktypes-col">
                    {blockTypes.map((x: string, i: number) => (
                        <div key={i} className={`wa-blocktypes-item ${blockTypes.length > 4 ? "exceeded" : ""}`}>{x}</div>
                    ))}
                </div>
            ),
        }),
        DTColProps.Small({
            title: "Toxic Client Label",
            dataIndex: "toxicClientLabelIds",
            key: "toxicClientLabelIds",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: props.tcLabels.map((x: ToxicClientLabelsList) => ({ text: x.labelName, value: x.labelId })),
                    inputProps: { mode: "multiple" },
                },
            },
            render: (toxicClientLabelIds: number[]) => {
                return toxicClientLabelIds.map((tclId: number) => {
                    let thisLabel = props.tcLabels.find((x: ToxicClientLabelsList) => x.labelId === tclId);
                    if (thisLabel === null) return null;
                    else return (
                        <div
                            key={tclId}
                            style={{
                                backgroundColor: `${thisLabel?.labelColor}`,
                                color: "black",
                                padding: "0.2828vh 0.7812vw",
                                wordWrap: "break-word",
                                borderRadius: 5,
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                                textAlign: "center",
                                maxWidth: "100%",
                                display: "inline-block",
                                marginRight: 5,
                                ...(toxicClientLabelIds.length > 1 ? { marginBottom: "0.8484vh" } : {}),
                            }}
                        >
                            {thisLabel?.labelName}
                        </div>
                    );
                });
            },
        }),
        DTColProps.Small({
            title: "Last Updated Date (Local)",
            dataIndex: "lastUpdatedTimeUtc",
            key: "lastUpdatedTimeUtc",
            sorter: true,
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: [],
                    inputProps: { showTime: true },
                },
            },
            render: (lastUpdatedTimeUtc: string, rowData: WithdrawalAuditData) => (
                <div className="grouped-dates-col">
                    <div className="last-updated-datetime">
                        <div className="by-user">{rowData.lastUpdatedBy}</div>
                        <div className="value">{DataTableColumnRender.DateTime(lastUpdatedTimeUtc)}</div>
                    </div>
                </div>
            ),
        }),
        DTColProps.XSmall({
            title: "Risk Type",
            dataIndex: "riskType",
            key: "riskType",
            fixed: "right",
            render: (riskType: number) => WITHDRAWAL_AUDIT_RISK_TYPE_LABELS[riskType],
        }),
        DTColProps.Small({
            title: "Status",
            dataIndex: "status",
            key: "status",
            fixed: "right",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: Object.keys(WITHDRAWAL_AUDIT_STATUS_LABEL)
                        .filter((key: string) => key !== (WITHDRAWAL_AUDIT_STATUS.PENDING).toString() && key !== (WITHDRAWAL_AUDIT_STATUS.RISK_CHECKING).toString())
                        .map((currKey: string) => ({ text: WITHDRAWAL_AUDIT_STATUS_LABEL[parseInt(currKey)], value: currKey })),
                    inputProps: { mode: "multiple" },
                },
            },
            render: (status: number) => <div
                className={`wa-status-tag ${WITHDRAWAL_AUDIT_STATUS_LABEL[status] === WITHDRAWAL_AUDIT_STATUS_LABEL[WITHDRAWAL_AUDIT_STATUS.APPROVE]
                    ? "approved"
                    : "rejected"
                    }`}
            >
                {WITHDRAWAL_AUDIT_STATUS_LABEL[status]}
            </div>,
        }),
        DTColProps.Small({
            title: "Last Comment",
            dataIndex: "lastComment",
            key: "lastComment",
            fixed: "right",
            width: "8.5vw",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.XXSmall({
            title: "",
            dataIndex: "actions",
            key: "actions",
            fixed: "right",
            render: (_: any, rowData: any, i: number) => (
                <div className="vertical-actions-col">
                    <Tooltip title="View Logs" placement="right" key={`view-l-${i}`}>
                        <Button
                            type="text"
                            icon={<HistoryOutlined />}
                            onClick={() =>
                                componentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "view_logs", data: rowData })
                            }
                        />
                    </Tooltip>
                </div>
            ),
        }),
    ], [props, waBlockTypes]);

    const options: FlexiDataTableOptionsProps = {
        defaultCollapseFilterPanel: true,
        serverFiltering: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                switch (FormData.key) {
                    case "view_logs":
                        setIsLogsLoading(true);
                        setIsLogModalOpen(true);
                        getEntryLogs(FormData.data.entryId);
                        break;
                };
                break;
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "requestTimeUtc") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["requestTimeUtcFrom"] = DateTimeUtil.GetUTC(FormData[x][0]);
                                fParams["requestTimeUtcTo"] = DateTimeUtil.GetUTC(FormData[x][1]);
                            }
                        } else if (x === "lastUpdatedTimeUtc") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["lastUpdatedTimeUtcFrom"] = DateTimeUtil.GetUTC(FormData[x][0]);
                                fParams["lastUpdatedTimeUtcTo"] = DateTimeUtil.GetUTC(FormData[x][1]);
                            }
                        } else if (x === "accountId") {
                            let intAccIds: number[] = [];
                            FormData[x].forEach((y: string) => intAccIds.push(Number(y)));
                            fParams["accountIds"] = intAccIds;
                        } else if (x === "serverId") {
                            fParams["serverIds"] = FormData[x];
                        } else if (x === "brandId") {
                            fParams["brandIds"] = FormData[x];
                        } else if (x === "clientId") {
                            let intClientIds: number[] = [];
                            FormData[x].forEach((y: string) => intClientIds.push(Number(y)));
                            fParams["clientIds"] = intClientIds;
                        } else if (x === "crmBrandId") {
                            fParams["crmBrandIds"] = FormData[x];
                        } else if (x === "regulatorId") {
                            fParams["regulatorIds"] = FormData[x];
                        } else if (x === "status") {
                            let inStatuses: number[] = [];
                            FormData[x].forEach((y: string) => inStatuses.push(Number(y)));
                            fParams["statuses"] = inStatuses;
                        } else if (x === "toxicClientLabel") {
                            fParams["toxicClientLabelIds"] = FormData[x];
                        } else if (x === "riskType") {
                            fParams["riskTypes"] = FormData[x];
                        } else if (x === "amountInUsd") {
                            if (FormData[x] === undefined) return false;
                            else {
                                let tmp = FormData[x].split("|");
                                fParams["amountInUsdFrom"] = Number(tmp[0]);
                                fParams["amountInUsdTo"] = Number(tmp[1]);
                            }
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            default:
                break;
        };
    };

    const getWithdrawalAuditList = useCallback(() => {
        apiRequest(APIs.GET_WITHDRAWAL_AUDIT_LIST, {
            statuses: [2, 3, 4, 5, 6],
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data === null || data.result.length === 0) {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                } else {
                    setData(data.result);
                    setPagination(prev => ({ ...prev, total: data.total }));
                };
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("withdrawal historical list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                });
            })
            .finally(() => setIsLoading(false));
    }, [sorting, pagination, filterParams]);

    const getEntryLogs = (entryId: number) => {
        apiRequest(APIs.GET_WITHDRAWAL_AUDIT_ENTRY_LOGS, { entryId: entryId })
            .then((data: any) => {
                setLogsData(data && data.length > 0 ? data : []);
                setIsLogModalOpen(true);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("withdrawal audit logs", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setLogsData([]);
                })
            )
            .finally(() => setIsLogsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getWithdrawalAuditList();
            setRunRefetchDataList(false);
        };
        return () => { };
    }, [runRefetchDataList]);

    useEffect(() => {
        if (props.isFilterLoaded) {
            setRunRefetchDataList(true);
        };
        return () => { };
    }, [props.isFilterLoaded]);

    return (
        <div className="withdrawal-historical-list-tab">
            <div className="single-page with-background">
                <div className={`active`}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="entryId"
                        title={false}
                        columns={columns}
                        options={options}
                        dataSource={data ?? []}
                        callback={componentCallback}
                        loading={isLoading}
                        serverSide={true}
                        pagination={pagination}
                    />
                    <ViewHistoricalLogModal
                        isLogModalOpen={isLogModalOpen}
                        logsData={logsData}
                        onClose={() => setIsLogModalOpen(false)}
                        isLoading={isLogsLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default HistoricalList;
