import React, { useMemo, useCallback } from "react";
import { Button, Col, Row, Space, Table, Tag, Tooltip, notification } from "antd";
import type { IRCPriceAlarm } from "@/hooks/useRCPriceAlarm";
import { AiOutlineCopy } from "react-icons/ai";
import { motion } from "framer-motion";
import MessageCard from "@/pages/SystemMonitor/components/MessageCard";

interface PriceAlarmTableProps {
    data: IRCPriceAlarm[];
    isLoading: boolean;
    errorText: string | null;
    expandedRowKeys: string[];
    onExpand: (expanded: boolean, record: any) => void;
    priceAlarmColor: string;
}

const PriceAlarmTable = React.memo(({ data, isLoading, errorText, expandedRowKeys, onExpand, priceAlarmColor }: PriceAlarmTableProps) => {
    const memoizedColumns = useMemo(
        () => [
            {
                title: "Brand",
                dataIndex: "brand",
                width: 100,
                sorter: (a: any, b: any) => a.brand.localeCompare(b.brand),
            },
            {
                title: "Symbol",
                dataIndex: "group",
                width: 100,
                sorter: (a: any, b: any) => a.group.localeCompare(b.group),
            },
            {
                title: "Duration",
                dataIndex: "showTime",
                width: 100,
                sorter: (a: any, b: any) => a.showTime.localeCompare(b.showTime),
            },
            {
                title: "Count",
                dataIndex: "alarmCount",
                width: 80,
                sorter: (a: any, b: any) => a.alarmCount - b.alarmCount,
            },
            {
                title: "Summary",
                dataIndex: "Action",
                render: (text: any, data: any) => {
                    const eachServer = data?.detail?.map((each: any) => each.server);
                    const uniqueServers = Array.from(new Set(eachServer));
                    const uniqueServersString = uniqueServers.join(", ");
                    const copyText = `(${data.brand}) ${data.group} no price for ${data.showTime} on ${uniqueServersString}`;
                    return (
                        <div style={{ padding: "0.5rem 1rem", backgroundColor: "#eee", borderRadius: 4, border: "1px solid #dfdfdf" }}>
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                                    <div style={{ fontSize: "1.125rem" }}>
                                        <strong>
                                            ({data.brand}) {data.group}
                                        </strong>{" "}
                                        no price for <strong>{data.showTime}</strong> on
                                    </div>
                                    <Tooltip title="Copy">
                                        <motion.div
                                            style={{
                                                fontSize: 16,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                                width: 24,
                                                height: 24,
                                                borderRadius: 4,
                                            }}
                                            onClick={() => {
                                                navigator.clipboard.writeText(copyText);
                                                notification.success({
                                                    message: "Copied to clipboard",
                                                    description: `${copyText}`,
                                                });
                                            }}
                                            whileHover={{ backgroundColor: "rgba(0,0,0,0.1)" }}
                                        >
                                            <AiOutlineCopy />
                                        </motion.div>
                                    </Tooltip>
                                </div>
                                <Space wrap size={1}>
                                    {uniqueServers?.map((each: any) => (
                                        <Tag key={each} style={{ margin: 0 }}>
                                            {each}
                                        </Tag>
                                    ))}
                                </Space>
                            </Space>
                        </div>
                    );
                },
            },
        ],
        []
    );

    const memoizedDetailColumns = useMemo(
        () => [
            {
                title: "Server",
                dataIndex: "server",
                sorter: (a: any, b: any) => a.server.localeCompare(b.server),
            },
            {
                title: "Symbol",
                dataIndex: "symbol",
                sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
            },
            {
                title: "Bid",
                dataIndex: "bid",
                sorter: (a: any, b: any) => a.bid - b.bid,
            },
            {
                title: "Ask",
                dataIndex: "ask",
                sorter: (a: any, b: any) => a.ask - b.ask,
            },
            {
                title: "Last Tick",
                dataIndex: "startDateStr",
                sorter: (a: any, b: any) => a.startDateStr.localeCompare(b.startDateStr),
            },
            {
                title: "Duration",
                dataIndex: "showStopTime",
                sorter: (a: any, b: any) => a.showStopTime.localeCompare(b.showStopTime),
            },
        ],
        []
    );

    const memoizedData = useMemo(() => {
        return data?.map(item => ({
            ...item,
            key: `${item.brand}-${item.group}`,
        }));
    }, [data]);

    const handleCopyAll = useCallback(() => {
        const copyText = data
            .map(each => {
                const eachServer = each?.detail?.map(each => each.server);
                const uniqueServers = Array.from(new Set(eachServer));
                const uniqueServersString = uniqueServers.join(", ");
                return `(${each.brand}) ${each.group} no price for ${each.showTime} on ${uniqueServersString}`;
            })
            .join("\n");
        navigator.clipboard.writeText(copyText);
        notification.success({
            message: "Copied to clipboard",
            description: `Copied ${data.length} items`,
        });
    }, [data]);

    if (isLoading) {
        return <MessageCard type="info">Loading...</MessageCard>;
    }

    if (errorText) {
        return <MessageCard type="error">{errorText}</MessageCard>;
    }

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Row align="middle">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    {data?.length === 0 ? (
                        <MessageCard type="success" size="small">
                            All good
                        </MessageCard>
                    ) : (
                        <MessageCard type="error" size="small">
                            {data.length} symbol{data.length > 1 && "s"} no price
                        </MessageCard>
                    )}
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Tooltip title="Copy all">
                        <Button
                            style={{
                                fontSize: 16,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                marginLeft: "auto",
                                width: 100,
                                height: 32,
                                borderRadius: 4,
                                gap: 4,
                            }}
                            size="small"
                            onClick={handleCopyAll}
                        >
                            <AiOutlineCopy /> <span style={{ fontSize: "0.8rem" }}>Copy All</span>
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
            <Table
                bordered
                columns={memoizedColumns}
                rowClassName={record => (record.alarmStatus === 1 ? priceAlarmColor : "")}
                dataSource={memoizedData}
                pagination={false}
                size="small"
                expandable={{
                    expandedRowRender: record => (
                        <Table
                            key={record.key}
                            pagination={false}
                            columns={memoizedDetailColumns}
                            rowClassName={record => (record.alarmStatus === 1 ? priceAlarmColor : "")}
                            dataSource={record.detail}
                            rowKey={record => `${record?.server}-${record?.symbol}`}
                            size="small"
                        />
                    ),
                    expandedRowKeys,
                    onExpand,
                }}
            />
        </Space>
    );
});

export default PriceAlarmTable;
