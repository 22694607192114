import { configureStore } from "@reduxjs/toolkit";
import systemReducer from "./reducers/system";
import issuelogReducer from "./reducers/issuelog";
import centralizedSettingReducer from "./reducers/centralizedsettings";
import { templateApiSlice } from "./apis/template";
import { reportHistoryApiSlice } from "./apis/history";
import { reportJobsApiSlice } from "./apis/reportJobs";
import { tagProfilesApiSlice } from "./apis/tagProfiles";
import { serversApiSlice } from "./apis/server";
import { brandsApiSlice } from "./apis/brand";
import { serverBrandMappingApiSlice } from "./apis/serverBrandMapping";
import { groupPurposesApiSlice } from "./apis/groupPurpose";
import { accountProfilesApiSlice } from "./apis/accountprofile";
import { tagSummaryApiSlice } from "./apis/tagSummary";
import { accRiskScoreApiSlice } from "./apis/accRiskScore";
import { riskScoreApiSlice } from "./apis/riskScore";
import { roleMenuApiSlice } from "./apis/role";
import { monitorProfileApiSlice } from "./apis/realtime";
import { tradeMonitorApiSlice } from "./apis/tradeMonitor";
import { metricGroupApiSlice } from "./apis/metricConfig";
import { priceMonitorSpreadAlarmApiSlice } from "./apis/pricemonitor/spreadalarm";
import { reportBatchApiSlice } from "./apis/report/reportbatch";

export const store = configureStore({
    reducer: {
        system: systemReducer,
        issuelog: issuelogReducer,
        centralizedsettings: centralizedSettingReducer,
        [templateApiSlice.reducerPath]: templateApiSlice.reducer,
        [reportHistoryApiSlice.reducerPath]: reportHistoryApiSlice.reducer,
        [reportJobsApiSlice.reducerPath]: reportJobsApiSlice.reducer,
        [tagProfilesApiSlice.reducerPath]: tagProfilesApiSlice.reducer,
        [accountProfilesApiSlice.reducerPath]: accountProfilesApiSlice.reducer,
        [tagSummaryApiSlice.reducerPath]: tagSummaryApiSlice.reducer,
        [accRiskScoreApiSlice.reducerPath]: accRiskScoreApiSlice.reducer,
        [riskScoreApiSlice.reducerPath]: riskScoreApiSlice.reducer,
        [serversApiSlice.reducerPath]: serversApiSlice.reducer,
        [brandsApiSlice.reducerPath]: brandsApiSlice.reducer,
        [serverBrandMappingApiSlice.reducerPath]: serverBrandMappingApiSlice.reducer,
        [groupPurposesApiSlice.reducerPath]: groupPurposesApiSlice.reducer,
        [roleMenuApiSlice.reducerPath]: roleMenuApiSlice.reducer,
        [monitorProfileApiSlice.reducerPath]: monitorProfileApiSlice.reducer,
        [tradeMonitorApiSlice.reducerPath]: tradeMonitorApiSlice.reducer,
        [metricGroupApiSlice.reducerPath]: metricGroupApiSlice.reducer,
        [priceMonitorSpreadAlarmApiSlice.reducerPath]: priceMonitorSpreadAlarmApiSlice.reducer,
        [reportBatchApiSlice.reducerPath]: reportBatchApiSlice.reducer,
    },
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware()
            .concat(templateApiSlice.middleware)
            .concat(reportHistoryApiSlice.middleware)
            .concat(reportJobsApiSlice.middleware)
            .concat(tagProfilesApiSlice.middleware)
            .concat(accountProfilesApiSlice.middleware)
            .concat(tagSummaryApiSlice.middleware)
            .concat(accRiskScoreApiSlice.middleware)
            .concat(riskScoreApiSlice.middleware)
            .concat(serversApiSlice.middleware)
            .concat(brandsApiSlice.middleware)
            .concat(serverBrandMappingApiSlice.middleware)
            .concat(groupPurposesApiSlice.middleware)
            .concat(roleMenuApiSlice.middleware)
            .concat(monitorProfileApiSlice.middleware)
            .concat(tradeMonitorApiSlice.middleware)
            .concat(metricGroupApiSlice.middleware)
            .concat(reportBatchApiSlice.middleware)
            .concat(priceMonitorSpreadAlarmApiSlice.middleware);
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
