import { useEffect, useMemo, useState } from "react";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import {
    BrandsList,
    CustomPaginationProps,
    ServersList,
    CidLabelsList,
    PreMarketAccountProfile,
    PreMarketAccountModelRule,
} from "../../../constants/type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../utils/Common";
import moment from "moment";
import { APIs, apiRequest } from "../../../services/apiConfig";
import { CheckCircleOutlined, CloseCircleOutlined, HistoryOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Descriptions, Modal, Popover, Tooltip } from "antd";
import { DateTimeUtil } from "../../../utils/datetime";
import { Link } from "react-router-dom";
import { ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileParams, getNavigationUrl } from "../AccountProfileHelper";
import LoadingComponent from "../../../components/Loading";
import ViewAuditLog from "./components/ViewAuditLog";
import { isEmptyOrNull } from "../../../utils/string";

export interface PreMarketAccModelCommonFilterPops {
    brands: BrandsList[];
    servers: ServersList[];
    countries: string[];
    cidLabels: CidLabelsList[];
};

const PreMarketAccModel = () => {
    const [data, setData] = useState<PreMarketAccountProfile[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 50,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({
        preMarketScoreFrom: 65,
        preMarketScoreTo: 100,
        cidToxicityRatioFrom: 0,
        cidToxicityRatioTo: 1,
        preMarketAthScoreFrom: 0,
        preMarketAthScoreTo: 100
    });
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [sorting, setSorting] = useState<string>("");
    const [refreshRate, setRefreshRate] = useState<number>(60);
    const [ruleData, setRuleData] = useState<any>({});
    const [logData, setLogData] = useState<PreMarketAccountProfile[]>([]);
    const [isLogModalOpen, setIsLogModalOpen] = useState<boolean>(false);
    const [isLogLoading, setIsLogLoading] = useState<boolean>(false);
    const [cfData, setCfData] = useState<PreMarketAccModelCommonFilterPops>({ brands: [], servers: [], countries: [], cidLabels: [], });

    const columns = useMemo(() => [
        DTColProps.XSmall({
            title: "Account ID",
            dataIndex: "accountId",
            key: "accountId",
            fixed: "left",
            options: {
                filter: {
                    type: ComponentType.number,
                },
            },
            // render: (accountId: number, rowData: PreMarketAccountProfile) => {
            //     return (
            //         <Link
            //             to={getNavigationUrl(
            //                 ACCOUNT_PROFILE_FROM_MODULE.PREMARKET_ACCOUNT,
            //                 getAccountProfileParams(rowData["serverId"], accountId, ACCOUNT_PROFILE_FROM_MODULE.PREMARKET_ACCOUNT, "", "", "", "", "")
            //             )}
            //             target="_blank"
            //             style={{ color: "#0e65cc" }}
            //         >
            //             {accountId}
            //         </Link>
            //     );
            // },
        }),
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "serverId",
            key: "serverId",
            fixed: "left",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: cfData.servers.map((x: ServersList) => ({ text: x.server, value: x.id })),
                    inputProps: {
                        mode: "multiple",
                    },
                },
            },
            render: (serverId: number | null) => {
                if (serverId === null || serverId === undefined) return "-";
                else {
                    let currServer = cfData.servers.find((x: ServersList) => x.id === serverId);
                    if (currServer !== undefined) return currServer.server;
                    else return "-";
                }
            },
        }),
        DTColProps.XSmall({
            title: "Brand",
            dataIndex: "brandId",
            key: "brandId",
            fixed: "left",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: cfData.brands.map((x: BrandsList) => ({ text: x.brand, value: x.id })),
                    inputProps: {
                        mode: "multiple",
                    },
                },
            },
            render: (brandId: number | null) => {
                if (brandId === null || brandId === undefined) return "-";
                else {
                    let currBrand = cfData.brands.find((x: BrandsList) => x.id === brandId);
                    if (currBrand !== undefined) return currBrand.brand;
                    else return "-";
                }
            },
        }),
        DTColProps.Small({
            title: "Country",
            dataIndex: "country",
            key: "country",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: cfData.countries.map((x: string) => ({ text: x, value: x })),
                    inputProps: {
                        mode: "multiple",
                    },
                },
            },
            render: (country: string) => isEmptyOrNull(country) ? "-" : country,
        }),
        DTColProps.Small({
            title: "Nationality",
            dataIndex: "nationality",
            key: "nationality",
            render: (nationality: string) => isEmptyOrNull(nationality) ? "-" : nationality,
        }),
        DTColProps.XSmall({
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (email: string) => isEmptyOrNull(email) ? "-" : email,
        }),
        DTColProps.DateTime_ServerTime({
            title: "Registration Date (Server)",
            dataIndex: "regDate",
            key: "regDate",
            sorter: (a: any, b: any) => (moment(b.regDate) > moment(a.regDate) ? -1 : 1),
            render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss") : "-"),
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        dateOnly: true,
                    },
                },
            },
        }),
        DTColProps.DateTime_ServerTime({
            title: "Last Accessed Date (Server)",
            dataIndex: "lastDate",
            key: "lastDate",
            sorter: (a: any, b: any) => (moment(b.lastDate) > moment(a.lastDate) ? -1 : 1),
            render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss") : "-"),
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        dateOnly: true,
                    },
                },
            },
        }),
        DTColProps.Small({
            title: "CID Label",
            dataIndex: "cidLabelIds",
            key: "cidLabelIds",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: cfData.cidLabels.map((x: CidLabelsList) => ({ text: x.name, value: x.id })),
                    inputProps: {
                        mode: "multiple",
                    },
                },
            },
            render: (cidLabelIds: number[]) => {
                if (cidLabelIds.length === 0) return "-";

                return cidLabelIds.map((currId: number, idx: number) => {
                    let currCidLabel = cfData.cidLabels.find((y: CidLabelsList) => y.id === currId);
                    if (currCidLabel === undefined) return undefined;

                    return (
                        <div style={{ display: "flex" }} key={`${currCidLabel.id}-${idx}`}>
                            <div
                                style={{
                                    backgroundColor: `${currCidLabel.hexCode}`,
                                    color: "white",
                                    marginBottom: "0.9898vh",
                                    padding: "0.16vh 0.651vw",
                                    wordWrap: "break-word",
                                    borderRadius: 3,
                                    fontSize: "0.7rem",
                                }}
                            >
                                {currCidLabel.name}
                            </div>
                        </div>
                    );
                });
            },
        }),
        DTColProps.Small({
            title: "CID Toxicity Ratio",
            dataIndex: "cidToxicityRatio",
            key: "cidToxicityRatio",
            options: {
                filter: {
                    type: ComponentType.slider,
                    value: "",
                    inputProps: {
                        defaultValue: [0, 1],
                        min: 0,
                        max: 1,
                        step: 0.01,
                    },
                },
            },
        }),
        {
            title: "Special Rules",
            className: "special-rules",
            children: [
                {
                    title: "Has Comment",
                    className: "has-comment",
                    children: [
                        DTColProps.XSmall(
                            {
                                title: "Crypto D/W",
                                dataIndex: "hasCryptoDwCmt",
                                key: "hasCryptoDwCmt",
                                render: (hasCryptoDwCmt: boolean) =>
                                    hasCryptoDwCmt ? (
                                        <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                                    ) : (
                                        <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                                    ),
                            },
                            ["text-center"]
                        ),
                        DTColProps.XSmall(
                            {
                                title: "Credit Card",
                                dataIndex: "hasCreditCardCmt",
                                key: "hasCreditCardCmt",
                                render: (hasCreditCardCmt: boolean) =>
                                    hasCreditCardCmt ? (
                                        <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                                    ) : (
                                        <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                                    ),
                            },
                            ["text-center"]
                        ),
                        DTColProps.XSmall(
                            {
                                title: "Bank",
                                dataIndex: "hasBankCmt",
                                key: "hasBankCmt",
                                render: (hasBankCmt: boolean) =>
                                    hasBankCmt ? (
                                        <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                                    ) : (
                                        <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                                    ),
                            },
                            ["text-center"]
                        ),
                    ],
                },
                DTColProps.XSmall(
                    {
                        title: "Has US IP",
                        dataIndex: "hasUsIp",
                        key: "hasUsIp",
                        render: (hasUsIp: boolean) =>
                            hasUsIp ? (
                                <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                            ) : (
                                <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                            ),
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Is Nationality Differ From Country",
                        dataIndex: "isNationalityDifferFromCountry",
                        key: "isNationalityDifferFromCountry",
                        render: (isNationalityDifferFromCountry: boolean) =>
                            isNationalityDifferFromCountry ? (
                                <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                            ) : (
                                <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                            ),
                    },
                    ["text-center"]
                ),
            ],
        },
        {
            title: "Has Crypto D/W Comment",
            dataIndex: "hasCryptoDwCmt",
            key: "hasCryptoDwCmt",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                    ],
                },
                visible: false,
            },
        },
        {
            title: "Has Credit Card Comment",
            dataIndex: "hasCreditCardCmt",
            key: "hasCreditCardCmt",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                    ],
                },
                visible: false,
            },
        },
        {
            title: "Has Bank Comment",
            dataIndex: "hasBankCmt",
            key: "hasBankCmt",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                    ],
                },
                visible: false,
            },
        },
        {
            title: "Has US IP",
            dataIndex: "hasUsIp",
            key: "hasUsIp",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                    ],
                },
                visible: false,
            },
        },
        {
            title: "Has Is Nationality Differ From Country Comment",
            dataIndex: "isNationalityDifferFromCountry",
            key: "isNationalityDifferFromCountry",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                    ],
                },
                visible: false,
            },
        },
        {
            title: "Scores",
            className: "scores",
            children: [
                DTColProps.XSmall(
                    {
                        title: "Country",
                        dataIndex: "countryScore",
                        key: "countryScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Email",
                        dataIndex: "emailScore",
                        key: "emailScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "CID",
                        dataIndex: "cidScore",
                        key: "cidScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "CID Toxicity Ratio",
                        dataIndex: "cidToxicityRatioScore",
                        key: "cidToxicityRatioScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Crypto D/W",
                        dataIndex: "cryptoDwCmtScore",
                        key: "cryptoDwCmtScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Credit Card",
                        dataIndex: "creditCardCmtScore",
                        key: "creditCardCmtScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Bank",
                        dataIndex: "bankCmtScore",
                        key: "bankCmtScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "US IP",
                        dataIndex: "usIpScore",
                        key: "usIpScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Nationality Differ From Country",
                        dataIndex: "nationalityDifferFromCountryScore",
                        key: "nationalityDifferFromCountryScore",
                    },
                    ["text-center"]
                ),
            ],
        },
        DTColProps.XSmall(
            {
                title: "Pre-Market Score",
                dataIndex: "preMarketScore",
                key: "preMarketScore",
                fixed: "right",
                sorter: true,
                render: (value: number) => currencyRender(value),
                options: {
                    filter: {
                        type: ComponentType.slider,
                        value: "",
                        inputProps: {
                            defaultValue: [65, 100],
                        },
                    },
                },
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "All Time Highest Pre-Market Score",
                dataIndex: "preMarketAthScore",
                key: "preMarketAthScore",
                fixed: "right",
                sorter: true,
                render: (value: number) => currencyRender(value),
                options: {
                    filter: {
                        type: ComponentType.slider,
                        value: "",
                        inputProps: {
                            defaultValue: [0, 100],
                        },
                    },
                },
            },
            ["text-center"]
        ),
        DTColProps.XXSmall(
            {
                title: "Is Toxic",
                dataIndex: "isToxic",
                key: "isToxic",
                fixed: "right",
                sorter: true,
                render: (isToxic: boolean) => isToxic
                    ? <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                    : <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />,
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [
                            { text: "Yes", value: true },
                            { text: "No", value: false },
                        ],
                    },
                },
            },
            ["text-center"]
        ),
        DTColProps.DateTime_ServerTime({
            title: "Last Modified Date (Server)",
            dataIndex: "modifiedTime",
            key: "modifiedTime",
            fixed: "right",
            sorter: (a: any, b: any) => {
                if (a.modifiedTime === null && b.modifiedTime) {
                    return moment(b.createdTime) > moment(a.createdTime) ? -1 : 1;
                } else if (a.modifiedTime === null) {
                    return moment(b.modifiedTime) > moment(a.createdTime) ? -1 : 1;
                } else if (b.modifiedTime === null) {
                    return moment(b.createdTime) > moment(a.modifiedTime) ? -1 : 1;
                } else {
                    return moment(b.modifiedTime) > moment(a.modifiedTime) ? -1 : 1;
                }
            },
            render: (value: string, rowData: PreMarketAccountProfile) =>
                value === null
                    ? DataTableColumnRender.DateTime_ServerTime(rowData.createdTime, "YYYY-MM-DD HH:mm:ss")
                    : DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss"),
            // options: {
            //     filter: {
            //         type: ComponentType.daterange,
            //         value: "",
            //         inputProps: {
            //             showTime: { format: "HH:mm:ss" },
            //         },
            //     },
            // },
        }),
        DTColProps.XXSmall({
            title: "",
            dataIndex: "viewLog",
            key: "viewLog",
            fixed: "right",
            render: (viewLog: any, rowData: PreMarketAccountProfile) => (
                <Tooltip placement="left" title="View Log">
                    <HistoryOutlined
                        style={{ fontSize: "1.125rem", cursor: "pointer" }}
                        onClick={() => {
                            setIsLogModalOpen(true);
                            getPreMarketAccProfileLogs(rowData);
                        }}
                    />
                </Tooltip>
            ),
        },
            ["text-center"]
        ),
    ], [cfData]);

    const componentCallback = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("")
                    : setSorting(
                        FormData.sorter.column && FormData.sorter.order
                            ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                            : ""
                    );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "accountId") {
                            fParams["accountIds"] = [FormData[x]];
                        } else if (x === "brandId") {
                            fParams["brandIds"] = FormData[x];
                        } else if (x === "serverId") {
                            fParams["serverIds"] = FormData[x];
                        } else if (x === "country") {
                            fParams["countries"] = FormData[x];
                        } else if (x === "regDate") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["regDateFrom"] = DateTimeUtil.GetOrigin(FormData[x][0], "YYYY-MM-DD");
                                fParams["regDateTo"] = DateTimeUtil.GetOrigin(FormData[x][1], "YYYY-MM-DD");
                            }
                        } else if (x === "lastDate") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["lastDateFrom"] = DateTimeUtil.GetOrigin(FormData[x][0]).slice(0, 10);
                                fParams["lastDateTo"] = DateTimeUtil.GetOrigin(FormData[x][1]).slice(0, 10);
                            }
                        } else if (x === "cidToxicityRatio") {
                            fParams.cidToxicityRatioFrom = FormData[x][0];
                            fParams.cidToxicityRatioTo = FormData[x][1];
                        } else if (x === "preMarketScore") {
                            fParams.preMarketScoreFrom = FormData[x][0];
                            fParams.preMarketScoreTo = FormData[x][1];
                        } else if (x === "preMarketAthScore") {
                            fParams.preMarketAthScoreFrom = FormData[x][0];
                            fParams.preMarketAthScoreTo = FormData[x][1];
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });

                setFilterParams(fParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.REFRESH_RATE_CHANGED:
                setRefreshRate(FormData.currentRefreshPeriod);
                break;
            case CALLBACK_KEY.REFRESH:
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getPreMarketAccountProfiles = () => {
        apiRequest(APIs.GET_PRE_MARKET_ACCOUNT_PROFILE, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    let newData = data.result.map((currData: PreMarketAccountProfile) => ({
                        ...currData,
                        key: `${currData.accountId}|${currData.serverId}`,
                    }));
                    setData(newData);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("pre-market account profiles", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getPreMarketAccProfileLogs = (rowData: PreMarketAccountProfile) => {
        setIsLogLoading(true);
        apiRequest(APIs.GET_PRE_MARKET_ACCOUNT_PROFILE_LOGS, {
            accountId: rowData.accountId,
            serverId: rowData.serverId
        })
            .then((data: any) => {
                setLogData(data && data.length > 0 ? data : []);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("pre-market account profile logs", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => {
                setIsLogLoading(false);
            });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            setRunRefetchDataList(false);
            getPreMarketAccountProfiles();
        }
        return () => { };
    }, [runRefetchDataList]);

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["brand", "server", "country", "cidLabel"],
        })
            .then((data: any) => {
                setCfData({
                    brands: data["brands"],
                    servers: data["servers"],
                    countries: data["country"],
                    cidLabels: data["cidLabel"],
                })
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("filter config list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            });

        apiRequest(APIs.GET_PRE_MARKET_RULES, {})
            .then((data: any) => {
                let temp_data: any = [];
                if (data.length > 0) {
                    data.forEach((currRule: PreMarketAccountModelRule) => {
                        temp_data[`${currRule.ruleId}|${currRule.ruleName}`] = temp_data[`${currRule.ruleId}|${currRule.ruleName}`] ?? [];
                        temp_data[`${currRule.ruleId}|${currRule.ruleName}`].push({ ...currRule });
                    });
                    setRuleData(temp_data);
                } else {
                    setRuleData({});
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("pre-market account rules", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            });
        return () => { };
    }, []);

    return (
        <>
            <div className="account-premarket-model-container">
                <CardBox title={"Account Pre-Market Model"}>
                    <div className="top-info-div">
                        <Popover
                            content={
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flex: 1,
                                        }}
                                    >
                                        {Object.keys(ruleData).map((currKey: string, mIdx: number) => {
                                            let currRuleName: string = currKey.slice(2, currKey.length);
                                            return (
                                                <Descriptions
                                                    layout="vertical"
                                                    size="small"
                                                    bordered
                                                    className="tiny-description"
                                                    key={`${currKey}-${mIdx}`}
                                                    style={{ marginRight: 10 }}
                                                    contentStyle={{ minWidth: "6.306vw", display: "block", maxWidth: "10vw" }}
                                                >
                                                    <Descriptions.Item
                                                        label={
                                                            <span
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                }}
                                                            >
                                                                <h4 style={{ width: "75%" }}>{currRuleName}</h4>
                                                                <Tooltip title="Score Cap">
                                                                    <h4
                                                                        style={{ marginRight: 5, width: "20%", textAlign: "right" }}
                                                                    >{`(${ruleData[currKey][0].ruleScoreCap})`}</h4>
                                                                </Tooltip>
                                                            </span>
                                                        }
                                                    >
                                                        <div
                                                            className="nice-scrollbar"
                                                            style={{
                                                                overflow: "auto",
                                                                height: "56.56vh",
                                                            }}
                                                        >
                                                            {ruleData[currKey].map((currRule: PreMarketAccountModelRule, idx: number) => {
                                                                return (
                                                                    <div
                                                                        key={`${currRuleName}-${currRule.id}-${idx}`}
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        <p style={{ width: "75%" }}>{currRule.name}</p>
                                                                        <p style={{ width: "20%", textAlign: "right", marginRight: 10 }}>
                                                                            {currRule.score}
                                                                        </p>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                            title={<h3>Account Pre-Market Rules</h3>}
                            trigger="click"
                            placement="leftTop"
                        >
                            <Button icon={<QuestionCircleOutlined />}>Account Pre-Market Rules</Button>
                        </Popover>
                    </div>
                    <FlexiDataTable
                        rowKeyProperty="key"
                        title=""
                        columns={columns}
                        dataSource={data || []}
                        loading={isLoading}
                        options={{
                            serverFiltering: true,
                            refresh: {
                                timer: true,
                                refreshSecond: refreshRate,
                                enablePeriodSelection: true,
                            },
                            defaultCollapseFilterPanel: true,
                        }}
                        callback={componentCallback}
                        pagination={pagination}
                        serverSide={true}
                        tableProps={{
                            bordered: true,
                        }}
                        filterInitialValue={{
                            preMarketScore: [65, 100],
                            cidToxicityRatio: [0, 1],
                            preMarketAthScore: [0, 100]
                        }}
                    />

                    <Modal
                        width={1500}
                        title={`Pre-Market Model Score Progression`}
                        open={isLogModalOpen}
                        onCancel={() => setIsLogModalOpen(false)}
                        cancelText="Close"
                        okButtonProps={{
                            style: { display: "none" },
                        }}
                    >
                        {isLogLoading ? (
                            <div style={{ textAlign: "center", paddingTop: "8vh", paddingBottom: "8vh" }}>
                                <LoadingComponent />
                            </div>
                        ) : (
                            <ViewAuditLog
                                auditLogData={logData}
                                servers={cfData.servers}
                                brands={cfData.brands}
                                countries={cfData.countries}
                                cidLabels={cfData.cidLabels}
                            />
                        )}
                    </Modal>
                </CardBox>
            </div>
        </>
    );
};

export default PreMarketAccModel;
