import React from "react";
import { Breadcrumb, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { colorMap } from "../StatusMonitor/statusUtils";

type ICurrMenu = "Overview" | "Meta Trader" | "Status Monitor" | "Reject Order" | "Server Performance" | "Issues Log" | "Data Center";

const defaultItems = [
    {
        key: "Overview",
        link: "systemmonitor/overview",
    },
    {
        key: "Status Monitor",
        link: "systemmonitor/statusmonitor",
    },
    {
        key: "Reject Order",
        link: "systemmonitor/rejectorder",
    },
    {
        key: "Meta Trader",
        link: "systemmonitor/metatrader",
    },
    {
        key: "Server Performance",
        link: "systemmonitor/serverperformance",
    },
    {
        key: "Issues Log",
        link: "systemmonitor/issueslog",
    },
    {
        key: "Data Center",
        link: "systemmonitor/datacenter",
    },
];

const SystemBreadCrumbs = ({ currMenu }: { currMenu: ICurrMenu }) => {
    // read localstorage
    const currMenuLS = localStorage.getItem("is_m");
    const currMenuParsed = currMenuLS ? JSON.parse(atob(currMenuLS)) : null;

    const items = defaultItems?.map(item => {
        // return label without link if it's the current menu

        // check current item.key matching any of the currMenuParsed's item key, if not, skip this item
        const findMatched = currMenuParsed.find((itemParsed: any) => {
            return itemParsed.key === item.link;
        });
        if (!findMatched) return null;
        if (item.key === currMenu) {
            return {
                key: item.key,
                label: (
                    <Tooltip title="You are here" placement="right">
                        <div style={{ width: "100%", cursor: "default", color: colorMap.primary }}>{item.key}</div>
                    </Tooltip>
                ),
            };
        }
        return {
            key: item.key,
            label: <Link to={`/${item.link}`}>{item.key}</Link>,
        };
    });

    return (
        <Breadcrumb style={{ marginBottom: "1rem" }}>
            {/* @ts-ignore */}
            <Breadcrumb.Item>
                <Link to={"/systemmonitor/overview"}>System Monitor</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item menu={{ items }}>{currMenu}</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default SystemBreadCrumbs;
