import { useEffect, useMemo, useState } from "react";
import { APIs, apiRequest } from "../../../services/apiConfig";
import { Badge, Empty, Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "@/utils/string";
import { SortList2 } from "@/utils/array";
import InfiniteScroll from "react-infinite-scroll-component";

interface IHolidayNotice {
    holiday: string;
    oriCount: number;
    symbols: IHolidayNoticeSymbolProps[];
}

interface IHolidayNoticeSymbolProps {
    symbol: string;
    cleanSymbol: string;
    serverId: number;
    serverName: string;
    timeRange: string;
    date: string;
    searchText: string;
}

interface IHolidayListObjectProps {
    datas: IHolidayNotice[];
    totalCount: number;
}

const HolidayNotice = () => {
    const initialLoadCount: number = 300;
    const [filterText, setFilterText] = useState<string>("");
    const [holidayNoticeList, setHolidayNoticeList] = useState<IHolidayNotice[]>([]);
    const [loadMoreCount, setLoadMoreCount] = useState<number>(initialLoadCount);

    const holidayListObject: IHolidayListObjectProps = useMemo(() => {
        let filteredText: string = `${defaultIfEmptyOrNull(filterText, "")}`.toLowerCase(),
            filteredList: IHolidayNotice[] = isEmptyOrNull(filteredText)
                ? holidayNoticeList
                : holidayNoticeList
                      .map(x => ({
                          ...x,
                          symbols: x.symbols.filter(y => y.searchText?.includes(filteredText)),
                      }))
                      .filter(x => x.holiday.includes(filterText) || x.symbols.length > 0);
        return {
            datas: filteredList.reduce(
                (z: any, x: IHolidayNotice) => {
                    if (z.countLimit >= loadMoreCount) return z;

                    if (z.countLimit + x.symbols.length < loadMoreCount) {
                        z.datas.push(x);
                        z.countLimit += x.symbols.length;
                    } else {
                        let remainingCount: number = loadMoreCount - z.countLimit;
                        z.datas.push({
                            ...x,
                            symbols: x.symbols.slice(0, remainingCount),
                        });
                        z.countLimit = loadMoreCount;
                    }
                    return z;
                },
                { datas: [] as IHolidayNotice[], countLimit: 0 as number }
            ).datas,
            totalCount: filteredList.reduce((count: number, x: IHolidayNotice) => count + x.symbols.length, 0),
        };
    }, [holidayNoticeList, filterText, loadMoreCount]);

    const getTemplate2: React.ReactNode = useMemo(() => {
        if (holidayListObject.datas.length > 0) {
            return (
                <div id="scrollableHolidayDiv" className="item-container nice-scrollbar">
                    <InfiniteScroll
                        next={() => setLoadMoreCount(prev => prev + initialLoadCount)}
                        hasMore={holidayListObject.totalCount > loadMoreCount}
                        loader={<div>-- Load More --</div>}
                        dataLength={loadMoreCount}
                        scrollableTarget="scrollableHolidayDiv"
                    >
                        {holidayListObject.datas.map((x: IHolidayNotice, xIdx: number) => {
                            return (
                                <div key={`thl-i-${xIdx}`} className="item">
                                    <div className="title">
                                        <span className="desc">{x.holiday}</span>
                                        <Badge count={x.oriCount} overflowCount={99999} />
                                    </div>
                                    {x.symbols.map((y: IHolidayNoticeSymbolProps, yIdx: number) => (
                                        <div key={`thl-i-si-${yIdx}`} className="sub-item">
                                            <Tooltip title={y.serverName}>
                                                <span>{y.serverName}</span>
                                            </Tooltip>
                                            <Tooltip title={y.symbol}>
                                                <span>{y.symbol}</span>
                                            </Tooltip>
                                            <Tooltip title={y.timeRange}>
                                                <span>{y.timeRange}</span>
                                            </Tooltip>
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                    </InfiniteScroll>
                </div>
            );
        }

        return (
            <div style={{ marginTop: "50px" }}>
                <Empty />
            </div>
        );
    }, [holidayListObject, loadMoreCount]);

    const getHolidayNoticeList = () => {
        apiRequest(APIs.GET_HOLIDAY_NOTICE, { isTodayOnly: true })
            .then((res: any) =>
                setHolidayNoticeList(
                    res && res.length > 0
                        ? res.map((x: IHolidayNotice) => ({
                              ...x,
                              oriCount: x.symbols.length,
                              symbols: SortList2(
                                  x.symbols.map((y: IHolidayNoticeSymbolProps) => ({
                                      ...y,
                                      searchText: `${y.symbol.toLowerCase()}|${y.serverName.toLowerCase()}|${y.cleanSymbol.toLowerCase()}`,
                                  })),
                                  ["serverName", "symbol"],
                                  ["ASC", "ASC"]
                              ),
                          }))
                        : []
                )
            )
            .catch((err: any) => console.log("APIs.GET_HOLIDAY_NOTICE", err));
    };

    useEffect(() => {
        getHolidayNoticeList();

        return () => {};
    }, []);

    return (
        <div className="holiday-notice-container">
            <div className="title-div">Today Holiday Symbol</div>
            <div className="search-panel">
                <Input type="text" prefix={<SearchOutlined />} onChange={(e: any) => setFilterText(e.target.value)} />
            </div>
            {getTemplate2}
        </div>
    );
};

export default HolidayNotice;
