import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apiConfig";
import { dummyData, dummyData2, dummyData3 } from "../pages/SpreadReportPage/dummyData";
import { plainAxiosInstance } from "../services/axiosSetup";
/*
sample request
symbol: AUDCAD
    start: 2024-04-02
    end: 2024-04-02
    groups: MT5_VT_ECN,MT5_VT_STP,GKFX,LP_MXT_Finalto
*/
interface ISpreadCompareRequest {
    symbol: string | null;
    start: string;
    end: string;
    groups: string[];
    originalGroups?: any;
    useDummy?: number | null;
    startTime?: string;
    endTime?: string;
}

const useSpreadCompare = ({ symbol, start, end, groups, useDummy }: ISpreadCompareRequest) => {
    //convert request to form data

    const startDateWithoutTime = start === null ? null : start.split("T")[0]; // if start is null, return null, otherwise split start by "T" and get the first element
    const endDateWithoutTime = end === null ? null : end.split("T")[0]; // if end is null, return null, otherwise split end by "T" and get the first element
    const req = new FormData();
    req.append("symbol", symbol ?? "");
    req.append("start", startDateWithoutTime ?? start);
    req.append("end", endDateWithoutTime ?? end);
    // groups is array of strings, so we need to convert it to string separated by comma
    const groupsStr = groups?.join(",");
    req.append("groups", groupsStr);
    // const token = getToken();
    const { data, refetch, isLoading, error } = useQuery({
        // @ts-ignore
        queryKey: ["rcSpreadCompare", symbol, start, end, groups],
        queryFn: () =>
            !symbol || !groups
                ? Promise.resolve({ data: null })
                : plainAxiosInstance.post(`${APIs.RC_SPREAD.POST_SPREAD_COMPARE}`, req, {
                    headers: {
                        Accept: "application/json",
                        "Content-type": "multipart/form-data",
                        // Authorization: `Bearer ${token}`,
                    },
                }),

        // axios.post(`${process.env.REACT_APP_API_URL}${APIs.RC_SPREAD.POST_SPREAD_COMPARE}`, req, {
        //       headers: {
        //           Accept: "application/json",
        //           "Content-type": "multipart/form-data",
        //           Authorization: `Bearer ${token}`,
        //       },
        //   }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
        enabled: !!symbol && !!groups && !!start && !!end,
    });
    // console.log("data rcSpreadCompare", data?.data, data);

    const reducer = (result: any, xAxis: any, index: any) => {
        // @ts-ignore
        data?.data?.legend?.forEach((name: string, i: number) => {
            result.push({
                name: name,
                time: xAxis,
                // @ts-ignore
                spread: data?.data?.series[i][index],
            });
        });
        return result;
    };
    // // reduce data?.data with reducer above
    // const transformedData = data?.data?.xAxis?.reduce(reducer, []);

    // #region dummy data and function
    const dummy = useDummy === 1 ? dummyData : useDummy === 2 ? dummyData2 : dummyData3;
    const dummyReducer = (result: any, xAxis: any, index: any) => {
        dummy?.legend.forEach((name: string, i: number) => {
            result.push({
                name: name,
                time: xAxis,
                spread: dummy.series[i][index],
            });
        });
        return result;
    };
    // reduce data?.data with reducer above
    // @ts-ignore
    const transformedData = useDummy ? dummy?.xAxis?.reduce(dummyReducer, []) : data?.data?.xAxis?.reduce(reducer, []);
    // console.log("transformedData", transformedData);
    // #endregion

    // @ts-ignore
    const spreadCompare = data?.data?.status ? [] : data?.data?.xAxis ? transformedData : [];
    // @ts-ignore
    const spreadCompareError = data?.data?.message || error?.message || error || null;

    return {
        spreadCompare,
        spreadCompareError,
        // spreadCompare: data?.data?.xAxis ? transformedData : [],
        // spreadCompareError: error || data?.data?.message,
        refetchSpreadCompare: refetch,
        isLoading,
    };
};

export default useSpreadCompare;
