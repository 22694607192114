import { KeyValuePair } from "@/constants/type";
import HistoricalGroupTransferRecord from "./historicalGroupTransferRecord";
import HistoricalMarketRisk from "./historicalMarketRisk";
import HistoricalStatistics from "./historicalStatistics";
import { Tabs } from "antd";
import { useState } from "react";

export interface GroupTransferHistoricalPageProps {
    reasonOptions: KeyValuePair[];
}

const GroupTransferHistoricalPageOption = [
    { key: "1", label: "Group Transfer Tool Record" },
    { key: "2", label: "Statistics" },
    { key: "3", label: "Market Risk" },
];

const GroupTransferHistoricalPage = (props: GroupTransferHistoricalPageProps) => {
    const [currTab, setCurrTab] = useState<string>("1");
    return (
        <div className="historical-container">
            <Tabs
                activeKey={currTab}
                onChange={(activeKey: string) => setCurrTab(activeKey)}
                type="card"
                style={{ padding: "0.5rem 0.5rem 0 0.5rem" }}
                tabBarStyle={{ marginBottom: 0 }}
                items={GroupTransferHistoricalPageOption}
            />
            <div style={{ padding: "0.3255vw 0.707vh" }} className="main-border">
                {currTab === "1" && <HistoricalGroupTransferRecord reasonOptions={props.reasonOptions} />}
                {currTab === "2" && <HistoricalStatistics />}
                {currTab === "3" && <HistoricalMarketRisk />}
            </div>
        </div>
    );
};

export default GroupTransferHistoricalPage;
