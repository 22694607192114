import { Modal, Form, Popconfirm, Button } from "antd";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType } from "../../../../../constants";
import { REQUIRED_FIELD } from "../../../../../constants/errorMessage";
import { useEffect, useMemo } from "react";
import { DefaultIfEmpty } from "../../../../../utils/object";

export interface AddNoteModalProps {
    selectedKeys: string[];
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    selectedRowPkVals: any[];
    isSubmitting: boolean;
}

export enum AddNoteModalCallbackKey {
    Close = 0,
    FormSubmit = 1,
}

const AddNoteModal = (props: AddNoteModalProps) => {
    const [executeForm] = Form.useForm();

    const isBtnLoading = useMemo(() => props.isSubmitting, [props.isSubmitting]);

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldsValue({ diffIds: props.selectedKeys, reason: "" });
            executeForm.setFieldsValue({ pkVals: props.selectedRowPkVals, reason: "" });
        }
    }, [props.isModalVisible, props.selectedKeys, props.selectedRowPkVals]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={"Add New Reason"}
            open={props.isModalVisible}
            onCancel={() => props.callback(AddNoteModalCallbackKey.Close, null)}
            maskClosable={false}
            footer={[
                <Button
                    key={"cancel"}
                    onClick={() => props.callback(AddNoteModalCallbackKey.Close, null)}
                >
                    Cancel
                </Button>,
                <Popconfirm
                    key={"submit"}
                    title="Are you sure to save these note(s)?"
                    onConfirm={() => {
                        executeForm
                            .validateFields()
                            .then((values: any) => {
                                props.callback(
                                    AddNoteModalCallbackKey.FormSubmit,
                                    DefaultIfEmpty(values, "pkVals", []).map((x: any) => ({ ...x, note: values.reason }))
                                );
                            })
                            .catch((err: any) => console.log(err));
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button loading={isBtnLoading} type="primary">Submit</Button>
                </Popconfirm>
            ]}
        >
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}>
                <FormComponent label="" name="diffIds" extra={{ type: ComponentType.hidden, value: [] }} />
                <FormComponent label="" name="pkVals" extra={{ type: ComponentType.hidden, value: [] }} />
                <FormComponent
                    label="Reason"
                    name="reason"
                    extra={{
                        type: ComponentType.textarea,
                        value: "",
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    }}
                />
            </Form>
        </Modal>
    );
};

export default AddNoteModal;
