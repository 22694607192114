import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { Col, Form, Modal, Row } from "antd";
import moment from "moment";
import { useEffect } from "react";

interface SyndicateDownloadReportModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
}

export enum SyndicateDownloadReportModalCallbackKey {
    Close = 0,
    Submit = 1,
}

const SyndicateDownloadReportModal = (props: SyndicateDownloadReportModalProps) => {
    const [executeForm] = Form.useForm();

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldValue("reportDate", "");
            executeForm.resetFields();
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"30vw"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okText="Submit"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        props.callback(SyndicateDownloadReportModalCallbackKey.Submit, {
                            startTime: moment(values.reportDate[0]).format("YYYY-MM-DD HH:mm"),
                            endTime: moment(values.reportDate[1]).format("YYYY-MM-DD HH:mm"),
                        });
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(SyndicateDownloadReportModalCallbackKey.Close, null)}
        >
            <Form form={executeForm} layout="inline">
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Report Date"
                            name="reportDate"
                            extra={{
                                type: ComponentType.daterange,
                                value: [],
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: {
                                    disabledDate: (current: any) => current && current > moment().endOf("day"),
                                    showTime: { format: "HH:mm" },
                                    format: "YYYY-MM-DD HH:mm",
                                },
                                dateFormat: "YYYY-MM-DD HH:mm",
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default SyndicateDownloadReportModal;
