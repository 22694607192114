import FlexiDataTable from "@/components/FlexiDataTable";
import { SUCCESS_FAILED, CALLBACK_KEY } from "@/constants";
import { FlexiDataTableCallbackProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { useGetReportBatchListQuery, ReportBatchResponse } from "@/store/apis/report/reportbatch";
import { addOrRemoveFromArray } from "@/utils/array";
import { DTColProps, DataTableColumnRender, ErrorMessageHandler, ErrorCatchValidator, getFileNameFromResponseHeader } from "@/utils/Common";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "@/utils/string";
import { ExclamationCircleOutlined, DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Tag, Modal, Button } from "antd";
import { useState, useMemo, useCallback, useEffect } from "react";

export enum ReportBatchModalCallbackKey {
    Close = 0,
}

export interface ReportBatchModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
    filterCallback?: (data: any) => boolean;
}

const ReportBatchModal = (props: ReportBatchModalProps) => {
    const { data, isLoading, refetch } = useGetReportBatchListQuery({}, { pollingInterval: 10000 });
    const [downloadingIds, setDownloadingIds] = useState<number[]>([]);

    const markupData = useMemo(() => {
        return props.filterCallback
            ? defaultIfEmptyOrNull(data, []).filter((x: any) => props.filterCallback && props.filterCallback(x))
            : defaultIfEmptyOrNull(data, []);
    }, [data, props.filterCallback]);

    const columns = [
        DTColProps.Middle({
            title: "Batch Name",
            dataIndex: "batchName",
            key: "batchName",
            fixed: "left",
        }),
        DTColProps.Small({
            title: "Start Time (Local)",
            dataIndex: "startTime",
            key: "startTime",
            render: (text: string | null) => {
                if (text === null) return "-";
                else {
                    let formattedDate = DataTableColumnRender.DateTime(text);
                    let idx = formattedDate.indexOf("+");
                    return formattedDate.slice(0, idx);
                }
            },
        }),
        DTColProps.Small({
            title: "End Time (Local)",
            dataIndex: "endTime",
            key: "endTime",
            render: (text: string | null) => {
                if (text === null) return "-";
                else {
                    let formattedDate = DataTableColumnRender.DateTime(text);
                    let idx = formattedDate.indexOf("+");
                    return formattedDate.slice(0, idx);
                }
            },
        }),
        {
            title: "Params",
            dataIndex: "params",
            key: "params",
            render: (params: string) => {
                if (isEmptyOrNull(params)) return "-";
                else {
                    let paramsArr = params.split(/&|;/);
                    return paramsArr.map((item: string, index: number) => (
                        <div
                            className="report-batch-params-tag"
                            key={`mdl-rpb-${index}`}
                            style={{
                                marginBottom: `${paramsArr?.length > 3 ? "8px" : 0}`,
                                marginRight: 3,
                                display: "inline-block",
                                padding: "0 7px",
                                borderRadius: 2,
                                backgroundColor: "#fafafa",
                                wordBreak: "break-all",
                                border: "1px solid #d9d9d9",
                                fontSize: 12,
                                maxWidth: "100%",
                            }}
                        >
                            {item.replace(/,|，/, ", ")}
                        </div>
                    ));
                }
            },
        },
        DTColProps.Small(
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (text: string) => (
                    <Tag color={text.toLowerCase() === "waiting" ? "orange" : text.toLowerCase() === "processing" ? "blue" : "green"}>{text}</Tag>
                ),
            },
            ["text-center"]
        ),
    ];

    const removeFile = (id: number) => {
        plainAxiosInstance
            .delete(`${APIs.RC_ROOT.DELETE_DOWNLOAD}?id=${id}`)
            .then((res: any) => {
                if (res.data === 0) {
                    ErrorMessageHandler(`File deleted successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                    setTimeout(refetch, 400);
                } else {
                    ErrorMessageHandler(`File deleted failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`File deleted failed`, SUCCESS_FAILED.OTHERS_FAILED, err))
            );
    };

    const downloadFile = useCallback(
        (record: ReportBatchResponse) => {
            if (downloadingIds.includes(record.id)) return;

            setDownloadingIds(prev => addOrRemoveFromArray([record.id], prev));
            plainAxiosInstance
                .get(`${APIs.RC_ROOT.GET_VERIFY_DOWNLOAD}?id=${record.id}`)
                .then((res: any) => {
                    if (typeof res.data === "boolean" && res.data) {
                        plainAxiosInstance
                            .get(`${APIs.RC_ROOT.GET_DOWNLOAD_FILE}?id=${record.id}`, {
                                headers: {
                                    Accept: "application/octet-stream, application/zip, */*",
                                },
                                responseType: "blob",
                            })
                            .then(response => {
                                const contentType = response.headers["content-type"];
                                const fileName = getFileNameFromResponseHeader(
                                    response,
                                    contentType === "application/zip" ? `${record.batchName}.zip` : `${record.batchName}.csv`
                                );
                                if (
                                    contentType === "application/octet-stream" ||
                                    contentType === "application/zip" ||
                                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                ) {
                                    // Handle the file download response
                                    const url = window.URL.createObjectURL(new Blob([response.data]));
                                    const link = document.createElement("a");
                                    link.href = url;
                                    link.setAttribute("download", fileName);
                                    document.body.appendChild(link);
                                    link.click();
                                    // Clean up
                                    window.URL.revokeObjectURL(url);
                                    ErrorMessageHandler(`File downloaded successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                                } else {
                                    ErrorMessageHandler(`Received non-file response. Error: ${response}.`, SUCCESS_FAILED.OTHERS_FAILED);
                                }
                            });
                    } else {
                        ErrorMessageHandler("the file", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, { message: "" });
                    }
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("the file", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err))
                )
                .finally(() => setDownloadingIds(prev => addOrRemoveFromArray([record.id], prev)));
        },
        [downloadingIds]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: number, formData: any) => {
        switch (type) {
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (formData.key === "delete") {
                    Modal.confirm({
                        icon: <ExclamationCircleOutlined />,
                        title: "Are you sure you want to delete?",
                        width: "30%",
                        onOk() {
                            removeFile(formData.data.id);
                        },
                        onCancel() {},
                    });
                } else if (formData.key === "download") {
                    downloadFile(formData.data);
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (props.isModalVisible) refetch && refetch();
    }, [props.isModalVisible, refetch]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"65%"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okButtonProps={{ style: { display: "none" } }}
            cancelText="Close"
            onCancel={() => props.callback(ReportBatchModalCallbackKey.Close, null)}
            footer={null}
        >
            <FlexiDataTable
                rowKeyProperty="id"
                title={false}
                loading={isLoading}
                columns={columns}
                options={{
                    enableFilter: false,
                    showHideColumns: false,
                    customExtraActionButton: (record: ReportBatchResponse, callback: (key: any, record: any) => void) => {
                        return record.status.toLowerCase() === "completed" ? (
                            <Button
                                type="text"
                                icon={<DownloadOutlined />}
                                loading={downloadingIds.includes(record.id)}
                                onClick={e => {
                                    e.preventDefault();
                                    callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "download", data: record });
                                }}
                            />
                        ) : (
                            <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={e => {
                                    e.preventDefault();
                                    callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "delete", data: record });
                                }}
                            />
                        );
                    },
                }}
                dataSource={markupData}
                callback={componentCallback}
            />
        </Modal>
    );
};

export default ReportBatchModal;
