import { useEffect, useState } from "react";
import SystemBreadCrumbs from "../components/SystemBreadCrumbs";
import { Tabs } from "antd";
import TabServerPerformance from "./TabServerPerformance";
const ServerPerformance = () => {
    const [currTab, setCurrTab] = useState<string | null>(null);

    // Ensure user can change tab by URL params
    useEffect(() => {
        const currTabParam = new URLSearchParams(window.location.search).get("tab");
        if (currTabParam) {
            setCurrTab(currTabParam);
        } else {
            setCurrTab("server_performance");
        }
    }, []);

    // Handle popstate event
    useEffect(() => {
        const handlePopState = () => {
            const currTabParam = new URLSearchParams(window.location.search).get("tab");
            if (currTabParam) {
                setCurrTab(currTabParam);
            } else {
                setCurrTab("server_performance");
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };

    if (!currTab) return null;

    return (
        <div>
            <SystemBreadCrumbs currMenu="Server Performance" />
            <Tabs
                activeKey={currTab}
                onChange={handleChangeTab}
                type="card"
                style={{ marginBottom: 0 }}
                items={[
                    {
                        label: "Server Performance",
                        key: "server_performance",
                        children: currTab === "server_performance" && <TabServerPerformance />,
                    },
                ]}
                tabBarStyle={{ marginBottom: 0 }}
            />
        </div>
    );
};

export default ServerPerformance;
