export const APIs = {
    GET_COMMON_METRIC_LIST: "/common/metric/config",
    GET_ACCOUNT_SEARCH: "/account/search",
    GET_ACCOUNT_PROFILE_LIST: "/account/list",
    GET_FILTER_CONFIG_LIST: "/common/filter/config",
    GET_RISK_SCORE_LIST: "/account/analysis/riskscore/getprofiles",
    GET_RISK_SCORE_METRICS: "/common/riskscore/config",
    GET_SETTING_LIST: "/common/setting/list",
    UPDATE_SETTING_BY_KEY: "/common/setting/update",
    GET_HOLIDAY_NOTICE: "/common/calendar/symbol-holiday",
    CREATE_RISK_SCORE_PROFILE: "/account/analysis/riskscore/createprofile",
    UPDATE_RISK_SCORE_PROFILE: "/account/analysis/riskscore/updateprofile",
    DELETE_RISK_SCORE_PROFILE: "/account/analysis/riskscore/deleteprofile",
    GET_BRAND_RISK_SCORE_LIST: "/account/analysis/riskscore/getbrand",
    CREATE_BRAND_RISK_SCORE: "/account/analysis/riskscore/createbrand",
    UPDATE_BRAND_RISK_SCORE: "/account/analysis/riskscore/updatebrand",
    DELETE_BRAND_RISK_SCORE: "/account/analysis/riskscore/deletebrand",
    ACCOUNT_SYMBOLS_RANKING_BY_METRIC: "/account/analysis/stats/ranking/symbol-ranking-by-metric",
    ACCOUNT_HISTORICAL_PNL: "/account/analysis/stats/metric/time-series",
    GET_ACCOUNT_SUMMARY_PANEL: "/account/analysis/stats/metric/as-of-now",
    GET_SOURCE_DISTRIBUTION_SUMMARY: "/account/analysis/stats/trade",
    GET_HISTORICAL_TRADES: "/account/historical/get-trades",
    GET_COMMENTS_HISTORY: "/account/analysis/riskscore/get-comments",
    UPDATE_COMMENT: "/account/analysis/riskscore/update-comment",
    CREATE_COMMENT: "/account/analysis/riskscore/create-comment",
    DELETE_COMMENT: "/account/analysis/riskscore/delete-comment",
    GET_ARCHIEVED_WATCH_LIST: "/account/watchlist/archive/list",
    ADD_WATCHLIST: "/account/watchlist/create",
    UPDATE_WATCHLIST_COMMENT: "/account/watchlist/update",
    REMOVE_WATCHLIST: "/account/watchlist/delete",
    DOWNLOAD_TRADE: "/account/historical/get-trades/download",
    GET_ACCOUNT_WATCH_LIST: "/account/watchlist",
    DOWNLOAD_WATCH_LIST: "/account/watchlist/download",
    UPDATE_ACCOUNT_LABELS: "/account/label/update",
    GET_AUDIT_LOGS: "/common/audit-log",

    GET_CLIENTS_LIST: "/client/list",
    GET_CLIENT_ACCOUNTS: "/client/accounts",
    GET_CLIENT_ACCOUNT_COMMENTS: "/client/accounts/comments",
    GET_CLIENT_COMMENTS: "/client/comments",
    CREATE_CLIENT_COMMENT: "/client/comment/create",
    DELETE_CLIENT_COMMENT: "/client/comment/delete",
    GET_CLIENT_ACCOUNT_SUMMARY_PANEL: "/client/stats/metric/as-of-now",
    GET_CLIENT_ACCOUNT_RANKING_BY_METRIC_SYMBOL: "/client/stats/ranking/account-ranking-by-metric-and-symbol",
    GET_CLIENT_SYMBOL_RANKING_BY_METRIC: "/client/stats/ranking/symbol-ranking-by-metric",
    GET_CLIENT_ACCOUNT_HISTORICAL_PNL: "/client/stats/metric/time-series",
    GET_PROBLEMATIC_LIST: "/client/problematic/list",
    UPLOAD_PREVIEW_PROBLEMATIC: "/client/problematic/upload/preview",
    CREATE_PROBLEMATIC: "/client/problematic/create",
    UPDATE_PROBLEMATIC: "/client/problematic/update",
    DOWNLOAD_PROBLEMATIC: "/client/problematic/download",
    GET_AUDIT_LOG_PROBLEMATIC: "/client/problematic/auditlog",
    GET_ACCOUNTS_PROBLEMATIC: "/client/problematic/accounts",
    GET_AUDIT_LOG_ACCOUNT_PROBLEMATIC_CLIENT: "/client/problematic/accounts/auditlog",
    GET_TOXIC_CLIENT_LABEL_LIST: "/client/toxic-client-checklist/label/list",
    CREATE_TOXIC_CLIENT_LABEL: "/client/toxic-client-checklist/label/create",
    UPDATE_TOXIC_CLIENT_LABEL: "/client/toxic-client-checklist/label/update",
    DELETE_TOXIC_CLIENT_LABEL: "/client/toxic-client-checklist/label/delete",
    GET_TOXIC_CLIENT_CHECK_LIST: "/client/toxic-client-checklist",
    GET_TOXIC_CLIENT_CHECK_BY_ACCOUNTS: "/client/toxic-client-checklist/account/list",
    CREATE_TOXIC_CLIENT_CHECK_ENTRY: "/client/toxic-client-checklist/entry/create",
    UPDATE_TOXIC_CLIENT_CHECK_ENTRY: "/client/toxic-client-checklist/entry/update",
    DELETE_TOXIC_CLIENT_CHECK_ENTRY: "/client/toxic-client-checklist/entry/delete",
    DOWNLOAD_TOXIC_CLIENT_CHECK_TEMPLATE: "/client/toxic-client-checklist/upload-template/download",
    UPLOAD_TOXIC_CLIENT_CHECK_TEMPLATE: "/client/toxic-client-checklist/entry/upload/preview",
    GET_TOXIC_CLIENT_CHECK_AUDIT_LOG: "/client/toxic-client-checklist/entry/audit-log",
    UPDATE_TOXIC_CLIENT_CHECK_RESTRICTIONS: "/client/toxic-client-checklist/restriction/status/update",
    GET_TOXIC_CLIENT_ENTRY_IMPORT_AUDIT_LOGS: "/client/toxic-client-checklist/entry/import/audit-log",
    DOWNLOAD_TOXIC_CLIENT_CHECK_LIST_EXCEL: "/client/toxic-client-checklist/download",
    GET_TOXIC_CLIENT_CHECK_LIST_ACC_RESTRICTIONS_CATALOGUE: "/client/toxic-client-checklist/account-view/restriction/catalog",
    BATCH_UPDATE_TOXIC_CLIENT_CHECK_RESTRICTIONS: "/client/toxic-client-checklist/restriction/status/update/batch",
    GET_HISTORICAL_TOXIC_CLIENT_CHECK_RESTRICTION_AUDIT_LOGS: "/client/toxic-client-checklist/restriction/audit-log",
    GET_TOXIC_CLIENT_CHECKLIST_REPORT_DASHBOARD: "/client/toxic-client-checklist/report",
    DELETE_TOXIC_CLIENT_ENTRY_IMPORT_BATCH: "/client/toxic-client-checklist/entry/import-batch/delete",
    GET_TOXIC_CLIENT_ALARM_CONFIG_LIST: "/client/toxic-client-checklist/alarm/config",
    UPDATE_TOXIC_CLIENT_ALARM_CONFIG_LIST: "/client/toxic-client-checklist/alarm/config/update",
    GET_TOXIC_CLIENT_SOURCE_CONFIG_LIST: "/client/toxic-client-checklist/source/list",
    CREATE_TOXIC_CLIENT_SOURCE: "/client/toxic-client-checklist/source/create",
    UPDATE_TOXIC_CLIENT_SOURCE: "/client/toxic-client-checklist/source/update",
    DELETE_TOXIC_CLIENT_SOURCE: "/client/toxic-client-checklist/source/delete",
    GET_WITHDRAWAL_AUDIT_LIST: "/client/withdrawal-audit/list",
    UPDATE_WITHDRAWAL_AUDIT: "/client/withdrawal-audit/update",
    GET_WITHDRAWAL_AUDIT_ENTRY_LOGS: "/client/withdrawal-audit/entry/audit-log",
    GET_WITHDRAWAL_AUDIT_API_LOGS: "/client/withdrawal-audit/api/audit-log",

    GET_METRIC_INFO: "/common/metric/config",
    GET_METRIC_CONFIGURATION_LIST: "/admin/metric-mapping/list",
    UPDATE_METRIC_MAPPING_LIST: "/admin/metric-mapping/update",
    GET_METRIC_GROUP_LIST: "/admin/metric-category/list",
    CREATE_METRIC_GROUP: "/admin/metric-category/update",
    UPDATE_METRIC_GROUP: "/admin/metric-category/update",
    DELETE_METRIC_GROUP: "/admin/metric-category/delete",
    UPDATE_METRIC_GROUP_ORDER: "/admin/metric-category/update-sorting",
    GET_METRIC_GLOSSARY_LIST: "/admin/metric-glossary/list",
    UPDATE_METRIC_GLOSSARY: "/admin/metric-glossary/update",
    GET_ACC_METRIC_SUM_LIST_360: "/admin/account360/account-summary/list",
    UPDATE_ACC_METRIC_SUM_LIST_360: "/admin/account360/account-summary/update",
    SORT_ACC_METRIC_SUM_LIST_360: "/admin/account360/account-summary/sort",
    GET_ACC_SYMBOL_LIST_360: "/admin/account360/account-symbol-summary/list",
    UPDATE_ACC_SYMBOL_LIST_360: "/admin/account360/account-symbol-summary/update",
    SORT_ACC_SYMBOL_LIST_360: "/admin/account360/account-symbol-summary/sort",
    GET_ACC_WATCH_LIST_GROUP_LIST: "/admin/account360/watchlist/group/list",
    CREATE_ACC_WATCH_LIST_GROUP: "/admin/account360/watchlist/group/create",
    UPDATE_ACC_WATCH_LIST_GROUP: "/admin/account360/watchlist/group/update",

    GET_REPORT_TEMPLATE: "/reporting/template/all",
    GET_REPORT_TEMPLATE_BY_ID: "/reporting/template",
    CREATE_TEMPLATE: "/reporting/template/create",
    UPDATE_TEMPLATE: "/reporting/template/update",
    DELETE_TEMPLATE: "/reporting/template/delete",
    DUPLICATE_TEMPLATE_BY_ID: "/reporting/template/duplicate",
    GET_REPORT_HISTORY_BY_ID: "/reporting/result",
    UPDATE_REPORT_RULES: "/reporting/result/update",
    CREATE_ADHOC: "/reporting/create",
    DOWNLOAD_REPORT: "/reporting/result/download",
    GET_REPORT_HISTORY_LIST: "/reporting/list",
    DELETE_REPORT_HISTORY: "/reporting/result/delete",

    USER_LOGIN: "/user/login",
    USER_LOGOUT: "/user/logout",
    USER_RESET_PASSWORD: "/user/forgot-pwd",
    GET_USER_PROFILE_BY_ID: "/user/profile",
    UPDATE_PROFILE: "/user/profile/update",

    GET_REPORT_JOBS_BY_ID: "/reporting/reportJobs",
    DROP_AND_RESYNC_REPORT_JOB_SCHEDULERS: "/reporting/reportJobEvent/drop-and-resync",
    RUN_JOB_SCHEDULER: "/scheduler/job/run",

    GET_TAG_PROFILE_LISTING: "/tagging/profile/list",
    CREATE_NEW_TAG_PROFILE: "/tagging/profile/create",
    UPDATE_TAG_PROFILE: "/tagging/profile/update",
    GET_TAG_PROFILE_LOGS: "/tagging/profile/log",
    DELETE_TAG_PROFILE: "/tagging/profile/delete",
    GET_TAG_EVENT_LIST: "/tagging/event/list",
    GET_ACCOUNT_RISK_SCORE: "/account/analysis/riskscore",
    GET_TAGGING_SUMMARY: "/tagging/account/event/stats",
    CREATE_NEW_TAG_EVENT: "/tagging/event/create",
    EDIT_TAG_EVENT: "/tagging/event/update",

    GET_USER_PROFILES: "/admin/user/profiles",
    CREATE_USER_ACCOUNT: "/admin/user/vims/bind",
    UPDATE_USER_ACCOUNT: "/admin/user/profile/update",
    DELETE_USER_ACCOUNT: "/admin/user/delete",
    RESET_PASSWORD_USER_ACCOUNT: "/admin/user/update-pwd",
    GET_USER_ROLE_LIST: "/admin/user/roles",
    GET_SERVERS_LIST: "/admin/settings/servers/list",
    CREATE_SERVER: "/admin/settings/servers/create",
    UPDATE_SERVER: "/admin/settings/servers/update",
    DELETE_SERVER: "/admin/settings/servers/delete",
    GET_BRANDS_LIST: "/admin/settings/brands/list",
    CREATE_BRAND: "/admin/settings/brands/create",
    UPDATE_BRAND: "/admin/settings/brands/update",
    DELETE_BRAND: "/admin/settings/brands/delete",
    GET_BRANDS_LIST_V2: "/admin/settings/V2/brands/list",
    CREATE_BRAND_V2: "/admin/settings/V2/brands/create",
    UPDATE_BRAND_V2: "/admin/settings/V2/brands/update",
    DELETE_BRAND_V2: "/admin/settings/V2/brands/delete",
    GET_DATA_CENTER_LIST_V2: "/admin/settings/V2/datacenter/get",
    CREATE_DATA_CENTER_V2: "/admin/settings/V2/datacenter/create",
    UPDATE_DATA_CENTER_V2: "/admin/settings/V2/datacenter/update",
    DELETE_DATA_CENTER_V2: "/admin/settings/V2/datacenter/delete",
    GET_EXTERNAL_SYSTEM_LIST_V2: "/admin/settings/V2/externalsystem/get",
    CREATE_EXTERNAL_SYSTEM_V2: "/admin/settings/V2/externalsystem/create",
    UPDATE_EXTERNAL_SYSTEM_V2: "/admin/settings/V2/externalsystem/update",
    GET_SERVERS_LIST_V2: "/admin/settings/V2/servers/list",
    GET_SERVERS_DETAIL_V2: "/admin/settings/V2/servers/detail",
    CREATE_SERVER_V2: "/admin/settings/V2/servers/create",
    UPDATE_SERVER_V2: "/admin/settings/V2/servers/update",
    DELETE_SERVER_V2: "/admin/settings/V2/servers/delete",
    GET_SERVER_BRAND_MAPPING_LIST: "/admin/settings/server-brand-mapping/list",
    CREATE_SERVER_BRAND_MAPPING: "/admin/settings/server-brand-mapping/create",
    UPDATE_SERVER_BRAND_MAPPING: "/admin/settings/server-brand-mapping/update",
    DELETE_SERVER_BRAND_MAPPING: "/admin/settings/server-brand-mapping/delete",
    GET_GROUP_PURPOSES_LIST: "/admin/settings/group-purposes/list",
    CREATE_GROUP_PURPOSE: "/admin/settings/group-purposes/create",
    UPDATE_GROUP_PURPOSE: "/admin/settings/group-purposes/update",
    DELETE_GROUP_PURPOSE: "/admin/settings/group-purposes/delete",
    GET_BRIDGE_LIST: "/admin/settings/V2/bridges/list",
    CREATE_BRIDGE: "/admin/settings/V2/bridges/create",
    UPDATE_BRIDGE: "/admin/settings/V2/bridges/update",
    GET_MENU_LISTING: "/admin/menu/list",
    GET_FULL_MENU_LISTING: "/admin/menu/full-list",
    CREATE_MENU: "/admin/menu/create",
    UPDATE_MENU: "/admin/menu/update",
    DELETE_MENU: "/admin/menu/delete",
    GET_ROLE_MENU_LISTING: "/admin/rolemenu/list",
    CREATE_ROLE_MENU: "/admin/rolemenu/create",
    UPDATE_ROLE_MENU: "/admin/rolemenu/update",
    DELETE_ROLE_MENU: "/admin/rolemenu/delete",
    HUB_FAILOVER_SETTING_LIST: "/admin/hubfailover/list",
    UPDATE_HUB_FAILOVER_SETTING: "/admin/hubfailover/update",
    GET_HUB_FAILOVER_SERVER_SECURITY_GROUP_LIST: "/admin/hubfailover/group/configurations/get",
    CREATE_HUB_FAILOVER_SERVER_SECURITY_GROUP_CONFIG: "/admin/hubfailover/group/configurations/create",
    UPDATE_HUB_FAILOVER_SERVER_SECURITY_GROUP_CONFIG: "/admin/hubfailover/group/configurations/update",
    GET_HUB_FAILOVER_SERVER_SECURITY_GROUP_DETAIL: "/admin/hubfailover/group/configuration-details/get",
    GET_SYMBOL_ASSET_TYPE_LISTING: "/admin/settings/symbol/asset-type/list",
    CREATE_SYMBOL_ASSET_TYPE: "/admin/settings/symbol/asset-type/create",
    UPDATE_SYMBOL_ASSET_TYPE: "/admin/settings/symbol/asset-type/update",
    DELETE_SYMBOL_ASSET_TYPE: "/admin/settings/symbol/asset-type/delete",
    GET_CLEAN_SYMBOL_LISTING: "/admin/settings/symbol/clean/list",
    CREATE_CLEAN_SYMBOL: "/admin/settings/symbol/clean/create",
    UPDATE_CLEAN_SYMBOL: "/admin/settings/symbol/clean/update",
    DELETE_CLEAN_SYMBOL: "/admin/settings/symbol/clean/delete",
    DOWNLOAD_CLEAN_SYMBOL_TEMPLATE: "/admin/settings/symbol/clean/upload-template/download",
    UPLOAD_CLEAN_SYMBOL_TEMPLATE: "/admin/settings/symbol/clean/upload/preview",
    EXPORT_CLEAN_SYMBOL: "/admin/settings/symbol/clean/download",
    DOWNLOAD_MT_SYMBOL_TEMPLATE: "/admin/settings/symbol/mt/upload-template/download",
    UPLOAD_MT_SYMBOL_TEMPLATE: "/admin/settings/symbol/mt/upload/preview",
    UPDATE_MT_SYMBOL: "/admin/settings/symbol/mt/update",
    EXPORT_MT_SYMBOL: "/admin/settings/symbol/mt/download",
    GET_MT_SYMBOL_LIST: "/admin/settings/symbol/mt/list",
    GET_SYMBOL_LIST: "/admin/settings/symbol/list",
    GET_SYMBOL_INFO: "/admin/settings/symbol/details",
    CREATE_SYMBOL: "/admin/settings/symbol/create",
    UPDATE_SYMBOL: "/admin/settings/symbol/update",
    DELETE_SYMBOL: "/admin/settings/symbol/delete",
    DOWNLOAD_SYMBOL_TEMPLATE: "/admin/settings/symbol/upload-template/download",
    UPDATE_SYMBOL_TEMPLATE: "/admin/settings/symbol/upload/preview",
    EXPORT_SYMBOL: "/admin/settings/symbol/download",
    GET_SYMBOL_SOURCE_LISTING: "/admin/settings/symbol/symbol-source-pair/list",
    GET_SYMBOL_SOURCE_INFO: "/admin/settings/symbol/symbol-source-pair/details",
    CREATE_SYMBOL_SOURCE_INFO: "/admin/settings/symbol/symbol-source-pair/create",
    UPDATE_SYMBOL_SOURCE_INFO: "/admin/settings/symbol/symbol-source-pair/update",
    DELETE_SYMBOL_SOURCE_INFO: "/admin/settings/symbol/symbol-source-pair/delete",
    GET_SYMBOL_MAPPING_DETAILS: "/admin/settings/symbol/mapping",
    CREATE_SYMBOL_MAPPING_DETAILS: "/admin/settings/symbol/mapping/create",
    UPDATE_SYMBOL_MAPPING_DETAILS: "/admin/settings/symbol/mapping/update",
    DELETE_SYMBOL_MAPPING_DETAILS: "/admin/settings/symbol/mapping/delete",
    GET_MR_GROUP_LIST: "/admin/market-risk/group/list",
    CREATE_MR_GROUP: "/admin/market-risk/group/create",
    UPDATE_MR_GROUP: "/admin/market-risk/group/update",
    DELETE_MR_GROUP: "/admin/market-risk/group/delete",
    GET_MR_SERVER_LIST: "/admin/market-risk/server-group/list",
    UPDATE_MR_SERVER: "/admin/market-risk/server-group/update",
    GET_MR_PK_LIST: "/admin/market-risk/pk/list",
    GET_MR_LP_LIST: "/admin/market-risk/lp/list",

    GET_MONITOR_PROFILE: "/monitoring/profile",
    CREATE_MONITOR_PROFILE: "/monitoring/profile/create",
    UPDATE_MONITOR_PROFILE: "/monitoring/profile/update",
    DELETE_MONITOR_PROFILE: "/monitoring/profile/delete",
    UPDATE_MONITOR_PROFILE_STATUS: "/monitoring/profile/status/toggle",
    GET_PROFILE_EVENT_LIST: "/monitoring/profile-event-rule-relation",
    GET_HISTORICAL_EVENT_LIST: "/monitoring/event/batches",
    VIEW_TRADE_PROFILE: "/monitoring/profile/view",
    VIEW_TRADE_EVENT_SUMMARY: "/monitoring/event/dashboard",
    GET_MONITOR_PROFILE_SUMMARY: "/monitoring/event/count",
    GET_MONITOR_PROFILE_TICKETS: "/monitoring/event/ticket-batch",
    GET_MONITOR_HISTORICAL_SUMMARY: "/monitoring/event/summary",
    GET_MONITOR_HISTORICAL_BY_ACCOUNT: "/monitoring/event/by-account",
    GET_MONITOR_EVENT_ACCOUNT_COMMENTS: "/monitoring/event/account/comments",
    CREATE_MONITOR_EVENT_ACCOUNT_COMMENT: "/monitoring/event/account/comment/create",
    UPDATE_MONITOR_EVENT_ACCOUNT_COMMENT: "/monitoring/event/account/comment/update",
    DELETE_MONITOR_EVENT_ACCOUNT_COMMENT: "/monitoring/event/account/comment/delete",
    UPDATE_MONITOR_ACCOUNT_EVENT_COMMENT: "/monitoring/account/event/comment/update",
    GET_DECAY_CHART_ACCOUNT_LIST: "/monitoring/decay-analysis/stats/account",
    GET_DECAY_CHART_TICKET_LIST: "/monitoring/decay-analysis/stats/trade",
    GET_CLOSED_DECAY_CHART_ACCOUNT_LIST: "/monitoring/closed-decay-analysis/stats/account",
    GET_CLOSED_DECAY_CHART_TICKET_LIST: "/monitoring/closed-decay-analysis/stats/trade",
    GET_HFT: "/monitoring/biti/gethft",
    GET_GROUPED_HFT: "/monitoring/biti/getgroupedhft",
    DOWNLOAD_DECAY_CHART_ACCOUNT_LIST: "/monitoring/decay-analysis/report/download",
    DOWNLOAD_CLOSED_DECAY_CHART_ACCOUNT_LIST: "/monitoring/closed-decay-analysis/report/download",
    GET_PMI_EVENT: "/monitoring/pmi/event",
    DOWNLOAD_PMI_LIST: "/monitoring/pmi/event/report/download",
    GET_TOXIC_ACCOUNT_EVENT: "/monitoring/toxic-account/event",
    GET_TOXIC_ACCOUNT_SNAPSHOT: "/monitoring/toxic-account/event/snapshot",
    DOWNLOAD_TOXIC_ACCOUNT_EVENT: "/monitoring/toxic-account/event/download",
    UPDATE_TOXIC_ACCOUNT_STATUS: "/monitoring/toxic-account/event/offquote",
    GET_TOXIC_ACCOUNT_HISTORICAL_LOG: "/monitoring/toxic-account/offquote/gethistactivities",
    GET_PRE_MARKET_ACCOUNT_PROFILE: "/monitoring/pre-market/account-profile",
    GET_PRE_MARKET_RULES: "/monitoring/pre-market/rule",
    GET_PRE_MARKET_ACCOUNT_PROFILE_LOGS: "/monitoring/pre-market/account-profile/audit-log",

    GET_NOTIFICATION_CONTENTS_LIST: "/notification/contentslist",
    UPDATE_NOTIFICATION_AS_READ: "/notification/content-update-as-read",
    DOWNLOAD_NOTIFICATION: "/notification/contentslist/download",
    GET_NOTIFICATION_CHANNEL_LIST: "/notification/channel/list",
    CREATE_NOTIFICATION_CHANNEL: "/notification/channel/create",
    UPDATE_NOTIFICATION_CHANNEL: "/notification/channel/update",
    GET_NOTIFICATION_QRCODE: "/notification/line/qrcode",
    GET_NOTIFICATION_LINE_USERS: "/notification/line/getusers",
    GET_NOTIFICATION_WHATSAPP_GETGROUPS: "/notification/whatsapp/getgroups",
    GET_ALARM_CONFIG_MODULES: "/notification/alarm/get-config-modules",
    GET_ALARM_CONFIG_RULES: "/notification/alarm/get-config-rules",
    UPDATE_ALARM_MODULE_AUDIT_LOGS: "/notification/alarm/update-module-audit-logs",

    GET_FILTER_DROPDOWN_TICKET_RESTORATION: "mt/mt4/getfilterfieldsdropdownvalue",
    GET_MT4_CLOSED_TRADES: "mt/mt4/getclosedtrades",
    GET_MT4_PREVIEW_CLOSED_TRADES_STOP_OUT: "mt/mt4/getpreviewclosedtrades",
    GET_HISTORICAL_RESTORE_TRADES: "mt/allmt/gethistoricalrestoretrades",
    GET_HISTORICAL_RETORE_TRADES_PROGRESS: "mt/allmt/gethistoricalrestoretradesprogress",
    CREATE_RESTORE_TRADE_TICKETS_JOB: "mt/allmt/createrestoretradestickets",
    GET_MT5_CLOSED_TRADES: "mt/mt5/getclosedtrades",
    GET_MT5_PREVIEW_CLOSED_TRADES_STOP_OUT: "mt/mt5/getpreviewclosedtrades",
    BATCH_UPLOAD_TICKET_RESTORATION_FOR_PREVIEW: "/mt/allmt/previewbybatchupload",

    GET_MT_SERVER_MANAGER_SECURITIY_STATUS: "/mt/hubfailover/managersecurity/server/status",
    SUBMIT_HUBFAILOVER_SECURITIES_SWITCHES: "/mt/hubfailover/securities/switch",
    GET_HUBSWITCHER_HISTORICAL_LIST: "/mt/hubfailover/securities/historical",
    GET_HUB_STATUS_LIST: "/mt/hubfailover/dashboard/status",

    GET_METRIC_TREND_INTERVAL: "/mr/getlp-metric-trend-interval",
    GET_SYMBOL_METRICS_SUMMARY: "/mr/getlp-symbol-metrics-summary",
    GET_METRICS_ACCOUNT: "/mr/getlp-metrics-account",
    GET_LP_MONITOR_ALARM_LISTING: "/mr/get-alarm-events",
    UPDATE_LP_MONITOR_ALARM_RESOLVED_STATUS: "/mr/update-alarm-events",
    GET_LP_MONITOR_ALARM_CONFIG: "/mr/get-alarm-config",
    DELETE_LP_MONITOR_ALARM_CONFIG: "/mr/delete-alarm-config",
    GET_LP_ALARM_CONFIG: "/mr/get-alarm-datasources",
    CREATE_LP_ALARM_CONFIG: "/mr/create-alarm-config",
    UPDATE_LP_ALARM_CONFIG: "/mr/update-alarm-config",
    GET_MR_ALARM_SUMMARY: "/mr/get-alarm-summary",
    SYNC_LP_DATA: "/mr/sync-lp-data",
    SEND_HOURLY_REPORT: "/mr/send-hourly-report",
    SEND_WEEKEND_HOURLY_REPORT: "/mr/send-weekend-hourly-report",
    SEND_EOD_REPORT: "/mr/send-eodpnl-report",
    SEND_SOCIAL_TRADING_REPORT: "/mr/send-socialtrading-report",

    GET_MIM_METRIC_AS_OF_NOW: "/mim/stats/metric/as-of-now",
    GET_MIM_METRIC_RANKING: "/mim/stats/metric/ranking",
    GET_MIM_METRIC_TIME_SERIES: "/mim/stats/metric/time-series",
    GET_MIM_ALARM_CONFIG_LIST: "/mim/alarm/config/list",
    CREATE_MIM_ALARM_CONFIG: "/mim/alarm/config/create",
    UPDATE_MIM_ALARM_CONFIG: "/mim/alarm/config/update",
    DELETE_MIM_ALARM_CONFIG: "/mim/alarm/config/delete",
    GET_ALARM_SUMMARY_LIST: "/mim/alarm/event/stats",
    GET_ALARM_LISTING: "/mim/alarm/event/summary",
    GET_ALARM_LISTING_EXPORT_EXCEL: "/mim/alarm/event/eod/report",

    CREATE_ALARM_COMMENT: "/mim/alarm/event/comment/create",
    CREATE_BULK_ALARM_COMMENT: "/alarm-center/event-comments/create",
    GET_ALARM_DASHBOARD_CONFIG: "/alarm-center/client-app-modules/get",
    GET_ALARM_DASHBOARD_STATUS_LIST: "/alarm-center/module-event-status/get",
    GET_ALARM_DASHBOARD_SEVERITY_SUMMARY: "/alarm-center/severity-statistics/get",
    GET_ALARM_DASHBOARD_EVENT_LIST: "/alarm-center/unresolved-events/get",

    GET_ALARM_COMMENT_HISTORY: "/alarm-center/comment-history/get",
    GET_ALARM_BREACH_SEVERITY_PROGRESS: "/alarm-center/breach-severity-progress/get",
    UPDATE_ALARM_COMMENT: "/alarm-center/event-comment/create",
    UPDATE_ALARM_STATUS: "/alarm-center/event-status/update",
    UPDATE_ALARM_ASSIGN_TASK: "/alarm-center/event-assignee/update",

    GET_HISTORICAL_ALARM_SUMMARY: "/alarm-center/historical-alarm-listing/get",
    EXPORT_HISTORICAL_ALARM_SUMMARY: "/alarm-center/historical-alarm-listing/download",

    // for RC migration
    RC_ROOT: {
        GET_SERVERS: "/rcpx/mainServerSetting",
        GET_BRANDS: "/rcpx/brandSetting",
        GET_SERVER_BRANDS: "/rcpx/brandMainServerSetting",
        GET_BRIDGES: "/rcpx/bridgeSetting",
        GET_SERVER_BRIDGES: "/rcpx/bridgeMainServerSetting",
        GET_DOWNLOADS: "/rcpx/reportBatch/getData",
        DELETE_DOWNLOAD: "/rcpx/reportBatch",
        GET_VERIFY_DOWNLOAD: "/rcpx/reportBatch/isFileExist",
        GET_DOWNLOAD_FILE: "/rcpx/reportBatch/download",
        GET_FEATURE_SETTINGS: "/rcpx/featureSetting",
    },
    RC_SPREAD: {
        GET_SPREAD_SYMBOLS: "/rcpx/spread/getSymbolList",
        POST_SPREAD_COMPARE: "/rcpx/spreadReport/comparing",
        POST_SPREAD_COMPARE_DOWNLOAD: "/rcpx/spreadReport/comparing/download",
        GET_SPREAD_SERVER_GROUPS: "/rcpx/spread/getSpreadGroupKind",
        GET_SPREAD_DAILY_AVG_DOWNLOAD: "/rcpx/spreadReport/dailyAvg/download",
    },
    RC_SYSTEM_MONITOR: {
        GET_STATUS_MONITOR: "/rcpx/appWall",
        GET_STATUS_MONITOR_DAILY: "/rcpx/onceAppWall",
        GET_STATUS_MONITOR_RERUN: "/rcpx/onceAppWall/runTime",
        GET_STATUS_MONITOR_EXCLUDE: "/rcpx/appWall/exclude",
        POST_STATUS_MONITOR_EXCLUDE: "/rcpx/appWall/exclude",
        POST_STATUS_MONITOR_DELETE_EXCLUDE: "/rcpx/appWall/deleteExclude",
    },
    RC_REJECT_ORDER: {
        GET_REJECT_ORDER_SERVERS: "/rcpx/rejectOrder/getServerList",
        GET_REJECT_ORDER_DOWNLOAD: "/rcpx/rejectOrder/downloadReport",
        GET_REJECT_ORDERS: "/rcpx/rejectOrder/getLastUpdateData",
        PUT_REJECT_ORDER_SOLVED: "/rcpx/rejectOrder/solvedRejectOrder",
        GET_REJECT_ORDER_SETTINGS: "/rcpx/rejectOrder/getRejectOrderSetting",
        POST_REJECT_ORDER_SETTINGS_UPDATE: "/rcpx/rejectOrder/updateRejectOrderSetting",
        DELETE_REJECT_ORDER_SETTINGS_UPDATE: "/rcpx/rejectOrder/deleteRejectOrderSetting",
    },
    RC_META_TRADER: {
        GET_SERVERS: "/rcpx/metaTraderServer/getStatusList",
        GET_MONITORS: "/rcpx/metaTraderServer/getMonitorList",
        GET_SERVER_TIME: "/rcpx/metaTraderServer/getServerTime",
        GET_DOWNLOAD_MONITOR: "/rcpx/metaTraderServer/downloadMonitor",
        POST_MONITOR_CHECK: "/rcpx/metaTraderServer/updateMonitorCheck",
        POST_MONITOR_COMMENT: "/rcpx/metaTraderServer/updateMonitorComment",
        POST_MONITOR_DONE: "/rcpx/metaTraderServer/updateMonitorDone",
    },
    RC_SERVER_PERFORMANCE: {
        GET_PERFORMANCE_MONITOR: "/rcpx/serverPerformance/performanceAlert",
        GET_PERFORMANCE_DETAIL: "/rcpx/serverPerformance/performance",
        GET_DOWNLOAD_MONITOR: "/rcpx/serverPerformance/performanceDownload",
        GET_SETTINGS: "/rcpx/serverPerformance/performanceThreshold",
    },
    RC_ISSUES_LOG: {
        GET_ISSUES_LOG: "/rcpx/monitorCheck",
        PUT_ISSUES_LOG_CHECK: "/rcpx/monitorCheck/check",
        PUT_ISSUES_LOG_DONE: "/rcpx/monitorCheck/done",
        PUT_ISSUES_LOG: "/rcpx/monitorCheck",
        GET_ISSUES_LOG_SERVERS: "/rcpx/monitorCheck/setting/server",
        GET_SERVER_LIST: "/rcpx/issuesLog/getAllMainServerList",
        // NOMONEY -- start
        GET_ISSUES_LOG_NOMONEY: "/rcpx/issuesLog/getNoMoneyReports",
        GET_ISSUES_LOG_NOMONEY_MAIL: "/rcpx/issuesLog/getNoMoneyReportMailLogs",
        GET_ISSUES_LOG_NOMONEY_SETTINGS: "/rcpx/issuesLog/getNoMoneyServerSettings",
        POST_ISSUES_LOG_NOMONEY_SETTINGS: "/rcpx/issuesLog/updateNoMoneyServerSettings",
        POST_ISSUES_LOG_DELETE_NOMONEY: "rcpx/issuesLog/deleteNoMoneyReports",
        // NOMONEY -- end
        GET_ISSUES_LOG_MT5_MONITOR: "/rcpx/issuesLog/getMt5OrderStateByLastTimeIsNull",
        POST_ISSUES_LOG_MT5: "/rcpx/issuesLog/getMt5OrderState",
        POST_ISSUES_LOG_MT5_DOWNLOAD: "/rcpx/issuesLog/downloadMt5OrderState",
        GET_ISSUES_LOG_ALARM_SETTINGS: "/rcpx/issuesLog/getErrorLogAlarmSettings",
        GET_ISSUES_LOG_HISTORY: "/rcpx/monitorCheck/history",
        GET_DOWNLOAD_ISSUES_LOG: "/rcpx/monitorCheck/history/download",
        GET_ISSUES_LOG_SETTINGS: "/rcpx/monitorCheck/setting",
        GET_ISSUES_LOG_SETTINGS_DOWNLOAD: "/rcpx/monitorCheck/setting/file",
        GET_ISSUES_LOG_SETTINGS_FILE: "/rcpx/monitorCheck/setting/download",
        POST_DOWNLOAD_ACCOUNT_MONITOR: "/rcpx/issuesLog/downloadAccountMonitorSetting",
        POST_DOWNLOAD_MANAGER_ACCOUNT_INTERCEPTED: "/rcpx/issuesLog/downloadManagerAccountIntercepted",
        POST_UPLOAD_ACCOUNT_MONITOR: "/rcpx/issuesLog/uploadAccountMonitorSetting",
        POST_UPLOAD_MANAGER_ACCOUNT_INTERCEPTED: "/rcpx/issuesLog/uploadManagerAccountIntercepted",

        GET_REQUEST_TIMEOUT_SETTINGS: "/rcpx/issuesLog/getStandardLogAlarmSettings",
        POST_REQUEST_TIMEOUT_SETTINGS: "/rcpx/issuesLog/updateStandardLogAlarmSettings",

        // Balance Adjustment Monitor
        GET_BALANCE_ADJUSTMENT_MONITOR: "/rcpx/balanceAdjustment/getPastData", // balanceAdjustment/getPastData/1440/1
        POST_BALANCE_ADJUSTMENT_COMMENT: "/rcpx/balanceAdjustment/comment",
        POST_BALANCE_ADJUSTMENT_SOLVED: "/rcpx/balanceAdjustment/solved",
        GET_BALANCE_ADJUSTMENT_DOWNLOAD: "/rcpx/balanceAdjustment/download", // download?date=${YYYY-MM-DD}&solvedStaus=0
        GET_BALANCE_ADJUSTMENT_RECORD_COUNT: "/rcpx/balanceAdjustment/getDataRecordCount", // download?date=${YYYY-MM-DD}&solvedStaus=0
        GET_BALANCE_ADJUSTMENT_SETTINGS: "/rcpx/balanceAdjustment/getSetting",
        POST_BALANCE_ADJUSTMENT_SETTINGS_UPLOAD: "/rcpx/balanceAdjustment/upload",
        GET_BALANCE_ADJUSTMENT_SMS_SETTINGS: "/rcpx/balanceAdjustment/getSmsSetting",
        GET_BALANCE_ADJUSTMENT_SETTINGS_DOWNLOAD: "/rcpx/balanceAdjustment/downloadAllSetting",
        GET_BALANCE_ADJUSTMENT_SMS_SETTINGS_DOWNLOAD: "/rcpx/balanceAdjustment/downloadAllSetting",
        POST_BALANCE_ADJUSTMENT_SMS_SETTINGS: "/rcpx/balanceAdjustment/updateSmsSetting",
    },
    RC_DATA_CENTER: {
        GET_DATA_CENTER_TYPES: "/rcpx/dataCenterSetting/type",
        GET_DATA_CENTER_MONITOR: "/rcpx/dataCenterMonitor/alarm/status",
        GET_DATA_CENTER_MONITOR_DOWNLOAD: "/rcpx/dataCenterMonitor/alarm/download",
        POST_DATA_CENTER_MONITOR_DOWNLOAD_HISTORY: "/rcpx/dataCenterMonitor/history/download",
        GET_DATA_CENTER_MONITOR_SETTINGS: "/rcpx/dataCenterMonitor/settings",
        GET_DATA_CENTER_MONITOR_SERVERS: "/rcpx/dataCenterSetting",
    },
    RC_PRICE_COMPARE: {
        // price compare servers
        GET_SERVERS: "/rcpx/priceAlarmSetting/servers",
        GET_SYMBOLDETAILS: "/rcpx/Price/getSymbolDetail", // getSymbolDetail?serverName=UK
        GET_COMPARE_OUR_SERVERS: "/rcpx/Price/getData2", // getData2?serverName=VT
        GET_COMPARE_COMPETITOR: "/rcpx/Price/getOtherData2", // getOtherData2?serverName=VT&competitorServerName=XMTrading-STP
    },
    RC_PRICE_ALARM: {
        GET_SERVER_STOP: "/rcpx/priceAlarmSummary/getServerStop",
        GET_SUMMARY: "/rcpx/priceAlarmSummary/getSummary",
        GET_RA: "/rcpx/priceAlarmSummary/getAppWallRA",
        GET_REPORT: "/rcpx/priceAlarmSummary/reportBatch",
    },
    RC_PRICE_OUTAGE: {
        GET_PRIORITY: "/rcpx/priceAlarmOutage/levelConfigs/SYMBOL_PRIORITY/NO_PRICE/max",
        GET_PRICE_OUTAGE: "/rcpx/priceAlarmOutage/alarm/latest/NO_PRICE", //  /1 = production /30 = others
        GET_PRICE_OUTAGE_HISTORY: "/rcpx/priceAlarmOutage/alarm/history/NO_PRICE", // priceAlarmOutage/alarm/history/NO_PRICE/full?startDate={startDate}&endDate={endDate}  --- date format pattern: yyyy-MM-dd HH:mm:ss
    },
    RC_MIDBIAS: {
        GET_MIDBIAS_PERIOD: "/rcpx/midBiasMonitor/getMidBiasDataWithinPeriod", // {period}/{count}/{range} eg. 20/1/0, 20/1/1440
        GET_MIDBIAS_SOLVED: "/rcpx/midBiasMonitor/getPastData", // /{dateRange}/1 eg. 1440/1
        GET_MIDBIAS_CHART: "/rcpx/midBiasMonitor/getLine/New",
        EVALUATE_MIDBIAS_LINE_DATA: "/rcpx/midBiasMonitor/evaluate",
        GET_MIDBIAS_SETTINGS: "/rcpx/midBiasMonitor/getSetting",
        POST_MIDBIAS_SETTINGS: "/rcpx/midBiasMonitor/updateSetting",
        GET_DOWNLOAD_REPORT: "/rcpx/midBiasMonitor/download",
        POST_DOWNLOAD_TIMETHRESHOLD: "/rcpx/midBiasMonitor/downloadTimeThreshold",
        POST_UPLOAD_TIMETHRESHOLD: "/rcpx/midBiasMonitor/uploadTimeThreshold",
        POST_DOWNLOAD_BLACKLIST: "/rcpx/midBiasMonitor/downloadBlackList",
        POST_UPLOAD_BLACKLIST: "/rcpx/midBiasMonitor/uploadBlackList",
        POST_SOLVED: "/rcpx/midBiasMonitor/solved",
        POST_GROUPSOLVED: "/rcpx/midBiasMonitor/groupSolved",
        POST_DATAGROUPSOLVED: "/rcpx/midBiasMonitor/dateGroupSolved",
        POST_WHATSAPP: "/rcpx/midBiasMonitor/sendToWhatsApp",
        GET_COMPARE_SERVERS: "/rcpx/midBiasMonitor/getCompareServerList",
        GET_MIDBIAS_HISTORICAL_DATA: "/rcpx/midBiasMonitor/getEvaluateData", // /getEvaluateData/{startDate}/{endDate}/{serverUno} eg. 2020-01-01/2020-01-02/1
        POST_MIDBIAS_CHART: "/rcpx/midBiasMonitor/getLine",
    },
    RC_PRICE_SETTINGS: {
        GET_PRICE_ALARM_SETTINGS_SERVERS: "/rcpx/priceAlarmSetting/servers",
        GET_PRICE_ALARM_SETTINGS_SYMBOL_TYPES: "/rcpx/priceAlarmSetting/symbol/types",
        GET_PRICE_ALARM_SETTINGS_DETAILS: "/rcpx/priceAlarmSetting/symbol/details",
        DOWNLOAD_PRICE_ALARM_SETTINGS: "/rcpx/priceAlarmSetting/symbol/details/download",
        UPLOAD_PRICE_ALARM_SETTINGS: "/rcpx/priceAlarmSetting/symbol/details/upload",

        GET_APP_RUN_LIST: "/rcpx/appRun/list",
        GET_APP_STATUS: "/rcpx/appRun/config", // /config/${encodeURIComponent(appGroup)}
        POST_APP_RESTART: "/rcpx/appRun/execute", // /execute/${encodeURIComponent(appName)/RESTART}
        GET_APP_LOGS: "/rcpx/appRun/logs", // /logs/${encodeURIComponent(appName)}?params=${encodeURIComponent(date)}

        GET_PRICE_BACKEND_OPT: "/rcpx/PriceMonitor/getData",
        POST_PRICE_BACKEND_OPT_DETAILS: "/rcpx/PriceMonitor/getCurrentSetting",
        POST_PRICE_BACKEND_OPT_SINGLE_RESTART: "/rcpx/PriceMonitor/runTime",
        POST_PRICE_BACKEND_OPT_RESTART: "/rcpx/PriceMonitor/batchRunTime",
        POST_PRICE_BACKEND_OPT_DETAILS_TICK_RECEIVER: "/rcpx/tickReport/tool/setting",
        GET_SPREAD_ALARM_SETTINGS: "/rcpx/spreadAlarmSetting/getSpreadAlarmSetting",
        DOWNLOAD_SPREAD_ALARM_SETTINGS: "/rcpx/spreadAlarmSetting/downLoadSpreadAlarmSetting",
        UPLOAD_SPREAD_ALARM_SETTINGS: "/rcpx/spreadAlarmSetting/uploadSpreadAlarmSetting",
        GET_PRICE_ALARM_COLOR_LIST: "/rcpx/priceAlarmColorSetting",
        GET_MID_PRICE_SETTING_LIST: "/rcpx/midPriceSetting/otherCompared", // /otherCompared?serverUno=${serverUno}
        POST_SAVE_UPDATE_ADD_MID_PRICE_SETTINGS: "/rcpx/midPriceSetting/otherCompared",
        DELETE_MID_PRICE_SETTINGS: "/rcpx/midPriceSetting/otherCompared",
        GET_MID_PRICE_SETTINGS_DOWNLOAD: "/rcpx/midPriceSetting/otherCompared/download", // /otherCompared/download?serverUno=${serverUno}
        POST_MID_PRICE_SETTINGS_UPLOAD: "/rcpx/midPriceSetting/otherCompared/upload",
        GET_PRICE_ANALYSIS_GROUP_SETTING_LIST: "/rcpx/priceAnalysis/settings/groups",
        GET_PRICE_ANALYSIS_GROUP_SERVERS: "/rcpx/priceAlarmSetting/servers",
        UPDATE_PRICE_ANALYSIS_GROUP_SETTING: "/rcpx/priceAnalysis/settings/groups",
        UPDATE_PRICE_ANALYSIS_SERVER_SETTING: "/rcpx/priceAnalysis/settings/servers",
        UPDATE_PRICE_ANALYSIS_SYMBOL_SETTING: "/rcpx/priceAnalysis/settings/symbols",
        GET_PRICE_ANALYSIS_SYMBOL_SETTING: "/rcpx/priceAnalysis/settings/symbolinfo",
        GET_PRICE_ANALYSIS_SYMBOL_SETTING_COMPARING: "/rcpx/priceAnalysis/settings/comparing",
    },
    RC_PRICE_MONITOR: {
        GET_SPREAD_ALARM_LIST: "/rcpx/spreadMonitor/getSpreadDataWithinPeriod",
        GET_RESOLVED_SPREAD_ALARM_LIST: "/rcpx/spreadMonitor/getPastData",
        RESOLVE_SPREAD_ALARM_ITEM: "/rcpx/spreadMonitor/groupSolved",
        RESOLVE_SPREAD_ALARM_BY_ID: "/rcpx/spreadMonitor/solved",
        SHOW_SPREAD_ALARM_LINE: "/rcpx/spreadMonitor/getLine/New",
        GET_REPORT_ROW_COUNT: "/rcpx/spreadMonitor/getDataRecordCount",
        GET_REPORT_DOWNLOAD: "/rcpx/spreadMonitor/download",
    },
    RC_LP_PRICE_COMPARE: {
        GET_SERVERS: "/rcpx/LpPriceCompared/getSelectServerList",
        GET_SYMBOLS_BY_SERVER: "/rcpx/LpPriceCompared/getSymbolList",
        GET_COMPARE_DATA: "/rcpx/LpPriceCompared/getCompareData",
        GET_REPORT_DOWNLOAD: "/rcpx/LpPriceCompared/downloadCompareData",
        GET_BRIDGE_MAIN_SERVER_SETTING: "rcpx/LpPriceCompared/getbridgeMainServerSetting",
        GET_SERVER_LIST_BY_SYMBOL: "rcpx/LpPriceCompared/getServerListBySymbol",
        GET_COMPARE_SET: "rcpx/LpPriceCompared/getCompareSet",
        GET_T10_TIME: "rcpx/LpPriceCompared/getT10Time",
        POST_SAVE_COMPARE_SET: "rcpx/LpPriceCompared/saveCompareSet",
        DELETE_COMPARE_SET: "rcpx/LpPriceCompared/deleteCompareSet",
    },
    RISK_TOOL: {
        GET_SWAP_CHARGING_TOOL_SYMBOL_SWAP: "/rcpx/swapChargingTool/getMap",
        GET_SWAP_CHARGING_TOOL_DOWNLOAD: "/rcpx/swapChargingTool/downloadMap",
        GET_SWAP_CHARGING_TOOL_UPLOAD: "/rcpx/swapChargingTool/uploadMap",
        GET_SWAP_CHARGING_TOOL_LOGIN: "/rcpx/swapChargingTool/getLogin",
        GET_SWAP_CHARGING_TOOL_LOGIN_DOWNLOAD: "/rcpx/swapChargingTool/downloadLogin",
        GET_SWAP_CHARGING_TOOL_LOGIN_UPLOAD: "/rcpx/swapChargingTool/uploadMap",
        GET_SWAP_CHARGING_TOOL_PROCESS: "/rcpx/swapChargingTool/getProcessData",
        GET_SWAP_CHARGING_TOOL_PROCESS_DOWNLOAD: "/rcpx/swapChargingTool/reportBatch",
        GET_SWAP_CHARGING_TOOL_PROCESS_DETAILS: "/rcpx/swapChargingTool/getTradesData",
        GET_SYMBOL_SWAP_UPLOAD_DATA: "/rcpx/symbolSwap",
        GET_SYMBOL_SWAP_UPLOAD_DOWNLOAD: "/rcpx/symbolSwap/reportBatch",
        GET_SYMBOL_SWAP_UPLOAD_UPLOAD: "/rcpx/symbolSwap/upload",
        GET_SYMBOL_SWAP_UPLOAD_SERVER_LIST: "/rcpx/symbolSwap/serverList",
        GET_SYMBOL_SWAP_UPLOAD_EXECUTE_UPDATE: "/rcpx/symbolSwap/executeUpdate",
        GET_HEDGE_TOOL_DATA: "/rcpx/hedge/getHedgePivotList",
        GET_HEDGE_TOOL_GET_REPORTDATE: "/rcpx/hedge/getReportDate",
        GET_HEDGE_TOOL_DOWNLOAD: "/rcpx/hedge/reportBatch",
        GET_HEDGE_TOOL_SHOW_SENSITIVE_DATA: "/rcpx/hedge/logSensitiveData",
        GET_GROUP_TRANSFER_TOOL_CONFIG: "/rcpx/groupTransferTool/getTransferReasonFrontDesks",
        GET_GROUP_TRANSFER_TOOL_PREVIEW: "/rcpx/groupTransferTool/getGroupTransferPreview",
        GET_GROUP_TRANSFER_TOOL_EXECUTE_TRANSFER: "/rcpx/groupTransferTool/groupTransfer",
        GET_GROUP_TRANSFER_TOOL_HISTORICAL_GETTODAYDEALDATE: "/rcpx/groupTransferTool/getTodayDealDate",
        GET_GROUP_TRANSFER_TOOL_HISTORICAL_GTTR: "/rcpx/groupTransferTool/getGroupTransferRecordsByDate",
        GET_GROUP_TRANSFER_TOOL_HISTORICAL_EXPORT_EXCEL: "/rcpx/groupTransferTool/downloadGroupTransferRecords",
        GET_GROUP_TRANSFER_TOOL_HISTORICAL_EDIT_RECORD: "/rcpx/groupTransferTool/updateRecord",
        GET_GROUP_TRANSFER_TOOL_HISTORICAL_STATISTIC_EQUITY_LIST: "/rcpx/groupTransferTool/getGroupTransferEquitiesByDate",
        GET_GROUP_TRANSFER_TOOL_HISTORICAL_STATISTIC_EQUITY_DOWNLOAD: "/rcpx/groupTransferTool/downloadGroupTransferEquities",
        GET_GROUP_TRANSFER_TOOL_HISTORICAL_STATISTIC_TRADE_LIST: "/rcpx/groupTransferTool/getGroupTransferTradesByDate",
        GET_GROUP_TRANSFER_TOOL_HISTORICAL_STATISTIC_TRADE_DOWNLOAD: "/rcpx/groupTransferTool/downloadGroupTransferTrades",
        GET_GROUP_TRANSFER_TOOL_HISTORICAL_MARKET_RISK_LIST: "/rcpx/groupTransferTool/getGroupByGroupTransferTradesByDate",
        GET_GROUP_TRANSFER_TOOL_HISTORICAL_MARKET_RISK_DOWNLOAD: "/rcpx/groupTransferTool/downloadGroupTransferTradesBySymbol",
        EDIT_GROUP_TRANSFER_TOOL_SETTING: "/rcpx/groupTransferTool/updateTransferReasonFrontDesk",
        DELETE_GROUP_TRANSFER_TOOL_SETTING: "/rcpx/groupTransferTool/removeTransferReasonFrontDesk",
        GET_ORDER_ROUTING_RECORD_BRIDGE_LIST: "/rcpx/orderRoutingRecord/getBridgeList",
        GET_ORDER_ROUTING_RECORD: "/rcpx/orderRoutingRecord",
        GET_ORDER_ROUTING_RECORD_HISTORY: "/rcpx/orderRoutingRecord/history",
        GET_ORDER_ROUTING_RECORD_DOWNLOAD: "/rcpx/orderRoutingRecord/download",
        // GET_CONFIG_DIFF_RECORD_LIST: "/rcpx/configDiffRecords/{0}/diff",
        GET_CONFIG_DIFF_RECORD_LIST_DOWNLOAD: "/rcpx/configDiffRecords/diff2/download",  // /diff2/download?configType=${encodeURIComponent(configType)}&dbName=${encodeURIComponent(dbName)}&tbName=${encodeURIComponent(tbName)}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}&server=${encodeURIComponent(server)}
        GET_CONFIG_DIFF_RECORD_LIST_ADD_NOTES: "/rcpx/configDiffRecords/{0}/diff/notes",
        GET_CONFIG_DIFF_RECORD_DIFF_COL_NAMES: "/rcpx/configDiffRecords/diff2/tables",
        GET_LATEST_CONFIG_DIFF_RECORD_TOTAL: "/rcpx/configDiffRecords/diff2/counts",  // /diff2/counts?dbName=${encodeURIComponent(dbName)}&tbName=${encodeURIComponent(tbName)}&startDate=${encodeURIComponent(startTime.momentDateFormat)}&endDate=${encodeURIComponent(endTime.momentDateFormat)}&server=${encodeURIComponent(server)}
        GET_CONFIG_DIFF_RECORD_DATA_LIST: "/rcpx/configDiffRecords/diff2",  // /diff2/?dbName=${encodeURIComponent(dbName)}&tbName=${encodeURIComponent(tbName)}&startDate=${encodeURIComponent(startTime.momentDateFormat)}&endDate=${encodeURIComponent(endTime.momentDateFormat)}&server=${encodeURIComponent(server)}&limit=${limit}&offset=${offset}
        POST_LATEST_CONFIG_DIFF_RECORD_NOTES: "/rcpx/configDiffRecords/diff2/notes",  // /diff2/notes?configDate=${encodeURIComponent(configDate)}&dbName=${encodeURIComponent(dbName)}&tbName=${encodeURIComponent(tbName)}
        GET_CA_DIVIDEND_MAP_UPLOAD_LIST: "/rcpx/caDividend/getCaDividendMap",
        CA_DIVIDEND_MAP_UPLOAD_UPLOAD_FILE: "/rcpx/caDividend/insertCaDividendMapByUpload",
        GET_CA_DIVIDEND_SEPARATE_PROCESS_SERVERS: "/rcpx/caDividend/getServerDetailList",
        GET_CA_DIVIDEND_SEPARATE_PROCESS_DATA_LIST: "/rcpx/caDividend/getCaDividendSeparateProcessData",
        GET_CA_DIVIDEND_SEPARATE_PROCESS_GROUP_DATA_LIST: "/rcpx/caDividend/getCaDividendSeparateProcessGroupData",
        GET_CA_DIVIDEND_SEPARATE_PROCESS_DOWNLOAD_REPORT: "/rcpx/caDividend/reportBatch",
        GET_WEEKEND_SWAPS_RECORDS_SERVERS: "/rcpx/pnlWeekendSwap/getServerDetailList",
        GET_WEEKEND_SWAPS_RECORDS_LIST: "/rcpx/pnlWeekendSwap/getPnlWeekendSwapProcessData",
        GET_WEEKEND_SWAPS_RECORDS_TRADE_LIST: "/rcpx/pnlWeekendSwap/getPnlWeekendSwapTradesData",
        GET_WEEKEND_SWAPS_RECORDS_DOWNLOAD_REPORT: "/rcpx/pnlWeekendSwap/reportBatch",
        GET_SYMBOL_LEVERAGE_BATCH_RECORD_LIST: "/rcpx/symbolLeverage/batchRecordList",
        GET_SYMBOL_LEVERAGE_UPDATE_DATA: "/rcpx/symbolLeverage/getUpdateData",
        GET_SYMBOL_LEVERAGE_MARGIN_LEVEL: "/rcpx/symbolLeverage/getMarginLevel",
        DELETE_SYMBOL_LEVERAGE_BATCH_RECORD: "/rcpx/symbolLeverage/deleteBatchRecord",
        GET_SYMBOL_LEVERAGE_BATCH_RECORD: "/rcpx/symbolLeverage/addBatchRecord",
        GET_SYMBOL_LEVERAGE_REFETCH_ORIGIN_SYMBOL_LEVERAGE: "/rcpx/symbolLeverage/refetchOriginSymbolLeverage",
        GET_SYMBOL_LEVERAGE_EXECUTE_SYMBOL_LEVERAGE_UPDATE: "/rcpx/symbolLeverage/executeSymbolLeverageUpdate",
        GET_SYMBOL_LEVERAGE_REFETCH_MARGIN_LEVEL: "/rcpx/symbolLeverage/refetchMarginLevel",
        GET_SYMBOL_LEVERAGE_CONFIRM_UPDATE_DATA: "/rcpx/symbolLeverage/confirmUpdateData",
        GET_SYMBOL_LEVERAGE_CONFIRM_BEFORE_MARGIN_LEVEL: "/rcpx/symbolLeverage/confirmBeforeMarginLevel",
        GET_SYMBOL_LEVERAGE_CONFIRM_UPDATE_CONFIG: "/rcpx/symbolLeverage/confirmUpdateConfig",
        GET_SYMBOL_LEVERAGE_CONFIRM_SYMBOL_LEVERAGE_UPDATE: "/rcpx/symbolLeverage/confirmSymbolLeverageUpdate",
        GET_SYMBOL_LEVERAGE_CONFIRM_AFTER_MARGIN_LEVEL: "/rcpx/symbolLeverage/confirmAfterMarginLevel",
        GET_SYMBOL_LEVERAGE_ROLLBACK_SYMBOL_LEVERAGE: "/rcpx/symbolLeverage/rollbackSymbolLeverage",
        GET_SYMBOL_LEVERAGE_DOWNLOAD_MARGIN_LEVEL: "/rcpx/symbolLeverage/downloadMarginLevel",
        POST_SYMBOL_LEVERAGE_ADD_UPDATE_DATA: "/rcpx/symbolLeverage/addUpdateData",
        GET_ROLLOVER_ADD_BATCH_RECORD: "/rcpx/rollOver/addRollOverBatchRecord",
        POST_ROLLOVER_BATCH_RECORD_LIST: "/rcpx/rollOver/getRollOverBatchRecordList",
        GET_ROLLOVER_DELETE_BATCH_RECORD: "/rcpx/rollOver/deleteRollOverBatchRecord",
        GET_ROLLOVER_EMAILTO: "/rcpx/rollOver/getRollOverEmailTo",
        GET_ROLLOVER_SEND_EMAIL: "/rcpx/rollOver/sendRollOverMail",
        GET_ROLLOVER_SERVER_LIST: "/rcpx/rollOver/getRollOverServerList",
        GET_ROLLOVER_BATCH_RECORD: "/rcpx/rollOver/getRollOverBatchRecord",
        GET_ROLLOVER_ADJUSTMENT_LIST: "/rcpx/rollOver/getAdjustmentList",
        GET_ROLLOVER_ADJUSTMENT_VALUE_EMAIL_TO: "/rcpx/rollOver/getRollOverAdjustmentValueEmailTo",
        GET_ROLLOVER_SEND_ADJUSTMENT_VALUE_EMAIL: "/rcpx/rollOver/sendAdjustmentValueMail",
        GET_ROLLOVER_CALC_OPEN_POSITION: "/rcpx/rollOver/calcRollOverOpenPosition",
        GET_ROLLOVER_OPEN_POSITION_LIST: "/rcpx/rollOver/getOpenPositionList",
        GET_ROLLOVER_EXECUTE_SUPPLEMENTARY_DEDUCTION_STATUS: "/rcpx/rollOver/getExecuteSupplementaryDeductionStatus",
        GET_ROLLOVER_SUPPLEMENTARY_LIST: "/rcpx/rollOver/getSupplementaryList",
        GET_ROLLOVER_EXECUTE_SUPPLEMENTARY: "/rcpx/rollOver/executeSupplementary",
        GET_ROLLOVER_REFRESH_SUPPLEMENTARY_STATUS: "/rcpx/rollOver/refreshSupplementaryStatus",
        GET_ROLLOVER_FINISH_SUPPLEMENTARY_STEP: "/rcpx/rollOver/finishSupplementaryStep",
        GET_ROLLOVER_DEDUCTION_LIST: "/rcpx/rollOver/getDeductionList",
        GET_ROLLOVER_EXECUTE_DEDUCTION: "/rcpx/rollOver/executeDeduction",
        GET_ROLLOVER_REFRESH_DEDUCTION_STATUS: "/rcpx/rollOver/refreshDeductionStatus",
        GET_ROLLOVER_FINISH_DEDUCTION_STATUS: "/rcpx/rollOver/finishDeductionStep",
        GET_ROLLOVER_DOWNLOAD_OPEN_POSITION: "/rcpx/rollOver/downloadOpenPosition",
        GET_ROLLOVER_DOWNLOAD_SUPPLEMENTARY_DEDUCTION: "/rcpx/rollOver/downloadSupplementaryDeductionCsv",
        GET_ROLLOVER_DOWNLOAD_VERIFICATION_AND_STOP_OUT: "/rcpx/rollOver/downloadVerificationAndStopOut",
        GET_ROLLOVER_ADD_ADJUSTMENT_BY_UPLOAD: "/rcpx/rollOver/addAdjustmentByUpload",
        GET_ROLLOVER_VERIFICATION_LIST: "/rcpx/rollOver/getVerificationList",
        GET_ROLLOVER_STOP_OUT_LIST: "/rcpx/rollOver/getStopOutList",
        GET_BALANCE_UPDATE_HISTORY_SERVERS: "/rcpx/balanceUpload/getServerList",
        GET_BALANCE_UPDATE_HISTORY_EVENT_LIST: "/rcpx/balanceUpload/getDtList",
        GET_BALANCE_UPDATE_HISTORY_LIST: "/rcpx/balanceUpload/getBalanceUploadRecord",
        GET_BALANCE_UPDATE_DOWNLOAD: "/rcpx/balanceUpload/download",
        GET_BALANCE_UPDATE_UPLOAD_File: "/rcpx/balanceUpload/upload",
        GET_BALANCE_UPDATE_UPLOAD_SERVERS: "/rcpx/balanceUpload/getServerList",
        GET_BALANCE_UPDATE_UPLOAD_APPLY_INDIVIDUALLY: "/rcpx/balanceUpload/apply",
        GET_BALANCE_UPDATE_UPLOAD_CANCEL_INDIVIDUALLY: "/rcpx/balanceUpload/cancel",
        GET_BALANCE_UPDATE_UPLOAD_PREVIEW: "/rcpx/balanceUpload/preview",
        GET_HORI_DIFF_RECORD_GET_BRANDs: "/rcpx/ConfigHoriDiff/getBrandList",
        GET_HORI_DIFF_RECORD_GET_TABLES: "/rcpx/ConfigHoriDiff/getTableList",
        GET_HORI_DIFF_RECORD_GET_LIST: "/rcpx/ConfigHoriDiff/getCompareStatus",
        GET_HORI_DIFF_RECORD_GET_LIST_RESULT: "/rcpx/ConfigHoriDiff/getCompareResult",
        GET_HORI_DIFF_RECORD_DOWNLOAD: "/rcpx/ConfigHoriDiff/downloadHoriDiffData",
        GET_FUNDING_FEE_SETTINGS: "/rcpx/fundingFeeSettings",
        CREATE_FUNDING_FEE_SETTINGS: "/rcpx/fundingFeeSettings",
        UPDATE_FUNDING_FEE_SETTINGS: "/rcpx/fundingFeeSettings",
        GET_FUNDING_FEE_PROCESS_SERVERS: "/rcpx/fundingFeeProcess/getFilterServerDataList",
        GET_FUNDING_FEE_PROCESS: "/rcpx/fundingFeeProcess/getFundingFeeProcessData",
        GET_FUNDING_FEE_PROCESS_TRADES: "/rcpx/fundingFeeProcess/getFundingFeeTradesData",
        GET_FUNDING_FEE_PROCESS_DOWNLOAD: "/rcpx/fundingFeeProcess/reportBatch",
    },
    RC_READ_ONLY: {
        GET_READ_ONLY: "/rcpx/readOnly/getReadOnly",
        GET_READ_ONLY_SERVER: "/rcpx/readOnly/getServer",
        GET_READ_ONLY_GROUP_CHANGE_REASON: "/rcpx/readOnly/getGroupChangeReason",
        GET_READ_ONLY_GROUP_ADD_REASON: "/rcpx/readOnly/addReason",
        POST_CANCEL_READ_ONLY: "/rcpx/readOnly/cancelReadOnly",
        POST_DOWNLOAD_READ_ONLY: "/rcpx/readOnly/executeDownload",
        CREATE_NEW_READ_ONLY: "/rcpx/readOnly/setReadOnly",
    },
    RC_LINKED_ACCOUNT_RECORD: {
        GET_MS_DATA: "/rcpx/linkedAccountRecord/getLinkedAccountMSData",
        GET_LOG_SENSITIVE_DATA: "/rcpx/linkedAccountRecord/logSensitiveData",
        GET_GROUP_SETTINGS: "/rcpx/linkedAccountRecord/getLinkedAccountGroupSettings",
        GET_EQUITY_MONITOR: "/rcpx/linkedAccountRecord/getEquityMonitorData",
        GET_SERVERS_LIST: "/rcpx/linkedAccountRecord/getAllMainServerList",
        POST_DOWNLOAD_REPORT: "/rcpx/linkedAccountRecord/reportBatch",
        POST_DOWNLOAD_EQUITY_MONITOR_REPORT: "/rcpx/linkedAccountRecord/EquityMonitorReportBatch",
        POST_UPDATE_GROUP_SETTINGS: "/rcpx/linkedAccountRecord/updateLinkedAccountGroupSettings",
        POST_UPDATE_EQUITY_MONITOR_COMMENT: "/rcpx/linkedAccountRecord/updateEquityMonitorComment",
        DELETE_GROUP_SETTINGS: "/rcpx/linkedAccountRecord/deleteLinkedAccountGroupSettings",
    },
    RC_LINKED_ACCOUNT_QUERY: {
        GET_SERVERS: "/rcpx/sameAccountServer/getServerList",
        POST_LINKED_ACC_QUERY_LIST: "/rcpx/sameAccountServer/getData",
        GET_SENSITIVE_DATA: "/rcpx/sameAccountServer/logSensitiveData", // /logSensitiveData?auditKey={auditKey}
    },
    RC_COMMISSION_RECORDS: {
        GET_SERVERS: "/rcpx/commissionPerDealHK/serverDetail",
        POST_DETAILED_COMMISSION_DATA: "/rcpx/commissionPerDealHK/getDetailData",
        GET_DOWNLOAD_FAILED_REPORT_CSV: "/rcpx/commissionPerDealHK/downloadFailReportCsv", // /downloadFailReportCsv?serverId=${serverId}&tickets=${tickets}&logins=${logins}
        DELETE_BATCH_DETAILED_COMMISSION_DATA: "/rcpx/commissionPerDealHK/deleteDetail", // /deleteDetail?id=${ids}
        GET_REMOVED_DETAILED_COMMISSION_DATA: "/rcpx/commissionPerDealHK/getRemovedDetailData",
        GET_DOWNLOAD_DELETED_REPORT_CSV: "/rcpx/commissionPerDealHK/downloadRemovedReportCsv", // /downloadRemovedReportCsv?lastTime=${YYYY-MM-DD}
    },
    RC_OUT_OF_PRICE_ALARM: {
        GET_ALARMS: "/rcpx/dealOop/getOopList", // add param at behind, /0 for unsolved, /1 for solved
        GET_SETTINGS: "/rcpx/dealOop/getSetting", // for whitelisted data
        POST_SOLVED: "/rcpx/dealOop/solved",
        POST_UPLOAD_SETTINGS: "/rcpx/dealOop/upload", // upload .xlsx as form data
        GET_DOWNLOAD_REPORT: "/rcpx/dealOop/downloadAllSetting",
    },
    RC_PRICE_ANALYSIS: {
        GET_PA_SETTINGS_GROUPS: "/rcpx/priceAnalysis/settings/groups",
        POST_PA_ALARM_LATEST_LIST: "/rcpx/priceAnalysis/alarm/latest",
        GET_PA_SETTINGS_SERVERS: "/rcpx/priceAnalysis/settings/servers",
        GET_PA_GROUP_ENABLED: "/rcpx/priceAnalysis/settings/groups/enabled",
        GET_PA_SETTINGS_SYMBOLS: "/rcpx/priceAnalysis/settings/symbols",
        POST_PA_ALARM_KLINE: "/rcpx/midBiasMonitor/priceAnalysis/kLine",
        POST_PA_ALARM_COMPARING: "/rcpx/priceAnalysis/alarm/comparing",
        GET_PA_ALARM_HISTORY: "/rcpx/priceAnalysis/alarm/history",
        POST_PA_ALARM_SOLVE: "/rcpx/priceAnalysis/alarm/solve",
        POST_PA_ALARM_SOLVE_ALL: "/rcpx/priceAnalysis/alarm/solve/all",
        POST_PA_ALARM_SOLVE_HISTORY: "/rcpx/priceAnalysis/alarm/solve/history",
    },
    RC_HFT_REPORT: {
        GET_HFT_RESULT: "/rcpx/hftScalper/getHftResult",
        GET_HFT_THRESHOLD: "/rcpx/hftScalper/getHftThreshold",
        POST_UPDATE_COMMENT: "/rcpx/hftScalper/updateComment",
        POST_DOWNLOAD_HFT_REPORT: "/rcpx/hftScalper/reportBatch",
        POST_UPDATE_SETTINGS: "/rcpx/hftScalper/updateHftThreshold",
    },
    RC_NEGATIVE_EQUITY_RECORD: {
        GET_SERVERS: "/rcpx/negativeEquity/getServerDetailList",
        GET_NE_PROCESS: "/rcpx/negativeEquity/getNegativeEquityProcess", // /getNegativeEquityProcess?serverId=${serverId}
        GET_SENSITIVE_DATA: "/rcpx/negativeEquity/logSensitiveData", // /logSensitiveData?auditKey={auditKey}
        POST_SET_CHECK_COUNT_TO_ZERO: "/rcpx/negativeEquity/setCheckCountZero", // /setCheckCountZero?serverId=${serverId}&login=${login}
        GET_NE_PROCESS_REPORT_DOWNLOAD: "/rcpx/negativeEquity/processReport", // /processReport?serverId=${serverId}
        GET_NE_CLOSED_TRADE_DATA: "/rcpx/negativeEquity/getNegativeEquityClosedTrade", // /getNegativeEquityClosedTrade?serverId=${serverId}&closeTime=${closeTime}
        GET_NE_CLOSED_TRADE_REPORT_DOWNLOAD: "/rcpx/negativeEquity/closedTradesReport", // /closedTradesReport?serverId=${serverId}&closeTime=${closeTime}
    },
    RC_CONFIG_DIFF: {
        GET_CD_SERVER_HISTORY_BY_SERVER: "/rcpx/configDiff/serverHistory", // /serverHistory/${serverId}
        GET_CD_DOWNLOAD: "/rcpx/configDiff/downloadConfigDiff", // /downloadConfigDiff?serverId=${serverId}&baseDate=${baseDate}&compareDate=${compareDate}
        POST_GENERATE_CONFIG: "/rcpx/configDiff/generateConfig",
    },
    RC_TICK_REPORT: {
        GET_SERVERS: "/rcpx/tickReport/servers",
        POST_DOWNLOAD: "/rcpx/tickReport/download/daily/symbols",
    },
    RC_MT_CLIENT_SECURITY: {
        GET_ACCOUNTS: "/rcpx/mtClientSecurity/accounts",
        DELETE_ACCOUNT: "/rcpx/mtClientSecurity/accounts/delete",
        POST_UPLOAD_ACCOUNTS: "/rcpx/mtClientSecurity/accounts/upload",
        POST_UPLOAD_COMMON: "/rcpx/mtClientSecurity/accounts/resign/common",
        POST_UPLOAD_RESIGN: "/rcpx/mtClientSecurity/accounts/resign",
        DOWNLOAD_SECURITY_CHANGE_LIST: "/rcpx/mtClientSecurity/accounts/download/1",
        DOWNLOAD_RESIGN_COMMON_ACCOUNT_LIST: "/rcpx/mtClientSecurity/accounts/download/2",
        DOWNLOAD_RESIGN_PERSONAL_ACCOUNT_LIST: "/rcpx/mtClientSecurity/accounts/download/3",
        GET_CHANGE_HISTORY_COUNT: "/rcpx/mtClientSecurity/history/count",
        GET_CHANGE_HISTORY: "/rcpx/mtClientSecurity/history",
        DOWNLOAD_CHANGE_HISTORY: "/rcpx/mtClientSecurity/history/download",
    },
    RC_DIVIDEND: {
        GET_SERVERS: "/rcpx/dividend/getServerDetailList",
        POST_DIVIDEND: "/rcpx/dividend/getDividendSeparateProcessData",
        POST_DOWNLOAD: "/rcpx/dividend/reportBatch",
        GET_DIVIDEND_UPLOADS: "/rcpx/dividend/getDividendMap", // dividend/getDividendMap?uploadDate=20240816&cleanSymbol=${symbol}
        GET_DIVIDEND_EXCLUDED_SYMBOLS: "/rcpx/dividend/getDividendExcludeSymbol",
        POST_DIVIDEND_UPLOAD_MAP: "/rcpx/dividend/insertDividendMapByUpload",
        POST_DIVIDEND_UPLOAD_EXCLUDED_SYMBOLS: "/rcpx/dividend/insertDividendExcludeSymbol",
    },
    RC_SYNDICATE_TRADING: {
        GET_SET_READ_ONLY: "/rcpx/trading/setOnlyRead", // /setOnlyRead?batchId=${batchId}
        GET_DOWNLOAD_TIME: "/rcpx/trading/getDownloadTime",
        POST_READ_ONLY_DATA: "/rcpx/trading/getOnlyRead",
        GET_READ_ONLY_DOWNLOAD: "/rcpx/trading/downloadReadOnlyOpt", // /downloadReadOnlyOpt?startTime=${startTime}&endTime=${endTime}
        GET_CANCEL_READ_ONLY: "/rcpx/trading/cancelOnlyRead", // /cancelOnlyRead?batchId=${batchId}
        POST_TOGETHER_ALL_DATA: "/rcpx/trading/togetherAllReport",
        POST_GET_VOICE_URL: "/rcpx/trading/getVoiceUrl",
        POST_SYNDICATE_TRADING_DATA: "/rcpx/trading/togetherAlarmReport",
        GET_SYNDICATE_TRADING_REPORT_DOWNLOAD: "/rcpx/trading/downloadReport", // /downloadReport?startTime=${startTime}&endTime=${endTime}
        DOWNLOAD_REPORT: "/rcpx/trading/reportBatch",
    },
    RC_CREDIT_TO_BALANCE: {
        GET_SERVERS: "/rcpx/creditToBalance/getServerList",
        GET_CREDIT_TO_BALANCE_DATA: "/rcpx/creditToBalance/loadPageData", // /loadPageData?serverIndexStr=${serverId}
        POST_CREATE_NEW_CTB_RECORD: "/rcpx/creditToBalance/addRecord",
        POST_UPDATE_CTB_RECORD: "/rcpx/creditToBalance/updateOne",
    },
    RC_AVERAGE_SPREAD: {
        GET_SPREAD_GROUP_KIND: "/rcpx/spread/getSpreadGroupKind",
        DOWNLOAD_AVERAGE_SPREAD_OLD: "/rcpx/spread/downloadGroupAvgSpread",
        DOWNLOAD_AVERAGE_SPREAD_NEW: "/rcpx/spreadReport/partialAvg/reportBatch",
    },
    RC_CID: {
        GET_SERVERS: "/rcpx/CId/getServerList",
        POST_CID_SEARCH_DATA: "/rcpx/CId/getCIDSearchData",
        POST_CID_SEARCH_DOWNLOAD: "/rcpx/CId/downLoadCIDSearchData",
        POST_LOGIN_SEARCH_DATA: "/rcpx/CId/getLoginSearchData",
        POST_LOGIN_SEARCH_DOWNLOAD: "/rcpx/CId/downLoadLoginSearchData",
        GET_DAILY_REPORT_DATA: "/rcpx/CId/getDailyReport", // /getDailyReport?reportDate=${today's date}
        GET_MAX_DAILY_REPORT_DATE: "/rcpx/CId/getMaxReportDate",
        GET_DAILY_REPORT_DOWNLOAD: "/rcpx/CId/downloadDailyReport", // /downloadDailyReport?reportDate=${today's date}
        GET_LABEL_SETTINGS: "/rcpx/CId/getLabelSetting",
        POST_CREATE_LABEL_SETTING: "/rcpx/CId/addLabelSetting",
        POST_UPDATE_LABEL_SETTING: "/rcpx/CId/updateLabelSetting",
        GET_LABEL_SETTINGS_DOWNLOAD: "/rcpx/CId/downloadLableSetting",
        DELETE_BATCH_LABEL_SETTING: "/rcpx/CId/deleteLabelSetting", // /deleteLabelSetting?id=${ids}
        POST_UPLOAD_LABEL_SETTING: "/rcpx/CId/uploadLabelSetting",
        GET_WHITELIST_SETTING: "/rcpx/CId/getWhiteListSetting",
        POST_CREATE_WHITELIST_SETTING: "/rcpx/CId/addWhiteListSetting",
        POST_UPDATE_WHITELIST_SETTING: "/rcpx/CId/updateWhiteListSetting",
        GET_WHITELIST_SETTING_DOWNLOAD: "/rcpx/CId/downloadWhiteListSetting",
        DELETE_BATCH_WHITELIST_SETTING: "/rcpx/CId/deleteWhiteListSetting", // /deleteWhiteListSetting?id=${ids}
        POST_UPLOAD_WHITELIST_SETTING: "/rcpx/CId/uploadLabelSetting",
        GET_CID_HISTORY: "/rcpx/CId/getHistoryData",
        POST_CREATE_CID_HISTORY: "/rcpx/CId/addHistoryData",
        POST_UPDATE_CID_HISTORY: "/rcpx/CId/updateHistoryData",
        POST_CID_HISTORY_DOWNLOAD: "/rcpx/CId/downloadHistoryData",
        DELETE_BATCH_CID_HISTORY: "/rcpx/CId/deleteHistory", // /deleteHistory?id=${ids}
        POST_UPLOAD_CID_HISTORY: "/rcpx/CId/uploadHistoryData",
        POST_ADD_CID_HISTORY_UPDATE: "/rcpx/CId/updateReportAdded",
    },
    RC_COMMISSION_SETTING: {
        GET_CONFIG: "/rcpx/commissionPerDealHK",
        GET_LIST: "/rcpx/commissionPerDealHK/getSettingData",
        UPDATE_RECORD: "/rcpx/commissionPerDealHK/setting",
        CHECK_DUPLICATE: "/rcpx/commissionPerDealHK/findRepeat",
        UPLOAD_FILE: "/rcpx/commissionPerDealHK/importSettings",
        DOWNLOAD_FILE: "/rcpx/commissionPerDealHK/exportSettings",
    },
    RC_BRIDGE_CONFIG_API: {
        GET_BRIDGE_CONFIG: "/rcpx/bridgeSetting",
        GET_BRIDGE_API_LIST: "/rcpx/bridgeConfigApiMapping",
    },
    RC_SERVER_FEATURE_ACCOUNT: {
        GET_LIST: "/rcpx/serverFeatureAccount",
    },
    RC_FEATURE_SETTING: {
        GET_FEATURE_SETTINGS_DATA: "/rcpx/featureSetting",
        POST_CREATE_FEATURE_SETTING: "/rcpx/featureSetting",
        PUT_UPDATE_FEATURE_SETTING: "/rcpx/featureSetting",
        DELETE_FEATURE_SETTING: "/rcpx/featureSetting", // /featureSetting?id=${ids}
    },
    RC_STATUS_CONFIG_RESTART: {
        GET_CONFIG_DIFF: "/rcpx/mainServerSetting/configdiff",
        GET_SYMBOL_CONFIG_STATUS_DATA: "/rcpx/onceAppWall/getSymbolConfigData",
        POST_RELOAD_SERVERS: "/rcpx/onceAppWall/reloadServer",
    },
    RC_SPREAD_GOOGLE_SHEET: {
        SYNC_SPREAD_URL: "/rcpx/spreadCSV",
        GET_SPREAD_TICK_RECEIVER_LIST: "/rcpx/PriceMonitor/getData?type=3",
        PROCESS_SPREAD_DATA_SYNC: "/rcpx/PriceMonitor/batchRunTime",
        SHOW_LOG: "/rcpx/tickReport/tool/setting",
    },
    RC_PE_ORDER_COMPARE: {
        GET_SERVERS: "/rcpx/sameAccountServer/getServerList",
        GET_PEOC_DOWNLOAD: "/rcpx/peCompareAlarm/download", // /download?startdate=${startDate}&enddate=${endDate}&symbol=${symbol}&serverUno=${serverUno}
        GET_PEOC_HISTORICAL_DATA: "/rcpx/peCompareAlarm/getPastData",
        POST_PEOC_COMMENT: "/rcpx/peCompareAlarm/comment",
        POST_PEOC_CHECK: "/rcpx/peCompareAlarm/check",
        POST_PEOC_SOLVED: "/rcpx/peCompareAlarm/solved",
    },
    RC_CLIENT_ANALYSIS: {
        GET_SERVERS: "/rcpx/clientAnalysis/getServerList",
        POST_QUERY_CLIENT_EXIST: "/rcpx/clientAnalysis/getClientExist",
        POST_GET_USER_CURRENCY: "/rcpx/clientAnalysis/getUserCurrency",
        POST_PNL_TREND_DATA: "/rcpx/clientAnalysis/getPnlTrend",
        POST_MARGIN_LEVEL_DATA: "/rcpx/clientAnalysis/getMarginLevel",
        POST_SYMBOL_VOLUME_DATA: "/rcpx/clientAnalysis/getSymbolVolume",
        POST_TRADE_TIME_DATA: "/rcpx/clientAnalysis/getTradeTime",
        POST_TRADING_TYPES_BY_USER: "/rcpx/clientAnalysis/getTradingTypesByUser",
        POST_EACH_SYMBOLS_PNL_BY_LOGIN: "/rcpx/clientAnalysis/getEachSymbolsPnlByLogin",
        POST_DAILY_EXPOSURES_BY_LOGIN: "/rcpx/clientAnalysis/getDailyExposuresByLogin",
        POST_SYMBOLS_BY_LOGIN: "/rcpx/clientAnalysis/getSymbolDataByLogin",
        POST_SYMBOLS_BY_SERVER: "/rcpx/clientAnalysis/getSymbolInfoByServer",
        POST_CANDLESTICK_MARK_BY_USER: "/rcpx/clientAnalysis/getCandlestickMarkByUser",
        POST_CANDLESTICK_CHART: "/rcpx/clientAnalysis/getCandlestickChart",
        POST_CANDLESTICK_USER_TRADES: "/rcpx/clientAnalysis/getCandlestickUserTrades",
    },
    RC_TRANSACTION_ORDER_CHECKER: {
        GET_SERVERS: "/rcpx/transactionOrderChecker/getAllErrorLogServers",
        GET_LOGS: "/rcpx/transactionOrderChecker/getJournalSearch",
    },
    RC_SERVER_ARCHIVING_TOOL: {
        GET_SERVER: "/rcpx/serverArchiving/servers",
        GET_DATA_LIST: "/rcpx/serverArchiving",
        EXPORT_TO_EXCEL: "/rcpx/serverArchiving/download",
        DOWNLOAD_EXCEL_TEMPLATE: "/rcpx/serverArchiving/template",
        DOWNLOAD_TRADE_REPORT: "/rcpx/serverArchiving/tradeReport",
    },
    RC_ADMINFEE: {
        GET_SERVERS: "/rcpx/adminfee/getServerDetailList",
        POST_AF_PROCESS_DATA: "/rcpx/adminfee/getAdminFeeProcessData",
        GET_AF_TRADES_DATA: "/rcpx/adminfee/getAdminFeeTradesData", // /getAdminFeeTradesData?serverId=${serverId}&receiveDate=${receiveDate}&login=${login}
        POST_AF_DOWNLOAD_REPORT: "/rcpx/adminfee/reportBatch",
        GET_AF_SETTINGS: "/rcpx/adminfee/getAdminFeeSettings",
        GET_ALL_SETTINGS_DOWNLOAD: "/rcpx/adminfee/downloadAllSetting",
        POST_UPLOAD_AF_SETTINGS: "/rcpx/adminfee/upload",
    },
};
