import { useEffect, useMemo, useState } from "react";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import moment from "moment";

const NsExcludeTab = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_ADMIN_FEE_SETTINGS_EDIT);

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);

    const columns = [
        DTColProps.Middle({
            title: "Server",
            dataIndex: "server",
            key: "server",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: "Login",
            dataIndex: "login",
            key: "login",
            sorter: (a: any, b: any) => a.login - b.login,
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
    ];

    const handleSubmit = (file: any) => {
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
                isLt5M = file.size / 1024 / 1024 < 5;

            if (fileExtension[0] !== ".xlsx") {
                ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            } else if (!isLt5M) {
                ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to import Admin Fee Setting?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("uploadFile", file);

                    plainAxiosInstance
                        .post(APIs.RC_ADMINFEE.POST_UPLOAD_AF_SETTINGS, formData)
                        .then(res => {
                            if (res.status === 200 && res.data === 0) {
                                ErrorMessageHandler(`File upload successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                            } else {
                                ErrorMessageHandler(`${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        })
                        .catch((error: any) => {
                            ErrorMessageHandler(`File upload failed: (${error.response.data.message}).`, SUCCESS_FAILED.OTHERS_FAILED);
                        })
                        .finally(() => setRunRefetchDataList(true));
                },
                onCancel() {},
            });
        } catch (error) {
            ErrorMessageHandler(`Error during uploading file. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            export: { text: "Download" },
            ...(enableUpdate && {
                upload: {
                    name: "file",
                    multiple: false,
                    accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    showUploadList: false,
                    onChange: (info: any) => {
                        if (info.file.status === "error") {
                            ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    },
                    customRequest: ({ file, onSuccess }: any) =>
                        setTimeout(() => {
                            onSuccess("ok");
                        }, 0),
                    beforeUpload: (file: any) => handleSubmit(file),
                },
            }),
        };
    }, [enableUpdate]);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                downloadAllSettings();
                break;
            default:
                break;
        }
    };

    const getAdminFeeSettings = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RC_ADMINFEE.GET_AF_SETTINGS)
            .then((res: any) => {
                if (res?.data?.length === 0) setData([]);
                else {
                    let newData = res.data.map((x: any) => ({
                        ...x,
                        newKey: `${x.server}|${x.login}`,
                    }));
                    setData(newData);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("admin fee settings", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                })
            )
            .finally(() => setIsLoading(false));
    };

    const downloadAllSettings = () => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_ADMINFEE.GET_ALL_SETTINGS_DOWNLOAD}`, {
                headers: { Accept: "application/octet-stream, */*" },
                responseType: "blob",
            })
            .then(res => {
                const contentType = res.headers["content-type"];
                const fileName = getFileNameFromResponseHeader(res, `AdminFee_Setting-${moment().format("YYYYMMDD_HHmmss")}.xlsx`);
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName); // or any other extension
                    link.setAttribute("type", "hidden");
                    document.body.appendChild(link);
                    link.click();
                    if (link.parentNode) {
                        link.parentNode.removeChild(link); // Clean up and remove the link
                    } else document.body.removeChild(link);
                    // Clean up
                    window.URL.revokeObjectURL(url);
                } else {
                    ErrorMessageHandler(`Received non-file response. Error: ${res}`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch(err => {
                ErrorMessageHandler(`Download error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
            })
            .finally(() => setIsExporting(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getAdminFeeSettings();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <>
            <div className="ns-exclude-tab">
                <FlexiDataTable
                    bordered
                    rowKeyProperty={"newKey"}
                    title={false}
                    columns={columns}
                    options={options}
                    callback={componentCallback}
                    dataSource={data}
                    loading={isLoading}
                    exporting={isExporting}
                    pagination={{ defaultPageSize: 50 }}
                />
            </div>
        </>
    );
};

export default NsExcludeTab;
