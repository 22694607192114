import { Button, Form, Segmented, Tooltip } from "antd";
import CardBox from "../../../../components/Common/CardBox";
import { useCallback, useEffect, useState } from "react";
import { KeyValuePair } from "../../../../constants/type";
import { DefaultIfEmpty } from "../../../../utils/object";
import DataTableComponent from "./components/dataTableComponent";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { ConfigDiffRecordDataProps, ConfigDiffRecordTableSchemas, ConfigDiffRecTabItems } from "./components/type";
import LoadingComponent from "@/components/Loading";
import { DownloadOutlined } from "@ant-design/icons";
import { defaultIfEmptyOrNull } from "@/utils/string";
import moment from "moment";
import { FormComponent } from "@/components/FormComponent";

export interface ConfigDiffRecordPageProps { }

const ConfigDiffRecordPage = (props: ConfigDiffRecordPageProps) => {
    const [currentTab, setCurrentTab] = useState<string>("");
    const [currentSelected, setCurrentSelected] = useState<string>("");
    const [resetCount, setResetCount] = useState<number>(0);
    const [configDiffRecordData, setConfigDiffRecordData] = useState<ConfigDiffRecordDataProps[]>([]);
    const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);
    const [tabExcelForm] = Form.useForm();
    const [filterParams, setFilterParams] = useState<any>({
        startDate: moment().startOf("day").subtract(1, "day").format("YYYY-MM-DD"),
        endDate: moment().endOf("day").format("YYYY-MM-DD"),
    });
    const [isTabExcelExporting, setIsTabExcelExporting] = useState<boolean>(false);

    const _apiParams = (configType: string) => {
        switch (configType) {
            case "OZ":
                return {
                    serverListApi: "bridgeSetting/1",
                    serverListValueKey: "bridgeId",
                    serverListLabelKey: "bridgeName"
                };
            case "PXM":
                return {
                    serverListApi: "bridgeSetting/2",
                    serverListValueKey: "bridgeId",
                    serverListLabelKey: "bridgeName"
                };
            default:
                return {
                    serverListApi: "mainServerSetting",
                    serverListValueKey: "serverId",
                    serverListLabelKey: "serverName"
                };
        }
    };

    const getConfigDiffRecColNames = () => {
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_CONFIG_DIFF_RECORD_DIFF_COL_NAMES}`)
            .then((res: any) => {
                if (res.data.length > 0) {
                    const _schemas = res.data.reduce((newObj: any, currObj: ConfigDiffRecordTableSchemas) => {
                        if (!newObj[currObj.configType]) {
                            newObj[currObj.configType] = [];
                        }
                        newObj[currObj.configType].push(currObj);
                        return newObj;
                    }, {});

                    let _filtered_schemas = Object.keys(_schemas).map((currKey: string) => ({
                        label: currKey,
                        configType: currKey,
                        configDiffTabItems: _schemas[currKey].map(({ tbName, tbColumns, tbPkColumns, tbIgnoreColumns, ...rest }: { tbName: string, tbColumns: string, tbPkColumns: string, tbIgnoreColumns: string, [key: string]: any }) => {
                            const pkCols = tbPkColumns.split(",");
                            const otherCols = tbColumns.split(",");
                            const ignoreCols = [...tbIgnoreColumns.split(","), "Action"];
                            return ({
                                label: tbName,
                                configDiffType: tbName.toUpperCase(),
                                configDiffProperties: [...pkCols, ...otherCols],
                                colorMarkedColumnIds: otherCols,
                                ignoreColumnIds: ignoreCols,
                                tableTitle: tbName,
                                pkCols,
                                tbName,
                                ...rest
                            });
                        }),
                        ..._apiParams(currKey),
                    }));

                    let f_schemas_obj = _filtered_schemas.reduce((newObj: any, currObj: ConfigDiffRecordDataProps) => {
                        newObj[currObj.configType] = {
                            ...currObj,
                            subOptions: currObj.configDiffTabItems.map((item: ConfigDiffRecTabItems) => ({
                                text: item.label,
                                value: item.label,
                            })),
                        };
                        return newObj;
                    }, {});
                    setConfigDiffRecordData(f_schemas_obj);
                    setCurrentTab(Object.keys(f_schemas_obj)[0]);
                    setCurrentSelected(f_schemas_obj[Object.keys(f_schemas_obj)[0]].subOptions[0].value);
                } else {
                    ErrorMessageHandler("config diff tables data. No data", SUCCESS_FAILED.FAILED_LOAD_DATA);
                };
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("config diff tables data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                })
            )
            .finally(() => setIsFilterLoaded(true));
    };

    const exportCSV = useCallback(() => {
        setIsTabExcelExporting(true);
        try {
            let reqQuery = `?configType=${currentTab}&startDate=${encodeURIComponent(filterParams.startDate)}&endDate=${encodeURIComponent(filterParams.endDate)}`;
            plainAxiosInstance
                .get(`${APIs.RISK_TOOL.GET_CONFIG_DIFF_RECORD_LIST_DOWNLOAD}/${reqQuery}`, { responseType: "blob" })
                .then(response => {
                    const fileName = getFileNameFromResponseHeader(
                        response,
                        `ConfigDiff_${currentTab}_${defaultIfEmptyOrNull(filterParams.startDate, "")}_${defaultIfEmptyOrNull(filterParams.endDate, "")}.xlsx`
                    );
                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(response.data);
                        const link = document.createElement("a");
                        link.setAttribute("href", url);
                        link.setAttribute("download", (`${fileName}`));
                        link.setAttribute("type", "hidden");
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        link.parentNode?.removeChild(link);
                        setTimeout(() => {
                            window.URL.revokeObjectURL(url);
                        }, 100);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) => ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED))
                .finally(() => setIsTabExcelExporting(false));
        } catch (e: any) {
            ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
            setIsTabExcelExporting(false);
        }
    }, [filterParams, currentTab]);

    useEffect(() => {
        getConfigDiffRecColNames();
        return () => { };
    }, []);

    return (
        <div className="config-diff-record-container">
            <CardBox title={"Config Diff Record"}>
                <LoadingComponent tip="Loading..." spinning={!isFilterLoaded}>
                    <div className="main-container">
                        <div className="top-container">
                            <div className="left">
                                <Segmented
                                    value={currentTab}
                                    options={Object.keys(configDiffRecordData)}
                                    onChange={(activeKey: any) => {
                                        setResetCount(prev => prev + 1);
                                        setCurrentTab(activeKey);
                                        setCurrentSelected(
                                            `${DefaultIfEmpty(configDiffRecordData, activeKey, { subOptions: [] }).subOptions.length > 0
                                                ? (configDiffRecordData[activeKey]["subOptions"] as KeyValuePair[])[0].value
                                                : ""
                                            }`
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="download-whole-container">
                            <Form
                                form={tabExcelForm}
                                layout="inline"
                                initialValues={{ cdDateRange: [moment().startOf("day").subtract(1, "day"), moment().endOf("day")] }}
                                onValuesChange={(changedValues: any) => {
                                    if (changedValues.cdDateRange && changedValues.cdDateRange.length === 2) {
                                        setFilterParams({
                                            ...filterParams,
                                            startDate: changedValues.cdDateRange[0].format("YYYY-MM-DD"),
                                            endDate: changedValues.cdDateRange[1].format("YYYY-MM-DD"),
                                        });
                                    };
                                }}
                            >
                                <FormComponent
                                    label={`Download ${currentTab} Config Diff Excel File`}
                                    name="cdDateRange"
                                    extra={{
                                        type: ComponentType.daterange,
                                        value: [],
                                        dateFormat: "YYYY-MM-DD",
                                        inputProps: {
                                            dateOnly: true,
                                            allowClear: false,
                                        },
                                    }}
                                />
                                <Tooltip title={`Download "${currentTab}" Config-Diff Records`}>
                                    <Button type="primary" icon={<DownloadOutlined />} loading={isTabExcelExporting} onClick={() => exportCSV()} />
                                </Tooltip>
                            </Form>
                        </div>
                        <DataTableComponent
                            currentTabKey={currentTab}
                            currInitialSelected={currentSelected}
                            resetFirstLoad={resetCount}
                            configDiffRecordData={configDiffRecordData}
                        />
                    </div>
                </LoadingComponent>
            </CardBox>
        </div>
    );
};

export default ConfigDiffRecordPage;
