import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SettingsProps {
    currentKey: string | undefined;
}

const initialSettings: SettingsProps = {
    currentKey: undefined,
};

export const centralizedSettingsSlice = createSlice({
    name: "centralizedSettings",
    initialState: initialSettings,
    reducers: {
        setCurrentKey: (state: SettingsProps, action: PayloadAction<string | undefined>) => {
            state.currentKey = action.payload;
        },
    },
});

export const { setCurrentKey } = centralizedSettingsSlice.actions;
export default centralizedSettingsSlice.reducer;
