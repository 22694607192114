import React from "react";
import useRCSpreadReportStore from "../../../store/useRCSpreadReportStore";

import { DatePicker, Space, Row, Col, Typography, Button } from "antd";

import moment from "moment";
import axios from "axios";
import { getToken } from "../../../services/localStorage";
import BasicFilter from "../../../components/BasicFilter";
import { APIs } from "../../../services/apis";
import { getFileNameFromResponseHeader } from "@/utils/Common";
const DailySpreadAverageDownloadFilters: React.FC = () => {
    const { RangePicker } = DatePicker;
    const { Text } = Typography;

    const startDate = useRCSpreadReportStore(state => state.dailyAvg.startDate);
    const endDate = useRCSpreadReportStore(state => state.dailyAvg.endDate);
    const setDailyAvg = useRCSpreadReportStore(state => state.setDailyAvg);

    const handleDownloadDailyAverage = () => {
        const token = getToken();
        axios
            .get(`${process.env.REACT_APP_API_URL}${APIs.RC_SPREAD.GET_SPREAD_DAILY_AVG_DOWNLOAD}?startDate=${startDate}&endDate=${endDate}`, {
                headers: {
                    Accept: "application/octet-stream, */*",
                    // "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            })
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                const fileName = getFileNameFromResponseHeader(response, `symbol_daily_report_${startDate}_${endDate}.xlsx`);
                if (contentType === "application/octet-stream") {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                } else {
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                console.log("download error", err);
            });
    };

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={16} xs={{ span: 24 }} lg={{ span: 16 }}>
                    <Text>Date Range *</Text>
                    <RangePicker
                        style={{ width: "100%" }}
                        onChange={(dates, dateStrings) => {
                            // console.log(dateStrings);
                            setDailyAvg({ startDate: dateStrings[0], endDate: dateStrings[1] });
                        }}
                        // disable selecting the day after today, and 20 days before today
                        disabledDate={current => {
                            return current && (current > moment().endOf("day") || current < moment().subtract(20, "days").startOf("day"));
                        }}
                        // disabledDate={(current) => current && current > moment().endOf("day")}

                        // read start and end from store
                        value={[moment(startDate), moment(endDate)]}
                    />
                </Col>
            </Row>
            <Space style={{ marginTop: "1rem", display: "flex", flexWrap: "wrap" }}>
                <Button
                    type="primary"
                    onClick={() => {
                        handleDownloadDailyAverage();
                    }}
                    disabled={!startDate || !endDate}
                    style={{ minWidth: 100 }}
                >
                    Download Report
                </Button>
            </Space>
        </>
    );
};

export default DailySpreadAverageDownloadFilters;
