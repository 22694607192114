import TabContent from "../../SystemMonitor/components/TabContent";
import MidBiasTable from "./MidBiasTable";
import useRCMidBias from "../../../hooks/useRCMidBias";
import { Space, Typography } from "antd";
import soundFile from "../../../assets/audios/rc/midbias.mp3";
import TimeDiff from "../../SystemMonitor/components/TimeDiff";
import { useAudioPlayer } from "@/helpers/audioHelper";
import { useEffect } from "react";

const Tab20min = () => {
    const { playSound } = useAudioPlayer(soundFile);
    const { isLoading, refetchRcMidBias, dataUpdatedAt, shouldPlayMidBiasAlarm, rcMidBiasGeneral, rcMidBiasFuture, isFetching } = useRCMidBias({
        duration: 0,
    });
    const { Title } = Typography;

    useEffect(() => {
        if (!isFetching) {
            if (shouldPlayMidBiasAlarm) {
                playSound();
            };
        };
    }, [isFetching]);

    return (
        <TabContent>
            <Space direction="vertical" style={{ padding: "1rem", width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem", gap: 16 }}>
                    <Space style={{ alignItems: "center" }} wrap>
                        <Title level={2} style={{ padding: 0, margin: 0 }}>
                            Mid Bias in 20 minutes
                        </Title>
                        <TimeDiff timestamp={dataUpdatedAt} refetch={refetchRcMidBias} isFetching={isLoading} rounded />
                    </Space>
                </div>
                {/* <Alert type="info" message="Using dummy data, remember to remove" /> */}
                <MidBiasTable data={rcMidBiasGeneral} refetch={refetchRcMidBias} />
                <MidBiasTable data={rcMidBiasFuture} refetch={refetchRcMidBias} />
            </Space>
        </TabContent>
    );
};

export default Tab20min;
