import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Input, Modal, Segmented, Space, Table, Tag, Tooltip } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import {
    CALLBACK_KEY,
    ComponentType,
    PRICE_BACKEND_OPT_SETTINGS_APP_STATUS_ENUM,
    PRICE_BACKEND_OPT_SETTINGS_APP_STATUS_LABELS,
    SUCCESS_FAILED,
} from "@/constants";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined, MinusCircleOutlined, ReadOutlined, ReloadOutlined } from "@ant-design/icons";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, ProfileProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import FlexiDataTable from "@/components/FlexiDataTable";
import LoadingComponent from "@/components/Loading";
import moment from "moment";
import EmptyData from "@/components/Common/Empty";
import { isEmptyOrNull } from "@/utils/string";
import { getProfile } from "@/services/localStorage";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { ToObjectWithKey } from "@/utils/array";

export interface PriceBackendOptBasedProps {
    pageType: number;
    dataType: number;
    multipleSelection?: boolean;
}

export interface PriceBackendOptSettingsV2Data {
    appName: string;
    appStatus: number;
    isLock: number;
}

export interface AppRunList {
    appGroup: string;
    appName: string;
    isHidden: number;
}

const TabRestartServer = () => {
    const uPr: ProfileProps | undefined = getProfile();
    const [segmentVal, setSegmentVal] = useState<string>("");
    const [segmentOptions, setSegmentOptions] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tabData, setTabData] = useState<PriceBackendOptSettingsV2Data[]>([]);
    const [isManualLoading, setIsManualLoading] = useState<boolean>(false);
    const [currApp, setCurrApp] = useState<string | null>(null);
    const [currAppLogsData, setCurrAppLogsData] = useState<string[]>([]);
    const scrollableLogsDivRef = useRef<any>(null);
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_PRICE_BACKEND_OPT_EDIT);

    const columns = useMemo(
        () => [
            {
                title: "App Name",
                dataIndex: "appName",
                key: "appName",
                sorter: (a: any, b: any) => a.appName.localeCompare(b.appName),
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            },
            {
                title: "App Reload Status",
                dataIndex: "appStatus",
                key: "appStatus",
                sorter: (a: any, b: any) => a.appStatus - b.appStatus,
                render: (appStatus: number) => (
                    <Tag
                        color={
                            appStatus === PRICE_BACKEND_OPT_SETTINGS_APP_STATUS_ENUM.RELOAD_SUCCESS
                                ? "green"
                                : appStatus === PRICE_BACKEND_OPT_SETTINGS_APP_STATUS_ENUM.RELOAD_FAILED
                                ? "red"
                                : appStatus === PRICE_BACKEND_OPT_SETTINGS_APP_STATUS_ENUM.INITIALIZING
                                ? "orange"
                                : "default"
                        }
                        icon={
                            appStatus === PRICE_BACKEND_OPT_SETTINGS_APP_STATUS_ENUM.RELOAD_SUCCESS ? (
                                <CheckCircleOutlined />
                            ) : appStatus === PRICE_BACKEND_OPT_SETTINGS_APP_STATUS_ENUM.RELOAD_FAILED ? (
                                <CloseCircleOutlined />
                            ) : appStatus === PRICE_BACKEND_OPT_SETTINGS_APP_STATUS_ENUM.INITIALIZING ? (
                                <SyncOutlined spin />
                            ) : (
                                <MinusCircleOutlined />
                            )
                        }
                    >
                        {PRICE_BACKEND_OPT_SETTINGS_APP_STATUS_LABELS[appStatus]}
                    </Tag>
                ),
            },
            DTColProps.Small(
                {
                    title: "",
                    dataIndex: "actions",
                    key: "actions",
                    render: (_: any, rowData: PriceBackendOptSettingsV2Data) => (
                        <Space size={"middle"}>
                            {enableUpdate && (
                                <Tooltip title="Reload app" placement="top" key={`reload-${rowData.appName}`}>
                                    <Button
                                        type="text"
                                        icon={<ReloadOutlined />}
                                        disabled={isLoading || isManualLoading || rowData.isLock === 1}
                                        onClick={() =>
                                            componentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "reload_app", data: rowData })
                                        }
                                    />
                                </Tooltip>
                            )}
                            <Tooltip title="Query logs" placement="top" key={`view-logs-${rowData.appName}`}>
                                <Button
                                    type="text"
                                    icon={<ReadOutlined />}
                                    disabled={isLoading || isManualLoading || rowData.isLock === 1}
                                    onClick={() =>
                                        componentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "view_query_log", data: rowData })
                                    }
                                />
                            </Tooltip>
                        </Space>
                    ),
                },
                ["text-center"]
            ),
        ],
        [isLoading, isManualLoading, enableUpdate]
    );

    const options: FlexiDataTableOptionsProps = {
        hideRowSelectionsSummary: true,
        ...(enableUpdate
            ? {
                  enableRowSelection: true,
                  rowSelectionData: {
                      rowSelectionType: "checkbox",
                      selectedRowKeys: selectedRowKeys,
                      options: {
                          fixed: "left",
                          selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
                          preserveSelectedRowKeys: true,
                      },
                  },
                  extraButtons: () => {
                      return (
                          <div className="extra-header-buttons" key={"pbeos-extra-buttons"}>
                              <Button
                                  key={"pbeos-restart"}
                                  icon={<ReloadOutlined />}
                                  onClick={() => componentCallback(CALLBACK_KEY.OTHERS, "reload-selected-apps")}
                                  disabled={selectedRowKeys.length === 0 || isManualLoading || isLoading}
                              >
                                  Reload Selected Apps
                              </Button>
                          </div>
                      );
                  },
              }
            : {}),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "reload_app") {
                    restartApp(FormData.data.appName);
                }
                if (FormData.key === "view_query_log") {
                    setCurrApp(FormData.data.appName);
                    queryAppLogs(FormData.data.appName);
                }
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowKeys(FormData.selectedRowKeys);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "reload-selected-apps") {
                    selectedRowKeys.forEach((appName: string) => restartApp(appName));
                }
                break;
            default:
                break;
        }
    };

    const getAppList = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_PRICE_SETTINGS.GET_APP_RUN_LIST}`)
            .then((res: any) => {
                if (res.data.length > 0) {
                    let tmpObj = ToObjectWithKey(res.data, "appGroup", "isHidden");
                    if (uPr !== undefined && uPr.isAdministrator) {
                        setSegmentOptions(Object.keys(tmpObj));
                        setSegmentVal(Object.keys(tmpObj)[0]);
                        getAppListStatuses(Object.keys(tmpObj)[0]);
                    } else {
                        let tmpStrArr = Object.keys(tmpObj).filter((x: string) => tmpObj[x] === 0);
                        setSegmentOptions(tmpStrArr);
                        setSegmentVal(tmpStrArr[0]);
                        getAppListStatuses(tmpStrArr[0]);
                    }
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("app list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setSegmentOptions([]);
                    setSegmentVal("");
                })
            )
            .finally(() => setIsLoading(false));
    }, [uPr]);

    const getAppListStatuses = (currSegment: string) => {
        setIsManualLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_PRICE_SETTINGS.GET_APP_STATUS}/${encodeURIComponent(currSegment)}`)
            .then((res: any) => {
                if (res.data.length > 0) {
                    setTabData(res.data);
                } else setTabData([]);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("app list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setTabData([]);
                })
            )
            .finally(() => setIsManualLoading(false));
    };

    const restartApp = (currApp: string) => {
        setIsManualLoading(true);
        plainAxiosInstance
            .post(`${APIs.RC_PRICE_SETTINGS.POST_APP_RESTART}/${encodeURIComponent(currApp)}/RESTART`)
            .then((res: any) => {
                if (res.status === 200) {
                    ErrorMessageHandler(
                        `${currApp} successfully queued to restart. Please check app logs when it is ready.`,
                        SUCCESS_FAILED.OTHERS_SUCCESS
                    );
                } else {
                    ErrorMessageHandler(`${currApp} failed to queue to restart`, SUCCESS_FAILED.OTHERS_FAILED, res);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler(`${currApp} failed to queue to restart`, SUCCESS_FAILED.OTHERS_FAILED, err);
                })
            )
            .finally(() => {
                setIsManualLoading(false);
                setSelectedRowKeys([]);
            });
    };

    const queryAppLogs = (currApp: string) => {
        setIsModalLoading(true);
        plainAxiosInstance
            .get(
                `${APIs.RC_PRICE_SETTINGS.GET_APP_LOGS}/${encodeURIComponent(currApp)}?params=${encodeURIComponent(
                    moment(new Date()).format("YYYYMMDD")
                )}`
            )
            .then((res: any) => {
                if (res.status === 200) {
                    setCurrAppLogsData(res.data);
                } else {
                    setCurrAppLogsData([]);
                    Modal.warning({
                        width: "30%",
                        centered: true,
                        title: `Query ${currApp} logs failed`,
                        content: `Error: ${res.message}`,
                        onOk: () => setCurrApp(null),
                    });
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    setCurrAppLogsData([]);
                    Modal.warning({
                        width: "30%",
                        centered: true,
                        title: `Query ${currApp} logs failed`,
                        content: `Error: ${err.message}`,
                        onOk: () => setCurrApp(null),
                    });
                })
            )
            .finally(() => setIsModalLoading(false));
    };

    useEffect(() => {
        getAppList();

        return () => {};
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (!isEmptyOrNull(segmentVal)) {
                getAppListStatuses(segmentVal);
            }
        }, 10000);
        return () => {
            clearInterval(timer);
            setIsLoading(false);
            setIsManualLoading(false);
        };
    }, [segmentVal]);

    const filteredLogsData = useMemo(() => {
        if (searchValue.length > 0 && currAppLogsData.length > 0) {
            return currAppLogsData.filter(line => line.toLowerCase().includes(searchValue.toLowerCase()));
        } else return currAppLogsData;
    }, [currAppLogsData, searchValue]);

    return (
        <div className="price-backend-opt-setting-v2-container">
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Price Backend Opt</span>
                </div>
            </div>
            <div className="info-panel">
                <div className="info-div">
                    <ol>
                        <li>
                            Reload <span>Price Calculate Tool</span> after uploading any new setting (price alarm setting, mid price setting... etc).
                        </li>
                        <li>
                            Reload <span>Price Calculate Tool</span> when error message alart from Price Receiver in Status Monitor (RA).
                        </li>
                        <li>
                            Reload <span>Price Broad Caster</span> when error message alert from Price Broad Caster in Status Monitor (RA).
                        </li>
                    </ol>
                </div>
                <div className="refresh-note">
                    <Button icon={<ReloadOutlined />} type="primary" disabled={isLoading} onClick={() => getAppList()}>
                        Refresh App List
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent />
                </div>
            ) : (
                <>
                    <div className="top-panel">
                        <Segmented
                            options={segmentOptions}
                            value={segmentVal}
                            onChange={(value: SegmentedValue) => {
                                setSegmentVal(value as string);
                                getAppListStatuses(value as string);
                            }}
                        />
                    </div>
                    <div className="container">
                        <FlexiDataTable
                            bordered
                            rowKeyProperty="appName"
                            title=""
                            columns={columns}
                            options={options}
                            dataSource={tabData ?? []}
                            callback={componentCallback}
                            loading={isManualLoading}
                        />
                        <Modal
                            width={"70vw"}
                            centered
                            destroyOnClose
                            title={`App Logs (${currApp})`}
                            open={currApp !== null}
                            onOk={() => setCurrApp(null)}
                            onCancel={() => setCurrApp(null)}
                            footer={false}
                            wrapClassName="app-logs-modal"
                        >
                            {isModalLoading ? (
                                <div className="loading-container">
                                    <LoadingComponent />
                                </div>
                            ) : (
                                <>
                                    <div className="search-box">
                                        <Input.Search
                                            placeholder="Search"
                                            enterButton
                                            onSearch={(value: string) => setSearchValue(value)}
                                            allowClear
                                            style={{ width: "60%", marginBottom: 10 }}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="nice-scrollbar" style={{ maxHeight: "75vh", overflow: "auto" }} ref={scrollableLogsDivRef}>
                                        {filteredLogsData.length > 0 ? (
                                            <div style={{ fontFamily: "monospace", paddingRight: 10 }}>
                                                {filteredLogsData.map((log: string, idx: number) => (
                                                    <div key={`log-${idx}`}>{log}</div>
                                                ))}
                                            </div>
                                        ) : (
                                            <EmptyData />
                                        )}
                                    </div>
                                </>
                            )}
                        </Modal>
                    </div>
                </>
            )}
        </div>
    );
};

export default TabRestartServer;
