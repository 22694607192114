import { useState } from "react";
import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import NsExcludeTab from "./NsExcludeTab";

const AdminFeeSettings = () => {
    const [segmentVal, setSegmentVal] = useState<string>("NS Exclude Swap Free List");

    return (
        <div className="adminfee-settings-container">
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Admin Fee Settings</span>
                </div>
            </div>
            <div className="top-panel">
                <Segmented
                    options={["NS Exclude Swap Free List"]}
                    value={segmentVal}
                    onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                />
            </div>
            <div className="container">
                {segmentVal === "NS Exclude Swap Free List" && <NsExcludeTab />}
            </div>
        </div>
    );
};

export default AdminFeeSettings;