import { useEffect } from "react";
import useRCStatusMonitor from "../../../hooks/useRCStatusMonitor";
import { massageData } from "./statusUtils";
import BasicFilter from "../../../components/BasicFilter";
import ServerStatus, { ServerStatusProps } from "./ServerStatus";
import ServerStatusFilterWeekend from "./ServerStatusFilterWeekend";
import TabContent from "../components/TabContent";
import ServerStatusVendor from "./ServerStatusVendor";
import useRCStatusMonitorStore from "../../../store/useRCStatusMonitorStore";
import Loader from "../components/Loader";
import MessageCard from "../components/MessageCard";
import fireTruck from "@/assets/audios/rc/fireTruck.mp3";
import marioCoin from "@/assets/audios/rc/marioCoin.mp3";
import { useAudioPlayer } from "@/helpers/audioHelper";

const TabWeekend = () => {
    const { playSound } = useAudioPlayer(fireTruck);
    const { playSound: playSound2 } = useAudioPlayer(marioCoin);
    const { rcStatusMonitor, isLoading, isFetching } = useRCStatusMonitor({
        department: "Weekend",
    });
    const dataWeekend = rcStatusMonitor ? massageData(rcStatusMonitor, "Weekend") : [];

    const vendor = useRCStatusMonitorStore(state => state.vendor.weekend);
    const filterDataWeekend = vendor ? dataWeekend?.filter((item: any) => item.vendor === vendor) : [];

    useEffect(() => {
        if (!isFetching) {
            const eCount = rcStatusMonitor.filter((item: any) => item.status === 2).length;
            const wCount = rcStatusMonitor.filter((item: any) => item.status === 1).length;

            if (eCount > 0) {
                playSound();
            }
            if (wCount > 0) {
                playSound2();
            }
        }
    }, [isFetching]);

    return (
        <TabContent>
            <BasicFilter show={false}>
                <ServerStatusFilterWeekend />
            </BasicFilter>

            {isLoading && rcStatusMonitor.length === 0 ? (
                <Loader />
            ) : (
                <>
                    <ServerStatusVendor data={dataWeekend as ServerStatusProps[]} vendorType="weekend" />
                    {!vendor && (
                        <div style={{ padding: "0 1rem" }}>
                            <MessageCard type="info">Please select a group from above</MessageCard>
                        </div>
                    )}
                    {filterDataWeekend?.map((item: any, index: number) => (
                        <ServerStatus key={index} data={item as ServerStatusProps} />
                    ))}
                </>
            )}
        </TabContent>
    );
};

export default TabWeekend;
