import { Button, Select, Space, Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, genMetricSelectorsByDimensions, roundUpValue } from "../../../../utils/Common";
import { CALLBACK_KEY, DIMENSION_LEVEL, DIMENSION_LEVEL_LABEL, SUCCESS_FAILED } from "../../../../constants";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { KeyValuePair, MimMetricsExtra, MimMetricsList } from "../../../../constants/type";
import MonitoringPagination from "./monitoringPagination";
import { DimemsionFilterType, mapFilterParams } from "..";
import { hasAnyKey } from "../../../../utils/object";
import { AlertOutlined, DownloadOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileParams, getNavigationUrl } from "../../../Analysis/AccountProfileHelper";
import { findLabelBasedOnValue } from "../../../../utils/array";
import { CSVLink } from "react-csv";

export interface TopBottomTableSummaryProps {
    mimMetrics: MimMetricsList[];
    filterOptList: any;
    selectedParams?: DimemsionFilterType[] | boolean;
    isSearch?: boolean;
    resetState: number;
}

interface BasicInfoProps {
    tableCols: any[];
    metricsInfo: MimMetricsExtra[];
    metricsOpt: any[];
}

interface AccountPairProps {
    accountId: number;
    serverId: number;
}

const TopBottomTableSummary = (props: TopBottomTableSummaryProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [refetchData, setRefetchData] = useState<boolean>(false);
    const [dataLimit, setDataLimit] = useState<number>(props.isSearch ? 10 : 10);
    const [topBottom, setTopBottom] = useState<boolean>(true);
    const [selectedMetric, setSelectedMetric] = useState<string>("total_pnl");
    const [selectedDimension, setSelectedDimension] = useState<number>(DIMENSION_LEVEL.ACCOUNT);

    const columns = useMemo(() => {
        switch (selectedDimension) {
            case DIMENSION_LEVEL.ACCOUNT:
                return [
                    DTColProps.XSmall({
                        title: "Server",
                        dataIndex: "server_id",
                        key: "server_id",
                        render: (server_id: string, rowData: any) => {
                            return <div>{findLabelBasedOnValue(props.filterOptList.Server, rowData.server_id)}</div>;
                        },
                    }),
                    DTColProps.XSmall({
                        title: "Account ID",
                        dataIndex: "account_id",
                        key: "account_id",
                        render: (account_id: string, rowData: any) => {
                            return (
                                <div className="mim-account-render-panel">
                                    {/* {props.isSearch ? (
                                        <Link
                                            to={getNavigationUrl(
                                                ACCOUNT_PROFILE_FROM_MODULE.INTRADAY_MONITOR_SEARCH,
                                                getAccountProfileParams(
                                                    rowData["server_id"],
                                                    rowData["account_id"],
                                                    ACCOUNT_PROFILE_FROM_MODULE.INTRADAY_MONITOR_SEARCH,
                                                    "",
                                                    "",
                                                    "",
                                                    "",
                                                    ""
                                                )
                                            )}
                                            target="_blank"
                                            style={{ color: "#0e65cc" }}
                                        >
                                            {rowData.account_id}
                                        </Link>
                                    ) : (
                                        <Link
                                            to={getNavigationUrl(
                                                ACCOUNT_PROFILE_FROM_MODULE.INTRADAY_MONITOR_SUMMARY,
                                                getAccountProfileParams(
                                                    rowData["server_id"],
                                                    rowData["account_id"],
                                                    ACCOUNT_PROFILE_FROM_MODULE.INTRADAY_MONITOR_SUMMARY,
                                                    "",
                                                    "",
                                                    "",
                                                    "",
                                                    ""
                                                )
                                            )}
                                            target="_blank"
                                            style={{ color: "#0e65cc" }}
                                        >
                                            {rowData.account_id}
                                        </Link>
                                    )} */}
                                    {rowData.account_id}
                                    {rowData.hasOwnProperty("isToxic") && rowData.isToxic && (
                                        <Tooltip title="This account has been documented in the Toxic Client Checklist.">
                                            <AlertOutlined />
                                        </Tooltip>
                                    )}
                                </div>
                            );
                        },
                    }),
                ];
            case DIMENSION_LEVEL.SYMBOL:
                return [
                    DTColProps.XSmall({
                        title: "Symbol",
                        dataIndex: "symbol",
                        key: "symbol",
                        fixed: "left",
                    }),
                ];
            case DIMENSION_LEVEL.SERVER:
                return [
                    DTColProps.XSmall({
                        title: "Server",
                        dataIndex: "server_id",
                        key: "server_id",
                        fixed: "left",
                        render: (server_id: string, rowData: any) => {
                            return <div>{findLabelBasedOnValue(props.filterOptList.Server, rowData.server_id)}</div>;
                        },
                    }),
                ];
            case DIMENSION_LEVEL.BRAND:
                return [
                    DTColProps.XSmall({
                        title: "Brand",
                        dataIndex: "brand_id",
                        key: "brand_id",
                        fixed: "left",
                        render: (brand_id: string, rowData: any) => {
                            return <div>{findLabelBasedOnValue(props.filterOptList.Brand, rowData.brand_id)}</div>;
                        },
                    }),
                ];
            case DIMENSION_LEVEL.COUNTRY:
                return [
                    DTColProps.XSmall({
                        title: "Country",
                        dataIndex: "country",
                        key: "country",
                        fixed: "left",
                        render: (country: string, rowData: any) => {
                            return <div>{findLabelBasedOnValue(props.filterOptList.Country, rowData.country)}</div>;
                        },
                    }),
                ];
            case DIMENSION_LEVEL.IB:
                return [
                    DTColProps.XSmall({
                        title: "Server",
                        dataIndex: "rebate_server_id",
                        key: "rebate_server_id",
                        render: (rebate_server_id: string, rowData: any) => {
                            return <div>{findLabelBasedOnValue(props.filterOptList.Server, rowData.rebate_server_id)}</div>;
                        },
                    }),
                    DTColProps.XSmall({
                        title: "Account ID",
                        dataIndex: "rebate_account_id",
                        key: "rebate_account_id",
                        // render: (rebate_account_id: string, rowData: any) => {
                        //     return (
                        //         <div className="user-column">
                        //             <Link
                        //                 to={getNavigationUrl(
                        //                     ACCOUNT_PROFILE_FROM_MODULE.INTRADAY_MONITOR_SEARCH,
                        //                     getAccountProfileParams(
                        //                         rowData["rebate_server_id"],
                        //                         rowData["rebate_account_id"],
                        //                         ACCOUNT_PROFILE_FROM_MODULE.INTRADAY_MONITOR_SEARCH,
                        //                         "",
                        //                         "",
                        //                         "",
                        //                         "",
                        //                         ""
                        //                     )
                        //                 )}
                        //                 target="_blank"
                        //                 style={{ color: "#0e65cc" }}
                        //             >
                        //                 {rowData.rebate_account_id}
                        //             </Link>
                        //         </div>
                        //     );
                        // },
                    }),
                ];
            case DIMENSION_LEVEL.SYMBOLASSETTYPE:
                return [
                    DTColProps.XSmall({
                        title: "Symbol Asset Type",
                        dataIndex: "symbol_asset_type_id",
                        key: "symbol_asset_type_id",
                        fixed: "left",
                        render: (symbol_asset_type_id: string, rowData: any) => {
                            return <div>{findLabelBasedOnValue(props.filterOptList.SymbolAssetType, rowData.symbol_asset_type_id)}</div>;
                        },
                    }),
                ];
            case DIMENSION_LEVEL.ACCGROUP:
                return [
                    DTColProps.XSmall({
                        title: "Account Group",
                        dataIndex: "group",
                        key: "group",
                        fixed: "left",
                    }),
                ];
            default:
                return [];
        }
    }, [
        props.filterOptList.Brand,
        props.filterOptList.Country,
        props.filterOptList.Server,
        props.filterOptList.SymbolAssetType,
        props.isSearch,
        selectedDimension,
    ]);

    const basicInfo: BasicInfoProps = useMemo(() => {
        let thisMetricsSet = props.mimMetrics.length > 0 ? genMetricSelectorsByDimensions(props.mimMetrics, selectedDimension) : [];

        return {
            metricsInfo: thisMetricsSet,
            metricsOpt: thisMetricsSet.map((x: MimMetricsList) => ({ label: x.metricDisplayName, value: x.metricName })),
            tableCols: [
                ...columns,
                ...thisMetricsSet.map((currSelector: any) =>
                    DTColProps.XSmall(
                        {
                            title: currSelector?.metricDisplayName,
                            dataIndex: currSelector?.metricName,
                            key: currSelector?.metricName,
                            sorter: (a: any, b: any) => a[currSelector.metricName] - b[currSelector.metricName],
                            render: (value: number) => (value !== 0 ? roundUpValue(value) : 0),
                        },
                        ["text-center"]
                    )
                ),
            ],
        };
    }, [props.mimMetrics, selectedDimension, columns]);

    const displayTitle = useMemo(
        () =>
            `${topBottom ? "Top" : "Bottom"} ${dataLimit} ${DIMENSION_LEVEL_LABEL[DIMENSION_LEVEL[selectedDimension]]} by ${basicInfo.metricsInfo.find(m => m.metricName === selectedMetric)?.metricDisplayName
            }`,
        [topBottom, dataLimit, selectedDimension, basicInfo, selectedMetric]
    );

    const getData = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_MIM_METRIC_RANKING, {
            dimensionSelectors: [selectedDimension],
            ...(basicInfo.metricsInfo.length > 0 && {
                metricSelectors: basicInfo.metricsInfo.map((x: MimMetricsExtra) => ({
                    metricName: x.metricName,
                    columnOrder: x.metricOrder,
                })),
                metricSorters: [
                    {
                        metricName: selectedMetric,
                        sortOrder: 1,
                        sortDirection: topBottom ? 1 : 0,
                    },
                ],
            }),
            metricPaginator: {
                limit: dataLimit,
                offset: 0,
            },
            ...(hasAnyKey(props.selectedParams) && { dimensionFilters: mapFilterParams(props.selectedParams) }),
        })
            .then((res: any) => {
                let markupData = res.map((x: any) => ({ ...x, id: `${Math.random()}`, isToxic: false }));
                setData(markupData);
                if (selectedDimension === DIMENSION_LEVEL.ACCOUNT) {
                    apiRequest(APIs.GET_TOXIC_CLIENT_CHECK_BY_ACCOUNTS, {
                        checklistGroupingMode: 1,
                        accountServerIdPairs: markupData.map((x: any) => ({ accountId: x.account_id, serverId: x.server_id })),
                    })
                        .then((res: any) => {
                            if (res.length > 0) {
                                let tmpToxicAccountKeys: string[] = res.map((x: any) => `${x.accountId}-${x.serverId}`);
                                setData(prev => prev.map(x => ({ ...x, isToxic: tmpToxicAccountKeys.includes(`${x.account_id}-${x.server_id}`) })));
                            }
                        })
                        .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Error in Toxic Check", err)))
                        .finally(() => setIsLoading(false));
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("User by PNL", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                setIsLoading(false);
            });
    }, [selectedDimension, basicInfo, selectedMetric, topBottom, dataLimit, props.selectedParams]);

    useEffect(() => {
        if (props.isSearch && props.selectedParams && hasAnyKey(props.selectedParams)) {
            setRefetchData(true);
        }
        return () => { };
    }, [props.isSearch, props.selectedParams]);

    useEffect(() => {
        if (refetchData) {
            getData();
            setRefetchData(false);
        }
        return () => { };
    }, [refetchData]);

    useEffect(() => setRefetchData(true), [props.resetState, selectedDimension, selectedMetric, topBottom, dataLimit]);

    return (
        <div className={`users-by-pnl-panel ${props.isSearch ? "search" : ""}`}>
            <div className={props.isSearch ? "title-box" : "title"}>
                <div className="flex-row-between">
                    <div className={props.isSearch ? "title-box-text" : "title-text"}>{displayTitle}</div>
                    <div>
                        <CSVLink
                            key={`btn-export-${Math.random()}`}
                            data={data.map((y: any) => {
                                return {
                                    ...y,
                                    server_id: findLabelBasedOnValue(props.filterOptList.Server, y.server_id),
                                    rebate_server_id: findLabelBasedOnValue(props.filterOptList.Server, y.rebate_server_id),
                                };
                            })}
                            headers={basicInfo.tableCols.map((x: { title: any; key: any }) => {
                                return { label: x.title, key: x.key };
                            })}
                            filename={`${displayTitle}-${new Date().getTime()}.csv`}
                        >
                            <Tooltip title="Export as CSV">
                                <Button type="primary" shape="circle" icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />} />
                            </Tooltip>
                        </CSVLink>
                    </div>
                </div>
                <div className="pgNav">
                    <div className="select-container">
                        <Select
                            onChange={(value: any) => setSelectedDimension(value)}
                            options={Object.keys(DIMENSION_LEVEL_LABEL).map((key: any) => ({
                                label: DIMENSION_LEVEL_LABEL[key],
                                value: DIMENSION_LEVEL[key],
                            }))}
                            value={selectedDimension}
                        />
                        <Select defaultValue={selectedMetric} onChange={(value: any) => setSelectedMetric(value)} options={basicInfo.metricsOpt} />
                    </div>
                    <div className="action-button">
                        <Space>
                            <Button
                                icon={topBottom ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                                onClick={() => setTopBottom(prev => !prev)}
                            />
                            <MonitoringPagination
                                pageNum={dataLimit}
                                onChangePgNum={(type: CALLBACK_KEY, currentPageNum: number) => {
                                    if (type === CALLBACK_KEY.MONITORING_PAGINATION) {
                                        setDataLimit(currentPageNum);
                                    }
                                }}
                            />
                        </Space>
                    </div>
                </div>
            </div>
            <FlexiDataTable
                title={false}
                rowKeyProperty={"id"}
                columns={basicInfo.tableCols}
                dataSource={data ?? []}
                loading={isLoading}
                options={{
                    showHideColumns: false,
                    enableFilter: false,
                }}
                pagination={false}
                scroll={{ y: props.isSearch ? 450 : 370, x: 200 }}
            />
        </div>
    );
};

export default TopBottomTableSummary;
