import { DownloadOutlined } from "@ant-design/icons";
import TabContent from "../../SystemMonitor/components/TabContent";
import { DatePicker, Form, Button, Row, Col, Typography, notification, Space } from "antd";
import moment from "moment";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import { getFileNameFromResponseHeader } from "@/utils/Common";

const TabReport = () => {
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const { Text } = Typography;
    const { Item } = Form;

    const initFormValue = {
        range: null,
    };

    const handleSubmit = async (values: any) => {
        const startDate = moment(values.range[0]).format("YYYY-MM-DD");
        const endDate = moment(values.range[1]).format("YYYY-MM-DD");
        plainAxiosInstance
            .get(`${APIs.RC_MIDBIAS.GET_DOWNLOAD_REPORT}?startdate=${startDate}&enddate=${endDate}`, {
                headers: {
                    Accept: "application/octet-stream, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                const fileName = getFileNameFromResponseHeader(response, `midbias_report_${startDate}_${endDate}.csv`);
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    // const getCurrentDate = moment().format("YYYY-MM-DD_HH-mm-ss");
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    notification.success({
                        message: "Downloaded",
                        description: `Mid Bias report downloaded successfully`,
                    });
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
                console.log("download error", err);
            });
    };

    return (
        <TabContent>
            <div style={{ padding: "1rem" }}>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={initFormValue}
                    /* onValuesChange={handleFormUpdate} */ requiredMark={true}
                    onFinish={handleSubmit}
                >
                    <Text>Date Range *</Text>
                    <Item
                        style={{ margin: 0, padding: 0 }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        required
                        name="range"
                        rules={[{ required: true, message: "Please select date range" }]}
                    >
                        <Row gutter={[8, 8]}>
                            <Col xs={{ span: 24 }} md={{ span: 16 }}>
                                <RangePicker
                                    style={{ width: "100%" }}
                                    // disable selecting the day after today, and 20 days before today
                                    disabledDate={current => {
                                        return current && current > moment().endOf("day");
                                        // return current && (current > moment().endOf("day") || current < moment().subtract(20, "days").startOf("day"));
                                    }}
                                    onChange={data => {
                                        form.setFieldsValue({ range: data });
                                    }}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                <Button icon={<DownloadOutlined />} type="primary" htmlType="submit">
                                    Download
                                </Button>
                            </Col>
                        </Row>
                    </Item>
                </Form>
            </div>
        </TabContent>
    );
};

export default TabReport;
