import { Modal, Tag } from "antd";
import CardBox from "../../../../components/Common/CardBox";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ContainerOutlined, ExclamationCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { APIs } from "../../../../services/apis";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "../../../../utils/string";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import moment from "moment";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "../../../ReportingModule/ReportBatch";
import ExecuteSymbolSwapUpdateModal from "./components/executeSymbolSwapUpdateModal";

export interface SymbolSwapUploadProps {}

interface SymbolSwapUploadItemProps {
    uploadDate: string;
    serverId: string;
    serverName: string;
    symbol: string;
    swapLong: number;
    swapShort: number;
    success: number;
    message: string | null;
    updateTime: string | null;
}

const SymbolSwapUpload = (props: SymbolSwapUploadProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);
    const [isExecutionModalVisible, setIsExecutionModalVisible] = useState<boolean>(false);
    const [data, setData] = useState<SymbolSwapUploadItemProps[]>([]);
    const [filterParam, setFilterParam] = useState<any>({ uploadDate: moment().format("YYYY-MM-DD") });

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_SYMBOLSWAPUPLOAD_EDIT);

    const markupData = useMemo(() => {
        let filterKeys = Object.keys(filterParam).filter(x => x !== "uploadDate");
        if (filterKeys.length > 0) {
            return data.filter((x: SymbolSwapUploadItemProps) =>
                filterKeys.every((key: string) => {
                    switch (key) {
                        case "serverName":
                        case "symbol":
                            return x[key].toLowerCase().includes(filterParam[key].toLowerCase());
                        default:
                            return true;
                    }
                })
            );
        }
        return data;
    }, [data, filterParam]);

    const columns: any[] = [
        DTColProps.Middle({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
        }),
        DTColProps.XSmall({
            title: "Date",
            dataIndex: "uploadDate",
            key: "uploadDate",
            options: {
                filter: {
                    type: ComponentType.date,
                    value: "",
                    dateFormat: "YYYY-MM-DD",
                    itemProps: {
                        rules: [{ required: true, message: "Please select date" }],
                    },
                    inputProps: {
                        disabledDate: false, // following the same logic as RC's
                    },
                },
            },
        }),
        DTColProps.Middle({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
        }),
        DTColProps.SCurrency({
            title: "Long",
            dataIndex: "swapLong",
            key: "swapLong",
        }),
        DTColProps.SCurrency({
            title: "Short",
            dataIndex: "swapShort",
            key: "swapShort",
        }),
        DTColProps.XSmall(
            {
                title: "Status",
                dataIndex: "success",
                key: "success",
                render: (success: number, record: SymbolSwapUploadItemProps) => {
                    if (isEmptyOrNull(record.updateTime)) return <Tag color="orange">Waiting</Tag>;

                    return success === 0 ? <Tag color="red">Failed</Tag> : success === 1 ? <Tag color="green">Success</Tag> : "";
                },
            },
            ["text-center"]
        ),
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
        },
        DTColProps.Small({
            title: "UpdateTime",
            dataIndex: "updateTime",
            key: "updateTime",
        }),
        {
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
            options: {
                visible: false,
                filter: {
                    type: ComponentType.text,
                },
            },
        },
        {
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            options: {
                visible: false,
                filter: {
                    type: ComponentType.text,
                },
            },
        },
    ];

    const handleSubmit = useCallback(
        (fileList: any[]) => {
            try {
                for (let index = 0, count = fileList.length; index < count; index++) {
                    const file = fileList[index];
                    var result = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]);
                    const isLt5M = file.size / 1024 / 1024 < 5;

                    if (result[0] !== ".xlsx") {
                        ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
                        return;
                    } else if (!isLt5M) {
                        ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
                        return;
                    }
                }

                Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    title: "Are you sure you want to import?",
                    width: "30%",
                    onOk() {
                        setIsUploading(true);
                        var formData = new FormData();
                        fileList.forEach((file: any) => {
                            formData.append("uploadFile", file);
                        });
                        formData.append("uploadDate", filterParam.uploadDate);

                        plainAxiosInstance
                            .post(APIs.RISK_TOOL.GET_SYMBOL_SWAP_UPLOAD_UPLOAD, formData)
                            .then(res => {
                                if (res.status === 200 && res.data === "SUCCESS") {
                                    ErrorMessageHandler(`File upload successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                                } else {
                                    ErrorMessageHandler(`${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                                }
                            })
                            .catch((error: any) => {
                                ErrorMessageHandler(`File upload failed:`, SUCCESS_FAILED.OTHERS_FAILED, error);
                            })
                            .finally(() => {
                                setIsUploading(false);
                                getMappingData();
                            });
                    },
                    onCancel() {},
                });
            } catch (error) {
                ErrorMessageHandler(`Error during uploading file. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
            }
        },
        [filterParam]
    );

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        ...(enableUpdate && {
            upload: {
                name: "file",
                multiple: true,
                maxCount: 30,
                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                showUploadList: false,
                onChange: (info: any) => {
                    if (info.file.status === "error") {
                        ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                },
                customRequest: ({ file, onSuccess }: any) =>
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0),
                beforeUpload: (file: any, fileList: any[]) => {
                    return new Promise((resolve, reject) => {
                        if (file.uid === fileList[fileList.length - 1].uid) {
                            handleSubmit(fileList);
                            reject();
                        } else {
                            reject();
                        }
                    });
                },
            },
            export: {
                text: "Download Template",
            },
            extraButtons: [
                { text: "Report Listing", value: "reportlist", icon: <ContainerOutlined /> },
                { text: "Execute Update", icon: <PlayCircleOutlined />, value: "execution" },
            ],
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let tmpFilterParams: any = {};
                Object.keys(FormData)
                    .filter(x => (FormData[x] === undefined ? false : FormData[x].toString().length > 0))
                    .forEach(x => {
                        if (x === "uploadDate") {
                            tmpFilterParams[x] = FormData[x].format("YYYY-MM-DD");
                        } else {
                            tmpFilterParams[x] = FormData[x];
                        }
                    });
                setFilterParam(tmpFilterParams);
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                downloadReport();
                break;
            case CALLBACK_KEY.OTHERS:
                switch (FormData) {
                    case "reportlist":
                        setIsReportModalVisible(true);
                        break;
                    case "execution":
                        setIsExecutionModalVisible(true);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    };

    const downloadReport = useCallback(() => {
        plainAxiosInstance
            .get(
                `${APIs.RISK_TOOL.GET_SYMBOL_SWAP_UPLOAD_DOWNLOAD}?date=${defaultIfEmptyOrNull(
                    filterParam.uploadDate,
                    moment().format("YYYY-MM-DD")
                )}`
            )
            .then((res: any) => {
                if (res.data === 0) {
                    ErrorMessageHandler(`Add to batch successed. Please download from【Report Listing】.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                } else {
                    ErrorMessageHandler(`Add to batch failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) => ErrorMessageHandler(`Add to batch failed: "${error.message}".`, SUCCESS_FAILED.OTHERS_FAILED));
    }, [filterParam]);

    const getMappingData = useCallback(() => {
        plainAxiosInstance
            .get(
                `${APIs.RISK_TOOL.GET_SYMBOL_SWAP_UPLOAD_DATA}?uploadDate=${defaultIfEmptyOrNull(
                    filterParam.uploadDate,
                    moment().format("YYYY-MM-DD")
                )}`
            )
            .then(res => {
                setData(res.data.map((x: SymbolSwapUploadItemProps) => ({ ...x, key: `${x.uploadDate}${x.serverId}${x.symbol}` })));
                setIsLoading(false);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol swap upload", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [filterParam]);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getMappingData();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    return (
        <>
            <div className="symbol-swap-upload-container">
                <CardBox title={"Symbol Swap Upload"}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="Id"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={markupData}
                        callback={componentCallback}
                        loading={isLoading}
                        uploading={isUploading}
                        filterInitialValue={{
                            uploadDate: moment(),
                        }}
                    />
                </CardBox>
            </div>
            <ReportBatchModal
                isModalVisible={isReportModalVisible}
                modalTitle={"Download Report"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                filterCallback={(record: any) => record.batchName === "SymbolSwap"}
            />
            <ExecuteSymbolSwapUpdateModal
                isModalVisible={isExecutionModalVisible}
                modalTitle={"Execute Symbol Swap Update"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsExecutionModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
            />
        </>
    );
};

export default SymbolSwapUpload;
