import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Space, Typography } from "antd";
import { FormComponent } from "@/components/FormComponent";
import { SUCCESS_FAILED, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { HFTThreshold, HFTThresholdRes } from "@/pages/RealTime/HFTReport";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";

const { Title } = Typography;

type SettingsTabProps = {
    hftThreshold: any;
    refreshData: () => void;
};

const dataRows: {
    section: string;
    data: { label: string; name: keyof HFTThreshold }[];
}[] = [
    {
        section: "Scream Threshold(Red/Orange)",
        data: [
            { label: "Less than 2 min", name: "profit2min" },
            { label: "Between 2 and 10 min", name: "profit10min" },
            { label: "Between 10 and 30 min", name: "profit30min" },
        ],
    },
    {
        section: "Profit Threshold Setting(Red)",
        data: [
            { label: "Level 1", name: "threshold1" },
            { label: "Level 2", name: "threshold2" },
            { label: "Level 3", name: "threshold3" },
        ],
    },
    {
        section: "2 Min New Profit Threshold Setting(Orange)",
        data: [
            { label: "Level 1", name: "newprofit2minLevel1" },
            { label: "Level 1 HISTORICAL PNL / TODAY PROFIT", name: "newprofitpnl2min" },
            { label: "Level 2", name: "newprofit2minLevel2" },
        ],
    },
    {
        section: "10 Min New Profit Threshold Setting(Orange)",
        data: [
            { label: "Level 1", name: "newprofit10minLevel1" },
            { label: "Level 1 HISTORICAL PNL / TODAY PROFIT", name: "newprofitpnl10min" },
            { label: "Level 2", name: "newprofit10minLevel2" },
        ],
    },
    {
        section: "30 Min New Profit Threshold Setting(Orange)",
        data: [
            { label: "Level 1", name: "newprofit30minLevel1" },
            { label: "Level 1 HISTORICAL PNL / TODAY PROFIT", name: "newprofitpnl30min" },
            { label: "Level 2", name: "newprofit30minLevel2" },
        ],
    },
];
const SettingsTab = ({ hftThreshold, refreshData }: SettingsTabProps) => {
    const [settingsForm] = Form.useForm<HFTThreshold>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isTouched, setIsTouched] = useState<boolean>(false);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_HFT_REPORT_SETTINGS_EDIT);

    useEffect(() => {
        if (isTouched) return;
        settingsForm.setFieldsValue(hftThreshold);
    }, [hftThreshold]);

    const onFormSubmit = useCallback(
        (values: HFTThreshold) => {
            setIsTouched(false);
            setIsSubmitting(true);
            plainAxiosInstance
                .post(
                    `${process.env.REACT_APP_API_URL}${APIs.RC_HFT_REPORT.POST_UPDATE_SETTINGS}`,
                    prepareSettingsForm({
                        ...values,
                        ...Object.keys(hftThreshold)
                            .filter((x: string) => x.indexOf("unknown-") === 0)
                            .reduce((obj: any, key: string) => {
                                obj[key] = hftThreshold[key];
                                return obj;
                            }, {}),
                    })
                )
                .then(res => {
                    if (res.data > 0) {
                        ErrorMessageHandler("HFT Threshold Settings", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        refreshData();
                    } else {
                        ErrorMessageHandler("HFT Threshold Settings", SUCCESS_FAILED.FAILED_UPDATE_DATA);
                    }
                })
                .catch(err => {
                    ErrorCatchValidator(err, (error: any) => ErrorMessageHandler("HFT Threshold Settings", SUCCESS_FAILED.FAILED_UPDATE_DATA, error));
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        },
        [hftThreshold]
    );

    return (
        <Card>
            <Form
                form={settingsForm}
                layout="vertical"
                initialValues={hftThreshold}
                onFinish={onFormSubmit}
                onFieldsChange={() => {
                    if (!isTouched) setIsTouched(true);
                }}
            >
                <Space direction="vertical" size="large">
                    {dataRows.map(row => (
                        <Row key={row.section}>
                            <Col span={16}>
                                <Title level={3}>{row.section}</Title>
                            </Col>
                            <Row gutter={16}>
                                {row.data.map(data => (
                                    <Col span={8} key={data.name}>
                                        <FormComponent
                                            label={data.label}
                                            name={data.name}
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                disabled: !enableUpdate,
                                            }}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Row>
                    ))}
                    {enableUpdate && (
                        <Row>
                            <Button type="primary" htmlType="submit" loading={isSubmitting} disabled={!isTouched}>
                                Update
                            </Button>
                        </Row>
                    )}
                </Space>
            </Form>
        </Card>
    );
};

export default SettingsTab;

function prepareSettingsForm(t: any): HFTThresholdRes[] {
    return Object.keys(t).reduce<HFTThresholdRes[]>((arr: HFTThresholdRes[], key: string) => {
        switch (key) {
            case "newprofitpnl10min":
            case "newprofitpnl2min":
            case "newprofitpnl30min":
            case "profit10min":
            case "profit2min":
            case "profit30min":
                if (t[`${key}`] !== undefined) {
                    arr.push({ type: key, level: 1, value: t[`${key}`] });
                }
                break;
            case "newprofit10minLevel1":
                if (t.newprofit10minLevel1 !== undefined) {
                    arr.push({ type: "newprofit10min", level: 1, value: t.newprofit10minLevel1 });
                }
                break;
            case "newprofit10minLevel2":
                if (t.newprofit10minLevel2 !== undefined) {
                    arr.push({ type: "newprofit10min", level: 2, value: t.newprofit10minLevel2 });
                }
                break;
            case "newprofit2minLevel1":
                if (t.newprofit2minLevel1 !== undefined) {
                    arr.push({ type: "newprofit2min", level: 1, value: t.newprofit2minLevel1 });
                }
                break;
            case "newprofit2minLevel2":
                if (t.newprofit2minLevel2 !== undefined) {
                    arr.push({ type: "newprofit2min", level: 2, value: t.newprofit2minLevel2 });
                }
                break;
            case "newprofit30minLevel1":
                if (t.newprofit30minLevel1 !== undefined) {
                    arr.push({ type: "newprofit30min", level: 1, value: t.newprofit30minLevel1 });
                }
                break;
            case "newprofit30minLevel2":
                if (t.newprofit30minLevel2 !== undefined) {
                    arr.push({ type: "newprofit30min", level: 2, value: t.newprofit30minLevel2 });
                }
                break;
            case "threshold1":
                if (t.profit10min !== undefined) {
                    arr.push({ type: "profit", level: 1, value: t.threshold1 });
                }
                break;
            case "threshold2":
                if (t.threshold2 !== undefined) {
                    arr.push({ type: "profit", level: 2, value: t.threshold2 });
                }
                break;
            case "threshold3":
                if (t.threshold3 !== undefined) {
                    arr.push({ type: "profit", level: 3, value: t.threshold3 });
                }
                break;
            case "threshold4":
                if (t[`${key}`] !== undefined) {
                    arr.push({ type: "symbol", level: 1, value: t[`${key}`] as number });
                }
                break;
            case "threshold5":
                if (t[`${key}`] !== undefined) {
                    arr.push({ type: "symbol", level: 2, value: t[`${key}`] as number });
                }
                break;
            default:
                if (key.indexOf("unknown-") === 0) {
                    const [type, level] = key.split("-").slice(1);
                    arr.push({ type, level: parseInt(level), value: t[key as keyof HFTThreshold] as number });
                }
                break;
        }
        return arr;
    }, []);
}
