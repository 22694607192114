import { Typography } from "antd";
import { motion } from "framer-motion";

const { Title } = Typography;

interface PriceOverviewToolCardProps {
    title: string;
    children: React.ReactNode;
    index?: number;
}

const PriceOverviewToolCard = ({ title, children, index = 0 }: PriceOverviewToolCardProps) => {
    return (
        <motion.div
            style={{ width: "100%", height: "100%", borderRadius: 16, padding: "1.5rem" }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0, background: "rgba(0,0,0,0.5)", transition: { delay: index * 0.05, type: "spring" } }}
        >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Title level={2} style={{ marginBottom: 0, lineHeight: 1, paddingBottom: 0, color: "white" }}>
                    {title}
                </Title>
                {children}
            </div>
        </motion.div>
    );
};

export default PriceOverviewToolCard;
