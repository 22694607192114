import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
// import { dummyOutage } from "../pages/PriceMonitor/PriceOutage/dummy";
/* sample data 
{
    "serverId": "AU",
    "symbol": "EURDKK+",
    "cleanSymbol": "EURDKK",
    "startDate": "2024-07-19 05:10:43",
    "duration": 2208000,
    "bid": 7.45974,
    "ask": 7.46191,
    "scenarios": 0,
    "level": 2,
    "compId": null,
    "compSymbol": null,
    "midDiff": null,
    "compTime": null,
    "serverOrder": 12,
    "alertStatus": 1
}
*/
export interface IPriceOutage {
    serverId: string;
    symbol: string;
    cleanSymbol: string;
    startDate: string;
    duration: number;
    bid: number;
    ask: number;
    scenarios: number;
    level: number;
    compId: number | null;
    compSymbol: string | null;
    midDiff: number | null;
    compTime: string | null;
    serverOrder: number;
    alertStatus: number;
    serverUno: number;
}

const useRCPriceOutage = ({ type }: { type: "prod" | "others" }) => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceOutage", type],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_OUTAGE.GET_PRICE_OUTAGE}/${type === "prod" ? "1" : "30"}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        enabled: type === "prod" || type === "others",
        staleTime: 10 * 1000,
        refetchInterval: 10 * 1000,
        // refetchIntervalInBackground: true,
    });

    const rcPriceOutage: IPriceOutage[] = data?.data?.msg ? [] : data?.data || [];

    // @ts-ignore
    const rcPriceOutageError = error?.response?.data?.message || data?.data?.msg || error?.message || error || null;

    return {
        rcPriceOutage,
        rcPriceOutageError,
        refetchRcPriceOutage: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};
export const useRCPriceOutagePriority = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceOutagePriority"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_OUTAGE.GET_PRIORITY}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000 * 5, //(60 x 1000ms x 5 = 5 minutes)
    });

    const rcPriceOutagePriority = data?.data?.msg ? null : data?.data || null;
    // @ts-ignore
    const rcPriceOutagePriorityError = data?.data?.msg || error?.message || error || null;

    return {
        rcPriceOutagePriority,
        rcPriceOutagePriorityError,
        refetchRcPriceOutagePriority: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

/* sample data
[
    {
        "serverId": "AU",
        "symbol": "CHINA50",
        "startDate": "2024-07-31 20:18:09",
        "duration": 163569,
        "bid": 11816,
        "ask": 11822,
        "scenarios": 0,
        "level": 0,
        "compId": null,
        "compSymbol": null,
        "midDiff": null,
        "compTime": null
    }
]
    */

export interface IPriceOutageHistory {
    serverId: string;
    symbol: string;
    startDate: string;
    duration: number;
    durationToHHMMSS: string;
    bid: number;
    ask: number;
    scenarios: number;
    level: number;
    compId: number | null;
    compSymbol: string | null;
    midDiff: number | null;
    compTime: string | null;
    cleanSymbol: string;
}

// interface IMassagedPriceOutageHistory {
//     serverId: string;
//     symbol: string;
//     startDate: string;
//     duration: number;
//     // durationToHHMMSS: string;
//     durationToHHMMSS: string;
//     bid: number;
//     ask: number;
//     scenarios: number;
//     level: number;
//     compId: number | null;
//     compSymbol: string | null;
//     midDiff: number | null;
//     compTime: string | null;
// }

export const useRCPriceOutageHistory = ({ startDate, endDate, isProd = true }: { startDate: string; endDate: string; isProd?: boolean }) => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceOutageHistory", isProd ? "prod" : "others"],
        queryFn: () =>
            plainAxiosInstance.get(
                `${APIs.RC_PRICE_OUTAGE.GET_PRICE_OUTAGE_HISTORY}${isProd ? "" : "/30"}?startDate=${startDate}&endDate=${endDate}`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            ),
        staleTime: 10 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 10 * 1000,
    });

    const convertTimestamp = (timestamp: number) => {
        const date = new Date(timestamp);
        return date.toISOString().slice(11, 19);
    };

    const rcPriceOutageHistory: IPriceOutageHistory[] | null = data?.data?.msg
        ? null
        : data?.data?.map((item: IPriceOutageHistory) => {
            // convert duration to HH:MM:SS
            const durationToHHMMSS = convertTimestamp(item.duration);
            return {
                ...item,
                durationToHHMMSS,
            };
        }) || null;

    // const massagedData: IMassagedPriceOutageHistory[] | null = rcPriceOutageHistory
    //     ? rcPriceOutageHistory?.map((item: IPriceOutageHistory) => {
    //           // convert duration to HH:MM:SS
    //           const durationToHHMMSS = convertTimestamp(item.duration);
    //           return {
    //               ...item,
    //               durationToHHMMSS,
    //           };
    //       })
    //     : null;

    // @ts-ignore
    const rcPriceOutageHistoryError = error?.response?.data?.message || data?.data?.msg || data?.data?.message || error?.message || error || null;

    return {
        rcPriceOutageHistory,
        rcPriceOutageHistoryError,
        refetchRcPriceOutageHistory: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

export default useRCPriceOutage;
