import { Col, Form, Modal, Row, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { RCIssueLogType, mapIssueLogBackToEnum, useRCIssuesLogSettings, useRCIssuesLogMutate } from "@/hooks/useRCIssuesLog";
import useRCIssuesLogServers from "@/hooks/useRCIssuesLogServers";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import LoadingComponent from "@/components/Loading";
import { FormComponent } from "@/components/FormComponent";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";

interface CommonIssuesLogExcludeAccountSettingsTableProps {
    type: RCIssueLogType;
    title?: string;
    enableUpdate: boolean;
}

const initFormValue = {
    mainServerId: null,
    login: "",
    loginName: "",
};

const CommonIssuesLogExcludeAccountSettingsTable = ({ type, title, enableUpdate }: CommonIssuesLogExcludeAccountSettingsTableProps) => {
    const typeNumber = mapIssueLogBackToEnum(type);
    const { rcIssuesLogServers, isLoading: isLoadingRCIssuesLogServers } = useRCIssuesLogServers();
    const { rcIssuesLogSettings, rcIssuesLogSettingsError, isLoading } = useRCIssuesLogSettings({ logType: type });
    const {
        addIssueLogSettings,
        deleteIssueLogSettings,
        uploadIssueLogSettings,
        isLoadingAddIssueLogSettings,
        isLoadingDeleteIssueLogSettings,
        isLoadingUploadIssueLogSettings,
    } = useRCIssuesLogMutate({ type });

    const [openAddModal, setOpenAddModal] = useState(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);

    const [form] = Form.useForm();
    const [formUpload] = Form.useForm();

    const serverOptions = useMemo(() => rcIssuesLogServers || [], [rcIssuesLogServers]);

    const massagedData = useMemo(() => {
        return rcIssuesLogSettings && rcIssuesLogSettings?.length >= 1
            ? rcIssuesLogSettings?.map((item: any, index: number) => {
                  return {
                      ...item,
                      key: `${item.mainServerId}-${item.login}-${item.loginName}`,
                  };
              })
            : [];
    }, [rcIssuesLogSettings]);

    const columns = [
        ...[
            {
                title: "Server",
                dataIndex: "mainServerId",
                key: "mainServerId",
                sorter: (a: any, b: any) => {
                    // sort alphabetically and length
                    if (a?.mainServerId?.toLowerCase() < b?.mainServerId?.toLowerCase()) {
                        return -1;
                    }
                    if (a?.mainServerId?.toLowerCase() > b?.mainServerId?.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                },
            },

            {
                title: "Login",
                dataIndex: "login",
                key: "login",
                sorter: (a: any, b: any) => {
                    if (typeof a.login === "number" && typeof b.login === "number") {
                        return a.login - b.login;
                    } else {
                        // sort alphabetically and length
                        if (a?.login?.toLowerCase() < b?.login?.toLowerCase()) {
                            return -1;
                        }
                        if (a?.login?.toLowerCase() > b?.login?.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    }
                },
            },
            {
                title: "Login Name",
                dataIndex: "loginName",
                key: "loginName",
                sorter: (a: any, b: any) => {
                    // sort alphabetically and length
                    if (a?.loginName?.toLowerCase() < b?.loginName?.toLowerCase()) {
                        return -1;
                    }
                    if (a?.loginName?.toLowerCase() > b?.loginName?.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                },
            },
        ],
    ];

    const handleAdd = async ({ mainServerId, login, loginName }: { mainServerId: string; login: number; loginName: string }) => {
        try {
            const resp = await addIssueLogSettings({
                mainServerId,
                login,
                loginName,
                type: typeNumber as number,
            });
            if (resp.status === 200 && resp?.data?.status === 400) {
                ErrorMessageHandler(`Failed to add settings. Error: ${resp?.data?.msg}`, SUCCESS_FAILED.OTHERS_FAILED);
            } else {
                ErrorMessageHandler("Settings", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                form.resetFields();
                setOpenAddModal(false);
            }
            // console.log(resp);
        } catch (err) {
            console.log("error while adding settings", err);
        }
    };
    const handleDelete = async ({
        mainServerId,
        login,
        loginName,
        type,
    }: {
        mainServerId: string;
        login: number;
        loginName: string;
        type: number;
    }) => {
        try {
            const resp = await deleteIssueLogSettings({
                mainServerId,
                login,
                loginName,
                type,
            });
            if (resp.status === 200 && resp?.data?.status === 400) {
                ErrorMessageHandler(`Failed to delete settings. Error: ${resp?.data?.msg}`, SUCCESS_FAILED.OTHERS_FAILED);
            } else {
                ErrorMessageHandler(`${mainServerId}: (${login}) ${loginName}`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
            }
            // console.log(resp);
        } catch (err) {
            console.log("error while adding settings", err);
        }
    };
    const handleUpload = async ({ formData, type }: { formData: FormData; type: number }) => {
        try {
            const resp = await uploadIssueLogSettings({ formData, type });
            if (resp.status === 200 && resp?.data?.status === 400) {
                ErrorMessageHandler(`Failed to upload settings. Error: ${resp?.data?.msg}.`, SUCCESS_FAILED.OTHERS_FAILED);
            } else {
                ErrorMessageHandler("Settings", SUCCESS_FAILED.SUCCESS_UPLOAD_DATA);
                formUpload.resetFields();
            }
            // console.log(resp);
        } catch (err) {
            ErrorMessageHandler(`Failed to upload settings. Error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };
    const handleDownload = async () => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_SETTINGS_DOWNLOAD}/${typeNumber}`, {
                headers: {
                    Accept: "application/octet-stream,text/csv, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                const contentType = response.headers["content-type"];
                const fileName = getFileNameFromResponseHeader(response, `issuelog_${type}_settings.csv`);
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "text/csv;charset=UTF-8" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    ErrorMessageHandler(`${type} settings`, SUCCESS_FAILED.SUCCESS_DOWNLOAD_DATA);
                } else {
                    ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    console.log("Received non-file response:", response);
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Failed to download settings. Error`, SUCCESS_FAILED.OTHERS_FAILED, err))
            )
            .finally(() => setIsExporting(false));
    };

    const handleSubmit = (file: any) => {
        let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
            isLt5M = file.size / 1024 / 1024 < 5;

        if (fileExtension[0] !== ".csv") {
            ErrorMessageHandler(`Please check file type. Only .csv files are allowed.`, SUCCESS_FAILED.OTHERS_FAILED);
            return;
        } else if (!isLt5M) {
            ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        }

        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure you want to import?",
            content: <p>{file?.name}</p>,
            width: "30%",
            okButtonProps: { loading: isLoadingUploadIssueLogSettings },
            onOk() {
                var formData = new FormData();
                formData.append("uploadFile", file);

                handleUpload({ formData, type: typeNumber as number });
            },
            onCancel() {},
        });
    };

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        separateActionButton: true,
        add: enableUpdate,
        delete: enableUpdate,
        export: massagedData.length > 0 ? { text: "Download" } : false,
        ...(enableUpdate && {
            upload: {
                name: "file",
                multiple: false,
                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                showUploadList: false,
                onChange: (info: any) => {
                    if (info.file.status === "error") {
                        ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                },
                customRequest: ({ file, onSuccess }: any) =>
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0),
                beforeUpload: (file: any) => handleSubmit(file),
            },
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setOpenAddModal(true);
                break;
            case CALLBACK_KEY.DO_DELETE:
                handleDelete(FormData);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                handleDownload();
                break;
            default:
                break;
        }
    };

    if (rcIssuesLogSettingsError !== null) {
        ErrorMessageHandler(rcIssuesLogSettingsError, SUCCESS_FAILED.OTHERS_FAILED);
    }

    return (
        <div className="single-page">
            <div className={`active`}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="key"
                    title={title === undefined ? "Exclude Account Settings" : title}
                    columns={columns}
                    dataSource={massagedData}
                    loading={isLoading || isLoadingDeleteIssueLogSettings}
                    pagination={{ defaultPageSize: 50, pageSizeOptions: [10, 20, 50, 100, 500, 1000] }}
                    options={options}
                    callback={componentCallback}
                    exporting={isExporting}
                />
                <Form
                    form={form}
                    layout="horizontal"
                    initialValues={initFormValue}
                    // /* onValuesChange={handleFormUpdate} */ requiredMark={true}
                    onFinish={handleAdd}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                >
                    <Modal
                        title="Create New Settings"
                        okText={"Submit"}
                        open={openAddModal}
                        okButtonProps={{ loading: isLoadingAddIssueLogSettings }}
                        onOk={() => form.submit()}
                        onCancel={() => {
                            form.resetFields();
                            setOpenAddModal(false);
                        }}
                    >
                        <LoadingComponent tip="Loading..." spinning={isLoadingRCIssuesLogServers && !rcIssuesLogServers}>
                            <div>
                                <Row>
                                    <Col span={22}>
                                        <FormComponent
                                            label="Server"
                                            name={"mainServerId"}
                                            extra={{
                                                type: ComponentType.dropdown,
                                                value: serverOptions.map((x: any) => ({
                                                    text: x.label,
                                                    value: x.value,
                                                })),
                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={22}>
                                        <FormComponent
                                            label="Login"
                                            name={"login"}
                                            extra={{
                                                type: ComponentType.text,
                                                value: "",
                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={22}>
                                        <FormComponent
                                            label="Login Name"
                                            name={"loginName"}
                                            extra={{
                                                type: ComponentType.text,
                                                value: "",
                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </LoadingComponent>
                    </Modal>
                </Form>
            </div>
        </div>
    );
};

export default CommonIssuesLogExcludeAccountSettingsTable;
