import { ComponentType } from "@/constants";
import { KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator } from "@/utils/Common";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Button, Divider, Form } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCallback, useState } from "react";
import { CompareItem } from "../index";
import { FormComponent } from "@/components/FormComponent";
import { DefaultIfEmpty } from "@/utils/object";

interface FilterByServerProps {
    servers: KeyValuePair[];
    onAddCompareItem: (items: CompareItem[]) => void;
}

const FilterByServer = ({ servers, onAddCompareItem }: FilterByServerProps) => {
    const [isSymbolListingFetching, setIsSymbolListingFetching] = useState<boolean>(false);
    const [symbols, setSymbols] = useState<KeyValuePair[]>([]);

    const [filterForm] = Form.useForm();
    const selectedSymbols: string[] = Form.useWatch("symbol", filterForm) || [];

    const onAddToList = useCallback(() => {
        let selectedServerUno = filterForm.getFieldValue("server") || "",
            selectedServerText = DefaultIfEmpty(
                servers.find(x => x.value === selectedServerUno),
                "text",
                ""
            );

        onAddCompareItem(
            selectedSymbols.map(x => ({
                compareServer: selectedServerUno,
                compareServerName: selectedServerText,
                compareSymbol: x,
            }))
        );
    }, [servers, onAddCompareItem, selectedSymbols]);

    const refetchSymbols = (sServer: number) => {
        setIsSymbolListingFetching(true);
        plainAxiosInstance
            .get(`${APIs.RC_LP_PRICE_COMPARE.GET_SYMBOLS_BY_SERVER}/${sServer}`)
            .then((res: any) => {
                setSymbols(res.data && res.data.length > 0 ? res.data.map((x: any) => ({ text: x.symbol, value: x.symbol })) : []);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Fetch symbol listing failed."));
                setSymbols([]);
            })
            .finally(() => setIsSymbolListingFetching(false));
    };

    const onFormValueChanged = (changedValues: any) => {
        if (changedValues.hasOwnProperty("server")) {
            filterForm.setFieldValue("symbol", []);
            refetchSymbols(changedValues.server);
        }
    };

    return (
        <Form form={filterForm} layout={"horizontal"} labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} onValuesChange={onFormValueChanged}>
            <Button
                type="primary"
                icon={<DoubleRightOutlined />}
                disabled={selectedSymbols.length === 0}
                onClick={onAddToList}
                className="extra-item-btn"
            >
                Add Selected Symbol
            </Button>
            <FormComponent
                label="Server"
                name="server"
                extra={{
                    type: ComponentType.dropdown,
                    value: servers,
                    inputProps: { allowClear: false },
                }}
            />
            <FormComponent
                label="Symbols"
                name="symbol"
                extra={{
                    type: ComponentType.dropdown,
                    value: symbols,
                    inputProps: {
                        mode: "multiple",
                        allowClear: true,
                        loading: isSymbolListingFetching,
                        className: "symbols-select",
                        dropdownRender: (menus: any) => (
                            <>
                                <Checkbox
                                    onChange={(e: CheckboxChangeEvent) => {
                                        filterForm.setFieldValue("symbol", e.target.checked ? symbols.map(item => item.value) : []);
                                    }}
                                    style={{ padding: "4px 8px 0px 8px" }}
                                    disabled={isSymbolListingFetching}
                                    checked={selectedSymbols.length === symbols.length && symbols.length > 0}
                                >
                                    Select All
                                </Checkbox>
                                <Divider style={{ margin: "8px 0" }} />
                                {menus}
                            </>
                        ),
                    },
                }}
            />
        </Form>
    );
};

export default FilterByServer;
