import { Modal, Timeline } from "antd";
import EmptyData from "@/components/Common/Empty";
import LoadingComponent from "@/components/Loading";
import { WAStatusLogs, WithdrawalAuditLogsData } from "@/constants/type";
import { WITHDRAWAL_AUDIT_STATUS, WITHDRAWAL_AUDIT_STATUS_LABEL } from "@/constants";
import { formatDistance } from "date-fns";
import { DataTableColumnRender } from "@/utils/Common";

interface ViewLogsModalProps {
    isLogModalOpen: boolean;
    logsData: any;
    onClose: () => void;
    isLoading: boolean;
};

const ViewHistoricalLogModal = (props: ViewLogsModalProps) => {
    return (
        <div>
            <Modal
                width={"50%"}
                style={{ top: 30 }}
                destroyOnClose
                title={"Historical Logs"}
                open={props.isLogModalOpen}
                onOk={props.onClose}
                onCancel={props.onClose}
                footer={false}
            >
                <div className="withdrawal-audit-logs-modal nice-scrollbar" style={{ maxHeight: "75vh", overflow: "auto" }}>
                    {
                        props.isLoading ? (
                            <div style={{ textAlign: "center", paddingTop: "8vh", paddingBottom: "8vh" }}>
                                <LoadingComponent />
                            </div>
                        ) : props.logsData.length < 1 ? (
                            <EmptyData />
                        ) : (
                            <Timeline>
                                {
                                    props.logsData.map((currStatusLog: WithdrawalAuditLogsData) => {
                                        return (
                                            <Timeline.Item key={`status-${currStatusLog.status}`}>
                                                <div className="timeline-item">
                                                    <div className="timeline-item-title">
                                                        <div className={`wa-tags wa-logs ${WITHDRAWAL_AUDIT_STATUS_LABEL[currStatusLog.status] === WITHDRAWAL_AUDIT_STATUS_LABEL[WITHDRAWAL_AUDIT_STATUS.PENDING]
                                                            ? "pending"
                                                            : WITHDRAWAL_AUDIT_STATUS_LABEL[currStatusLog.status] === WITHDRAWAL_AUDIT_STATUS_LABEL[WITHDRAWAL_AUDIT_STATUS.RISK_CHECKING]
                                                                ? "checking"
                                                                : WITHDRAWAL_AUDIT_STATUS_LABEL[currStatusLog.status] === WITHDRAWAL_AUDIT_STATUS_LABEL[WITHDRAWAL_AUDIT_STATUS.APPROVE]
                                                                    ? "approved"
                                                                    : "rejected"
                                                            }`}
                                                        >
                                                            {WITHDRAWAL_AUDIT_STATUS_LABEL[currStatusLog.status]}
                                                        </div>
                                                    </div>
                                                    {currStatusLog.logs.length < 1 ? null : (
                                                        <div>
                                                            <Timeline>
                                                                {
                                                                    currStatusLog.logs
                                                                        // .sort((a: WAStatusLogs, b: WAStatusLogs) => new Date(a.createdTimeUtc).getTime() - new Date(b.createdTimeUtc).getTime())
                                                                        .map((currEntryLog: WAStatusLogs, i: number) => (
                                                                            <Timeline.Item key={`entry-${i}`}>
                                                                                <div className="timeline-item">
                                                                                    <div className="timeline-item-title">
                                                                                        {currEntryLog.currentStatus !== currEntryLog.originStatus ? (
                                                                                            <>
                                                                                                <b>{currEntryLog.createdBy}</b> updated status to <b>{WITHDRAWAL_AUDIT_STATUS_LABEL[currEntryLog.currentStatus]}</b>.
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <b>{currEntryLog.createdBy}</b> updated comment.
                                                                                            </>
                                                                                        )
                                                                                        }
                                                                                    </div>
                                                                                    {currEntryLog.comment &&
                                                                                        <div className={`timeline-item-content`}>
                                                                                            {currEntryLog.comment}
                                                                                        </div>
                                                                                    }
                                                                                    <div className="timeline-item-datetime-info">
                                                                                        {formatDistance(new Date(currEntryLog.createdTimeUtc), new Date(), {
                                                                                            includeSeconds: true,
                                                                                        })}{" "}
                                                                                        ago - {DataTableColumnRender.DateTime(currEntryLog.createdTimeUtc)}
                                                                                    </div>
                                                                                </div>
                                                                            </Timeline.Item>
                                                                        ))
                                                                }
                                                            </Timeline>
                                                        </div>
                                                    )}
                                                </div>
                                            </Timeline.Item>
                                        )
                                    })
                                }
                            </Timeline>
                        )
                    }
                </div>
            </Modal>
        </div>
    );
};

export default ViewHistoricalLogModal;