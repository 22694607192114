import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
import { massageData } from "./../pages/SystemMonitor/StatusMonitor/statusUtils";
const useRCStatusMonitor = ({ department }: { department: string }) => {
    const { data, refetch, isLoading, error, dataUpdatedAt, isFetching } = useQuery({
        queryKey: ["rcStatusMonitor", department],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_SYSTEM_MONITOR.GET_STATUS_MONITOR}/${department}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        refetchInterval: 30 * 1000,
    });

    const rcStatusMonitor = data?.data?.status ? [] : data?.data || [];
    const massagedData = rcStatusMonitor ? massageData(rcStatusMonitor, department) : [];

    const totalErrorCount = massagedData?.reduce((acc: number, item: any) => acc + item.errorCount, 0) ?? 0;
    const totalWarningCount = massagedData?.reduce((acc: number, item: any) => acc + item.warningCount, 0) ?? 0;

    // @ts-ignore
    const rcStatusMonitorError = data?.data?.message || error?.message || error || null;
    return {
        rcStatusMonitor,
        rcStatusMonitorTotalErrorCount: totalErrorCount,
        rcStatusMonitorTotalWarningCount: totalWarningCount,
        rcStatusMonitorError,
        refetchRcStatusMonitor: refetch,
        dataUpdatedAt,
        isLoading,
        isFetching,
    };
};

export default useRCStatusMonitor;
