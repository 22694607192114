import React, { useState } from "react";
import useRCMetaTraders from "../../../hooks/useRCMetaTraders";
// import { dummyMetaTrader } from "./dummy";
import useRCMetaTraderStore from "../../../store/useRCMetaTraderStore";
import { Button, Col, Row, Space, Tag, Tooltip, Typography } from "antd";
import { colorMap, colorMapRGB } from "../StatusMonitor/statusUtils";
import { AnimatePresence, motion } from "framer-motion";
import { DownOutlined, WarningOutlined } from "@ant-design/icons";
import ResizeablePanel from "../../../components/Motion/ResizablePanel";
import MessageCard from "../components/MessageCard";

interface IMassagedServerData {
    serverId: string;
    server: string;
    name: string;
    type: string;
    sendTime: string;
    status: number;
}
interface IMassagedServerDetails {
    type: string;
    data: IMassagedServerData[];
    danger: number;
    success: number;
}

export const massageData = (res: any) => {
    const nowRes = res;

    const uniqType = Array.from(new Set(nowRes.map((item: any) => item.type)));

    // 依據Type塞資料
    const typeData = uniqType.map(type => {
        const tempData = nowRes.filter((data2: any) => data2.type === type);

        let danger = 0,
            success = 0;

        tempData.forEach((data: any) => {
            if (data.status === 0) success++;
            if (data.status === 1) danger++;
        });

        return {
            type: type,
            data: tempData,
            danger: danger,
            success: success,
        };
    });
    return typeData;
};
const MetaTraders = () => {
    const { rcMetaTraders } = useRCMetaTraders();
    // @ts-ignore
    const data: IMassagedServerDetails[] = rcMetaTraders
        ? massageData(rcMetaTraders)
              // @ts-ignore
              .sort((a, b) => (a.type > b.type ? 1 : -1))
        : [];
    // const massagedData = dummyMetaTrader ? massageData(dummyMetaTrader) : [];
    const showSuccess = useRCMetaTraderStore(state => state.display.success);
    const showDanger = useRCMetaTraderStore(state => state.display.danger);
    const [expandedData, setExpandedData] = useState<string[]>([]);
    const { Text } = Typography;

    // check groupData, if all servers are hidden, hide the group
    const filteredData = data
        .filter(group => {
            return group.data.some(server => {
                if (server.status === 1 && showDanger) return true;
                if (server.status === 0 && showSuccess) return true;
                return false;
            });
        })
        ?.sort((a, b) => b.danger - a.danger);

    if (filteredData.length === 0) return null;

    const totalDanger = filteredData.reduce((acc, group) => acc + group.danger, 0);

    return (
        <div style={{ borderBottom: "1px solid rgba(0,0,0,0.05)", padding: "1rem" }}>
            {totalDanger >= 1 && (
                <div style={{ marginBottom: "0.5rem" }}>
                    <MessageCard type="error">
                        {totalDanger} danger{totalDanger >= 2 && "s"}
                    </MessageCard>
                </div>
            )}
            <Row gutter={[8, 8]}>
                {filteredData?.map((group, index) => {
                    // check if any of the servers in the group has a status of 2 (error), or 1 (warning)
                    const hasError = group.data.some(server => server.status === 2);
                    const hasWarning = group.data.some(server => server.status === 1);

                    const finalData = group.data
                        .filter(server => {
                            // it can be showDanger, showWarning and showSuccess combination, or only 2 of them, or none
                            if (server.status === 1 && showDanger) return true;
                            if (server.status === 0 && showSuccess) return true;
                            return false;
                        })
                        ?.sort((a, b) => b.status - a.status);

                    const isExpanded = expandedData.includes(group.type);
                    if (finalData.length === 0) return <></>;
                    return (
                        <Col
                            key={group.type + index}
                            xs={{ span: 24 }}
                            sm={{ span: isExpanded ? 24 : 12 }}
                            md={{ span: isExpanded ? 24 : 8 }}
                            lg={{ span: isExpanded ? 24 : 6 }}
                        >
                            <div
                                key={index}
                                style={{
                                    border: "1px solid rgba(0,0,0,0.25)",
                                    borderRadius: "4px",
                                    padding: "0.5rem",
                                    background: hasError
                                        ? `rgba(${colorMapRGB.error}, 0.1)`
                                        : hasWarning
                                        ? `rgba(${colorMapRGB.warning}, 0.1)`
                                        : "none",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <div style={{ width: "100%", display: "flex", marginBottom: 4 }}>
                                    <Row style={{ flex: 1 }}>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                            <Text>{group.type}</Text>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                            <Space direction="horizontal" size={4} wrap>
                                                {group.danger >= 1 && (
                                                    <Tag color={colorMap.error} style={{ margin: 0 }}>
                                                        {group.danger} Danger
                                                    </Tag>
                                                )}
                                                {group.success >= 1 && (
                                                    <Tag color={colorMap.success} style={{ margin: 0 }}>
                                                        {group.success} Success
                                                    </Tag>
                                                )}
                                            </Space>
                                        </Col>
                                    </Row>

                                    <Button
                                        type="text"
                                        shape="circle"
                                        size="small"
                                        icon={
                                            <motion.div
                                                animate={{
                                                    //expandedData is an array of expanded groups, check if group.type is one of them
                                                    rotate: isExpanded ? 180 : 0,
                                                }}
                                                onClick={() => {
                                                    if (isExpanded) {
                                                        setExpandedData(expandedData.filter(item => item !== group.type));
                                                    } else {
                                                        setExpandedData([...expandedData, group.type]);
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <DownOutlined />
                                            </motion.div>
                                        }
                                    />
                                </div>
                                <ResizeablePanel>
                                    <AnimatePresence>
                                        {
                                            // if not expanded, show summary
                                            !isExpanded ? (
                                                <div key="minimal" style={{ lineHeight: "10px" }}>
                                                    {finalData.map((server, index) => (
                                                        <ServerDetailsMinimal
                                                            server={server}
                                                            key={server.name + index.toString()}
                                                            delay={index * 0.015}
                                                        />
                                                    ))}
                                                </div>
                                            ) : (
                                                // if expanded, show details
                                                <motion.div key="full" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                                    <Row gutter={[8, 8]}>
                                                        {finalData.map((server, index) => (
                                                            <Col
                                                                xs={{ span: 24 }}
                                                                sm={{ span: 12 }}
                                                                md={{ span: 6 }}
                                                                lg={{ span: 6 }}
                                                                xl={{ span: 4 }}
                                                                key={server.serverId + index.toString()}
                                                            >
                                                                <ServerDetails server={server} />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </motion.div>
                                            )
                                        }
                                    </AnimatePresence>
                                </ResizeablePanel>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

const HoverInfo = ({ title, info }: { title: string; info: string }) => {
    const { Text, Paragraph } = Typography;
    const primaryTextStyle = { color: "white" };
    const secondaryTextStyle = { color: "rgba(255,255,255,0.75)", fontSize: "0.85em", lineHeight: "0.9", display: "block" };
    return (
        <Paragraph style={{ margin: 0 }}>
            <Text style={secondaryTextStyle}>{title}</Text>
            <Text style={primaryTextStyle}>{info || "-"}</Text>
        </Paragraph>
    );
};

const ServerDetailsMinimal = ({ server, delay }: { server: IMassagedServerData; delay: number }) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay } }}
            exit={{ opacity: 0 }}
            style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: server.status === 0 ? colorMap.success : colorMap.error,
                margin: "1px",
                lineHeight: "10px",
                borderRadius: "2px",
                cursor: "pointer",
            }}
            whileHover={{ scale: 1.2 }}
        >
            <Tooltip
                title={
                    <Space size={4} direction="vertical">
                        <div>
                            {server.serverId ? <HoverInfo title="Server ID" info={server.serverId.toString()} /> : null}
                            <HoverInfo title="Server Name" info={server.name.toString()} />
                            <Tag color={server.status === 0 ? colorMap.success : colorMap.error}>{server.status === 0 ? "Success" : "Danger"}</Tag>
                        </div>
                    </Space>
                }
            >
                <motion.div style={{ display: "inline-block", width: "10px", height: "10px" }} />
            </Tooltip>
        </motion.div>
    );
};

const ServerDetails = ({ server }: { server: IMassagedServerData }) => {
    const isError = server.status === 1;

    return (
        <div
            style={{
                padding: "0.5rem",
                backgroundColor: "rgba(0,0,0,0.8)",
                border: "4px solid",
                borderColor: isError ? colorMap.error : "transparent",
                height: "100%",
                borderRadius: "8px",
                overflow: "hidden",
                position: "relative",
            }}
        >
            {/* put WarningOutlined to bottom right of div, enlarge to 64px, and position absolute, zIndex below the content, need to be hidden if it flows outside */}
            <WarningOutlined
                style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "10px",
                    fontSize: "6rem",
                    zIndex: 1,
                    color: isError ? colorMap.error : "transparent",
                    opacity: 0.8,
                }}
            />
            <Space direction="vertical" size={8} style={{ zIndex: 2, position: "relative" }}>
                {server.serverId ? <HoverInfo title="Server ID" info={server.serverId.toString()} /> : null}
                <div>
                    <HoverInfo title="Server Name" info={server.name.toString()} />
                    <Tag color={isError ? colorMap.error : colorMap.success}>{isError ? "Danger" : "Success"}</Tag>
                </div>
            </Space>
        </div>
    );
};

export default MetaTraders;
