import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SYMBOL_SCOPE_TYPE } from "@/constants";
import { KeyValuePair } from "@/constants/type";
import { DefaultIfEmpty } from "@/utils/object";
import { CheckOutlined, ClearOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Form, Popover, Tag } from "antd";
import { FormInstance } from "antd/es/form";
import { useEffect, useMemo, useState } from "react";

export interface FilterComponentProps {
    form: FormInstance;
    productCleanSymbols: KeyValuePair[];
    pricingCleanSymbols: KeyValuePair[];
    symbolAssetType: any[];
    serverType: any[];
    onFilterSubmit: Function;
}

const FilterComponent = (props: FilterComponentProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({
        type: SYMBOL_SCOPE_TYPE.PRODUCT,
        cleanSymbols: [],
        cleanSymbolsPricing: [],
        symbolAssetTypeIds: [],
        symbolAssetTypeIdsPricing: [],
        serverTypeIds: [],
    });
    const currentType = Form.useWatch("type", props.form);

    const selectionObj = useMemo(
        () => ({
            symbolAssetObj: props.symbolAssetType.reduce((finalObj: any, item: any) => {
                finalObj[item.value] = item.text;
                return finalObj;
            }, {}),
            serverTypeObj: props.serverType.reduce((finalObj: any, item: any) => {
                finalObj[item.value] = item.text;
                return finalObj;
            }, {}),
        }),
        [props.symbolAssetType, props.serverType]
    );

    const cleanSymbolAndAssetTypeTemplate: React.ReactNode = useMemo(
        () =>
            currentType === SYMBOL_SCOPE_TYPE.PRODUCT ? (
                <>
                    <FormComponent
                        label="Clean Symbol"
                        name="cleanSymbols"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.productCleanSymbols,
                            inputProps: {
                                mode: "multiple",
                                style: {
                                    width: "100%",
                                },
                            },
                        }}
                    />
                    <FormComponent
                        label="Symbol Asset Type"
                        name="symbolAssetTypeIds"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.symbolAssetType,
                            inputProps: {
                                mode: "multiple",
                                style: {
                                    width: "100%",
                                },
                            },
                        }}
                    />
                </>
            ) : currentType === SYMBOL_SCOPE_TYPE.PRICING ? (
                <>
                    <FormComponent
                        label="Clean Symbol"
                        name="cleanSymbolsPricing"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.pricingCleanSymbols,
                            inputProps: {
                                mode: "multiple",
                                style: {
                                    width: "100%",
                                },
                            },
                        }}
                    />
                    <FormComponent
                        label="Symbol Asset Type"
                        name="symbolAssetTypeIdsPricing"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.symbolAssetType,
                            inputProps: {
                                mode: "multiple",
                                style: {
                                    width: "100%",
                                },
                            },
                        }}
                    />
                </>
            ) : (
                <></>
            ),
        [currentType, props.productCleanSymbols, props.pricingCleanSymbols, props.symbolAssetType]
    );

    const selectedItems: React.ReactNode = useMemo(() => {
        return (
            <>
                {filterParams.serverTypeIds && filterParams.serverTypeIds.length > 0 && (
                    <div className="filter-selection-panel">
                        <span>Server Type:</span>
                        {filterParams.serverTypeIds.map((x: string, idx: number) => (
                            <Tag key={`sim-fpd-c-${idx}`} style={{ padding: "0 0.5vw 0 0.5vw", borderRadius: "3px" }}>
                                {DefaultIfEmpty(selectionObj.serverTypeObj, x, x)}
                            </Tag>
                        ))}
                    </div>
                )}
                {currentType === SYMBOL_SCOPE_TYPE.PRODUCT
                    ? filterParams.cleanSymbols &&
                      filterParams.cleanSymbols.length > 0 && (
                          <div className="filter-selection-panel">
                              <span>Clean Symbols:</span>
                              {filterParams.cleanSymbols.map((x: string, idx: number) => (
                                  <Tag key={`sim-fpd-c-${idx}`} style={{ padding: "0 0.5vw 0 0.5vw", borderRadius: "3px" }}>
                                      {x}
                                  </Tag>
                              ))}
                          </div>
                      )
                    : filterParams.cleanSymbolsPricing &&
                      filterParams.cleanSymbolsPricing.length > 0 && (
                          <div className="filter-selection-panel">
                              <span>Clean Symbols:</span>
                              {filterParams.cleanSymbolsPricing.map((x: string, idx: number) => (
                                  <Tag key={`sim-fpd-c-${idx}`} style={{ padding: "0 0.5vw 0 0.5vw", borderRadius: "3px" }}>
                                      {x}
                                  </Tag>
                              ))}
                          </div>
                      )}
                {currentType === SYMBOL_SCOPE_TYPE.PRODUCT
                    ? filterParams.symbolAssetTypeIds &&
                      filterParams.symbolAssetTypeIds.length > 0 && (
                          <div className="filter-selection-panel">
                              <span>Symbol Asset Type:</span>
                              {filterParams.symbolAssetTypeIds.map((x: number, idx: number) => (
                                  <Tag key={`sim-fpd-at-${idx}`} style={{ padding: "0 0.5vw 0 0.5vw", borderRadius: "3px" }}>
                                      {DefaultIfEmpty(selectionObj.symbolAssetObj, x, x)}
                                  </Tag>
                              ))}
                          </div>
                      )
                    : filterParams.symbolAssetTypeIdsPricing &&
                      filterParams.symbolAssetTypeIdsPricing.length > 0 && (
                          <div className="filter-selection-panel">
                              <span>Symbol Asset Type:</span>
                              {filterParams.symbolAssetTypeIdsPricing.map((x: number, idx: number) => (
                                  <Tag key={`sim-fpd-at-${idx}`} style={{ padding: "0 0.5vw 0 0.5vw", borderRadius: "3px" }}>
                                      {DefaultIfEmpty(selectionObj.symbolAssetObj, x, x)}
                                  </Tag>
                              ))}
                          </div>
                      )}
            </>
        );
    }, [selectionObj, filterParams, currentType]);

    useEffect(() => {
        if (isOpen) {
            if (currentType === SYMBOL_SCOPE_TYPE.PRODUCT) {
                props.form.setFieldValue("cleanSymbolsPricing", []);
                props.form.setFieldValue("symbolAssetTypeIdsPricing", []);
            } else if (currentType === SYMBOL_SCOPE_TYPE.PRICING) {
                props.form.setFieldValue("cleanSymbols", []);
                props.form.setFieldValue("symbolAssetTypeIds", []);
            }
        }
    }, [isOpen, currentType]);

    return (
        <div className="filter-component-panel">
            <div className="left">
                <Popover
                    overlayClassName="filter-component-popover-css"
                    open={isOpen}
                    content={
                        <>
                            <Form form={props.form} layout="vertical" labelAlign="left" initialValues={filterParams}>
                                <FormComponent
                                    label="Server Type"
                                    name="serverTypeIds"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: props.serverType,
                                        inputProps: {
                                            mode: "multiple",
                                            style: {
                                                width: "100%",
                                            },
                                        },
                                    }}
                                />
                                <FormComponent
                                    label=""
                                    name="type"
                                    extra={{
                                        type: ComponentType.radio,
                                        value: [
                                            { text: "Product", value: SYMBOL_SCOPE_TYPE.PRODUCT },
                                            { text: "Pricing", value: SYMBOL_SCOPE_TYPE.PRICING },
                                        ],
                                    }}
                                />
                                {cleanSymbolAndAssetTypeTemplate}
                            </Form>
                        </>
                    }
                    title={
                        <>
                            <div className="filter-header-panel">
                                <div className="text-panel">Filters</div>
                                <div className="buttons">
                                    <Button
                                        type="text"
                                        icon={<ClearOutlined />}
                                        className="reset-style shadow-1"
                                        onClick={() => {
                                            props.form.setFieldsValue({
                                                type: SYMBOL_SCOPE_TYPE.PRODUCT,
                                                cleanSymbols: [],
                                                cleanSymbolsPricing: [],
                                                symbolAssetTypeIds: [],
                                                symbolAssetTypeIdsPricing: [],
                                                serverTypeIds: [],
                                            });
                                        }}
                                    />
                                    <Button
                                        type="text"
                                        icon={<CheckOutlined />}
                                        className="save-style shadow-1"
                                        onClick={() => {
                                            props.form
                                                .validateFields()
                                                .then((values: any) => {
                                                    setFilterParams(
                                                        Object.assign(
                                                            {
                                                                cleanSymbols: [],
                                                                cleanSymbolsPricing: [],
                                                                symbolAssetTypeIds: [],
                                                                symbolAssetTypeIdsPricing: [],
                                                            },
                                                            values
                                                        )
                                                    );
                                                    props.onFilterSubmit && props.onFilterSubmit(values);
                                                })
                                                .catch(err => {})
                                                .finally(() => setIsOpen(false));
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    trigger="click"
                >
                    <FilterOutlined style={{ fontSize: "1.125rem" }} onClick={() => setIsOpen(true)} />
                </Popover>
            </div>
            <div className="right">{selectedItems}</div>
        </div>
    );
};

export default FilterComponent;
