import { Button, Steps } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { stepInfo } from "../stepInfo";
import { KeyValuePair, RollOverBatchRecordList } from "@/constants/type";
import LoadingComponent from "@/components/Loading";
import Step1Adjustment from "./Step1Adjustment";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";
import Step2OpenPosition from "./Step2OpenPosition";
import Step3Supplementary from "./step3Supplementary";
import Step4Deduction from "./Step4Deduction";
import Step5Completion from "./Step5Completion";
import moment from "moment";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { DefaultIfEmpty } from "@/utils/object";

interface EditBatchComponentProps {
    currentBatchId: string | undefined;
}

export interface EditBatchRecordBased {
    batchId: string;
    currentStep: number;
    refreshBatch: () => void;
}

export const executeStatusLabel = [
    {
        label: "Init",
        value: 0,
        btnType: "",
    },
    {
        label: "Processing",
        value: 1,
        btnType: "warning",
    },
    {
        label: "Success",
        value: 2,
        btnType: "success",
    },
    {
        label: "Failed",
        value: 3,
        btnType: "danger",
    },
    {
        label: "Not create execute record",
        value: -1,
        btnType: "secondary",
    },
];

const EditBatchComponent = (props: EditBatchComponentProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [adjustmentEmailTo, setAdjustmentEmailTo] = useState<string>("");
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [currentBatchRecord, setCurrentBatchRecord] = useState<RollOverBatchRecordList | undefined>(undefined);

    const stepItems = useMemo(
        () =>
            stepInfo.map(item => ({
                key: item.value,
                title: item.label,
                description: item.description,
            })),
        []
    );

    const getCurrentBatchInfo = useCallback(() => {
        if (props.currentBatchId === undefined) return;

        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_BATCH_RECORD}?batchId=${props.currentBatchId}`)
            .then((res: any) => {
                if (res.data) {
                    setCurrentBatchRecord(res.data);
                    setCurrentStep(res.data.currentStep);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("roll over batch record", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [props.currentBatchId]);

    const refreshCurrentBatchRecord = useCallback(() => getCurrentBatchInfo(), [getCurrentBatchInfo]);

    const currentFooterTemplate: React.ReactNode = useMemo(() => {
        return (
            <div className="step-footer">
                {currentStep > 0 && (
                    <Button type="default" icon={<ArrowLeftOutlined />} onClick={() => setCurrentStep(prev => prev - 1)}>
                        Previous
                    </Button>
                )}

                {currentStep < stepItems.length - 1 && (
                    <Button type="primary" icon={<ArrowRightOutlined />} onClick={() => setCurrentStep(prev => prev + 1)}>
                        Next
                    </Button>
                )}
            </div>
        );
    }, [stepItems, currentStep]);

    const currentStepTemplate: React.ReactNode = useMemo(() => {
        if (currentBatchRecord === undefined) return <LoadingComponent />;

        switch (currentStep) {
            case 0:
                return (
                    <Step1Adjustment
                        batchId={currentBatchRecord.id}
                        currentStep={currentBatchRecord.currentStep}
                        emailTo={adjustmentEmailTo}
                        refreshBatch={refreshCurrentBatchRecord}
                    />
                );
            case 1:
                return (
                    <Step2OpenPosition
                        batchId={currentBatchRecord.id}
                        currentStep={currentBatchRecord.currentStep}
                        servers={servers}
                        batchCleanSymbol={DefaultIfEmpty(currentBatchRecord, "cleanSymbol", "")}
                        refreshBatch={refreshCurrentBatchRecord}
                    />
                );
            case 2:
                return (
                    <Step3Supplementary
                        batchId={currentBatchRecord.id}
                        currentStep={currentBatchRecord.currentStep}
                        servers={servers}
                        refreshBatch={refreshCurrentBatchRecord}
                    />
                );
            case 3:
                return (
                    <Step4Deduction
                        batchId={currentBatchRecord.id}
                        currentStep={currentBatchRecord.currentStep}
                        servers={servers}
                        refreshBatch={refreshCurrentBatchRecord}
                    />
                );
            case 4:
                return (
                    <Step5Completion
                        servers={servers}
                        batchId={currentBatchRecord.id}
                        currentStep={currentBatchRecord.currentStep}
                        refreshBatch={refreshCurrentBatchRecord}
                        updatedData={currentBatchRecord !== undefined ? moment(currentBatchRecord.updateDate).format("YYYY-MM-DD") : ""}
                    />
                );
            default:
                return <></>;
        }
    }, [currentStep, currentBatchRecord, adjustmentEmailTo, servers, refreshCurrentBatchRecord, isLoading]);

    const getServerList = () => {
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_SERVER_LIST}`)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    setServers([
                        ...[{ text: "All", value: "" }],
                        ...res.data.map((server: any) => ({ text: server.serverName, value: server.serverId })),
                    ]);
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Error retrieve server list")));
    };

    const getAdjustmentEmailTo = () => {
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_ADJUSTMENT_VALUE_EMAIL_TO}`)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    setAdjustmentEmailTo(res.data);
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Error retrieve adjustment email to")));
    };

    const getConfig = () => {
        getServerList();
        getAdjustmentEmailTo();
    };

    useEffect(() => {
        if (props.currentBatchId !== undefined) {
            getCurrentBatchInfo();
        } else {
            setCurrentStep(0);
            setIsLoading(true);
        }
    }, [props.currentBatchId]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className="rollover-tool-edit-component-container">
            <Steps
                size="small"
                current={currentStep}
                onChange={(current: number) => setCurrentStep(current)}
                items={stepItems}
                className="step-header"
            />
            <div className="step-content">{currentStepTemplate}</div>
            {currentFooterTemplate}
        </div>
    );
};

export default EditBatchComponent;
