import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { useState, useEffect, useCallback } from "react";
import CreateEditPriceAlarmColorModal, { CreateEditPriceAlarmColorModalCallbackKey } from "./CreateEditPriceAlarmColorModal";

export interface PriceAlarmColorSettingPageProps {}

export interface PriceAlarmColorSettingProps {
    symbol: string;
    warningColor: string;
}

const PriceAlarmColorSettingPage = (props: PriceAlarmColorSettingPageProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<PriceAlarmColorSettingProps[]>([]);
    const [selectedData, setSelectedData] = useState<PriceAlarmColorSettingProps | undefined>(undefined);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_PRICE_ALARM_COLOR_SETTINGS_EDIT);

    const columns: any[] = [
        {
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        DTColProps.Middle({
            title: "Color Code",
            dataIndex: "warningColor",
            key: "warningColor",
            render: (text: string) => (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <span style={{ width: "12px", height: "12px", background: `${text}`, marginRight: "8px" }}></span>
                    <span>{text}</span>
                </div>
            ),
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsModalVisible(true);
                setIsEditMode(false);
                setSelectedData(undefined);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsModalVisible(true);
                setIsEditMode(true);
                setSelectedData(FormData);
                break;
            case CALLBACK_KEY.DO_DELETE:
                plainAxiosInstance
                    .delete(`${APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_COLOR_LIST}?symbol=${FormData.symbol}`)
                    .then((res: any) => {
                        if (`${res.data.code}` === "0") {
                            ErrorMessageHandler("price alarm color setting", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            setRunRefetchDataList(true);
                        } else {
                            ErrorMessageHandler("price alarm color setting", SUCCESS_FAILED.FAILED_DELETE_DATA);
                        }
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("price alarm color setting", SUCCESS_FAILED.FAILED_DELETE_DATA, err)
                        )
                    );
                break;
            default:
                break;
        }
    };

    const formSubmit = useCallback(
        (data: any) => {
            if (isEditMode) {
                plainAxiosInstance
                    .put(APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_COLOR_LIST, data)
                    .then((res: any) => {
                        if (`${res.data.code}` === "0") {
                            ErrorMessageHandler("price alarm color setting", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                            setRunRefetchDataList(true);
                        } else {
                            ErrorMessageHandler("price alarm color setting", SUCCESS_FAILED.FAILED_UPDATE_DATA);
                        }
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("price alarm color setting", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                        )
                    );
            } else {
                plainAxiosInstance
                    .post(APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_COLOR_LIST, data)
                    .then((res: any) => {
                        if (`${res.data.code}` === "0") {
                            ErrorMessageHandler("price alarm color setting", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                            setRunRefetchDataList(true);
                        } else {
                            ErrorMessageHandler("price alarm color setting", SUCCESS_FAILED.FAILED_CREATE_DATA);
                        }
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("price alarm color setting", SUCCESS_FAILED.FAILED_CREATE_DATA, err)
                        )
                    );
            }
        },
        [isEditMode]
    );

    const getPriceAlarmColorSettings = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_COLOR_LIST)
            .then((res: any) => {
                setData(res.data && res.data.length > 0 ? res.data : []);
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("price alarm color setting", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getPriceAlarmColorSettings();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Price Alarm Color Settings</span>
                </div>
            </div>
            <div className="price-alarm-color-setting-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="symbol"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <CreateEditPriceAlarmColorModal
                data={selectedData}
                isModalVisible={isModalVisible}
                modalTitle={isEditMode ? "Edit Price Alarm Color" : "Create Price Alarm Color"}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case CreateEditPriceAlarmColorModalCallbackKey.Close:
                            setIsModalVisible(false);
                            break;
                        case CreateEditPriceAlarmColorModalCallbackKey.FormSubmit:
                            formSubmit(data);
                            setIsModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
            />
        </>
    );
};

export default PriceAlarmColorSettingPage;
