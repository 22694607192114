import React from "react";
import useRCSpreadReportStore from "../../../store/useRCSpreadReportStore";
import useRCSpreadGroups from "../../../hooks/useRCSpreadGroups";
import useRCSymbols from "../../../hooks/useRCSymbols";
import { Select, DatePicker, Space, Row, Col, Typography, Button, Alert, TimePicker, Form } from "antd";
import type { SelectProps } from "antd";
import moment from "moment";
import BasicFilter from "../../../components/BasicFilter";
import CommonPaddingWrapper from "../CommonPaddingWrapper";
import Loader from "../../SystemMonitor/components/Loader";
import { REQUIRED_FIELD } from "@/constants/errorMessage";

const AverageSpreadAnalysisFilters: React.FC = () => {
    const [searchForm] = Form.useForm();

    const { RangePicker } = DatePicker;
    const { RangePicker: TimeRangePicker } = TimePicker;
    const { Text } = Typography;

    const start = useRCSpreadReportStore(state => state.averageSpreadAnalysis.start);
    const end = useRCSpreadReportStore(state => state.averageSpreadAnalysis.end);
    const symbol = useRCSpreadReportStore(state => state.averageSpreadAnalysis.symbol);
    const groups = useRCSpreadReportStore(state => state.averageSpreadAnalysis.groups);
    const startTime = useRCSpreadReportStore(state => state.averageSpreadAnalysis.startTime);
    const endTime = useRCSpreadReportStore(state => state.averageSpreadAnalysis.endTime);
    const setAverageSpreadAnalysis = useRCSpreadReportStore(state => state.setAverageSpreadAnalysis);
    const updateAverageSpreadAnalysisQuery = useRCSpreadReportStore(state => state.updateAverageSpreadAnalysisQuery);

    const { rcSymbols, rcSymbolsError, refetchRcSymbols, isLoading: isLoadingRcSymbols } = useRCSymbols();
    const { rcSpreadGroups, rcSpreadGroupsError, refetchRcSpreadGroups, isLoading: isLoadingRcSpreadGroups } = useRCSpreadGroups();

    const optionsRcSymbols: SelectProps["options"] = rcSymbols?.map((each: any) => ({ label: each?.name, value: each?.name })) ?? [];

    const handleResetFilter = () => {
        setAverageSpreadAnalysis({
            start: new Date().toISOString().split("T")[0],
            end: new Date().toISOString().split("T")[0],
            startTime: "00:00",
            endTime: "23:59",
            groups: { 1: [], 2: [], 4: [] },
            symbol: null,
        });

        searchForm.setFieldsValue({
            start: new Date().toISOString().split("T")[0],
            dateRange: [moment(new Date().toISOString().split("T")[0]), moment(new Date().toISOString().split("T")[0])],
            timeRange: [moment("00:00", "HH:mm"), moment("23:59", "HH,mm")],
            groups: { 1: [], 2: [], 4: [] },
            symbol: null,
        });
    };

    if ((isLoadingRcSymbols || isLoadingRcSpreadGroups) && !rcSymbolsError && !rcSpreadGroupsError) {
        return (
            <CommonPaddingWrapper>
                <Loader />
            </CommonPaddingWrapper>
        );
    }
    if (rcSymbolsError) {
        return (
            <CommonPaddingWrapper>
                <Alert
                    message={`Having some error while loading symbol. Error: ${rcSymbolsError}`}
                    type="warning"
                    action={
                        <Button size="small" type="text" onClick={() => refetchRcSymbols()} disabled={isLoadingRcSymbols}>
                            Retry
                        </Button>
                    }
                />
            </CommonPaddingWrapper>
        );
    }
    if (rcSpreadGroupsError) {
        return (
            <CommonPaddingWrapper>
                <Alert
                    message={`Having some error while loading spread groups. Error: ${rcSpreadGroupsError}`}
                    type="warning"
                    action={
                        <Button size="small" type="text" onClick={() => refetchRcSpreadGroups()} disabled={isLoadingRcSpreadGroups}>
                            Retry
                        </Button>
                    }
                />
            </CommonPaddingWrapper>
        );
    }

    return (
        <BasicFilter>
            <Form
                form={searchForm}
                layout="vertical"
                onFinish={updateAverageSpreadAnalysisQuery}
                initialValues={{
                    symbol,
                    dateRange: [
                        start ? moment(start) : moment(new Date().toISOString().split("T")[0]),
                        end ? moment(end) : moment(new Date().toISOString().split("T")[0]),
                    ],
                    timeRange: [
                        startTime ? moment(startTime, "HH:mm") : moment("00:00", "HH,mm"),
                        endTime ? moment(endTime, "HH,mm") : moment("23,59", "HH,mm"),
                    ],
                    "Our Servers": groups[2],
                    "Liquidity Provider": groups[1],
                    "Outsider Brokers": groups[4],
                }}
            >
                <Space style={{ width: "100%" }} direction="vertical">
                    <Row gutter={8}>
                        <Col span={8} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <Form.Item label="Symbol" name="symbol" rules={[{ required: true, message: REQUIRED_FIELD }]}>
                                <Select
                                    options={optionsRcSymbols}
                                    onChange={val => {
                                        setAverageSpreadAnalysis({
                                            symbol: val ? val : null,
                                        });
                                    }}
                                    placeholder="Select Symbol"
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                        <Col span={16} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <Form.Item label="Date Range" name="dateRange" rules={[{ required: true, message: REQUIRED_FIELD }]}>
                                <RangePicker
                                    style={{ width: "100%" }}
                                    onChange={(dates, dateStrings) => {
                                        setAverageSpreadAnalysis({
                                            start: dateStrings[0],
                                            end: dateStrings[1],
                                        });
                                    }}
                                    disabledDate={current => {
                                        return current && (current > moment().endOf("day") || current < moment().subtract(13, "days").startOf("day"));
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={16} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <Form.Item label="Time Range" name="timeRange" rules={[{ required: true, message: REQUIRED_FIELD }]}>
                                <TimeRangePicker
                                    style={{ width: "100%" }}
                                    format="HH:mm"
                                    onChange={(time, timeString) => {
                                        setAverageSpreadAnalysis({
                                            startTime: timeString[0],
                                            endTime: timeString[1],
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        {Object.keys(rcSpreadGroups)?.map((each: any) => {
                            const options = rcSpreadGroups[each]?.map((each: any) => ({ label: each, value: each })) ?? [];
                            const groupNaming = {
                                1: "Liquidity Provider",
                                2: "Our Servers",
                                4: "Outsider Brokers",
                            };
                            const order = {
                                1: 3,
                                2: 1,
                                4: 2,
                            };
                            return (
                                <Col
                                    span={8}
                                    key={each}
                                    xs={{ span: 24 }}
                                    lg={{ span: 12 }}
                                    xl={{ span: 8 }}
                                    order={order[each as keyof typeof order]}
                                >
                                    <Text>{groupNaming[each as keyof typeof groupNaming]}</Text>
                                    <Select
                                        allowClear
                                        mode="multiple"
                                        showSearch
                                        placeholder={`Select ${groupNaming[each as keyof typeof groupNaming]}`}
                                        value={groups[each] ?? []}
                                        style={{ width: "100%" }}
                                        onChange={value => {
                                            setAverageSpreadAnalysis({
                                                groups: { ...groups, [each]: value },
                                            });
                                        }}
                                        options={options}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                    <Space
                        style={{
                            marginTop: "1rem",
                            display: "flex",
                            flexWrap: "wrap",
                            borderTop: "1px solid rgba(0, 0, 0, 0.06)",
                            paddingTop: "1rem",
                        }}
                    >
                        <Button type="default" onClick={() => handleResetFilter()} style={{ minWidth: 100 }}>
                            Reset
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                updateAverageSpreadAnalysisQuery();
                            }}
                            disabled={
                                !start ||
                                !end ||
                                !startTime ||
                                !endTime ||
                                !symbol ||
                                (groups[1]?.length <= 0 && groups[2]?.length <= 0 && groups[4]?.length <= 0)
                            }
                            style={{ minWidth: 100 }}
                        >
                            Generate Report
                        </Button>
                    </Space>
                </Space>
            </Form>
        </BasicFilter>
    );
};

export default AverageSpreadAnalysisFilters;
