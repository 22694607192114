import { useMemo, useEffect, useState, useRef } from "react";

const useAudio = (url: any): [boolean, Function] => {
    const audio = useMemo<HTMLAudioElement>(() => new Audio(url), []);
    const [playing, setPlaying] = useState<boolean>(false);

    const playOrStop = (): void => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
        return () => {};
    }, [playing]);

    useEffect(() => {
        audio.addEventListener("ended", () => setPlaying(false));
        return () => {
            audio.removeEventListener("ended", () => setPlaying(false));
        };
    }, []);

    return [playing, playOrStop];
};

export default useAudio;

/**
 * A custom hook to handle audio playback.
 * @param {string} initialSoundTrack - The initial soundtrack to use.
 * @returns {object} - An API to control audio playback.
 */
const useAudioPlayer = (initialSoundTrack: any) => {
    const [soundTrack] = useState<any>(initialSoundTrack);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const audioRef = useRef<any>(null);

    // Initialize or update the audio object when the soundtrack changes
    useEffect(() => {
        if (soundTrack) {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
            audioRef.current = new Audio(soundTrack);
            audioRef.current.onended = () => {
                setIsPlaying(false);
            };
        }
    }, [soundTrack]);

    /**
     * Play the audio track once from the beginning.
     */
    const playSound = () => {
        if (!soundTrack) {
            console.error("Soundtrack not set. Please provide a valid soundtrack.");
            return;
        }

        if (audioRef.current) {
            // Stop any ongoing playback and reset
            audioRef.current.pause();
            audioRef.current.currentTime = 0;

            // Play the audio once
            audioRef.current.loop = false; // Disable looping to ensure single play
            audioRef.current
                .play()
                .then(() => {
                    setIsPlaying(true);
                })
                .catch((err: any) => {
                    console.error("Error playing audio:", err);
                });
        }
    };

    return { playSound, isPlaying };
};

export { useAudioPlayer };
