import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { CustomPaginationProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DefaultIfEmpty, objectRemoveProps } from "@/utils/object";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "@/utils/string";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import CreateEditCommSettingModal, { CreateEditCommSettingModalCallbackKey } from "./CreateEditCommSettingModal";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import { SortList } from "@/utils/array";

export interface BrandServerCommissionProps {}

interface CommissionSettingProps {
    commissionRate: number;
    currency: string;
    exchangeMarkupRate: number;
    id: number;
    isDel: number;
    minimumPerCharge: number;
    operateTime: string;
    operateTimeStr: string;
    operator: number;
    operatorName: string;
    remark: null | string;
    security: number;
    serverId: number;
    serverName: string;
    symbol: string;
    typeName: string;
}

interface configDataProps {
    symbolType: KeyValuePair[];
    symbol: KeyValuePair[];
    serverDetails: KeyValuePair[];
    remarkHK: any;
}

const BrandServerCommission = (props: BrandServerCommissionProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [editData, setEditData] = useState<any | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<CommissionSettingProps[]>([]);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [configData, setConfigData] = useState<configDataProps>({ symbolType: [], symbol: [], serverDetails: [], remarkHK: {} });

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_COMMISSION_SETTINGS_EDIT);

    const checkDuplicate = (values: any) => {
        return plainAxiosInstance.post(APIs.RC_COMMISSION_SETTING.CHECK_DUPLICATE, values);
    };

    const handleSubmit = useCallback(
        async (values: any) => {
            const dupRes = await checkDuplicate(isEmptyOrNull(values.id) ? objectRemoveProps(values, ["id"]) : values);
            if (isEmptyOrNull(editData)) {
                if (dupRes.data === 1) {
                    ErrorMessageHandler(`Duplicate record found. Please check and try again.`, SUCCESS_FAILED.OTHERS_FAILED);
                } else {
                    plainAxiosInstance
                        .post(APIs.RC_COMMISSION_SETTING.UPDATE_RECORD, values)
                        .then((res: any) => {
                            if (res.data === 0) {
                                ErrorMessageHandler("commission setting", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                                setRunRefetchDataList(true);
                            } else {
                                ErrorMessageHandler("commission setting", SUCCESS_FAILED.FAILED_CREATE_DATA);
                            }
                        })
                        .catch((error: any) =>
                            ErrorCatchValidator(error, (err: any) =>
                                ErrorMessageHandler("commission setting", SUCCESS_FAILED.FAILED_CREATE_DATA, err)
                            )
                        )
                        .finally(() => setIsLoading(false));
                }
            } else {
                if (dupRes.data === 1) {
                    ErrorMessageHandler(`Duplicate record found. Please check and try again.`, SUCCESS_FAILED.OTHERS_FAILED);
                } else {
                    plainAxiosInstance
                        .put(APIs.RC_COMMISSION_SETTING.UPDATE_RECORD, values)
                        .then((res: any) => {
                            if (res.data === 0) {
                                ErrorMessageHandler("commission setting", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                                setRunRefetchDataList(true);
                            } else {
                                ErrorMessageHandler("commission setting", SUCCESS_FAILED.FAILED_UPDATE_DATA);
                            }
                        })
                        .catch((error: any) =>
                            ErrorCatchValidator(error, (err: any) =>
                                ErrorMessageHandler("commission setting", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                            )
                        )
                        .finally(() => setIsLoading(false));
                }
            }
        },
        [editData]
    );

    const uploadFile = (file: any) => {
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
                isLt5M = file.size / 1024 / 1024 < 5;

            if (fileExtension[0] !== ".xlsx") {
                ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            } else if (!isLt5M) {
                ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to import?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("file", file);

                    plainAxiosInstance
                        .post(APIs.RC_COMMISSION_SETTING.UPLOAD_FILE, formData)
                        .then(res => {
                            ErrorMessageHandler(
                                `Total: ${res.data.count}, Added: ${res.data.successCount}, Modified: ${res.data.modifyCount}, Failed: ${res.data.failedCount}.`,
                                SUCCESS_FAILED.OTHERS_SUCCESS
                            );
                        })
                        .catch((error: any) => {
                            ErrorMessageHandler(`File upload failed: (${error.response.data.message}).`, SUCCESS_FAILED.OTHERS_FAILED);
                        });
                },
                onCancel() {},
            });
        } catch (error) {
            ErrorMessageHandler(`Error during uploading file. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const exportFile = useCallback(() => {
        try {
            let serverId = DefaultIfEmpty(filterParams, "serverId", 0);
            plainAxiosInstance
                .get(`${plainAxiosInstance.defaults.baseURL}${APIs.RC_COMMISSION_SETTING.DOWNLOAD_FILE}?serverId=${serverId}`, {
                    headers: {
                        Accept: "application/octet-stream,text/csv, */*",
                    },
                    responseType: "blob",
                })
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const fileName = getFileNameFromResponseHeader(response, `CommissionPerDealHkSetting.xlsx`);

                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) => ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED));
        } catch (e: any) {
            ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    }, [filterParams]);

    const columns: any[] = useMemo(
        () => [
            {
                title: "Server",
                dataIndex: "serverName",
                key: "serverName",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: configData.serverDetails,
                    },
                },
            },
            {
                title: "Asset Type",
                dataIndex: "typeName",
                key: "typeName",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: configData.symbolType,
                    },
                },
            },
            {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: configData.symbol,
                    },
                },
            },
            DTColProps.SCurrency(
                {
                    title: "Commission Rate",
                    dataIndex: "commissionRate",
                    key: "commissionRate",
                },
                [],
                -1
            ),
            DTColProps.SCurrency(
                {
                    title: "Min commission",
                    dataIndex: "minimumPerCharge",
                    key: "minimumPerCharge",
                },
                [],
                -1
            ),
            DTColProps.XSmall(
                {
                    title: "Currency",
                    dataIndex: "currency",
                    key: "currency",
                },
                ["text-center"]
            ),
            DTColProps.Currency(
                {
                    title: "Exchange Rate Markup",
                    dataIndex: "exchangeMarkupRate",
                    key: "exchangeMarkupRate",
                },
                [],
                -1
            ),
            DTColProps.Middle({
                title: "Operator",
                dataIndex: "operatorName",
                key: "operatorName",
            }),
            DTColProps.Small({
                title: "Operate Time",
                dataIndex: "operateTimeStr",
                key: "operateTimeStr",
            }),
        ],
        [configData]
    );

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        separateActionButton: true,
        ...(enableUpdate && {
            add: enableUpdate,
            edit: enableUpdate,
            delete: enableUpdate,
            upload: {
                name: "file",
                multiple: false,
                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                showUploadList: false,
                onChange: (info: any) => {
                    if (info.file.status === "error") {
                        ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                },
                customRequest: ({ file, onSuccess }: any) =>
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0),
                beforeUpload: (file: any) => uploadFile(file),
            },
            export: {
                text: "Download",
            },
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;

            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "serverName") {
                            filterParams["serverId"] = FormData[x];
                        } else if (x === "typeName") {
                            filterParams["security"] = FormData[x];
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.CREATE_NEW:
                setIsModalVisible(true);
                setEditData(undefined);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsModalVisible(true);
                setEditData(FormData);
                break;
            case CALLBACK_KEY.DO_DELETE:
                deleteRecord(FormData);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                exportFile();
                break;
            default:
                break;
        }
    };

    const deleteRecord = (record: any) => {
        setIsLoading(true);
        plainAxiosInstance
            .delete(`${APIs.RC_COMMISSION_SETTING.UPDATE_RECORD}?id=${record.id}`)
            .then((res: any) => {
                if (res.data === 0) {
                    ErrorMessageHandler("commission setting", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                } else {
                    ErrorMessageHandler("commission setting", SUCCESS_FAILED.FAILED_DELETE_DATA);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("commission setting", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            )
            .finally(() => setRunRefetchDataList(true));
    };

    const getCommissionListing = useCallback(() => {
        setIsLoading(true);
        let postParams = {
            entity: filterParams,
            limit: pagination.pageSize,
            offset: ((pagination.current as number) - 1) * (pagination.pageSize as number),
        };

        plainAxiosInstance
            .post(APIs.RC_COMMISSION_SETTING.GET_LIST, postParams)
            .then((res: any) => {
                if (res.data.rows && res.data.rows.length > 0) {
                    setData(res.data.rows);
                    setPagination(prev => ({ ...prev, total: res.data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .finally(() => setIsLoading(false));
    }, [filterParams, pagination]);

    const getConfig = () => {
        Promise.all([
            plainAxiosInstance.get(`${APIs.RC_COMMISSION_SETTING.GET_CONFIG}/symbolType`),
            plainAxiosInstance.get(`${APIs.RC_COMMISSION_SETTING.GET_CONFIG}/symbol`),
            plainAxiosInstance.get(`${APIs.RC_COMMISSION_SETTING.GET_CONFIG}/remarkHK`),
            plainAxiosInstance.get(`${APIs.RC_COMMISSION_SETTING.GET_CONFIG}/serverDetail`),
        ]).then((res: any) => {
            setConfigData({
                symbolType: SortList(
                    res[0].data.map((item: any) => ({ text: item.name, value: item.id })),
                    "text"
                ),
                symbol: SortList(
                    res[1].data.map((item: any) => ({ text: item.symbol, value: item.symbol })),
                    "text"
                ),
                serverDetails: SortList(
                    res[3].data.map((item: any) => ({ text: item.name, value: item.id })),
                    "text"
                ),
                remarkHK: res[2].data.values,
            });
        });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getCommissionListing();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Commission Settings</span>
                </div>
            </div>
            <div className="brand-server-commission-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                />
            </div>
            <CreateEditCommSettingModal
                isModalVisible={isModalVisible}
                modalTitle={isEmptyOrNull(editData) ? "Create Commission Setting" : "Edit Commission Setting"}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case CreateEditCommSettingModalCallbackKey.Close:
                            setIsModalVisible(false);
                            break;
                        case CreateEditCommSettingModalCallbackKey.Submit:
                            handleSubmit(data);
                            setIsLoading(true);
                            setIsModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                servers={configData.serverDetails}
                assetType={configData.symbolType}
                data={editData}
            />
        </>
    );
};

export default BrandServerCommission;
