import { Segmented } from "antd";
import { useEffect, useMemo, useState } from "react";
import CardBox from "../../../components/Common/CardBox";
import { SUCCESS_FAILED } from "../../../constants";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import DataTab from "./DataTab";
import SearchTab from "./SearchTab";
import ExcelTab from "./ExcelTab";

export type HFTThresholdRes = {
    type: string;
    level: number;
    value: number;
};

export type HFTResultRes = {
    login: number;
    serverId: number;
    type: number;
    group: string;
    enableReadonly: number;
    count: number;
    maxProfitSymbol: string;
    todayProfit: number;
    sumAmounts: number;
    winRatio: number;
    modifyTime: string;
    comment: string | null;
    serverName: string;
    todayProfitThreshold: number | null;
    sumAmountsThreshold: number | null;
    hftRatio: number;
    hftProfit: number;
    historicalPnl: number | null;
    stopOut: number;
    balance: number;
    credit: number;
    rebate?: string | null;
};

export type HFTResultFormatted = {
    serverId: number;
    serverName: string;
    login: number;
    group: string;
    count: number;
    sumAmounts: number;
    sumAmountsText: string;
    dpmText: string;
    todayProfit: number;
    todayProfitText: string;
    maxProfitSymbol: string;
    winRatio: number;
    winRatioText: string;
    hftRatio: number;
    hftRatioText: string;
    hftProfit: number;
    hftProfitText: string;
    historicalPnl: number | null;
    historicalPnlText: string | null;
    comment: string | null;
    stopOut: number;
    balance: number;
    credit: number;
    type: number;
    enableReadonly: number;
    modifyTime: string;
    todayProfitThreshold: number | null;
    sumAmountsThreshold: number | null;
    rebate?: string | null;
};

export type HFTThreshold = {
    newprofit10minLevel1: number;
    newprofit10minLevel2: number;
    newprofit2minLevel1: number;
    newprofit2minLevel2: number;
    newprofit30minLevel1: number;
    newprofit30minLevel2: number;
    newprofitpnl10min: number;
    newprofitpnl2min: number;
    newprofitpnl30min: number;
    profit10min: number;
    profit2min: number;
    profit30min: number;
    threshold1: number; //profit1
    threshold2: number; //profit2
    threshold3: number; //profit3
    threshold4?: number; //symbol1
    threshold5?: number; //symbol2
};

const initThreshold: HFTThreshold = {
    newprofit10minLevel1: 0,
    newprofit10minLevel2: 0,
    newprofit2minLevel1: 0,
    newprofit2minLevel2: 0,
    newprofit30minLevel1: 0,
    newprofit30minLevel2: 0,
    newprofitpnl10min: 0,
    newprofitpnl2min: 0,
    newprofitpnl30min: 0,
    profit10min: 0,
    profit2min: 0,
    profit30min: 0,
    threshold1: 30,
    threshold2: 50,
    threshold3: 100,
};

const HFTReport = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<string>("Data");
    const [hftResult, setHFTResult] = useState<HFTResultFormatted[]>([]);
    const [threshold, setThreshold] = useState<HFTThreshold>(initThreshold);

    const segmentChild = useMemo(
        () => [
            {
                title: "Data",
                component: <DataTab hftResults={hftResult} hftThreshold={threshold} isLoading={isLoading} refreshData={fetchData} />,
            },
            {
                title: "Search",
                component: <SearchTab hftResults={hftResult} hftThreshold={threshold} isLoading={isLoading} refreshData={fetchData} />,
            },
            {
                title: "Excel",
                component: <ExcelTab />,
            },
        ],
        [hftResult, threshold, isLoading]
    );

    function fetchData() {
        setIsLoading(true);
        const allData = Promise.allSettled([getHFTResult(), getHFTThreshold()]);
        allData.then(_ => {
            setIsLoading(false);
        });
    }

    async function getHFTResult(): Promise<HFTResultFormatted[]> {
        return new Promise((resolve, reject) => {
            return plainAxiosInstance
                .post(`${APIs.RC_HFT_REPORT.GET_HFT_RESULT}`)
                .then(res => {
                    const data = filterHFTResult(res.data as HFTResultRes[]);
                    setHFTResult(data);
                    return resolve(data);
                })
                .catch((error: any) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("HFT Result", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                    return reject(error);
                });
        });
    }

    function filterHFTResult(data: HFTResultRes[]): HFTResultFormatted[] {
        return data.map(item => ({
            serverId: item.serverId,
            serverName: item.serverName,
            login: item.login,
            group: item.group,
            count: item.count,
            comment: item.comment,
            type: item.type,
            enableReadonly: item.enableReadonly,
            modifyTime: item.modifyTime,
            todayProfitThreshold: item.todayProfitThreshold,
            sumAmountsThreshold: item.sumAmountsThreshold,
            stopOut: item.stopOut,
            maxProfitSymbol: item.maxProfitSymbol,
            todayProfit: item.todayProfit,
            todayProfitText: decimalFormat(item.todayProfit, 2),
            sumAmounts: item.sumAmounts,
            sumAmountsText: formatFixedAndTrimZero(item.sumAmounts, 5),
            dpmText: decimalFormat(item.todayProfit / item.sumAmounts, 2),
            winRatio: item.winRatio,
            winRatioText: percentFormat(item.winRatio),
            hftRatio: item.hftRatio,
            hftRatioText: percentFormat(item.hftRatio),
            hftProfit: item.hftProfit,
            hftProfitText: decimalFormat(item.hftProfit, 2),
            historicalPnl: item.historicalPnl,
            historicalPnlText: decimalFormat(item.historicalPnl ?? 0, item.group.endsWith("_BTC") ? 8 : 0),
            balance: item.balance,
            credit: item.credit,
            rebate: item.rebate,
        }));
    }

    async function getHFTThreshold(): Promise<HFTThreshold> {
        return new Promise((resolve, reject) => {
            return plainAxiosInstance
                .post(`${APIs.RC_HFT_REPORT.GET_HFT_THRESHOLD}`)
                .then(res => {
                    const data = filterHFTThreshold(res.data as HFTThresholdRes[]);
                    setThreshold(data);
                    return resolve(data);
                })
                .catch((error: any) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("HFT Threshold", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                    return reject(error);
                });
        });
    }

    function filterHFTThreshold(data: HFTThresholdRes[]): HFTThreshold {
        const hftThreshold: HFTThreshold = {
            ...initThreshold,
        };
        data.forEach(item => {
            switch (item.type) {
                case "newprofit10min":
                    if (item.level === 1) {
                        hftThreshold.newprofit10minLevel1 = item.value;
                    }
                    if (item.level === 2) {
                        hftThreshold.newprofit10minLevel2 = item.value;
                    }
                    break;
                case "newprofit2min":
                    if (item.level === 1) {
                        hftThreshold.newprofit2minLevel1 = item.value;
                    }
                    if (item.level === 2) {
                        hftThreshold.newprofit2minLevel2 = item.value;
                    }
                    break;
                case "newprofit30min":
                    if (item.level === 1) {
                        hftThreshold.newprofit30minLevel1 = item.value;
                    }
                    if (item.level === 2) {
                        hftThreshold.newprofit30minLevel2 = item.value;
                    }
                    break;
                case "newprofitpnl10min":
                    if (item.level === 1) {
                        hftThreshold.newprofitpnl10min = item.value;
                    }
                    break;
                case "newprofitpnl2min":
                    if (item.level === 1) {
                        hftThreshold.newprofitpnl2min = item.value;
                    }
                    break;
                case "newprofitpnl30min":
                    if (item.level === 1) {
                        hftThreshold.newprofitpnl30min = item.value;
                    }
                    break;
                case "profit":
                    if (item.level === 1) {
                        hftThreshold.threshold1 = item.value;
                    }
                    if (item.level === 2) {
                        hftThreshold.threshold2 = item.value;
                    }
                    if (item.level === 3) {
                        hftThreshold.threshold3 = item.value;
                    }
                    break;
                case "profit10min":
                    if (item.level === 1) {
                        hftThreshold.profit10min = item.value;
                    }
                    break;
                case "profit2min":
                    if (item.level === 1) {
                        hftThreshold.profit2min = item.value;
                    }
                    break;
                case "profit30min":
                    if (item.level === 1) {
                        hftThreshold.profit30min = item.value;
                    }
                    break;
                case "symbol":
                    if (item.level === 1) {
                        hftThreshold.threshold4 = item.value;
                    }
                    if (item.level === 2) {
                        hftThreshold.threshold5 = item.value;
                    }
                    break;
                default:
                    break;
            }
        });
        return hftThreshold;
    }

    const getAllData = () => {
        setIsLoading(true);
        Promise.all([getHFTResult(), getHFTThreshold()]).then(res => setIsLoading(false));
    };

    useEffect(() => {
        getAllData();

        const interval = setInterval(getAllData, 1000 * 60);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="hft-report-container">
            <CardBox title={"HFT Report"}>
                <div className="main-container">
                    <Segmented
                        className="tab-container"
                        value={currentTab}
                        options={segmentChild.map((item: any) => item.title)}
                        onChange={(activeKey: any) => setCurrentTab(activeKey)}
                    />
                    <div className="tab-body">
                        {segmentChild.map((child, index) => {
                            return (
                                <div className={currentTab === child.title ? "" : "hide"} key={index}>
                                    {child.component}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </CardBox>
        </div>
    );
};

export default HFTReport;

function decimalFormat(value: number, fixed: number) {
    return value.toFixed(fixed).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

function formatFixedAndTrimZero(value: number | null | undefined, fixed: number) {
    if (value) {
        return decimalFormat(value, fixed);
    }
    return "";
}

function percentFormat(value: number) {
    return (value * 100).toFixed(2) + "%";
}
