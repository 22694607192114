import useRCRejectOrderServers from "@/hooks/useRCRejectOrderServers";
import { APIs } from "@/services/apis";
import { getToken } from "@/services/localStorage";
import { DownloadOutlined } from "@ant-design/icons";
import { DatePicker, Form, Typography, notification, Modal, Row, Col, Select } from "antd";
import axios from "axios";
import moment from "moment";
import Loader from "../components/Loader";
import { useEffect } from "react";
import { getFileNameFromResponseHeader } from "@/utils/Common";

const { Item } = Form;
const { Text } = Typography;
const { RangePicker } = DatePicker;

interface DownloadRejectOrderModalProps {
    open: boolean;
    handleModalOpen: (open: boolean) => void;
}

const initValues = {
    server: null,
    range: null,
};

const DownloadRejectOrderModal = (props: DownloadRejectOrderModalProps) => {
    const { rcRejectOrderServers, isLoading } = useRCRejectOrderServers();
    const [form] = Form.useForm();

    const serverOptions = rcRejectOrderServers?.map((each: any) => ({ label: each, value: each })) ?? [];

    const handleSubmit = async (values: any) => {
        const token = getToken();
        const startTime = moment(values.range[0]).format("YYYY-MM-DD") + " 00:00";
        const endTime = moment(values.range[1]).format("YYYY-MM-DD") + " 23:59";
        const server = values.server;
        axios
            .get(
                `${process.env.REACT_APP_API_URL}${APIs.RC_REJECT_ORDER.GET_REJECT_ORDER_DOWNLOAD}?startTime=${startTime}&endTime=${endTime}&server=${server}`,
                {
                    headers: {
                        Accept: "application/octet-stream, */*",
                        // "Content-type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: "blob",
                }
            )
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                const fileName = getFileNameFromResponseHeader(response, `reject_order_${server}_${startTime}_${endTime}.xlsx`);
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    notification.success({
                        message: "Downloaded",
                        description: `Reject Order report for server ${server} from ${startTime} to ${endTime} downloaded successfully`,
                    });
                    props.handleModalOpen(false);
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
                console.log("download error", err);
            });
    };

    useEffect(() => {
        if (props.open) {
            form.setFieldsValue(initValues);
        }
    }, [props.open]);

    return (
        <Modal
            title="Download Reject Order"
            okText={"Download"}
            okButtonProps={{
                icon: <DownloadOutlined />,
            }}
            open={props.open}
            onOk={() => form.submit()}
            onCancel={() => props.handleModalOpen(false)}
        >
            {isLoading && !rcRejectOrderServers ? (
                <Loader />
            ) : (
                <Form form={form} layout="vertical" initialValues={initValues} requiredMark={true} onFinish={handleSubmit}>
                    <Row gutter={[8, 8]}>
                        <Col span={8} xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 8 }}>
                            <Text>Server *</Text>
                            <Item style={{ margin: 0, padding: 0 }} name="server" rules={[{ required: true, message: "Please select server" }]}>
                                <Select
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder="Select Servers"
                                    options={serverOptions}
                                    showSearch
                                    onChange={data => {
                                        form.setFieldsValue({ server: data });
                                    }}
                                />
                            </Item>
                        </Col>
                        <Col span={16} xs={{ span: 24 }} md={{ span: 16 }}>
                            <Text>Date Range *</Text>
                            <Item
                                style={{ margin: 0, padding: 0 }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                required
                                name="range"
                                rules={[{ required: true, message: "Please select date range" }]}
                            >
                                <RangePicker
                                    style={{ width: "100%" }}
                                    // following the same logic as RC's
                                    // disable selecting the day after today, and 20 days before today
                                    // disabledDate={current => {
                                    //     return current && (current > moment().endOf("day") || current < moment().subtract(20, "days").startOf("day"));
                                    // }}
                                    onChange={data => {
                                        form.setFieldsValue({ range: data });
                                    }}
                                />
                            </Item>
                        </Col>
                    </Row>
                </Form>
            )}
        </Modal>
    );
};

export default DownloadRejectOrderModal;
