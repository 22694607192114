import { useCallback, useEffect, useState } from "react";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair, GroupTransferToolUserInfoProps } from "../../../../../constants/type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../../constants";
import moment from "moment";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { APIs } from "../../../../../services/apis";
import { REQUIRED_FIELD } from "../../../../../constants/errorMessage";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "../../../../../utils/Common";
import { isEmptyOrNull } from "../../../../../utils/string";
import LoadingComponent from "../../../../../components/Loading";
import message from "antd/lib/message";
import HistoricalGroupTransferRecordModal, { HistoricalGroupTransferRecordModalCallbackKey } from "./historicalGroupTransferToolRecordModal";
import { Modal } from "antd";

export interface HistoricalGroupTransferRecordProps {
    reasonOptions: KeyValuePair[];
}

const HistoricalGroupTransferRecord = (props: HistoricalGroupTransferRecordProps) => {
    const [firstLoading, setFirstLoading] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [data, setData] = useState<GroupTransferToolUserInfoProps[]>([]);
    const [filterParams, setFilterParams] = useState<any>({});
    const [defaultDate, setDefaultDate] = useState<string>("");
    const [isExecutionModalVisible, setIsExecutionModalVisible] = useState<boolean>(false);
    const [currentEditData, setCurrentEditData] = useState<GroupTransferToolUserInfoProps | undefined>(undefined);

    const exportAsExcel = useCallback(() => {
        try {
            plainAxiosInstance
                .get(
                    `${plainAxiosInstance.defaults.baseURL}${APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_EXPORT_EXCEL}?startDate=${filterParams.startDate}&endDate=${filterParams.endDate}`,
                    {
                        headers: {
                            Accept: "application/octet-stream, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const fileName = getFileNameFromResponseHeader(response, `GroupTransferToolRecord_${moment().format("YYYYMMDDHHmmss")}.xlsx`);
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) => ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED));
        } catch (e: any) {
            ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    }, [filterParams]);

    const columns = [
        {
            title: "Date",
            dataIndex: "dateRange",
            key: "dateRange",
            options: {
                visible: false,
                filter: {
                    type: ComponentType.daterange,
                    value: [],
                    rules: [{ required: true, message: REQUIRED_FIELD }],
                    inputProps: {
                        dateOnly: true,
                        allowClear: false,
                    },
                },
            },
        },
        DTColProps.Small({
            title: "Server",
            dataIndex: "server",
            key: "server",
        }),
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        {
            title: "From Group",
            dataIndex: "fromGroup",
            key: "fromGroup",
        },
        {
            title: "To Group",
            dataIndex: "toGroup",
            key: "toGroup",
        },
        {
            title: "Change Reason",
            dataIndex: "changeReason",
            key: "changeReason",
        },
        {
            title: "Front Desk",
            dataIndex: "changeReasonFrontDesk",
            key: "changeReasonFrontDesk",
        },
        DTColProps.Small({
            title: "Operation Time",
            dataIndex: "operationTime",
            key: "operationTime",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        edit: true,
        separateActionButton: true,
        export: {
            text: "Download Report",
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "dateRange") {
                            filterParams.startDate = moment(FormData[x][0]).format("YYYY-MM-DD");
                            filterParams.endDate = moment(FormData[x][1]).format("YYYY-MM-DD");
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.DO_EDIT:
                let tmp: GroupTransferToolUserInfoProps = { ...FormData } as GroupTransferToolUserInfoProps;
                if (tmp.changeReasonFrontDesk === "undefined") {
                    tmp.changeReasonFrontDesk = "";
                }
                if (tmp.changeReason === "undefined") {
                    tmp.changeReason = "";
                }
                setCurrentEditData(tmp);
                setIsExecutionModalVisible(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                exportAsExcel();
                break;
            default:
                break;
        }
    };

    const confirmEditRecord = (dataValues: any) => {
        Modal.confirm({
            title: "Update Confirmation",
            content: "Are you sure want to update the group transfer record?",
            onOk: () => {
                setIsLoading(true);
                plainAxiosInstance
                    .post(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_EDIT_RECORD, dataValues)
                    .then((res: any) => {
                        if (res.data) {
                            ErrorMessageHandler(`Group transfer record updated successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                            setRunRefetchDataList(true);
                        } else {
                            ErrorMessageHandler(`Group transfer record updated failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("group transfer tool record", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                        )
                    )
                    .finally(() => setIsLoading(false));
            },
        });
    };

    const getRecordList = useCallback(() => {
        const formData = new FormData();
        formData.append("startDate", filterParams.startDate);
        formData.append("endDate", filterParams.endDate);

        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_GTTR, formData)
            .then((res: any) => {
                if (res.data) {
                    setData(res.data.map((x: GroupTransferToolUserInfoProps) => ({ ...x, keyId: `${x.server}-${x.login}-${x.operationTime}` })));
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("group transfer tool record", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [filterParams]);

    const getDefaultDate = () => {
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_GETTODAYDEALDATE)
            .then((res: any) => {
                if (res.data) {
                    setDefaultDate(res.data);
                    setFilterParams({ startDate: res.data, endDate: res.data });
                    setRunRefetchDataList(true);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("group transfer tool date", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setFirstLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getRecordList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        getDefaultDate();
    }, []);

    return (
        <>
            <div className="group-transfer-tool-record-container">
                {firstLoading ? (
                    <LoadingComponent />
                ) : (
                    <>
                        <FlexiDataTable
                            rowKeyProperty="keyId"
                            bordered
                            title={<span className="flexi-table-small-title-style">Group Transfer Record</span>}
                            columns={columns}
                            dataSource={data}
                            options={options}
                            callback={componentCallback}
                            loading={isLoading}
                            filterInitialValue={{
                                ...(!isEmptyOrNull(defaultDate) && {
                                    dateRange: [moment(`${defaultDate}T00:00:00`), moment(`${defaultDate}T00:00:00`)],
                                }),
                            }}
                        />
                    </>
                )}
            </div>
            <HistoricalGroupTransferRecordModal
                isModalVisible={isExecutionModalVisible}
                modalTitle={"Edit Group Transfer Tool Record"}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case HistoricalGroupTransferRecordModalCallbackKey.Close:
                            setCurrentEditData(undefined);
                            setIsExecutionModalVisible(false);
                            break;
                        case HistoricalGroupTransferRecordModalCallbackKey.FormSubmit:
                            confirmEditRecord(data);
                            setCurrentEditData(undefined);
                            setIsExecutionModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                reasonOptions={props.reasonOptions}
                data={currentEditData}
            />
        </>
    );
};

export default HistoricalGroupTransferRecord;
