import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { Modal, Form, Row, Col, message, Button, Upload } from "antd";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { KeyValuePair } from "@/constants/type";
import { UploadOutlined } from "@ant-design/icons";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { useCallback, useEffect, useState } from "react";
import { ErrorMessageHandler } from "@/utils/Common";

export interface BalanceUpdateUploadModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    serverList: KeyValuePair[];
}

export enum BalanceUpdateUploadModalCallbackKey {
    Close = 0,
    SubmitSuccess = 1,
    SubmitFailed = 2,
    Submitting = 3,
}

const BalanceUpdateUploadModal = (props: BalanceUpdateUploadModalProps) => {
    const [uploadFile, setUploadFile] = useState<any>(undefined);
    const [fileList, setFileList] = useState<any[]>([]);
    const [executeForm] = Form.useForm();

    const confirmExecute = useCallback(
        (serverId: string) => {
            if (uploadFile === undefined) {
                ErrorMessageHandler(`Please upload any file.`, SUCCESS_FAILED.OTHERS_FAILED);
                return;
            }

            props.callback(BalanceUpdateUploadModalCallbackKey.Close, null);

            Modal.confirm({
                title: "Are you sure you want to update Balance Upload?",
                onOk: () => {
                    props.callback(BalanceUpdateUploadModalCallbackKey.Submitting, null);

                    var formData = new FormData();
                    formData.append("uploadFile", uploadFile);

                    plainAxiosInstance
                        .post(`${APIs.RISK_TOOL.GET_BALANCE_UPDATE_UPLOAD_File}/${serverId}`, formData)
                        .then(res => {
                            if (res.status === 200 && res.data.indexOf("BalanceUpload") === -1) {
                                ErrorMessageHandler("File upload successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                                props.callback && props.callback(BalanceUpdateUploadModalCallbackKey.SubmitSuccess, `${serverId},${res.data}`);
                            } else {
                                ErrorMessageHandler(`${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                                props.callback && props.callback(BalanceUpdateUploadModalCallbackKey.SubmitFailed, null);
                            }
                        })
                        .catch((error: any) => {
                            ErrorMessageHandler(`File upload failed: (${error.response.data.msg}).`, SUCCESS_FAILED.OTHERS_FAILED);
                            props.callback && props.callback(BalanceUpdateUploadModalCallbackKey.SubmitFailed, null);
                        });
                },
            });
        },
        [uploadFile, props]
    );

    const handleSubmit = (file: any, fileList: any) => {
        let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
            isLt5M = file.size / 1024 / 1024 < 5;

        if (fileExtension[0] !== ".xlsx") {
            ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        } else if (!isLt5M) {
            ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        }

        setUploadFile(file);
        setFileList(fileList);
    };

    useEffect(() => {
        if (props.isModalVisible) {
            setUploadFile(undefined);
            setFileList([]);
            executeForm.setFieldsValue({ selectedServer: "" });
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"25vw"}
            title="Upload Balance Update"
            open={props.isModalVisible}
            okText="Execute"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        confirmExecute(values.selectedServer);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(BalanceUpdateUploadModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 8 }}>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Server"
                            name="selectedServer"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.serverList,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={8} style={{ textAlign: "right", paddingRight: "8px", display: "flex", justifyContent: "flex-end", marginTop: "4px" }}>
                        Upload File:
                    </Col>
                    <Col span={14}>
                        <Upload
                            {...{
                                name: "file",
                                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                                multiple: false,
                                fileList: fileList,
                                showUploadList: {
                                    showRemoveIcon: false,
                                    showPreviewIcon: false,
                                    showDownloadIcon: false,
                                },
                                onChange: (info: any) => {
                                    if (info.file.status === "error") {
                                        ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                                    }
                                },
                                customRequest: ({ file, onSuccess }: any) =>
                                    setTimeout(() => {
                                        onSuccess("ok");
                                    }, 0),
                                beforeUpload: (file: any, fileList: any) => handleSubmit(file, fileList),
                            }}
                        >
                            <Button htmlType="button" icon={<UploadOutlined style={{ fontSize: "0.875rem" }} />} style={{ width: "auto" }}>
                                Upload File
                            </Button>
                        </Upload>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default BalanceUpdateUploadModal;
