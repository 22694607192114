import { useEffect, useMemo, useState } from "react";
import { Input, Select, Tabs } from "antd";
import PnlTab from "./PnlTab";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";
import LoadingComponent from "@/components/Loading";
import { ClientAnalysisParams } from "@/constants/type";
import AssociationTab from "./AssociationTab";
import KlineTab from "./KlineTab";

interface ServersList {
    value: number;
    name: string;
    metaTraderVersion: number;
}

const ClientAnalysis = () => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [selectedServer, setSelectedServer] = useState<string>("");
    const [loginSearch, setLoginSearch] = useState<string>("");
    const [params, setParams] = useState<ClientAnalysisParams>({ server: "", login: "", currency: "" });
    const [isUserExist, setIsUserExist] = useState<boolean>(false);
    const [servers, setServers] = useState<ServersList[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);

    const tabItems = useMemo(
        () => [
            {
                label: `PNL`,
                key: "1",
                children: <PnlTab params={params} isInitialLoading={isLoading} />,
            },
            {
                label: `Association`,
                key: "2",
                children: <AssociationTab params={params} isInitialLoading={isLoading} />,
            },
            {
                label: `K-Line`,
                key: "3",
                children: <KlineTab params={params} isInitialLoading={isLoading} />,
            },
        ],
        [params, isLoading]
    );

    const getClient = () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("server", selectedServer);
        formData.append("login", loginSearch);
        plainAxiosInstance
            .post(`${APIs.RC_CLIENT_ANALYSIS.POST_QUERY_CLIENT_EXIST}`, formData)
            .then((res: any) => {
                if (res.data) {
                    Promise.all([getUserCurrency(formData), setIsUserExist(true)]);
                } else {
                    ErrorMessageHandler(
                        `User ${loginSearch} does not exist or have been archived in ${selectedServer} server.`,
                        SUCCESS_FAILED.OTHERS_FAILED
                    );
                    setIsUserExist(false);
                    setIsLoading(false);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("user data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setIsUserExist(false);
                    setIsLoading(false);
                })
            );
    };

    const getUserCurrency = (formData: any) => {
        plainAxiosInstance
            .post(`${APIs.RC_CLIENT_ANALYSIS.POST_GET_USER_CURRENCY}`, formData)
            .then((res: any) => {
                setParams({
                    server: selectedServer,
                    login: loginSearch,
                    currency: res.data,
                });
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("user data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                })
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        plainAxiosInstance
            .get(`${APIs.RC_CLIENT_ANALYSIS.GET_SERVERS}`)
            .then((res: any) => {
                if (res.status === 200) {
                    setServers(res.data);
                    setSelectedServer(res.data[0].name);
                } else setServers([]);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setServers([]);
                })
            )
            .finally(() => setIsFilterLoaded(true));
        return () => {};
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            if (loginSearch !== "") {
                let login = parseInt(loginSearch);
                if (typeof login === "number" && login > 0 && login <= 2147483647) {
                    getClient();
                } else {
                    ErrorMessageHandler(
                        `User login input must be a number between 1 and 2147483647. Please enter a valid login number.`,
                        SUCCESS_FAILED.OTHERS_FAILED
                    );
                }
            }
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <div className="client-analysis-container">
            <div className="big-title">Client Analysis</div>
            <LoadingComponent tip="Loading filters..." spinning={!isFilterLoaded}>
                <div className="filter-bar shadow-1">
                    <Input.Group compact>
                        <Select
                            showSearch
                            placeholder={`Please select server`}
                            onChange={(value: any) => {
                                setSelectedServer(value);
                            }}
                            options={servers.map((x: ServersList) => ({ label: x.name, value: x.name }))}
                            value={selectedServer}
                            style={{ width: 300 }}
                        />
                        <Input.Search
                            type="text"
                            name="search"
                            style={{ width: 500 }}
                            allowClear
                            autoComplete="off"
                            onChange={(e: any) => setLoginSearch(e.currentTarget.value)}
                            onSearch={() => setRunRefetchDataList(true)}
                        />
                    </Input.Group>
                </div>
                <div className="main-content">
                    {isLoading ? (
                        <div className="loading-container">
                            <LoadingComponent tip="Loading..." />
                        </div>
                    ) : isUserExist ? (
                        <Tabs type="card" defaultActiveKey="1" destroyInactiveTabPane tabBarStyle={{ marginBottom: "0" }} items={tabItems} />
                    ) : null}
                </div>
            </LoadingComponent>
        </div>
    );
};

export default ClientAnalysis;
