import FlexiDataTable from "@/components/FlexiDataTable";
import { InnerPageActionMode, ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { CreateEditInlineFormBased, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { DTColProps, ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { useState, useEffect } from "react";
import MRServerEditPage from "./components/editPage";

interface MRServerSettingsProps {}

const MRServerSettings = (props: MRServerSettingsProps) => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dataList, setDataList] = useState<any[]>([]);
    const [refetch, setRefetch] = useState<number>(0);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_MR_GROUP_SETTINGS_EDIT);

    const columns = [
        DTColProps.Large({
            title: "Server Group Name",
            dataIndex: "name",
            key: "name",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        DTColProps.Status({
            title: "Status",
            dataIndex: "isEnabled",
            key: "isEnabled",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Active", value: true },
                        { text: "Inactive", value: false },
                    ],
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        edit: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, rowData: any) => {
        switch (type) {
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: rowData["id"] });
                setRefetch(prev => prev + 1);
                break;
            default:
                break;
        }
    };

    const getMRServerGroupListing = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_MR_SERVER_LIST, {})
            .then((res: any) => {
                setDataList(res && res.length > 0 ? res : []);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("MR server grouping list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getMRServerGroupListing();
        return () => {};
    }, []);

    return (
        <>
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="id"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={dataList}
                        callback={componentCallback}
                        loading={isLoading}
                    />
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    <MRServerEditPage
                        {...formObject}
                        callback={(action: InnerPageActionMode, value: any) => {
                            switch (action) {
                                case InnerPageActionMode.BACK:
                                    setIsFirstOpt(prev => !prev);
                                    if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                        getMRServerGroupListing();
                                    }
                                    break;
                            }
                        }}
                        resetState={refetch}
                    />
                </div>
            </div>
        </>
    );
};

export default MRServerSettings;
