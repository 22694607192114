import { Modal, Form } from "antd";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType } from "../../../../../constants";
import { KeyValuePair } from "../../../../../constants/type";
import { useEffect } from "react";
import { REQUIRED_FIELD } from "../../../../../constants/errorMessage";
import { isEmptyOrNull } from "@/utils/string";

export interface GroupTransferModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    reasonOptions: KeyValuePair[];
}

export enum GroupTransferModalCallbackKey {
    Close = 0,
    FormSubmit = 1,
}

const GroupTransferModal = (props: GroupTransferModalProps) => {
    const [executeForm] = Form.useForm();

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldsValue({ changeReason: "", changeReasonFrontDesk: "" });
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={"Group Transfer Reason Form"}
            open={props.isModalVisible}
            okText="Submit"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        if (values.hasOwnProperty("changeReasonFrontDesk") && isEmptyOrNull(values.changeReasonFrontDesk)) {
                            delete values.changeReasonFrontDesk;
                        }
                        props.callback(GroupTransferModalCallbackKey.FormSubmit, values);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(GroupTransferModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}>
                <FormComponent
                    label="Change Reason"
                    name="changeReason"
                    extra={{
                        type: ComponentType.text,
                        value: "",
                        //rules: [{ required: true, message: REQUIRED_FIELD }],
                    }}
                />
                <FormComponent
                    label="Front Desk"
                    name="changeReasonFrontDesk"
                    extra={{
                        type: ComponentType.dropdown,
                        value: props.reasonOptions,
                        //rules: [{ required: true, message: REQUIRED_FIELD }],
                    }}
                />
            </Form>
        </Modal>
    );
};

export default GroupTransferModal;
