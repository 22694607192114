import LoadingComponent from "@/components/Loading";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { useState } from "react";
import EventSummaryProfile from "./components/EventSummaryProfile";
import HolidayNotice from "./components/HolidayNotice";

const DashboardPage = () => {
    const [isManualLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [runRefetchDataList2, setRunRefetchDataList2] = useState<boolean>(false);
    const [showHistoricalEventModule, setShowHistoricalEventModule] = useState<boolean>(false);

    const authHp = new AuthHelper();
    const atleastOneModule = authHp.getAuthModuleKeys().length > 0;
    const monitorProfileAccess = authHp.isAuthorized(AuthKeys.MONITOR_TRADE_VIEW);
    //const historicalEventAccess = authHp.isAuthorized(AuthKeys.HISTORICAL_EVENT_BATCHES_VIEW);

    // const componentCallback: FlexiDataTableCallbackProps = (type, FormData: any) => {
    //     switch (type) {
    //         case CALLBACK_KEY.REFRESH:
    //             setRunRefetchDataList(true);
    //             setRunRefetchDataList2(true);
    //             break;
    //         case CALLBACK_KEY.OTHERS:
    //             switch (FormData["key"]) {
    //                 case DASHBOARD_MODULES.HISTORICAL_EVENTS:
    //                     setShowHistoricalEventModule(false);
    //             }
    //             break;
    //     }
    // };

    return (
        <div className="dashboard-container">
            {isManualLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <>
                    {/* <div className="content-card">
                   {monitorProfileAccess && (
                        <>
                            <div className="timer">
                                    <TimerButton
                                        key={`btn-refresh-timer-${Math.random()}`}
                                        period={Number(60)}
                                        enablePeriodSelection={false}
                                        onFinish={(type: CALLBACK_KEY, period: number) => {
                                            componentCallback(CALLBACK_KEY.REFRESH, { currentRefreshPeriod: period });
                                        }}
                                    />
                                </div>
                            <MonitorProfileSummary runRefetchDataList={runRefetchDataList2} setRunRefetchDataList={setRunRefetchDataList2} />
                            {historicalEventAccess && showHistoricalEventModule && (
                                    <DashboardHistoricalTrade
                                        runRefetchDataList={runRefetchDataList}
                                        setRunRefetchDataList={setRunRefetchDataList}
                                        componentCallback={componentCallback}
                                    />
                                )}
                        </>
                    )} </div>*/}
                    <div>
                        <div className="header-title">Dashboard</div>
                        <div className="main-content">
                            <div className="left">{monitorProfileAccess && <EventSummaryProfile />}</div>
                            <div className="right">{atleastOneModule && <HolidayNotice />}</div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default DashboardPage;
