import React, { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "../../../utils/Common";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { CustomPaginationProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "../../../constants/type";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import LoadingComponent from "../../../components/Loading";
import { isEmptyOrNull } from "../../../utils/string";
import { CommissionRecordDataRow } from "./type";
import { Button, message, Modal, Table } from "antd";
import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { REQUIRED_FIELD } from "@/constants/errorMessage";

const FailedRecords = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.COMMISSION_RECORD_EDIT);

    const [data, setData] = useState<CommissionRecordDataRow[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [servers, setServers] = useState<KeyValuePair[]>([{ text: "All", value: 0 }]);
    const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({
        logins: "",
        serverId: 0,
        status: 3,
        tickets: "",
    });
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

    const columns = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Server",
                dataIndex: "serverId",
                key: "serverId",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    },
                },
                fixed: "left",
                render: (_: number, rowData: CommissionRecordDataRow) => rowData.serverName,
            }),
            DTColProps.XSmall({
                title: "Ticket",
                dataIndex: "ticket",
                key: "ticket",
                fixed: "left",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                        text: "Deal",
                    },
                },
            }),
            DTColProps.XSmall({
                title: "Login",
                dataIndex: "login",
                key: "login",
                fixed: "left",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            DTColProps.Small({
                title: "Group",
                dataIndex: "group",
                key: "group",
            }),
            DTColProps.Middle({
                title: "Open Time",
                dataIndex: "openTimeStr",
                key: "openTimeStr",
                width: "10vw",
            }),
            DTColProps.Middle({
                title: "Close Time",
                dataIndex: "closeTimeStr",
                key: "closeTimeStr",
                width: "10vw",
            }),
            DTColProps.XSmall({
                title: "Side",
                dataIndex: "typeStr",
                key: "typeStr",
            }),
            DTColProps.Small({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
            }),
            DTColProps.XSmall({
                title: "Lots",
                dataIndex: "volume",
                key: "volume",
            }),
            DTColProps.Small({
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
            }),
            DTColProps.Small({
                title: "Commission",
                dataIndex: "commission",
                key: "commission",
            }),
            DTColProps.Middle({
                title: "Update Time",
                dataIndex: "rateTimeStr",
                key: "rateTimeStr",
                width: "10vw",
            }),
            DTColProps.Small({
                title: "Operator",
                dataIndex: "operatorName",
                key: "operatorName",
            }),
        ],
        [servers]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            export: {
                text: "",
                Element: (
                    <Button
                        key={"export"}
                        icon={<DownloadOutlined />}
                        style={{ marginLeft: 10 }}
                        loading={isExporting}
                        onClick={() => downloadFailedRecords(filterParams)}
                    >
                        Export as CSV
                    </Button>
                ),
            },
            ...(enableUpdate && {
                extraButtons: () => {
                    return (
                        <Button
                            key={"rccrfd-batch-del"}
                            icon={<DeleteOutlined />}
                            style={{ marginLeft: 10 }}
                            loading={isDeleting}
                            disabled={selectedRowKeys.length === 0}
                            onClick={batchDeleteRecords}
                        >
                            Batch Delete
                        </Button>
                    );
                },
                enableRowSelection: true,
                rowSelectionData: {
                    rowSelectionType: "checkbox",
                    selectedRowKeys: selectedRowKeys,
                    options: {
                        fixed: "left",
                        selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
                        preserveSelectedRowKeys: true,
                    },
                },
            }),
            hideRowSelectionsSummary: true,
            serverFiltering: true,
        }),
        [isExporting, selectedRowKeys, isDeleting, filterParams, enableUpdate]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "login") {
                            fParams["logins"] = FormData[x];
                        } else if (x === "ticket") {
                            fParams["tickets"] = FormData[x];
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowKeys(FormData.selectedRowKeys);
                break;
            default:
                break;
        }
    };

    const downloadFailedRecords = useCallback(
        (fParams: any) => {
            let serverId = isEmptyOrNull(fParams.serverId) ? 0 : fParams.serverId;
            let tickets = isEmptyOrNull(fParams.tickets) ? "" : fParams.tickets;
            let logins = isEmptyOrNull(fParams.logins) ? "" : fParams.logins;
            setIsExporting(true);
            plainAxiosInstance
                .get(`${APIs.RC_COMMISSION_RECORDS.GET_DOWNLOAD_FAILED_REPORT_CSV}?serverId=${serverId}&tickets=${tickets}&logins=${logins}`, {
                    headers: {
                        Accept: "application/octet-stream, */*",
                    },
                    responseType: "blob",
                })
                .then(response => {
                    let thisServer = servers.find(x => x.value === fParams.serverId);
                    const fileName = getFileNameFromResponseHeader(response, `CommissionFailRecord_${thisServer?.text}__${moment().format("x")}.csv`);
                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        // Handle the file download response
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName); // or any other extension
                        link.setAttribute("type", "hidden");
                        document.body.appendChild(link);
                        link.click();
                        if (link.parentNode) {
                            link.parentNode.removeChild(link); // Clean up and remove the link
                        } else document.body.removeChild(link);
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch(err => {
                    ErrorMessageHandler(`Download error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
                })
                .finally(() => setIsExporting(false));
        },
        [servers]
    );

    const batchDeleteRecords = () => {
        Modal.confirm({
            title: "Are you sure you want to delete these records?",
            width: "30%",
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                setIsDeleting(true);
                setIsLoading(true);
                plainAxiosInstance
                    .delete(`${APIs.RC_COMMISSION_RECORDS.DELETE_BATCH_DETAILED_COMMISSION_DATA}?id=${selectedRowKeys.join(",")}`, {})
                    .then((res: any) => {
                        if (res.data === 0) {
                            ErrorMessageHandler("Failed records", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            setSelectedRowKeys([]);
                            setRunRefetchDataList(true);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("failed records", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => setIsDeleting(false));
            },
            onCancel: () => {},
        });
    };

    const getConfig = () => {
        plainAxiosInstance
            .get(`${APIs.RC_COMMISSION_RECORDS.GET_SERVERS}`)
            .then((res: any) => {
                if (res.status === 200) {
                    let initialServersList: KeyValuePair[] = [...servers];
                    let serversList = res.data.map((x: any) => ({ value: x.id, text: x.name }));
                    setServers(initialServersList.concat(serversList));
                    setRunRefetchDataList(true);
                } else {
                    setServers([]);
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsFilterLoaded(true));
    };

    const getFailedRecords = useCallback(() => {
        let current = pagination.current === undefined ? 1 : pagination.current;
        let pageSize = pagination.pageSize === undefined ? 10 : pagination.pageSize;
        let params = {
            limit: pagination.pageSize,
            offset: (current - 1) * pageSize,
            entity: {
                serverId: isEmptyOrNull(filterParams.serverId) ? 0 : filterParams.serverId,
                tickets: isEmptyOrNull(filterParams.tickets) ? "" : filterParams.tickets,
                logins: isEmptyOrNull(filterParams.logins) ? "" : filterParams.logins,
                status: 3,
            },
        };
        plainAxiosInstance
            .post(`${APIs.RC_COMMISSION_RECORDS.POST_DETAILED_COMMISSION_DATA}`, params)
            .then((res: any) => {
                if (res.status === 200) {
                    setData(res.data.rows);
                    setPagination(prev => ({ ...prev, total: res.data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("failed records", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                })
            )
            .finally(() => setIsLoading(false));
    }, [pagination, filterParams]);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getFailedRecords();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
        return () => {};
    }, []);

    return (
        <div className="failed-records-tab">
            <LoadingComponent tip="Loading filters..." spinning={!isFilterLoaded}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                    filterInitialValue={{
                        serverId: 0,
                        ticket: "",
                        login: "",
                    }}
                    serverSide={true}
                    pagination={pagination}
                />
            </LoadingComponent>
        </div>
    );
};

export default FailedRecords;
