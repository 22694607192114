import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Form, Button, Tooltip, Select } from "antd";
import moment from "moment";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../../../../constants";
import { FormInstance } from "antd/es/form";
import { KeyValuePair } from "../../../../../constants/type";
import { useCallback, useEffect, useState } from "react";
import { APIs } from "../../../../../services/apis";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { ErrorMessageHandler, getFileNameFromResponseHeader } from "../../../../../utils/Common";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "@/utils/string";
import { ConfigDiffRecordDataProps } from "./type";

export interface FilterFormComponentProps {
    KeyStr: string;
    currentTabKey: string;
    form: FormInstance;
    servers: KeyValuePair[];
    callback: (type: number, data: any) => void;
    dbName: string;
    tbName: string;
    configType: string;
    initialFilterParams: any;
    dropdownSelections: KeyValuePair[];
    configDiffRecordData: ConfigDiffRecordDataProps[];
};

export enum FilterFormComponentCallbackKey {
    FormSubmit = 1,
    DropdownSelection = 2,
};

const FilterFormComponent = (props: FilterFormComponentProps) => {
    const [filterParams, setFilterParams] = useState<any>(props.initialFilterParams);
    const [currentSelected, setCurrentSelected] = useState<string | number | boolean>("");
    const [isExporting, setIsExporting] = useState<boolean>(false);

    const exportCSV = useCallback(() => {
        setIsExporting(true);
        try {
            let reqQuery = `?configType=${props.configType}&dbName=${encodeURIComponent(props.dbName)}&tbName=${encodeURIComponent(props.tbName)}&startDate=${encodeURIComponent(filterParams.startDate)}&endDate=${encodeURIComponent(filterParams.endDate)}`;
            plainAxiosInstance
                .get(`${APIs.RISK_TOOL.GET_CONFIG_DIFF_RECORD_LIST_DOWNLOAD}${!isEmptyOrNull(filterParams.server) ? reqQuery += `&server=${encodeURIComponent(filterParams.server)}` : reqQuery}`, { responseType: "blob" })
                .then(response => {
                    const fileName = getFileNameFromResponseHeader(
                        response,
                        `ConfigDiff_${props.KeyStr}_${!isEmptyOrNull(filterParams.server) ? `${filterParams.server}` : "ALL"}_${defaultIfEmptyOrNull(filterParams.startDate, "")}_${defaultIfEmptyOrNull(filterParams.endDate, "")}.xlsx`
                    );
                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(response.data);
                        const link = document.createElement("a");
                        link.setAttribute("href", url);
                        link.setAttribute("download", (`${fileName}`));
                        link.setAttribute("type", "hidden");
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        link.parentNode?.removeChild(link);
                        setTimeout(() => {
                            window.URL.revokeObjectURL(url);
                        }, 100);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) => ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED))
                .finally(() => setIsExporting(false));
        } catch (e: any) {
            ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
            setIsExporting(false);
        }
    }, [props.KeyStr, filterParams, props.configType, props.dbName, props.tbName]);

    /**
     *  20/1/2025 - Xin
     *  leaving it here just in case they need it again in the future
     */
    // const processDownload = useCallback(() => {
    //     let reqQuery = `?dbName=${encodeURIComponent(props.dbName)}&tbName=${encodeURIComponent(props.tbName)}&startDate=${encodeURIComponent(filterParams.startDate)}&endDate=${encodeURIComponent(filterParams.endDate)}`;
    //     plainAxiosInstance
    //         .get(`${APIs.RISK_TOOL.GET_LATEST_CONFIG_DIFF_RECORD_TOTAL}${!isEmptyOrNull(filterParams.server) ? reqQuery += `&server=${encodeURIComponent(filterParams.server)}` : reqQuery}`)
    //         .then((res: any) => {
    //             if (res.data > 0) {
    //                 exportCSV();
    //             } else {
    //                 message.warning("No data found for the selected filter criteria.", 3);
    //             }
    //         })
    //         .catch((error: any) => {
    //             ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Error occured during download`, SUCCESS_FAILED.OTHERS_FAILED, err))
    //         })
    // }, [filterParams, props.dbName, props.tbName]);

    const onFormFinish = useCallback(() => {
        props.callback && props.callback(FilterFormComponentCallbackKey.FormSubmit, filterParams);
    }, [filterParams]);

    useEffect(() => {
        if (props.dropdownSelections && props.dropdownSelections?.length > 0) {
            setCurrentSelected(props.dropdownSelections[0]?.value);
            props.callback && props.callback(FilterFormComponentCallbackKey.DropdownSelection, props.dropdownSelections[0]?.value);
        };
    }, [props.dropdownSelections]);

    return (
        <>
            <div className="left">
                <Form
                    form={props.form}
                    layout="inline"
                    initialValues={{ hddateRange: [moment().startOf("day").subtract(1, "day"), moment().endOf("day")] }}
                    onValuesChange={(values: any, allValues: any) => {
                        if (!(allValues?.hasOwnProperty("hddateRange") && allValues.hddateRange !== undefined && allValues.hddateRange.length === 2)) {
                            ErrorMessageHandler(`Please select date range.`, SUCCESS_FAILED.OTHERS_FAILED);
                            return;
                        }

                        let hlFilterParams: any = {};
                        Object.keys(allValues)
                            .filter(x => allValues[x] && (Array.isArray(allValues[x]) ? allValues[x].length > 0 : allValues[x].toString().length > 0))
                            .map(x => {
                                if (x === "hdserver") {
                                    hlFilterParams["server"] = allValues[x];
                                } else if (x === "hddateRange") {
                                    hlFilterParams["startDate"] = allValues[x][0].format("YYYY-MM-DD");
                                    hlFilterParams["endDate"] = allValues[x][1].format("YYYY-MM-DD");
                                } else {
                                    hlFilterParams[x] = allValues[x];
                                }
                                return x;
                            });
                        setFilterParams(hlFilterParams);
                    }}
                    onFinish={onFormFinish}
                >
                    <FormComponent
                        label=""
                        name="hdserver"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.servers,
                            inputProps: { placeholder: "All servers", style: { width: "10vw" } },
                            itemProps: {
                                style: { marginRight: "0" },
                            },
                        }}
                    />
                    <FormComponent
                        label=""
                        name="hddateRange"
                        extra={{
                            type: ComponentType.daterange,
                            value: [],
                            dateFormat: "YYYY-MM-DD",
                            inputProps: {
                                dateOnly: true,
                                allowClear: false,
                            },
                            itemProps: {
                                style: { marginRight: "0" },
                            },
                        }}
                    />
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} />
                    <Tooltip title={`Download Config-Diff Records of "${props.KeyStr}"`}>
                        <Button type="primary" icon={<DownloadOutlined />} style={{ marginLeft: 16 }} loading={isExporting} onClick={() => exportCSV()} />
                    </Tooltip>
                </Form>
            </div>
            <div className="right">
                <Select
                    value={currentSelected}
                    dropdownMatchSelectWidth={false}
                    options={props.dropdownSelections}
                    onChange={(value: any) => {
                        setCurrentSelected(value);
                        props.callback && props.callback(FilterFormComponentCallbackKey.DropdownSelection, value);
                    }}
                />
            </div>
        </>
    );
};

export default FilterFormComponent;
