import FixedTimeCountDown from "@/components/Common/CountDown/FixedTimeCountDown";
import { SUCCESS_FAILED } from "@/constants";
import { LPMetricsTrendInterval, MRGroups, KeyValuePair, StatusListResponseProps } from "@/constants/type";
import AuthHelper from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { getProfile } from "@/services/localStorage";
import { ToObjectWithKey } from "@/utils/array";
import { DimensionLevelObjectConfig, ErrorCatchValidator, ErrorMessageHandler, currencyRender, DataTableColumnRender } from "@/utils/Common";
import { SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { Input, Select, Button } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { UserList } from "../IntradayMonitor/Search";
import AlarmBtn from "./components/LPSummary/AlarmBtn";
import AlarmSummaryForLPSummary from "./components/LPSummary/AlarmSummaryForLPSummary";
import LPListing from "./components/LPSummary/LPListing";
import LPSummaryBySymbol from "./components/LPSummary/LPSummaryBySymbol";
import TinySummaryChart from "./components/TinySummaryChart";
import ReportDropdownMenu2 from "./components/LPSummary/ReportDropdownMenu2";

export interface LPMonitorSummaryProps {}

const LPMonitorSummary = (props: LPMonitorSummaryProps) => {
    const [data, setData] = useState<LPMetricsTrendInterval[]>([]);
    const [unresolvedAlarmCount, setUnresolvedAlarmCount] = useState<number>(0);
    const [MarketRiskGroups, setMarketRiskGroups] = useState<MRGroups[]>([]);
    const [SelectedMRGroup, setSelectedMRGroup] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [submitMRGroup, setSubmitMRGroup] = useState<boolean>(false);
    const uPr: any = getProfile();
    const serverTime_UTCOffset: number | undefined = uPr?.hasOwnProperty("stom") ? uPr.stom : undefined;
    const authHp = new AuthHelper();
    const [lpMappingData, setLpMappingData] = useState<any>({
        dimension: {},
        metricName: {},
    });
    const [refetch, setRefetch] = useState<number>(0);
    const [userList, setUserList] = useState<UserList[]>([]);
    const [alarmStatusList, setAlarmStatusList] = useState<any>({});
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [isLpDataLoading, setIsLpDataLoading] = useState<boolean>(true);

    const getSummaryData = () => {
        apiRequest(APIs.GET_METRIC_TREND_INTERVAL, {
            marketGroupIds: SelectedMRGroup,
            historicalTrendInMinutes: 1440,
            frequencyInMinutes: 10,
            viewExposureByAccountLevel: false,
        })
            .then((res: any) => {
                res.lpMetricTrendResults && setData(res.lpMetricTrendResults);
            })
            .finally(() => setIsLpDataLoading(false));
    };

    const getAlarmData = () => {
        apiRequest(APIs.GET_LP_MONITOR_ALARM_LISTING, {
            current: 1,
            limit: 1,
        })
            .then((res: any) => {
                setUnresolvedAlarmCount(res.totalUnresolved);
            })
            .finally(() => setIsLoading(false));
    };

    const getConfigData = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["marketriskgroups", "user"],
        })
            .then((res: any) => {
                setMarketRiskGroups(
                    res.mrGroups.length > 0 ? res.mrGroups.map((x: MRGroups) => ({ label: x.marketGroup, value: x.marketGroupId })) : []
                );
                setUserList(res.users.map((x: any) => ({ text: x.name, value: x.id })));
            })
            .finally(() => setIsLoading(false));
    };

    const getLPConfigData = () => {
        apiRequest(APIs.GET_LP_ALARM_CONFIG, {})
            .then((res: any) => {
                setLpMappingData({
                    dimension: ToObjectWithKey(DimensionLevelObjectConfig("LPDimensionArr"), "value"),
                    metricName:
                        res.length > 0
                            ? ToObjectWithKey(
                                  res.reduce((arr: KeyValuePair[], i: any) => {
                                      arr = arr.concat(
                                          i.dataSources.map((x: any) => ({
                                              text: x.propertyDisplayName,
                                              value: x.propertyName.toLowerCase(),
                                          }))
                                      );
                                      return arr;
                                  }, []),
                                  "value"
                              )
                            : {},
                });
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => console.log(err)))
            .finally(() => setIsLoading(false));
    };

    const getCurrentServerTime = () => {
        let utcOffset = uPr?.hasOwnProperty("stom") ? uPr.stom : undefined;
        return (utcOffset !== undefined ? moment().utcOffset(utcOffset as number) : moment().tz("EET")).format("YYYY-MM-DD HH:mm:ss");
    };

    const getLPAlarmStatus = () => {
        apiRequest(APIs.GET_ALARM_DASHBOARD_STATUS_LIST, {
            clientApps: ["Insight"],
            moduleCodes: ["MR"],
        })
            .then((res: StatusListResponseProps[]) => {
                if (res && res.length > 0) {
                    setAlarmStatusList(
                        res[0].status.map(status => ({
                            value: status.statusCode,
                            text: status.statusDescription,
                        }))
                    );
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm status", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    const syncLpData = () => {
        setIsBtnLoading(true);
        apiRequest(APIs.SYNC_LP_DATA, {})
            .then((res: any) => {
                ErrorMessageHandler(`All LP related data has been synced up successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                setIsLpDataLoading(true);
                getSummaryData();
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler(`Failed to sync up all LP related data`, SUCCESS_FAILED.OTHERS_FAILED, err)
                );
            })
            .finally(() => setIsBtnLoading(false));
    };

    useEffect(() => {
        setSubmitMRGroup(false);
        if (runRefetchDataList) {
            setIsLoading(true);
            setIsLpDataLoading(true);
            getLPAlarmStatus();
            getLPConfigData();
            getConfigData();
            getSummaryData();
            getAlarmData();
            setSubmitMRGroup(true);
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <div className="lp-monitor-summary-container">
            <div className="big-title">Market Risk Monitor - Summary</div>
            <div className="second-bar shadow-1">
                <div className="left">
                    <Input.Group compact>
                        <Select
                            allowClear
                            mode="multiple"
                            placeholder={`Select MR Group`}
                            onChange={(value: any) => {
                                setSelectedMRGroup(value);
                            }}
                            options={MarketRiskGroups}
                            style={{ width: "300px" }}
                        />

                        <Button
                            icon={<SearchOutlined />}
                            onClick={() => {
                                setRunRefetchDataList(true);
                            }}
                        />
                    </Input.Group>
                </div>
                <div className="right">
                    <div className="resync-lp-data-btn">
                        <Button icon={<SyncOutlined />} loading={isBtnLoading} onClick={() => syncLpData()}>
                            Resync LP Data
                        </Button>
                    </div>

                    <div className="hourly-report-container">
                        <ReportDropdownMenu2 />
                    </div>
                </div>
            </div>
            <div className="main-content shadow-1">
                <div className="header-row">
                    <div className="left">
                        {data.length > 0 &&
                            data.map(
                                (x: LPMetricsTrendInterval, idx: number) =>
                                    (x.metric === 0 || x.metric === 1) && (
                                        <TinySummaryChart
                                            key={`mr-main-title-chart-${x.metric}`}
                                            title={x.metricDesc}
                                            value={currencyRender(x.total, 0)}
                                            enableChangePercentage
                                            tinyChartProps={{
                                                data: x.metricTrend.map(y => parseFloat(y.total.toFixed(2))),
                                                customContent: (title: any, data: any) => {
                                                    if (data && data.length > 0) {
                                                        return (
                                                            <div className="tiny-chart-tooltips-custom-container">
                                                                <span className="title">
                                                                    {DataTableColumnRender.DateTime_ServerTime(
                                                                        x.metricTrend[parseInt(data[0].title)].serverDateTime
                                                                    )}
                                                                </span>
                                                                <span>{currencyRender(data[0].value, 2)}</span>
                                                            </div>
                                                        );
                                                    }
                                                    return "";
                                                },
                                            }}
                                        />
                                    )
                            )}
                    </div>
                    <div className="right">
                        <div className="timer-container">
                            <FixedTimeCountDown onFinish={() => setRunRefetchDataList(true)} />
                        </div>
                        <span className="last-refresh-time">Last Refresh Time (Server): {getCurrentServerTime()}</span>
                        <AlarmBtn count={unresolvedAlarmCount} />
                    </div>
                </div>

                <div className="sub-row">
                    <LPSummaryBySymbol isLoading={isLpDataLoading} selectedMRGroup={SelectedMRGroup} />
                    <div className="right-panel">
                        <AlarmSummaryForLPSummary
                            lpMappingData={lpMappingData}
                            isLoading={isLoading}
                            userList={userList}
                            alarmStatusList={alarmStatusList}
                        />
                        <LPListing isLoading={isLpDataLoading} selectedMRGroup={SelectedMRGroup} submitMRGroup={submitMRGroup} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LPMonitorSummary;
