import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Button, Empty, Form, Spin } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CompareItem } from "../index";
import { FormComponent } from "@/components/FormComponent";
import { ARRAY_ACTION_TYPE, GetConstraintKeyList, GetUniqueKeysList, SortList, ToObjectWithKey } from "@/utils/array";
import { isEmptyOrNull } from "@/utils/string";
import { DefaultIfEmpty } from "@/utils/object";

interface FilterBySymbolProps {
    servers: KeyValuePair[];
    onAddCompareItem: (items: CompareItem[]) => void;
}

interface BridgeListProps {
    bridgeName: string;
    serverUno: number;
    serverText: string;
}

interface BridgeInfosProps {
    bridgeOptions: KeyValuePair[];
    bridgeObj: { [key: string]: KeyValuePair[] };
    serverObj: { [key: string]: KeyValuePair };
}

const FilterBySymbol = ({ servers, onAddCompareItem }: FilterBySymbolProps) => {
    const [isFetchingServerBySymbol, setIsFetchingServerBySymbol] = useState<boolean>(false);
    const [bridgeList, setBridgeList] = useState<BridgeListProps[]>([]);
    const [serverList, setServerList] = useState<KeyValuePair[]>([]);

    const [filterForm] = Form.useForm();
    const selectedSymbol: string = Form.useWatch("symbol", filterForm) || "";
    const selectedServers: string[] = Form.useWatch("servers", filterForm) || [];

    const bridgeInfos: BridgeInfosProps = useMemo(() => {
        return {
            bridgeOptions: GetUniqueKeysList(bridgeList, "bridgeName", true).map((x: string) => ({ text: x, value: x })),
            bridgeObj:
                bridgeList.length > 0
                    ? bridgeList.reduce((obj: any, x: BridgeListProps) => {
                          if (obj.hasOwnProperty(x.bridgeName)) {
                              obj[x.bridgeName].push({ text: x.serverText, value: x.serverUno });
                          } else {
                              obj[x.bridgeName] = [{ text: x.serverText, value: x.serverUno }];
                          }
                          return obj;
                      }, {})
                    : {},
            serverObj: ToObjectWithKey(servers, "text"),
        };
    }, [bridgeList, servers]);

    const onAddToList = useCallback(
        () =>
            onAddCompareItem(
                selectedServers.map(item => ({
                    compareServerName: item,
                    compareServer: DefaultIfEmpty(bridgeInfos.serverObj, item, { value: "" }).value,
                    compareSymbol: selectedSymbol,
                }))
            ),
        [bridgeInfos, selectedServers, onAddCompareItem]
    );

    const reCheckedServersBySelectedBridgeName = useCallback(() => {
        let selectedBridgeGroup: string = filterForm.getFieldValue("bridgeGroup") || "",
            serverListFromBridgeList: number[] = bridgeInfos.bridgeObj.hasOwnProperty(selectedBridgeGroup)
                ? bridgeInfos.bridgeObj[selectedBridgeGroup].map(x => x.value as number)
                : [],
            innerServersList = servers
                .filter((x: KeyValuePair) => serverListFromBridgeList.includes(x.value as number))
                .map((x: KeyValuePair) => x.text),
            unCheckServerCount = GetConstraintKeyList(
                serverList.map(x => x.text),
                innerServersList,
                ARRAY_ACTION_TYPE.LEFT_OUTER
            ).length;
        filterForm.setFieldsValue({
            servers: innerServersList,
            ...(unCheckServerCount > 0 && {
                checkAll: false,
            }),
        });
    }, [bridgeInfos, serverList, servers]);

    const refetchServers = (sSymbol: string) => {
        if (isEmptyOrNull(sSymbol)) {
            setServerList([]);
            return;
        }

        setIsFetchingServerBySymbol(true);
        plainAxiosInstance
            .get(`${APIs.RC_LP_PRICE_COMPARE.GET_SERVER_LIST_BY_SYMBOL}/${sSymbol}`)
            .then((res: any) => {
                setServerList(
                    res.data && res.data.length > 0
                        ? SortList(
                              res.data.map((item: any) => ({
                                  text: item.mainServerId,
                                  value: item.mainServerId,
                              })),
                              "text"
                          )
                        : []
                );
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Servers by Symbol", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsFetchingServerBySymbol(false));
    };

    const onFormValueChanged = useCallback(
        (changedValues: any) => {
            if (changedValues.hasOwnProperty("bridgeGroup")) {
                reCheckedServersBySelectedBridgeName();
            }
            if (changedValues.hasOwnProperty("symbol")) {
                refetchServers(changedValues.symbol);
            }
            if (changedValues.hasOwnProperty("checkAll")) {
                if (changedValues.checkAll && !isEmptyOrNull(filterForm.getFieldValue("bridgeGroup") || "")) {
                    filterForm.setFieldValue("bridgeGroup", "");
                }

                filterForm.setFieldsValue({ servers: changedValues.checkAll ? serverList.map(x => x.value) : [] });
            }
            if (changedValues.hasOwnProperty("servers")) {
                let currentIsChecked = filterForm.getFieldValue("checkAll") || false;
                if (
                    GetConstraintKeyList(
                        serverList.map(x => x.value),
                        changedValues.servers,
                        ARRAY_ACTION_TYPE.LEFT_OUTER
                    ).length < 1
                ) {
                    !currentIsChecked && filterForm.setFieldValue("checkAll", true);
                } else {
                    currentIsChecked && filterForm.setFieldValue("checkAll", false);
                }

                if (changedValues.servers === serverList.map(x => x.value)) {
                    filterForm.setFieldValue("checkAll", true);
                }
            }
        },
        [bridgeInfos, serverList, reCheckedServersBySelectedBridgeName]
    );

    const getBridgeListing = () => {
        plainAxiosInstance
            .get(APIs.RC_LP_PRICE_COMPARE.GET_BRIDGE_MAIN_SERVER_SETTING)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    setBridgeList(res.data.map((x: any) => ({ bridgeName: x.bridgeName, serverUno: x.serverUno, serverText: x.serverId })));
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Bridge Server Settings", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    useEffect(() => {
        getBridgeListing();
    }, []);

    return (
        <Form form={filterForm} layout={"horizontal"} onValuesChange={onFormValueChanged}>
            <Button
                type="primary"
                icon={<DoubleRightOutlined />}
                onClick={onAddToList}
                disabled={selectedServers.length === 0}
                className="extra-item-btn"
            >
                Add Selected Server
            </Button>
            <div className="symbol-container">
                <FormComponent
                    label="Symbol"
                    name="symbol"
                    extra={{
                        type: ComponentType.text,
                        value: "",
                        inputProps: {
                            style: { width: 180 },
                            allowClear: true,
                        },
                    }}
                />
                <FormComponent
                    label=""
                    name="checkAll"
                    extra={{
                        type: ComponentType.checkbox,
                        value: "Check All",
                    }}
                />
            </div>
            <div className="radio-group-container">
                <FormComponent label="" name="bridgeGroup" extra={{ type: ComponentType.radio, value: bridgeInfos.bridgeOptions }} />
            </div>
            <div className="servers-checkbox-container">
                <Spin spinning={isFetchingServerBySymbol}>
                    {serverList.length === 0 ? (
                        <Empty
                            description={selectedSymbol === "" ? "Search servers by symbol" : !isFetchingServerBySymbol ? "No servers found" : null}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    ) : (
                        <FormComponent label="" name="servers" extra={{ type: ComponentType.checkboxgroup, value: serverList }} />
                    )}
                </Spin>
            </div>
        </Form>
    );
};

export default FilterBySymbol;
