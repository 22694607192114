import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Space, Table, Typography, notification } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { APIs } from "../../../../services/apis";
import MessageCard from "../../components/MessageCard";
import { getFileNameFromResponseHeader } from "@/utils/Common";

function csvToJson(csv: string) {
    const lines = csv.trim().split("\n");
    const headers = lines[0].split(",");
    const result = [];

    for (let i = 1; i < lines.length; i++) {
        const obj: any = {};
        const currentLine = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentLine[j];
        }

        result.push(obj);
    }

    const json = JSON.stringify(result, null, 2);
    const parsed = JSON.parse(json);
    return parsed;
}

const columns = [
    {
        title: "Date Time",
        dataIndex: "dt",
    },
    {
        title: "Server",
        dataIndex: "serv",
        sorter: (a: any, b: any) =>
            // localecompare
            a.serv.localeCompare(b.serv),
    },
    {
        title: "Message",
        dataIndex: "msg",
        sorter: (a: any, b: any) =>
            // localecompare
            a.msg.localeCompare(b.msg),
    },
    {
        title: "IP",
        dataIndex: "ip",
        sorter: (a: any, b: any) =>
            // localecompare
            a.ip.localeCompare(b.ip),
    },
    {
        title: "Comment",
        dataIndex: "comment",
        sorter: (a: any, b: any) =>
            // localecompare
            a.comment.localeCompare(b.comment),
    },
    {
        title: "Comment User",
        dataIndex: "comment_user",
        sorter: (a: any, b: any) =>
            // localecompare
            a.comment_user.localeCompare(b.comment_user),
        render: (text: string) => <p style={{ wordBreak: "break-word", margin: 0, padding: 0 }}>{text}</p>,
    },
    {
        title: "Comment Time",
        dataIndex: "comment_time",
        sorter: (a: any, b: any) =>
            // localecompare
            a.comment_time.localeCompare(b.comment_time),
    },
    {
        title: "Solved",
        dataIndex: "solved",
        sorter: (a: any, b: any) =>
            // localecompare
            a.solved.localeCompare(b.solved),
    },
    {
        title: "Solved User",
        dataIndex: "solved_user",
        sorter: (a: any, b: any) =>
            // localecompare
            a.solved_user.localeCompare(b.solved_user),
        render: (text: string) => <p style={{ wordBreak: "break-word", margin: 0, padding: 0 }}>{text}</p>,
    },

    {
        title: "Solved Time",
        dataIndex: "solved_time",
        sorter: (a: any, b: any) =>
            // localecompare
            a.solved_time.localeCompare(b.solved_time),
    },
];

const DownloadBalanceAdjustment = () => {
    const { Item } = Form;
    const { Text } = Typography;
    const dateFormat = "YYYY-MM-DD";
    const [reportDate, setReportDate] = useState<string | null>(null);
    const [data, setData] = useState<any>([]);

    const handleDownload = async () => {
        if (!reportDate) {
            return;
        }
        // GET_BALANCE_ADJUSTMENT_DOWNLOAD
        // date=${YYYY-MM-DD}&solvedStaus=0
        const date = moment(reportDate).format("YYYY-MM-DD");
        plainAxiosInstance
            .get(`${APIs.RC_ISSUES_LOG.GET_BALANCE_ADJUSTMENT_DOWNLOAD}?date=${date}`, {
                headers: {
                    Accept: "application/octet-stream, */*",
                },
                // responseType: "blob",
            })
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                const fileName = getFileNameFromResponseHeader(response, `balanceAdjustment_${date}.csv`);
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    console.log("response.data", response.data);
                    const jsonResult = csvToJson(response.data);
                    console.log("jsonResult", jsonResult);
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    notification.success({
                        message: "Downloaded",
                        description: `Balance Adjustment report on ${date} downloaded successfully`,
                    });
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
                console.log("download error", err);
            });
    };

    const handleChangeDate = async (dateString: string) => {
        if (!dateString || !reportDate) {
            setData([]);
        }
        const date = moment(dateString).format("YYYY-MM-DD");
        try {
            const resp = await plainAxiosInstance.get(`${APIs.RC_ISSUES_LOG.GET_BALANCE_ADJUSTMENT_DOWNLOAD}?date=${date}`, {
                headers: {
                    Accept: "application/octet-stream, */*",
                },
                // responseType: "blob",
            });
            console.log("resp", resp);
            if (resp && resp.data) {
                const jsonResult = await csvToJson(resp.data);
                setData(jsonResult);
            }
        } catch (error) {
            console.log("error while change date", error);
            setData([]);
        }
    };

    return (
        <Space style={{ width: "100%", padding: "1rem" }} direction="vertical">
            <Text>Select Date *</Text>
            <Row gutter={[8, 8]}>
                <Col span={8} xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 8 }}>
                    <Item style={{ margin: 0, padding: 0 }} name="dateValue" rules={[{ required: true, message: "Please select a date" }]}>
                        <DatePicker
                            style={{ width: "100%" }}
                            // @ts-ignore
                            dateFormat={dateFormat}
                            showToday={false}
                            disabledDate={
                                // disable the date after today
                                current => current && current > moment().endOf("day")
                            }
                            onChange={(value, dateString) => {
                                console.log(value, dateString);
                                setReportDate(dateString);
                                handleChangeDate(dateString);
                            }}
                            // onChange={onTimePickerChange}
                        />
                    </Item>
                </Col>
                <Col>
                    <Button type="primary" icon={<DownloadOutlined />} onClick={() => handleDownload()} disabled={!reportDate}>
                        Download
                    </Button>
                </Col>
            </Row>
            {reportDate && data.length > 0 ? (
                <>
                    <Text strong>Report for {reportDate}</Text>
                    <MessageCard type="info">
                        {data.length} {data.length > 1 ? "records" : "record"}
                    </MessageCard>
                    <Table dataSource={data || []} columns={columns} size="small" />
                </>
            ) : (
                <MessageCard type="info">No data for this date</MessageCard>
            )}
        </Space>
    );
};

export default DownloadBalanceAdjustment;
