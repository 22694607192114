import { FormComponent } from "@/components/FormComponent";
import { SUCCESS_FAILED, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ToObjectWithKey } from "@/utils/array";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { DefaultIfEmpty } from "@/utils/object";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, InputRef, Modal, Row, Select, Space } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";

type ReadOnlyForm = {
    server?: string;
    login?: string;
    reason?: string;
};
type CreateSettingsModalProps = {
    open: boolean;
    onModalCancel: () => void;
    onModalOk: () => void;
    serversOptions: {
        text: string;
        value: number;
    }[];
};
type GroupReason = {
    label: string;
    value: string;
};

const SetReadOnlyModal = ({ open, onModalCancel, onModalOk, serversOptions }: CreateSettingsModalProps) => {
    const [readOnlyForm] = Form.useForm<ReadOnlyForm>();
    const [groupChangeReason, setGroupChangeReason] = useState<GroupReason[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const onCancelForm = () => {
        readOnlyForm.resetFields();
        onModalCancel();
    };

    const onOkConfirm = useCallback(() => {
        readOnlyForm
            .validateFields()
            .then((values: any) => {
                Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    title: `Are you sure you want to set ReadOnly?`,
                    width: "30%",
                    onOk() {
                        setSubmitting(true);
                        let reasonObj = ToObjectWithKey(groupChangeReason, "value", "label");
                        let formData = new FormData();
                        Object.keys(values).forEach(key => {
                            if (key === "reason") {
                                formData.append(key, DefaultIfEmpty(reasonObj, values[key], values[key]));
                                return;
                            }
                            formData.append(key, values[key]);
                        });

                        plainAxiosInstance
                            .post(APIs.RC_READ_ONLY.CREATE_NEW_READ_ONLY, formData)
                            .then((res: any) => {
                                if (res.data) {
                                    let rData = res.data;
                                    if (rData.code === 0) {
                                        ErrorMessageHandler(rData.message, SUCCESS_FAILED.OTHERS_FAILED);
                                    } else {
                                        if (rData.successNum > 0 && rData.faildNum <= 0) {
                                            ErrorMessageHandler(`${rData.successNum} success.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                                        } else if (rData.successNum > 0 && rData.faildNum > 0) {
                                            ErrorMessageHandler(
                                                `${rData.successNum} success, ${rData.faildNum} failed.`,
                                                SUCCESS_FAILED.OTHERS_SUCCESS
                                            );
                                        } else {
                                            ErrorMessageHandler(
                                                `Notice! This batch of data has been set to read-only or the account is wrong!`,
                                                SUCCESS_FAILED.OTHERS_FAILED
                                            );
                                        }
                                    }
                                } else {
                                    ErrorMessageHandler("ReadOnly", SUCCESS_FAILED.FAILED_CREATE_DATA);
                                }
                            })
                            .catch((error: any) =>
                                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("ReadOnly", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                            )
                            .finally(() => {
                                setSubmitting(false);
                                onModalOk();
                                readOnlyForm.resetFields();
                            });
                    },
                    onCancel() {},
                });
            })
            .catch(() => {});
    }, [groupChangeReason]);

    const getReasons = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_READ_ONLY.GET_READ_ONLY_GROUP_CHANGE_REASON}`)
            .then(res => setGroupChangeReason(res.data && res.data.length > 0 ? res.data.map((x: any) => ({ label: x.reason, value: x.id })) : []))
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("ReadOnly reasons", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getReasons();
    }, []);

    return (
        <Modal
            width="50vw"
            destroyOnClose
            maskClosable={false}
            title={"Add ReadOnly"}
            okButtonProps={{ disabled: submitting, loading: submitting }}
            open={open}
            onCancel={onCancelForm}
            onOk={onOkConfirm}
        >
            <Form form={readOnlyForm} layout="horizontal" labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Server"
                            name="server"
                            extra={{
                                type: ComponentType.dropdown,
                                value: serversOptions,
                                inputProps: {},
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Login"
                            name="logins"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <CustomSelectGroupReasons groupReasons={groupChangeReason} isLoading={isLoading} onReasonAdd={getReasons} />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

type CustomSelectGroupReasonsProps = {
    groupReasons: GroupReason[];
    isLoading: boolean;
    onReasonAdd: () => void;
};

const CustomSelectGroupReasons = ({ groupReasons, isLoading, onReasonAdd }: CustomSelectGroupReasonsProps) => {
    const [inputReason, setInputReason] = useState<string>("");
    const [isAddingReason, setIsAddingReason] = useState<boolean>(false);
    const inputRef = useRef<InputRef | null>(null);

    const addReason = () => {
        Modal.confirm({
            title: "Are you sure you want to add reason?",
            onOk: () => {
                setIsAddingReason(true);
                plainAxiosInstance
                    .get(`${APIs.RC_READ_ONLY.GET_READ_ONLY_GROUP_ADD_REASON}?reason=${inputReason}`)
                    .then(res => {
                        onReasonAdd();
                        setInputReason("");
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new ReadOnly reason", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                    })
                    .finally(() => setIsAddingReason(false));
            },
        });
    };

    return (
        <>
            <Form.Item label={"ReadOnly Reason"} className="form-select" name={"reason"} rules={[{ required: true, message: REQUIRED_FIELD }]}>
                <Select
                    loading={isLoading}
                    placeholder="Please select a reason"
                    dropdownRender={menu => (
                        <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Space style={{ padding: "0 8px 4px" }}>
                                <Input
                                    placeholder="Add new reason"
                                    ref={inputRef}
                                    value={inputReason}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputReason(event.target.value)}
                                    disabled={isAddingReason}
                                />
                                <Button type="text" icon={<PlusOutlined />} onClick={addReason} disabled={isAddingReason}>
                                    Add Reason
                                </Button>
                            </Space>
                        </>
                    )}
                    options={groupReasons}
                />
            </Form.Item>
        </>
    );
};

export default SetReadOnlyModal;
