import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED, InnerPageActionMode } from "@/constants";
import {
    BrandsList,
    ServersList,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    CreateEditInlineFormBased,
    KeyValuePair,
} from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { useGetGroupPurposesQuery } from "@/store/apis/groupPurpose";
import { ErrorCatchValidator, DTColProps, ErrorMessageHandler } from "@/utils/Common";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useState, useEffect, useMemo } from "react";
import GroupPurposeCreateEditPage from "./GroupPurposeCreateEditPage";
import { DefaultIfEmpty } from "@/utils/object";

interface ConfigSettingsProps {
    servers: KeyValuePair[];
    brands: KeyValuePair[];
    serverObj: any;
    brandObj: any;
}

const GroupPurposeListPage = () => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [refetchState, setRefetchState] = useState<number>(0);
    const { isLoading, data, refetch } = useGetGroupPurposesQuery({});
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const [brands, setBrands] = useState<KeyValuePair[]>([]);
    const [servers, setServers] = useState<KeyValuePair[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_GROUP_PURPOSE_MANAGEMENT_EDIT);

    const configSettings: ConfigSettingsProps = useMemo(() => {
        return {
            servers: servers,
            serverObj: servers.reduce((acc: any, cur: KeyValuePair) => ({ ...acc, [`${cur.value}`]: cur.text }), {}),
            brands: brands,
            brandObj: brands.reduce((acc: any, cur: KeyValuePair) => ({ ...acc, [`${cur.value}`]: cur.text }), {}),
        };
    }, [brands, servers]);

    const columns = useMemo(
        () => [
            DTColProps.Large({
                title: "Group Purpose",
                dataIndex: "groupPurpose",
                key: "groupPurpose",
                fixed: "left",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            {
                title: "Group Purpose Pattern",
                dataIndex: "groupPurposePattern",
                key: "groupPurposePattern",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            },
            DTColProps.Small({
                title: "Brand",
                dataIndex: "brandId",
                key: "brandId",
                render: (brandId: number) => DefaultIfEmpty(configSettings.brandObj, `${brandId}`, `${brandId}`),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: configSettings.brands,
                    },
                },
            }),
            DTColProps.Small({
                title: "Server",
                dataIndex: "serverId",
                key: "serverId",
                render: (serverId: number) => DefaultIfEmpty(configSettings.serverObj, `${serverId}`, `${serverId}`),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: configSettings.servers,
                    },
                },
            }),
            DTColProps.Small({
                title: "Include in Reports",
                dataIndex: "includeInReports",
                key: "includeInReports",
                align: "center",
                render: (includeInReportsFlag: boolean, rowData: any) => {
                    if (rowData.includeInReports) {
                        return <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />;
                    } else {
                        return <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />;
                    }
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [
                            { text: "Yes", value: true },
                            { text: "No", value: false },
                        ],
                    },
                },
            }),
            DTColProps.Small({
                title: "Is Live",
                dataIndex: "isLive",
                key: "isLive",
                align: "center",
                render: (isLiveFlag: boolean, rowData: any) => {
                    if (rowData.isLive) {
                        return <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />;
                    } else {
                        return <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />;
                    }
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [
                            { text: "Yes", value: true },
                            { text: "No", value: false },
                        ],
                    },
                },
            }),
        ],
        [configSettings]
    );

    const options: FlexiDataTableOptionsProps = {
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, GroupPurposeData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetchState(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: GroupPurposeData.id });
                setRefetchState(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_GROUP_PURPOSE, { groupPurposeId: GroupPurposeData.id })
                    .then(data => ErrorMessageHandler(GroupPurposeData.groupPurpose, SUCCESS_FAILED.SUCCESS_DELETE_DATA))
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("group purpose", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => {
                        setManualLoading(false);
                        refetch();
                    });
                break;
            default:
                break;
        }
    };

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server", "brand"] })
            .then((data: any) => {
                setServers(data.servers.map((x: ServersList) => ({ text: x.server, value: x.id })));
                setBrands(data.brands.map((x: BrandsList) => ({ text: x.brand, value: x.id })));
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log(err)))
            .finally(() => setManualLoading(false));
    };

    useEffect(() => {
        getFilterConfigList();

        refetch();
        return () => {};
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Group Purpose Management</span>
                </div>
            </div>
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="id"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={data?.data || []}
                        callback={componentCallback}
                        loading={isLoading || manualLoading}
                    />
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    <GroupPurposeCreateEditPage
                        brands={configSettings.brands}
                        servers={configSettings.servers}
                        {...formObject}
                        callback={(action: InnerPageActionMode, value: any) => {
                            switch (action) {
                                case InnerPageActionMode.BACK:
                                    setIsFirstOpt(prev => !prev);
                                    if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                        refetch();
                                    }
                                    break;
                            }
                        }}
                        resetState={refetchState}
                    />
                </div>
            </div>
        </>
    );
};

export default GroupPurposeListPage;
