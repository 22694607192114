import React, { useEffect } from "react";
import useRCStatusMonitor from "../../../hooks/useRCStatusMonitor";
import { massageData } from "./statusUtils";
import BasicFilter from "../../../components/BasicFilter";
import ServerStatus, { ServerStatusProps } from "./ServerStatus";
import ServerStatusFilterWeekend from "./ServerStatusFilterWeekend";
import TabContent from "../components/TabContent";
import ServerStatusVendor from "./ServerStatusVendor";
import useRCStatusMonitorStore from "../../../store/useRCStatusMonitorStore";
import ExcludedButton from "./ExcludedButton";
import soundRA from "../../../assets/audios/rc/bee3sec.mp3"; // RC original sound
import Loader from "../components/Loader";
import MessageCard from "../components/MessageCard";
import { useAudioPlayer } from "@/helpers/audioHelper";
const TabRA = () => {
    const { playSound } = useAudioPlayer(soundRA);
    const { rcStatusMonitor, isLoading, isFetching } = useRCStatusMonitor({ department: "RA" });
    const dataRA = rcStatusMonitor ? massageData(rcStatusMonitor, "RA") : [];

    const vendor = useRCStatusMonitorStore(state => state.vendor.ra);
    const filterDataRA = vendor ? dataRA?.filter((item: any) => item.vendor === vendor) : [];

    const totalErrorCount = dataRA?.reduce((acc: number, item: any) => acc + item.errorCount, 0) ?? 0;
    const totalWarningCount = dataRA?.reduce((acc: number, item: any) => acc + item.warningCount, 0) ?? 0;

    useEffect(() => {
        if (!isFetching) {
            if (totalErrorCount >= 1 || totalWarningCount >= 1) {
                playSound();
            };
        }
    }, [isFetching]);

    return (
        <TabContent>
            <BasicFilter
                show={false}
                titleBarChildren={
                    <>
                        <ExcludedButton />
                    </>
                }
            >
                <ServerStatusFilterWeekend />
            </BasicFilter>

            {isLoading && rcStatusMonitor.length === 0 ? (
                <Loader />
            ) : (
                <>
                    <ServerStatusVendor data={dataRA as ServerStatusProps[]} vendorType="ra" />
                    {!vendor && (
                        <div style={{ padding: "0 1rem" }}>
                            <MessageCard type="info">Please select a group from above</MessageCard>
                        </div>
                    )}
                    {filterDataRA?.map((item: any, index: number) => (
                        <ServerStatus key={index} data={item as ServerStatusProps} />
                    ))}
                </>
            )}
        </TabContent>
    );
};

export default TabRA;
