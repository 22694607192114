import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";
import { ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CreateEditInlineFormBased } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { Col, Form, Row } from "antd";
import { useMemo, useState, useCallback, useEffect } from "react";

interface MRServerEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

const MRServerEditPage = (props: MRServerEditPageProps) => {
    const isAddAction = useMemo(() => props.mode === InnerPageActionMode.CREATE_NEW, [props.mode]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [configForm] = Form.useForm();
    const [configData, setConfigData] = useState<any>({ mrPKServers: [] });

    const onSubmit = useCallback(
        (values: any) => {
            if (isAddAction) {
                //Temporary not support create function, will be added later
            } else {
                apiRequest(APIs.UPDATE_MR_SERVER, values)
                    .then(res => {
                        ErrorMessageHandler("MR Server Group setting", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("MR Server Group setting", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                        )
                    );
            }
        },
        [isAddAction]
    );

    const getMRConfigDetails = (id?: number | string | undefined) => {
        if (isEmptyOrNull(id)) {
            configForm.resetFields();
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        apiRequest(APIs.GET_MR_SERVER_LIST, { id: [id as number] })
            .then((res: any) => {
                if (res.length > 0) {
                    configForm.setFieldsValue({ ...res[0], serverNames: res[0].servers.map((x: any) => x.serverName) });
                } else {
                    ErrorMessageHandler("MR server group setting", SUCCESS_FAILED.FAILED_LOAD_DATA);
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("MR server group setting", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                props.callback && props.callback(InnerPageActionMode.BACK);
            })
            .finally(() => setIsLoading(false));
    };

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["marketriskpkserver"],
        })
            .then((data: any) => {
                setConfigData({
                    mrPKServers: data.mrPkServer
                        .sort((a: { showName: string }, b: { showName: string }) => a.showName.localeCompare(b.showName))
                        .map((x: any) => ({ text: `${x.showName}`, value: `${x.serverName}` })),
                });
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to get filter config list: ", err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => getMRConfigDetails(props.id), [props.resetState]);
    useEffect(() => getConfigList(), []);

    return (
        <SitePageHeaderInline
            enableSubmit
            title={isAddAction ? "Create New MR Server Group Settings" : "Edit MR Server Group Settings"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        configForm.resetFields();
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        configForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="lpmonitor-create-edit-form">
                    <Form labelAlign="left" labelCol={{ span: 7 }} form={configForm} layout="horizontal" initialValues={{}} onFinish={onSubmit}>
                        <FormComponent label="" name="id" extra={{ type: ComponentType.hidden, value: "" }} />
                        <Row>
                            <Col span={11} className="main-panel-left">
                                <FormComponent
                                    label="Server Group Name"
                                    name="name"
                                    extra={{ type: ComponentType.text, value: "", rules: [{ required: true, message: REQUIRED_FIELD }] }}
                                />
                                <FormComponent
                                    label="Description"
                                    name="description"
                                    extra={{ type: ComponentType.text, value: "", rules: [{ required: true, message: REQUIRED_FIELD }] }}
                                />
                                <FormComponent
                                    label="Servers"
                                    name="serverNames"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: configData.mrPKServers,
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                        inputProps: {
                                            mode: "multiple",
                                        },
                                    }}
                                />
                                <FormComponent
                                    label="Status"
                                    name="isEnabled"
                                    extra={{ type: ComponentType.switch, value: ["Inactive", "Active"] }}
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </SitePageHeaderInline>
    );
};

export default MRServerEditPage;
