import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs } from "../../../services/apis";
import { axiosBaseQueryRC } from "../../axiosBaseQuery";
import { SortList } from "@/utils/array";

export interface ReportBatchResponse {
    batchIdNameParams: string;
    batchName: string;
    createTime: string;
    createUser: number;
    downloadCount: number;
    endTime: string | null;
    fileName: string;
    filePath: string | null;
    id: number;
    lastDownloadTime: string | null;
    note: string | null;
    params: string;
    seperateStr: string;
    startTime: string | null;
    status: string;
}

export const reportBatchApiSlice = createApi({
    reducerPath: "reportBatch",
    baseQuery: axiosBaseQueryRC(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: builder => ({
        getReportBatchList: builder.query<ReportBatchResponse[], any>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: arg => ({ url: `${APIs.RC_ROOT.GET_DOWNLOADS}`, method: "GET" }),
            transformResponse: (returnValue: any) => {
                return SortList(returnValue.data[1], "createTime", undefined, "DESC");
            },
        }),
    }),
});

export const { useGetReportBatchListQuery } = reportBatchApiSlice;
