import EmptyData from "@/components/Common/Empty";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import { SymbolServerType, SUCCESS_FAILED, ComponentType, SYMBOL_SERVER_TYPES } from "@/constants";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ToObjectWithKey, ToOptionTypeList } from "@/utils/array";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { DefaultIfEmpty, objectRemoveProps, getAvailableObjectElement } from "@/utils/object";
import { isEmptyOrNull, defaultIfEmptyOrNull } from "@/utils/string";
import { DownloadOutlined, UploadOutlined, PlusOutlined, SearchOutlined, CopyOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Segmented, Tag, Upload } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as XLSX from "xlsx";
import FilterComponent from "./components/filterComponent";
import { SymbolMappingProfileSettingProps } from "./components/SymbolMappingComponent";
import SymbolInfoCreateEditPage from "./SymbolInfoCreateEditPage";
import { KeyValuePair } from "@/constants/type";
import ExportExcelModal, { ExportExcelModalCallbackKey } from "./components/ExportExcelModal";
import DownloadTemplateModal, { DownloadTemplateModalCallbackKey } from "./components/DownloadTemplateModal";

export interface SymbolInfoProps {}

interface SymbolDetailsProps {
    isMTServer?: boolean;
    symbol: SymbolInfosProps;
    symbolHolidays: SymbolHolidayProps[];
    symbolSessions: SymbolSessionProps[];
}

interface SymbolInfosProps {
    cleanSymbol: string | null;
    cleanSymbolPricing: string | null;
    contractSize: number;
    createdBy: string;
    createdDateUtc: string;
    currencyBase: string;
    currencyMargin: string;
    currencyProfit: string;
    description: string;
    digits: number;
    isMainStream: boolean;
    isRemoved: boolean;
    lowerSymbol: string;
    modifiedBy: string | null;
    modifiedDateUtc: string | null;
    server: string;
    serverId: number;
    serverType: string;
    serverTypeId: number;
    suffix: string | null;
    symbol: string;
    symbolAssetType: string | null;
    symbolAssetTypeId: number | null;
    symbolAssetTypePricing: string | null;
    symbolAssetTypeIdPricing: number | null;
    symbolGroup: string | null;
    symbolId: string;
    upperSymbol: string;
    volumeSizeAdj: number;
}

export interface SymbolHolidayProps {
    createdBy: string;
    createdDateUtc: string;
    date: string;
    description: string;
    modifiedBy: string | null;
    modifiedDateUtc: string | null;
    serverId: number;
    symbol: string;
    symbolHolidayId: string;
    symbolId: string;
    timeRange: string;
}

interface SymbolSessionProps {
    createdBy: string;
    createdDateUtc: string;
    fridaySession: string | null;
    modifiedBy: string | null;
    modifiedDateUtc: string | null;
    mondaySession: string | null;
    saturdaySession: string | null;
    sessionType: string;
    serverId: number;
    sundaySession: string | null;
    symbol: string;
    symbolSessionId: string;
    symbolId: string;
    thursdaySession: string | null;
    tuesdaySession: string | null;
    wednesdaySession: string | null;
}

interface CleanSymbolProps {
    ProductCleanSymbol: KeyValuePair[];
    PricingCleanSymbol: KeyValuePair[];
}

const enum ActionKey {
    CREATE_NEW = 1,
    EDIT = 2,
}

export const tagTemplate = (timeRangeText: string, key: string) => {
    let separator = timeRangeText.indexOf(",") > -1 ? "," : timeRangeText.indexOf(";") > -1 ? ";" : "|";
    return timeRangeText.split(separator).map((x: string, idx: number) => (
        <Tag key={`${key}-${idx}`} style={{ padding: "0 0.3255vw 0 0.3255vw", borderRadius: "5px" }}>
            {x}
        </Tag>
    ));
};

const SymbolInfo = (props: SymbolInfoProps) => {
    const groupByOptions: any = [
        { text: "Server", value: 1 },
        { text: "Symbol", value: 2 },
    ];
    const symbolSessionTypeOptions: any = {
        "0": "Quote",
        "1": "Trade",
    };
    const defaultSymbolConfigModel = {
        symbolId: "",
        serverId: "",
        symbol: "",
        cleanSymbol: "",
        //pricingCleanSymbol: "",
        suffix: "",
        //symbolGroup: "",
        description: "",
        //currencyBase: "",
        currencyProfit: "",
        //currencyMargin: "",
        digits: "",
        contractSize: "",
        volumeSizeAdj: "",
        //isRemoved: "",
        //symbolHolidays: [],
        /*...Object.keys(symbolSessionTypeOptions).reduce((finalObj: any, x: string) => {
            finalObj[`symses-${x}-symbolSessionId`] = "";
            finalObj[`symses-${x}-symbolId`] = "";
            finalObj[`symses-${x}-mondaySession`] = "";
            finalObj[`symses-${x}-tuesdaySession`] = "";
            finalObj[`symses-${x}-wednesdaySession`] = "";
            finalObj[`symses-${x}-thursdaySession`] = "";
            finalObj[`symses-${x}-fridaySession`] = "";
            finalObj[`symses-${x}-saturdaySession`] = "";
            finalObj[`symses-${x}-sundaySession`] = "";
            return finalObj;
        }, {}),
        */
    };

    const enum ColSpan {
        LabelSpan = 8,
        InputSpan = 16,
    }

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_SYMBOL_MANAGEMENT_EDIT);

    const [groupBy, setGroupBy] = useState<number>(1);
    const [currentMode, setCurrentMode] = useState<number>(1);
    const [firstSelection, setFirstSelection] = useState<string>("");
    const [secondSelection, setSecondSelection] = useState<string>("");
    const [symbolSearch, setSymbolSearch] = useState<string>("");
    const [serverSearch, setServerSearch] = useState<string>("");
    const [dataInfo, setDataInfo] = useState<any>({ server: [], symbol: [] });
    const [ori_servers, setOri_servers] = useState<any>([]);
    const [ori_symbol, setOri_symbol] = useState<any>([]);
    const [ori_nonmtsymbol, setOri_nonMTsymbol] = useState<any>([]);
    const [ori_productCleanSymbol, setOri_productCleanSymbol] = useState<any>([]);
    const [ori_pricingCleanSymbol, setOri_pricingCleanSymbol] = useState<any>([]);
    const [ori_symbolAssetTypes, setOri_SymbolAssetTypes] = useState<any>([]);
    const [servers, setServers] = useState<any>({});
    const [symbolDetails, setSymbolDetails] = useState<SymbolDetailsProps | undefined>(undefined);
    const [currentSessionMode, setCurrentSessionMode] = useState<string>("");
    const [refetchData, setRefetchData] = useState<boolean>(false);
    const [currentActionStatus, setCurrentActionStatus] = useState<number>(1); //1 => Create, 2 => Edit
    const [filterParams, setFilterParams] = useState<any>({});
    const [loadingPanel, setLoadingPanel] = useState<number>(0); //1 - server, 2 - symbol, 3 - Symbol Details
    const [isLoadingSymbolMappingComponent, setIsLoadingSymbolMappingComponent] = useState<boolean>(true);

    //Create Modal State
    //const [isSymbolMappingModalVisible, setIsSymbolMappingModalVisible] = useState<boolean>(false);
    const [showUpdateSymbolButton, setShowUpdateSymbolButton] = useState<boolean>(false);
    const [configData, setConfigData] = useState<any>({});
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isModalVisible2, setIsModalVisible2] = useState<boolean>(false);
    const [isModalVisible3, setIsModalVisible3] = useState<boolean>(false);
    const [createSymbolForm] = Form.useForm();
    const [mainFilterForm] = Form.useForm();
    const [downloadForm] = Form.useForm();
    const [exportForm] = Form.useForm();

    const serverList = useMemo(() => {
        if (serverSearch.length > 0) {
            let lowerServerSearch: string = serverSearch.toLowerCase();
            /**
             *  25/09/2024 - Xin
             *  NOTE: not using DefaultIfEmpty fn because changed servers obj values
             *  [previously] servers = { serverId: serverDisplayName }
             *  [now] servers = {
             *    serverId: { serverId, serverDisplayName, serverTypeId, serverType, ...server },
             *  }
             */
            return dataInfo["server"].filter(
                (x: any) => (servers[x.serverId]?.serverDisplayName ?? "").toLowerCase().indexOf(lowerServerSearch) > -1
            );
        }
        return dataInfo["server"];
    }, [serverSearch, dataInfo, servers]);

    const symbolList = useMemo(() => {
        if (symbolSearch.length > 0) {
            let lowerSymbolSearch: string = symbolSearch.toLowerCase();
            return dataInfo["symbol"].filter((x: any) => `${x["symbol"]}`.toLowerCase().indexOf(lowerSymbolSearch) > -1);
        }
        return dataInfo["symbol"];
    }, [symbolSearch, dataInfo]);

    const ori_cleanSymbol: CleanSymbolProps = useMemo(
        () => ({
            ProductCleanSymbol: ori_productCleanSymbol.map((x: any) => ({ text: x.name, value: x.name })),
            PricingCleanSymbol: ori_pricingCleanSymbol.map((x: any) => ({ text: x.name, value: x.name })),
        }),
        [ori_productCleanSymbol, ori_pricingCleanSymbol]
    );

    const createEditFormProps = useMemo(
        () => ({
            servers: ori_servers
                .filter((x: any) => x.serverTypeId !== SymbolServerType.MT4 && x.serverTypeId !== SymbolServerType.MT5)
                .map((x: any) => ({ text: x.serverDisplayName, value: x.serverId })),
            allServers: ori_servers.map((x: any) => ({ text: x.serverDisplayName, value: x.serverId })),
            symbols: ori_symbol.map((x: any) => ({ text: x.name, value: x.name })),
            nonmtsymbols: ori_nonmtsymbol.map((x: any) => ({ text: x.name, value: x.name })),
            allSymbols: [
                ...ori_symbol.map((x: any) => ({ text: x.name, value: x.name })),
                ori_nonmtsymbol.map((x: any) => ({ text: x.name, value: x.name })),
            ],
            cleanSymbols: ori_cleanSymbol.ProductCleanSymbol,
            symbolAssetType: ori_symbolAssetTypes.map((x: any) => ({ text: x.name, value: x.id })),
        }),
        [ori_symbol, ori_nonmtsymbol, ori_cleanSymbol, ori_symbolAssetTypes, ori_servers]
    );

    const showPreviewError = useCallback(
        (array: any) => {
            let serverObj = ToObjectWithKey(ori_servers, "serverId", "serverDisplayName");
            Modal.error({
                title: "Error Preview",
                width: "52.08vw",
                bodyStyle: { padding: "1vw 2vh" },
                content: array.map((x: any) => (
                    <div className="upload-error-div">
                        <FlexiDataTable
                            title={
                                <span className="flexi-table-small-title-style">{`Tab "${DefaultIfEmpty(serverObj, x.serverId, x.serverId)}"`}</span>
                            }
                            loading={false}
                            columns={[
                                DTColProps.XXSmall({
                                    title: "Row",
                                    dataIndex: "rowNum",
                                    key: "rowNum",
                                }),
                                DTColProps.Small({
                                    title: "Symbol",
                                    dataIndex: "symbol",
                                    key: "symbol",
                                }),
                                {
                                    title: "Remarks",
                                    dataIndex: "remarks",
                                    key: "remarks",
                                },
                            ]}
                            options={{
                                enableFilter: false,
                                showHideColumns: false,
                            }}
                            dataSource={x.symbols}
                            scroll={{ x: "max-content" }}
                            bordered
                            {...(x.symbols.length > 10 ? { pagination: { pageSize: 10 } } : { pagination: false })}
                        />
                    </div>
                )),
            });
        },
        [ori_servers]
    );

    const triggerAPICalling = useCallback(
        (viewCurrentMode: number, params: any) => {
            setLoadingPanel(viewCurrentMode === 1 || viewCurrentMode === 4 ? 1 : 2);
            apiRequest(APIs.GET_SYMBOL_LIST, { ...filterParams, ...params })
                .then(res => {
                    if (viewCurrentMode === 1 || viewCurrentMode === 4) {
                        setDataInfo((prev: any) => ({ ...prev, ...{ server: res } }));
                    } else if (viewCurrentMode === 2 || viewCurrentMode === 3) {
                        setDataInfo((prev: any) => ({ ...prev, ...{ symbol: res } }));
                    }
                })
                .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol list", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)))
                .finally(() => setLoadingPanel(0));
        },
        [filterParams]
    );

    const deleteSymbol = useCallback(
        (symbolId: string) => {
            apiRequest(APIs.DELETE_SYMBOL, { symbolId })
                .then(res => {
                    ErrorMessageHandler("symbol configuration", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol configuration", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                )
                .finally(() => {
                    if (!isEmptyOrNull(firstSelection)) {
                        triggerAPICalling(groupBy, { symbolListingMode: groupBy });
                    }
                    setRefetchData(true);
                    setSymbolDetails(undefined);
                    setSecondSelection("");
                });
        },
        [groupBy, firstSelection, triggerAPICalling]
    );

    const convertExcelToJSON = (uploadedFile: any) => {
        if (!uploadedFile) return null;
        if (uploadedFile.size > 5000000) {
            ErrorMessageHandler(`Maximum file size is 5 mb.`, SUCCESS_FAILED.OTHERS_FAILED);
            return null;
        }

        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        // Set up callback for when FileReader is done loading
        reader.onload = (event: any) => {
            /* Parse data */
            const bstr = event.target.result;
            const wb = XLSX.read(bstr, {
                type: rABS ? "binary" : "array",
                bookVBA: true,
                raw: true,
            });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            const excelRows: any[] = XLSX.utils.sheet_to_json(ws);

            if (excelRows.length === 0) {
                ErrorMessageHandler(`File is empty.`, SUCCESS_FAILED.OTHERS_FAILED);
                return null;
            }

            let formData1 = new FormData();
            formData1.append("IsPreviewOnly", "true");
            formData1.append("File", uploadedFile);
            apiRequest(APIs.UPDATE_SYMBOL_TEMPLATE, formData1, "POST", "json", {
                Accept: "text/plain",
                "Content-type": "multipart/form-data",
            })
                .then((data: any) => {
                    if (data.length > 0 && data.some((y: any) => y.symbols.length > 0)) {
                        showPreviewError(data);
                    } else {
                        let formData = new FormData();
                        formData.append("IsPreviewOnly", "false");
                        formData.append("File", uploadedFile);

                        apiRequest(APIs.UPDATE_SYMBOL_TEMPLATE, formData, "POST", "json", {
                            Accept: "text/plain",
                            "Content-type": "multipart/form-data",
                        })
                            .then((data: any) => {
                                ErrorMessageHandler(`symbols upload successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                                setRefetchData(true);
                            })
                            .catch((error: any) =>
                                ErrorCatchValidator(error, (err: any) => {
                                    if (err.status === -3) {
                                        Modal.warning({
                                            title: "Warning!",
                                            content: err.message,
                                        });
                                    } else {
                                        ErrorMessageHandler(`Failed to upload symbols`, SUCCESS_FAILED.OTHERS_FAILED, err);
                                    }
                                })
                            );
                    }
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => {
                        if (err.status === -3) {
                            Modal.warning({
                                title: "Warning!",
                                content: err.message,
                            });
                        } else {
                            ErrorMessageHandler(`Failed to upload symbols`, SUCCESS_FAILED.OTHERS_FAILED, err);
                        }
                    })
                );
        };

        // Call FileReader
        if (rABS) {
            reader.readAsBinaryString(uploadedFile);
        } else {
            reader.readAsArrayBuffer(uploadedFile);
        }
    };

    const prepareEditConfiguration = (params: SymbolDetailsProps, isClone: boolean = false) => {
        let recordObj: any = {
            ...defaultSymbolConfigModel,
            ...(isClone
                ? {
                      serverId: "",
                      symbolId: "",
                  }
                : {
                      serverId: params.symbol.serverId,
                      symbolId: params.symbol.symbolId,
                  }),
            ...{
                symbol: params.symbol.symbol,
                cleanSymbol: params.symbol.cleanSymbol,
                pricingCleanSymbol: params.symbol.cleanSymbolPricing,
                suffix: params.symbol.suffix,
                symbolGroup: defaultIfEmptyOrNull(params.symbol.symbolGroup, ""),
                description: defaultIfEmptyOrNull(params.symbol.description, ""),
                currencyBase: defaultIfEmptyOrNull(params.symbol.currencyBase, ""),
                currencyProfit: defaultIfEmptyOrNull(params.symbol.currencyProfit, ""),
                currencyMargin: defaultIfEmptyOrNull(params.symbol.currencyMargin, ""),
                digits: defaultIfEmptyOrNull(params.symbol.digits, ""),
                contractSize: defaultIfEmptyOrNull(params.symbol.contractSize, ""),
                volumeSizeAdj: defaultIfEmptyOrNull(params.symbol.volumeSizeAdj, ""),
                isRemoved: isEmptyOrNull(params.symbol.isRemoved) ? "" : params.symbol.isRemoved ? "1" : "0",
            },
            symbolHolidays:
                params.symbolHolidays.length > 0
                    ? isClone
                        ? params.symbolHolidays.map(x => ({ ...x, symbolHolidayId: "" }))
                        : params.symbolHolidays
                    : [],
            ...(params.symbolSessions.length > 0 &&
                params.symbolSessions.reduce((finalObj: any, obj: SymbolSessionProps) => {
                    let sesKey: string = obj.sessionType;
                    finalObj[`symses-${sesKey}-symbolSessionId`] = isClone ? "" : obj.symbolSessionId;
                    finalObj[`symses-${sesKey}-symbolId`] = isClone ? "" : obj.symbolId;
                    finalObj[`symses-${sesKey}-mondaySession`] = obj.mondaySession;
                    finalObj[`symses-${sesKey}-tuesdaySession`] = obj.tuesdaySession;
                    finalObj[`symses-${sesKey}-wednesdaySession`] = obj.wednesdaySession;
                    finalObj[`symses-${sesKey}-thursdaySession`] = obj.thursdaySession;
                    finalObj[`symses-${sesKey}-fridaySession`] = obj.fridaySession;
                    finalObj[`symses-${sesKey}-saturdaySession`] = obj.saturdaySession;
                    finalObj[`symses-${sesKey}-sundaySession`] = obj.sundaySession;
                    return finalObj;
                }, {})),
        };
        createSymbolForm.setFieldsValue(recordObj);
        setCurrentActionStatus(isClone ? ActionKey.CREATE_NEW : ActionKey.EDIT);
        setIsModalVisible(true);
    };

    const getSymbolDetails = (symbolId: string) => {
        setLoadingPanel(3);
        setSymbolDetails(undefined);
        setCurrentSessionMode("");
        apiRequest(APIs.GET_SYMBOL_INFO, { symbolId })
            .then((res: SymbolDetailsProps) => {
                let returnRes: SymbolDetailsProps = {
                    ...res,
                    isMTServer: res.symbol.serverTypeId === SymbolServerType.MT4 || res.symbol.serverTypeId === SymbolServerType.MT5,
                };
                setSymbolDetails(returnRes);
                if (returnRes.symbolSessions.length > 0) {
                    setCurrentSessionMode(returnRes.symbolSessions[0].sessionType);
                }
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol details", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)))
            .finally(() => setLoadingPanel(0));
    };

    const submitCreateForm = useCallback(
        (params: any) => {
            apiRequest(APIs.CREATE_SYMBOL, params)
                .then(res => {
                    ErrorMessageHandler("new symbol configuration", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new symbol configuration", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                )
                .finally(() => {
                    if (!isEmptyOrNull(firstSelection)) {
                        triggerAPICalling(groupBy, { symbolListingMode: groupBy });
                    }
                    setRefetchData(true);
                });
        },
        [currentMode, firstSelection, groupBy]
    );

    const submitUpdateForm = useCallback(
        (params: any) => {
            apiRequest(APIs.UPDATE_SYMBOL, params)
                .then(res => {
                    ErrorMessageHandler("symbol configuration", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol configuration", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                )
                .finally(() => {
                    getSymbolDetails(groupBy === 1 ? secondSelection : firstSelection);
                    setRefetchData(true);
                });
        },
        [firstSelection, secondSelection, groupBy]
    );

    const SubmitCreateEditForm = (values: any) => {
        /// DO Edit Part
        let isUpdateFlag: boolean = !isEmptyOrNull(values["symbolId"]),
            params: any = {
                ...(isUpdateFlag && {
                    symbolId: values["symbolId"],
                }),
                ...objectRemoveProps(getAvailableObjectElement(values), ["symbolId"]),
            };

        if (isUpdateFlag) {
            submitUpdateForm(params);
        } else {
            submitCreateForm(params);
        }

        /*
        if (values["symbolHolidays"] && values["symbolHolidays"].length > 0) {
            params["symbolHolidays"] = values["symbolHolidays"].map((x: any) => ({
                description: x["description"],
                date: x["date"],
                timeRange: x["timeRange"],
                ...(!isEmptyOrNull(x["symbolHolidayId"]) && {
                    symbolHolidayId: x["symbolHolidayId"],
                }),
            }));
        }

        //Prepare symbolInfo parameter values
        let symbolInfoParams: any = Object.keys(values).filter(x => x.indexOf("si-") === 0 && !isEmptyOrNull(values[x]));
        if (symbolInfoParams.length > 0) {
            params["symbolInfo"] = symbolInfoParams.reduce((obj: any, x: string) => {
                if (x.substring(3) === "symbolInfoId") {
                    if (!isEmptyOrNull(values[x])) {
                        obj[x.substring(3)] = values[x];
                    }
                } else if (x.substring(3) === "isRemoved") {
                    if (!isEmptyOrNull(values[x])) {
                        obj[x.substring(3)] = values[x] === "1";
                    }
                } else {
                    obj[x.substring(3)] = values[x];
                }
                return obj;
            }, {});
        }

        //Prepare symbolSession parameter values
        let symSesObj = Object.keys(values)
            .filter(x => x.indexOf("symses-") === 0)
            .reduce(
                (obj: any, x: string) => {
                    let keyArr = x.split("-");
                    if (keyArr.length === 3) {
                        if (keyArr[2] === "symbolSessionId") {
                            if (!isEmptyOrNull(values[x])) {
                                obj[keyArr[1]][keyArr[2]] = values[x];
                            }
                        } else {
                            obj[keyArr[1]][keyArr[2]] = values[x];
                        }
                    }
                    return obj;
                },
                { "0": {}, "1": {} }
            );

        let symSesArry = Object.keys(symSesObj)
            .filter(x => Object.keys(symSesObj[x]).filter(y => !isEmptyOrNull(symSesObj[x][y])).length > 0)
            .map(x => ({
                sessionType: parseInt(x),
                ...symSesObj[x],
            }));
        if (symSesArry.length > 0) {
            params["symbolSessions"] = symSesArry;
        }

        if (isUpdateFlag) {
            submitUpdateForm(params);
        } else {
            submitCreateForm(params, values["symbolSelectionType"] === 1);
        }*/
    };

    const getSelectionList = useCallback(() => {
        let params: any = { symbolListingMode: currentMode };
        if (currentMode === 3) {
            params["selectedServerId"] = firstSelection;
        } else if (currentMode === 4) {
            params["selectedSymbol"] = firstSelection;
        }

        triggerAPICalling(currentMode, { ...filterParams, ...params });
    }, [currentMode, firstSelection, filterParams]);

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["symbol", "nonmtsymbol", "cleansymbol", "pricingcleansymbol", "symbolassettype", "riskinsightserver"],
        })
            .then(res => {
                setOri_symbol(res.symbols);
                setOri_nonMTsymbol(res.nonMtSymbols);
                setOri_productCleanSymbol(res.cleanSymbols);
                setOri_pricingCleanSymbol(res.pricingCleanSymbols);
                setOri_SymbolAssetTypes(res.symbolAssetTypes);
                setOri_servers(res.riskInsightServers);
                setServers(ToObjectWithKey(res.riskInsightServers, "serverId"));
                setRefetchData(true);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config info", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );

        apiRequest(APIs.GET_SETTING_LIST, { keys: ["SymbolMappingProfileSettingPropConfigs"] })
            .then((res: any) => {
                if (res.length > 0) {
                    let configData: SymbolMappingProfileSettingProps[] = JSON.parse(res[0].value);
                    setConfigData({
                        options: configData.map(x => ({ label: x.symbolMappingProfileName, value: x.symbolMappingProfileId })),
                        keyValueObj: ToObjectWithKey(configData, "symbolMappingProfileId"),
                    });
                    setShowUpdateSymbolButton(true);
                } else {
                    setConfigData({
                        options: [],
                        keyValueObj: {},
                    });
                    setShowUpdateSymbolButton(false);
                }
            })
            .finally(() => setIsLoadingSymbolMappingComponent(false));
    };

    useEffect(() => {
        if (refetchData) {
            setRefetchData(false);
            getSelectionList();
        }
    }, [refetchData]);

    useEffect(() => {
        getConfigList();
        return () => {};
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Symbol Management</span>
                </div>
            </div>
            <div
                className="main-top-container"
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center" }}
            >
                <div className="left">
                    <span style={{ fontSize: "1rem", fontWeight: "bold", color: "#f00f00" }}>
                        (** Create/Update features only support for LP's symbol only. **)
                    </span>
                </div>
                <div className="right">
                    <div style={{ marginTop: "1vh", marginBottom: "1vh", textAlign: "right" }}>
                        {enableUpdate && (
                            <>
                                <Button
                                    key={`btn-sy-dt-${Math.random()}`}
                                    htmlType="button"
                                    style={{ marginLeft: "0.651vw", width: "auto" }}
                                    onClick={event => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        downloadForm.setFieldsValue({
                                            downloadMode: "",
                                            serverIds: [],
                                            symbols: [],
                                            cleanSymbols: [],
                                            symbolAssetTypeIds: [],
                                        });
                                        setIsModalVisible2(true);
                                    }}
                                    icon={<DownloadOutlined />}
                                >
                                    Download Template
                                </Button>
                                <Upload
                                    name="file"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    showUploadList={false}
                                    onChange={(info: any) => {
                                        if (info.file.status === "error") {
                                            ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                                        }
                                    }}
                                    beforeUpload={(file: any) => {
                                        convertExcelToJSON(file);
                                    }}
                                    customRequest={(info: any) => {}}
                                >
                                    <Button
                                        htmlType="button"
                                        icon={<UploadOutlined />}
                                        style={{ marginLeft: "0.651vw" }}
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        Upload Excel
                                    </Button>
                                </Upload>
                            </>
                        )}
                        <Button
                            icon={<DownloadOutlined />}
                            style={{ marginLeft: "0.651vw" }}
                            onClick={(event: any) => {
                                event.preventDefault();
                                event.stopPropagation();
                                exportForm.setFieldsValue({
                                    serverIds: [],
                                    symbols: [],
                                    cleanSymbols: [],
                                    symbolAssetTypeIds: [],
                                });
                                setIsModalVisible3(true);
                            }}
                        >
                            Export as Excel
                        </Button>
                    </div>
                </div>
            </div>

            <div className="symbol-info-page-container shadow-1">
                <div className="top-header-div">
                    <div className="filter-div">
                        <Form initialValues={{ groupBy: groupBy }}>
                            <FormComponent
                                label="Group By"
                                name="groupBy"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: groupByOptions,
                                    inputProps: {
                                        allowClear: false,
                                        //defaultValue: groupBy,
                                        onChange: (value: any) => {
                                            setGroupBy(value);
                                            setCurrentMode(value);
                                            setFirstSelection("");
                                            setSecondSelection("");
                                            setServerSearch("");
                                            setSymbolSearch("");
                                            setDataInfo({ server: [], symbol: [] });
                                            setRefetchData(true);
                                        },
                                    },
                                    itemProps: {
                                        style: {
                                            marginBottom: "0",
                                        },
                                    },
                                }}
                            />
                        </Form>
                    </div>
                    <div className="buttons-div">
                        <FilterComponent
                            form={mainFilterForm}
                            serverType={ToOptionTypeList(SYMBOL_SERVER_TYPES)}
                            productCleanSymbols={ori_cleanSymbol.ProductCleanSymbol}
                            pricingCleanSymbols={ori_cleanSymbol.PricingCleanSymbol}
                            symbolAssetType={ori_symbolAssetTypes.map((x: any) => ({ text: x.name, value: x.id }))}
                            onFilterSubmit={(values: any) => {
                                let finalParams: any = Object.keys(values).reduce((finalObj: any, i: any) => {
                                    if (values[i] !== undefined) {
                                        finalObj[i] = values[i];
                                    }
                                    return finalObj;
                                }, {});
                                setFilterParams(finalParams);

                                if (!isEmptyOrNull(firstSelection)) {
                                    triggerAPICalling(groupBy, { ...finalParams, ...{ symbolListingMode: groupBy } });
                                }
                                setRefetchData(true);
                            }}
                        />
                        {enableUpdate && (
                            <>
                                <Button
                                    htmlType="button"
                                    style={{ marginLeft: "0.651vw" }}
                                    icon={<PlusOutlined style={{ fontSize: "0.875rem" }} />}
                                    onClick={event => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setCurrentActionStatus(ActionKey.CREATE_NEW);
                                        setIsModalVisible(true);
                                        createSymbolForm.setFieldsValue({ ...defaultSymbolConfigModel });
                                    }}
                                >
                                    Create New Symbol
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <div className="main-container">
                    <div className="left">
                        <div className={`group-${groupBy}`}>
                            <div className="title">{groupBy === 1 ? "Server" : "Symbol"}</div>
                            <div className="content">
                                <div className="search-panel">
                                    <Input
                                        type="text"
                                        name="search"
                                        placeholder="Search"
                                        className="search-input"
                                        suffix={<SearchOutlined />}
                                        allowClear
                                        value={groupBy === 1 ? serverSearch : symbolSearch}
                                        onChange={(e: any) => {
                                            groupBy === 1 ? setServerSearch(e.currentTarget.value) : setSymbolSearch(e.currentTarget.value);
                                        }}
                                    />
                                </div>
                                <div className="item-container nice-scrollbar">
                                    {groupBy === 1 ? (
                                        loadingPanel === 1 ? (
                                            <LoadingComponent />
                                        ) : (
                                            serverList.map((x: any, idx: number) => {
                                                let currServer = servers[x["serverId"]] ?? undefined;
                                                return (
                                                    <div
                                                        key={`sipc-g1-${idx}`}
                                                        className={firstSelection === x["serverId"] ? "active" : ""}
                                                        onClick={(e: any) => {
                                                            setFirstSelection(x["serverId"]);
                                                            setSecondSelection("");
                                                            setCurrentMode(3);
                                                            setRefetchData(true);
                                                        }}
                                                    >
                                                        <div>
                                                            <span>{isEmptyOrNull(currServer) ? "" : currServer?.serverDisplayName}</span>
                                                            {currServer && <span className="mini-tag">{currServer?.serverType}</span>}
                                                        </div>
                                                        <span className="count-text">{x["symbolCount"]}</span>
                                                    </div>
                                                );
                                            })
                                        )
                                    ) : loadingPanel === 2 ? (
                                        <LoadingComponent />
                                    ) : (
                                        symbolList.map((x: any, idx: number) => (
                                            <div
                                                key={`sipc-g1-${idx} ${firstSelection === x["symbol"] ? "active" : ""}`}
                                                className={firstSelection === x["symbol"] ? "active" : ""}
                                                onClick={(e: any) => {
                                                    setFirstSelection(x["symbol"]);
                                                    setSecondSelection("");
                                                    setCurrentMode(4);
                                                    setRefetchData(true);
                                                }}
                                            >
                                                <span>{x["symbol"]}</span>
                                                <span className="count-text">{x["serverCount"]}</span>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`group-${groupBy === 1 ? 2 : 1}`}>
                            <div className="title">{groupBy === 1 ? "Symbol" : "Server"}</div>
                            <div className="content">
                                <div className="search-panel">
                                    <Input
                                        type="text"
                                        name="search2"
                                        placeholder="Search"
                                        className="search-input"
                                        suffix={<SearchOutlined />}
                                        allowClear
                                        value={groupBy === 1 ? symbolSearch : serverSearch}
                                        onChange={(e: any) => {
                                            groupBy === 1 ? setSymbolSearch(e.currentTarget.value) : setServerSearch(e.currentTarget.value);
                                        }}
                                    />
                                </div>
                                <div className="item-container nice-scrollbar">
                                    {groupBy === 1 ? (
                                        loadingPanel === 2 ? (
                                            <LoadingComponent />
                                        ) : (
                                            symbolList.map((x: any, idx: number) => (
                                                <div
                                                    key={`sipc-g1-${idx}`}
                                                    className={secondSelection === x["symbolId"] ? "active" : ""}
                                                    onClick={(e: any) => {
                                                        setSecondSelection(x["symbolId"]);
                                                        getSymbolDetails(x["symbolId"]);
                                                    }}
                                                >
                                                    <span>{x["symbol"]}</span>
                                                </div>
                                            ))
                                        )
                                    ) : loadingPanel === 1 ? (
                                        <LoadingComponent />
                                    ) : (
                                        serverList.map((x: any, idx: number) => {
                                            let currServer = servers[x["serverId"]] ?? undefined;
                                            return (
                                                <div
                                                    key={`sipc-g1-${idx}`}
                                                    className={secondSelection === x["serverId"] ? "active" : ""}
                                                    onClick={(e: any) => {
                                                        setSecondSelection(x["serverId"]);
                                                        getSymbolDetails(x["symbolId"]);
                                                    }}
                                                >
                                                    <span>{isEmptyOrNull(currServer) ? "" : currServer?.serverDisplayName}</span>
                                                    {currServer && <span className="mini-tag">{currServer?.serverType}</span>}
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="top-header-container">
                            <div className="left-title">
                                <span>Symbol Information</span>
                            </div>
                            <div className="buttons-group">
                                {enableUpdate && symbolDetails !== undefined && (
                                    <>
                                        <Button type="default" icon={<CopyOutlined />} onClick={() => prepareEditConfiguration(symbolDetails, true)}>
                                            Duplicate Settings
                                        </Button>
                                        {!symbolDetails.isMTServer && (
                                            <>
                                                <Button
                                                    type="default"
                                                    icon={<EditOutlined />}
                                                    style={{ marginLeft: "0.651vw" }}
                                                    onClick={() => prepareEditConfiguration(symbolDetails)}
                                                >
                                                    Edit
                                                </Button>
                                                <Popconfirm
                                                    key={"cetmp-del"}
                                                    title="Confirm to delete?"
                                                    onConfirm={() => deleteSymbol(symbolDetails.symbol.symbolId)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        style={{ marginLeft: "0.651vw" }}
                                                        icon={<DeleteOutlined style={{ fontSize: "0.875rem" }} />}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Popconfirm>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="symbol-details-main-container">
                            {symbolDetails === undefined ? (
                                loadingPanel === 3 ? (
                                    <div style={{ display: "block", width: "100%", textAlign: "center", marginTop: "18vh" }}>
                                        <LoadingComponent />
                                    </div>
                                ) : (
                                    <></>
                                )
                            ) : (
                                <>
                                    <div className={`main-panel ${symbolDetails.isMTServer ? "" : "main-only"}`}>
                                        <div className="basic-symbol-info-div">
                                            <h1 className="title">Basic Info</h1>
                                            <div className="details-container">
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Server</Col>
                                                    <Col span={ColSpan.InputSpan}>
                                                        {/** NOTE: not using DefaultIfEmpty fn because changed server obj values */}
                                                        {servers[symbolDetails.symbol.serverId]?.serverDisplayName ?? symbolDetails.symbol.serverId}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Symbol</Col>
                                                    <Col span={ColSpan.InputSpan}>{symbolDetails.symbol.symbol}</Col>
                                                </Row>
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Suffix</Col>
                                                    <Col span={ColSpan.InputSpan}>{defaultIfEmptyOrNull(symbolDetails.symbol.suffix, "-")}</Col>
                                                </Row>
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Clean Symbol</Col>
                                                    <Col span={ColSpan.InputSpan}>{defaultIfEmptyOrNull(symbolDetails.symbol.cleanSymbol, "-")}</Col>
                                                </Row>
                                                {symbolDetails.isMTServer && (
                                                    <Row>
                                                        <Col span={ColSpan.LabelSpan}>Clean Symbol (Pricing)</Col>
                                                        <Col span={ColSpan.InputSpan}>
                                                            {defaultIfEmptyOrNull(symbolDetails.symbol.cleanSymbolPricing, "-")}
                                                        </Col>
                                                    </Row>
                                                )}
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Symbol Asset Type</Col>
                                                    <Col span={ColSpan.InputSpan}>
                                                        {defaultIfEmptyOrNull(symbolDetails.symbol.symbolAssetType, "-")}
                                                    </Col>
                                                </Row>
                                                {symbolDetails.isMTServer && (
                                                    <Row>
                                                        <Col span={ColSpan.LabelSpan}>Symbol Asset Type (Pricing)</Col>
                                                        <Col span={ColSpan.InputSpan}>
                                                            {defaultIfEmptyOrNull(symbolDetails.symbol.symbolAssetTypePricing, "-")}
                                                        </Col>
                                                    </Row>
                                                )}
                                                {symbolDetails.isMTServer && (
                                                    <Row>
                                                        <Col span={ColSpan.LabelSpan}>Symbol Group</Col>
                                                        <Col span={ColSpan.InputSpan}>
                                                            {defaultIfEmptyOrNull(symbolDetails.symbol.symbolGroup ?? "", "Uncategorized")}
                                                        </Col>
                                                    </Row>
                                                )}
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Description</Col>
                                                    <Col span={ColSpan.InputSpan}>
                                                        {defaultIfEmptyOrNull(symbolDetails.symbol.description ?? "", "-")}
                                                    </Col>
                                                </Row>
                                                {symbolDetails.isMTServer && (
                                                    <Row>
                                                        <Col span={ColSpan.LabelSpan}>Base Currency</Col>
                                                        <Col span={ColSpan.InputSpan}>
                                                            {defaultIfEmptyOrNull(symbolDetails.symbol.currencyBase ?? "", "-")}
                                                        </Col>
                                                    </Row>
                                                )}
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Profit Currency</Col>
                                                    <Col span={ColSpan.InputSpan}>
                                                        {defaultIfEmptyOrNull(symbolDetails.symbol.currencyProfit ?? "", "-")}
                                                    </Col>
                                                </Row>
                                                {symbolDetails.isMTServer && (
                                                    <Row>
                                                        <Col span={ColSpan.LabelSpan}>Margin Currency</Col>
                                                        <Col span={ColSpan.InputSpan}>
                                                            {defaultIfEmptyOrNull(symbolDetails.symbol.currencyMargin ?? "", "-")}
                                                        </Col>
                                                    </Row>
                                                )}
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Contract Size</Col>
                                                    <Col span={ColSpan.InputSpan}>
                                                        {defaultIfEmptyOrNull(symbolDetails.symbol.contractSize ?? "", "-")}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Volume Size Adjustment</Col>
                                                    <Col span={ColSpan.InputSpan}>
                                                        {defaultIfEmptyOrNull(symbolDetails.symbol.volumeSizeAdj ?? "", "-")}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Digits</Col>
                                                    <Col span={ColSpan.InputSpan}>{defaultIfEmptyOrNull(symbolDetails.symbol.digits ?? "", "-")}</Col>
                                                </Row>
                                                {symbolDetails.isMTServer && (
                                                    <Row>
                                                        <Col span={ColSpan.LabelSpan}>Is Symbol Removed ?</Col>
                                                        <Col span={ColSpan.InputSpan}>
                                                            {isEmptyOrNull(symbolDetails.symbol.isRemoved ?? "")
                                                                ? "-"
                                                                : symbolDetails.symbol.isRemoved
                                                                ? "Yes"
                                                                : "No"}
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                            {/* <div className="created-by-container">
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Last Updated By</Col>
                                                    <Col span={ColSpan.InputSpan}>{symbolDetails.createdBy}</Col>
                                                </Row>
                                                <Row>
                                                    <Col span={ColSpan.LabelSpan}>Last Updated Date</Col>
                                                    <Col span={ColSpan.InputSpan}>{DataTableColumnRender.DateTime(symbolDetails.createdDateUtc)}</Col>
                                                </Row>
                                            </div> */}
                                        </div>
                                    </div>
                                    {symbolDetails.isMTServer && (
                                        <div className="sub-panel">
                                            <div className="basic-symbol-info-div">
                                                <h1 className="title">
                                                    <span>Trading Hours</span>
                                                    {symbolDetails.symbolSessions.length > 0 && (
                                                        <Segmented
                                                            options={symbolDetails.symbolSessions.map(x => ({
                                                                label: symbolSessionTypeOptions[x.sessionType] ?? x.sessionType,
                                                                value: x.sessionType,
                                                            }))}
                                                            value={currentSessionMode}
                                                            onChange={(sValued: any) => setCurrentSessionMode(sValued)}
                                                        />
                                                    )}
                                                </h1>
                                                {symbolDetails.symbolSessions.length === 0 ? (
                                                    <EmptyData />
                                                ) : (
                                                    symbolDetails.symbolSessions
                                                        .filter(x => x.sessionType === currentSessionMode)
                                                        .map(y => (
                                                            <div key={`sistp-${y.sessionType}`} className="details-container">
                                                                <Row>
                                                                    <Col span={ColSpan.LabelSpan}>Monday</Col>
                                                                    <Col span={ColSpan.InputSpan}>
                                                                        {isEmptyOrNull(y.mondaySession)
                                                                            ? "-"
                                                                            : tagTemplate(y.mondaySession as string, `sistp-${y.sessionType}-ms`)}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={ColSpan.LabelSpan}>Tuesday</Col>
                                                                    <Col span={ColSpan.InputSpan}>
                                                                        {isEmptyOrNull(y.tuesdaySession)
                                                                            ? "-"
                                                                            : tagTemplate(y.tuesdaySession as string, `sistp-${y.sessionType}-ts`)}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={ColSpan.LabelSpan}>Wednesday</Col>
                                                                    <Col span={ColSpan.InputSpan}>
                                                                        {isEmptyOrNull(y.wednesdaySession)
                                                                            ? "-"
                                                                            : tagTemplate(y.wednesdaySession as string, `sistp-${y.sessionType}-ws`)}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={ColSpan.LabelSpan}>Thursday</Col>
                                                                    <Col span={ColSpan.InputSpan}>
                                                                        {isEmptyOrNull(y.thursdaySession)
                                                                            ? "-"
                                                                            : tagTemplate(y.thursdaySession as string, `sistp-${y.sessionType}-tss`)}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={ColSpan.LabelSpan}>Friday</Col>
                                                                    <Col span={ColSpan.InputSpan}>
                                                                        {isEmptyOrNull(y.fridaySession)
                                                                            ? "-"
                                                                            : tagTemplate(y.fridaySession as string, `sistp-${y.sessionType}-fs`)}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={ColSpan.LabelSpan}>Saturday</Col>
                                                                    <Col span={ColSpan.InputSpan}>
                                                                        {isEmptyOrNull(y.saturdaySession)
                                                                            ? "-"
                                                                            : tagTemplate(y.saturdaySession as string, `sistp-${y.sessionType}-ss`)}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={ColSpan.LabelSpan}>Sunday</Col>
                                                                    <Col span={ColSpan.InputSpan}>
                                                                        {isEmptyOrNull(y.sundaySession)
                                                                            ? "-"
                                                                            : tagTemplate(y.sundaySession as string, `sistp-${y.sessionType}-sus`)}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        ))
                                                )}
                                            </div>
                                            <div className="basic-symbol-info-div">
                                                <h1 className="title">Upcoming Holidays</h1>
                                                {symbolDetails.symbolHolidays.length === 0 ? (
                                                    <EmptyData />
                                                ) : (
                                                    <div className="details-container">
                                                        {symbolDetails.symbolHolidays.map((holiday: SymbolHolidayProps, i: number) => (
                                                            <Row key={`shr-${i}`}>
                                                                <Col span={ColSpan.LabelSpan}>{holiday.description}</Col>
                                                                <Col span={ColSpan.InputSpan * (1 / 3)}>{holiday.date}</Col>
                                                                <Col span={ColSpan.InputSpan * (2 / 3)} style={{ paddingLeft: "0.651vw" }}>
                                                                    {isEmptyOrNull(holiday.timeRange)
                                                                        ? "-"
                                                                        : tagTemplate(holiday.timeRange as string, `${holiday.symbolHolidayId}`)}
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {enableUpdate && (
                <Modal
                    open={isModalVisible}
                    style={{ top: 10 }}
                    maskClosable={false}
                    destroyOnClose={true}
                    width="40%"
                    title={currentActionStatus === 1 ? "Create New Symbol" : "Edit Symbol"}
                    okText="Save"
                    cancelText="Cancel"
                    onOk={() => {
                        createSymbolForm
                            .validateFields()
                            .then((values: any) => {
                                SubmitCreateEditForm(values);
                                setIsModalVisible(false);
                            })
                            .catch(err => {});
                    }}
                    onCancel={() => {
                        setIsModalVisible(false);
                    }}
                >
                    <SymbolInfoCreateEditPage
                        form={createSymbolForm}
                        {...createEditFormProps}
                        //symbolSessionTypes={Object.keys(symbolSessionTypeOptions).map(x => ({ text: symbolSessionTypeOptions[x], value: x }))}
                    />
                </Modal>
            )}

            <DownloadTemplateModal
                isModalVisible={isModalVisible2}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case DownloadTemplateModalCallbackKey.Close:
                            setIsModalVisible2(false);
                            break;
                        default:
                            break;
                    }
                }}
                servers={createEditFormProps.servers}
                symbols={createEditFormProps.nonmtsymbols}
                cleanSymbols={ori_cleanSymbol.ProductCleanSymbol}
                symbolAssetType={createEditFormProps.symbolAssetType}
            />

            <ExportExcelModal
                isModalVisible={isModalVisible3}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case ExportExcelModalCallbackKey.Close:
                            setIsModalVisible3(false);
                            break;
                        default:
                            break;
                    }
                }}
                servers={createEditFormProps.allServers}
                symbols={createEditFormProps.allSymbols}
                cleanSymbols={ori_cleanSymbol.ProductCleanSymbol}
                pricingCleanSymbols={ori_cleanSymbol.PricingCleanSymbol}
                symbolAssetType={createEditFormProps.symbolAssetType}
            />
        </>
    );
};

export default SymbolInfo;
