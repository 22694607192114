import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample response from the API
[
  {
    "bridgeId": "ATOM8",
    "serverId": "VGPUK",
    "takerId": "MT4",
    "comment": null
  },
  {
    "bridgeId": "AUS",
    "serverId": "AU1",
    "takerId": "MT4",
    "comment": null
  }
]
*/

// const dummy = [
//     {
//         bridgeId: "ATOM8",
//         serverId: "VGPUK",
//         takerId: "MT4",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "AU1",
//         takerId: "MT4",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "AU2",
//         takerId: "MT4-2",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "AU3",
//         takerId: "MT4-3",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "AU4",
//         takerId: "MT4-4",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "AU5",
//         takerId: "MT4-5",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "AU6",
//         takerId: "MT4-6",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "AUTick",
//         takerId: "MT4-AUTICK",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "Moneta1",
//         takerId: "MT4-Moneta-1",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "MT5AU",
//         takerId: "MT5",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "MT5Moneta",
//         takerId: "MT5-Moneta",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "MT5Moneta1",
//         takerId: "MT5-Moneta-1",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "MT5VT",
//         takerId: "MT5-VT",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "MXT",
//         takerId: "MT4-MXT",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "RF",
//         takerId: "MT4-ROCKFORT",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "VT1",
//         takerId: "MT4-MXT",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "VT2",
//         takerId: "MT4-MXT-2",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "VT4",
//         takerId: "MT4-MXT-4",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "VT7",
//         takerId: "MT4-MXT-7",
//         comment: null,
//     },
//     {
//         bridgeId: "AUS",
//         serverId: "VT8",
//         takerId: "MT4-MXT-8",
//         comment: null,
//     },
//     {
//         bridgeId: "BYBIT",
//         serverId: "MT5Bybit",
//         takerId: "MT5",
//         comment: null,
//     },
//     {
//         bridgeId: "GMN",
//         serverId: "IV",
//         takerId: "MT4",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_ICB_UK",
//         serverId: "BHS1",
//         takerId: "trade/infbs_mt4",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_ICB_UK",
//         serverId: "BHS2",
//         takerId: "trade/live05_mt4",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_ICB_UK",
//         serverId: "BHS3",
//         takerId: "trade/infbs3_mt4",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_ICB_UK",
//         serverId: "INF06",
//         takerId: "trade/live06_mt4",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_ICB_UK",
//         serverId: "Live06",
//         takerId: "trade/live06_mt4",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_ICB_UK",
//         serverId: "VIDA08",
//         takerId: "trade/infbs3_mt4",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_Infinox_UK",
//         serverId: "ICN",
//         takerId: "trade/infcn_mt4",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_Infinox_UK",
//         serverId: "INFTick",
//         takerId: "trade/reporting_uk_mt4",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_Infinox_UK",
//         serverId: "IUK",
//         takerId: "trade/vantageuk_mt4",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_Infinox_UK",
//         serverId: "MT5INF",
//         takerId: "trade/infinox_mt5",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_Infinox_UK",
//         serverId: "MT5VIDA",
//         takerId: "trade/infinox_mt5",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_IXCapital_UK",
//         serverId: "MT5INF",
//         takerId: "trade/infinox_mt5",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_Vantage_UK",
//         serverId: "MT5ST",
//         takerId: "trade/startrader_mt5",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_Vantage_UK",
//         serverId: "MT5ST2",
//         takerId: "trade/startraderprime_mt5",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_Vantage_UK",
//         serverId: "MT5UK",
//         takerId: "trade/vfx_mt5",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_Vantage_UK",
//         serverId: "ST2",
//         takerId: "trade/startrader_mt4",
//         comment: null,
//     },
//     {
//         bridgeId: "PXM_Vantage_UK",
//         serverId: "ST3",
//         takerId: "trade/startraderprime_mt4",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "AU1",
//         takerId: "MT4-AU1",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "AU2",
//         takerId: "MT4-AU2",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "AU3",
//         takerId: "MT4-AU3",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "AU4",
//         takerId: "MT4-AU4",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "AU5",
//         takerId: "MT4-AU5",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "AU6",
//         takerId: "MT4-AU6",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "IV",
//         takerId: "MT4-Startrade",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "KCM",
//         takerId: "MT4-MXT",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "Moneta1",
//         takerId: "MT4-Moneta-1",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "MT5AT",
//         takerId: "MT5-ALPHATICK",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "MT5AU",
//         takerId: "MT5-AU",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "MT5Moneta",
//         takerId: "MT5-Moneta",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "MT5Moneta1",
//         takerId: "MT5-Moneta-1",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "MT5PUG",
//         takerId: "MT5-PUG",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "MT5UK2",
//         takerId: "MT5-UK2",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "MT5VT",
//         takerId: "MT5-VT",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "MXT",
//         takerId: "MT4-MXT",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "PU3",
//         takerId: "MT4-PUG3",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "PU4",
//         takerId: "MT4-PUG4",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "PU5",
//         takerId: "MT4-PUG5",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "PU6",
//         takerId: "MT4-PUG6",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "PU7",
//         takerId: "MT4-PUG7",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "PUG",
//         takerId: "MT4-PUG",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "PUG2",
//         takerId: "MT4-PUG2",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UK1",
//         takerId: "MT4-UK1",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UK10",
//         takerId: "MT4-UK10",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UK11",
//         takerId: "MT4-UK11",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UK2",
//         takerId: "MT4-UK2",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UK3",
//         takerId: "MT4-UK3",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UK4",
//         takerId: "MT4-UK4",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UK5",
//         takerId: "MT4-UK5",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UK6",
//         takerId: "MT4-UK6",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UK7",
//         takerId: "MT4-UK7",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UK8",
//         takerId: "MT4-UK8",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UK9",
//         takerId: "MT4-UK9",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "UM2",
//         takerId: "MT4-UM2",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "VGPUK",
//         takerId: "MT4-LLP",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "VJP",
//         takerId: "MT4-VJP",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "VT1",
//         takerId: "MT4-MXT",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "VT2",
//         takerId: "MT4-MXT-2",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "VT3",
//         takerId: "MT4-MXT-3",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "VT4",
//         takerId: "MT4-MXT-4",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "VT5",
//         takerId: "MT4-MXT-5",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "VT6",
//         takerId: "MT4-MXT-6",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "VT7",
//         takerId: "MT4-MXT-7",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "VT8",
//         takerId: "MT4-MXT-8",
//         comment: null,
//     },
//     {
//         bridgeId: "StockOZHub",
//         serverId: "VT9",
//         takerId: "MT4-MXT-9",
//         comment: null,
//     },
//     {
//         bridgeId: "VIG",
//         serverId: "MT5PUG",
//         takerId: "MT5-PUG",
//         comment: null,
//     },
//     {
//         bridgeId: "VIG",
//         serverId: "MT5VJP",
//         takerId: "MT5-VJP",
//         comment: null,
//     },
//     {
//         bridgeId: "VIG",
//         serverId: "OPL",
//         takerId: "MT4-OPL",
//         comment: null,
//     },
//     {
//         bridgeId: "VIG",
//         serverId: "PU4",
//         takerId: "MT4-PUG4",
//         comment: null,
//     },
//     {
//         bridgeId: "VIG",
//         serverId: "PU6",
//         takerId: "MT4-PUG6",
//         comment: null,
//     },
//     {
//         bridgeId: "VIG",
//         serverId: "PUG",
//         takerId: "MT4-PUG",
//         comment: null,
//     },
//     {
//         bridgeId: "VIG",
//         serverId: "PUG2",
//         takerId: "MT4-PUG2",
//         comment: null,
//     },
//     {
//         bridgeId: "VIG",
//         serverId: "VCN",
//         takerId: "MT4",
//         comment: null,
//     },
//     {
//         bridgeId: "VIG",
//         serverId: "VCN2",
//         takerId: "MT4-2",
//         comment: null,
//     },
//     {
//         bridgeId: "VIG",
//         serverId: "VJP",
//         takerId: "MT4",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "MT5AT",
//         takerId: "MT5-AT",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "MT5ST2",
//         takerId: "MT5-UK-2",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "MT5UK2",
//         takerId: "MT5-UK2",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "PU3",
//         takerId: "MT4-PUG-3",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "PU5",
//         takerId: "MT4-PUG-5",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "PU7",
//         takerId: "MT4-PUG-7",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UK1",
//         takerId: "MT4",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UK10",
//         takerId: "MT4-10",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UK11",
//         takerId: "MT4-11",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UK2",
//         takerId: "MT4-2",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UK3",
//         takerId: "MT4-3",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UK4",
//         takerId: "MT4-4",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UK5",
//         takerId: "MT4-5",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UK6",
//         takerId: "MT4-6",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UK7",
//         takerId: "MT4-7",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UK8",
//         takerId: "MT4-8",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UK9",
//         takerId: "MT4-9",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UKTick",
//         takerId: "MT4-UKTICK",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "UM2",
//         takerId: "MT4-UM2",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "VT3",
//         takerId: "MT4-MXT-3",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "VT5",
//         takerId: "MT4-MXT-5",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "VT6",
//         takerId: "MT4-MXT-6",
//         comment: null,
//     },
//     {
//         bridgeId: "VUK",
//         serverId: "VT9",
//         takerId: "MT4-MXT-9",
//         comment: null,
//     },
// ];

interface RCServerBridge {
    bridgeId: string;
    serverId: string;
    takerId: string;
    comment: string | null;
}

/**
 * This hook is used to get the list of RC Servers's Bridge (full name) and the server details
 */
const useRCServerBridges = (): {
    rcServerBridges: RCServerBridge[] | null;
    rcServerBridgesError: string | null;
    refetchRcServerBridges: () => void;
    isFetching: boolean;
    isLoading: boolean;
} => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcServerBridges"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ROOT.GET_SERVER_BRIDGES}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000 * 5, //(60 x 1000ms x 5 = 5 minutes)
    });

    const rcServerBridges: RCServerBridge[] | null = data?.data?.status ? null : data?.data || null;
    // const rcServerBridges: RCServerBridge[] | null = dummy;
    // @ts-ignore
    const rcServerBridgesError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcServerBridges,
        rcServerBridgesError,
        refetchRcServerBridges: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCServerBridges;
