import { useEffect, useMemo, useState } from "react";
import CardBox from "../../../../components/Common/CardBox";
import LoadingComponent from "../../../../components/Loading";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { ADMINFEE_PROCESS_STATUSES, CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import moment from "moment";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { AdminFeeProcessData, CustomPaginationProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "../../../../constants/type";
import { ContainerOutlined, ExclamationCircleOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { DateTimeUtil } from "../../../../utils/datetime";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { APIs } from "../../../../services/apis";
import ViewTradesModal from "./ViewTradesModal";
import { Modal } from "antd";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "@/pages/ReportingModule/ReportBatch";
const AdminFeeProcess = () => {
    const [data, setData] = useState<AdminFeeProcessData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 30,
        total: 0,
    });
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({
        serverId: 0,
        receiveDate: moment().format("YYYYMMDD"),
        login: "",
    });
    const [currTradeView, setCurrTradeView] = useState<AdminFeeProcessData | null>(null);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);

    const columns = useMemo(() => [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            fixed: "left",
            sorter: true,
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: servers,
                    rules: [{ required: true, message: REQUIRED_FIELD }],
                },
            },
        }),
        DTColProps.Middle({
            title: "Received Date",
            dataIndex: "receiveDate",
            key: "receiveDate",
            fixed: "left",
            width: "10vw",
            options: {
                filter: {
                    type: ComponentType.date,
                    value: "",
                    dateFormat: "YYYY-MM-DD",
                    rules: [{ required: true, message: REQUIRED_FIELD }],
                },
            },
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
            fixed: "left",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Group",
            dataIndex: "group",
            key: "group",
        }),
        DTColProps.Small({
            title: "Sub Account",
            dataIndex: "subAccount",
            key: "subAccount",
            render: (subAccount: number) => subAccount === 1 ? "Yes" : "No",
        }),
        DTColProps.XSmall({
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: (a: any, b: any) => a.status - b.status,
            render: (status: number) => ADMINFEE_PROCESS_STATUSES[status],
        }),
        DTColProps.XSmall({
            title: "Swap",
            dataIndex: "swap",
            key: "swap",
        },
            ["text-right"]
        ),
        DTColProps.Middle({
            title: "Created Date",
            dataIndex: "formatCreateDate",
            key: "formatCreateDate",
            width: "10vw",
        }),
    ], [servers]);

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        separateActionButton: true,
        rowExtra: [
            {
                text: "View Trades",
                value: "view_adminfee_trades",
                icon: <UnorderedListOutlined />
            },
        ],
        export: { text: "Download Report" },
        extraButtons: [{ text: "Report Listing", value: "reportlist", icon: <ContainerOutlined /> }],
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "receiveDate") {
                            fParams["receiveDate"] = DateTimeUtil.GetOrigin(FormData[x], "YYYYMMDD");
                        } else if (x === "server") {
                            fParams["serverId"] = FormData[x];
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "view_adminfee_trades") {
                    setCurrTradeView(FormData.data);
                };
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                downloadAFReport(filterParams);
                break;
            case CALLBACK_KEY.OTHERS:
                switch (FormData) {
                    case "reportlist":
                        setIsReportModalVisible(true);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        };
    };

    const getAdminFeeProcessData = () => {
        plainAxiosInstance
            .post(`${APIs.RC_ADMINFEE.POST_AF_PROCESS_DATA}`, {
                rows: pagination.pageSize,
                page: pagination.current,
                ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            })
            .then((res: any) => {
                if (res.data.resultList.length > 0) {
                    let newData = res.data.resultList.map((x: AdminFeeProcessData) => ({
                        ...x,
                        key: x.receiveDate + "_" + x.login,
                    }))
                    setData(newData);
                    setPagination((prev) => ({ ...prev, total: res.data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                };
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("admin fee process data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setData([]);
                setPagination((prev) => ({ ...prev, total: 0 }));
            }))
            .finally(() => setIsLoading(false));
    };

    const downloadAFReport = (fParams: any) => {
        setIsExporting(true);
        plainAxiosInstance
            .post(`${APIs.RC_ADMINFEE.POST_AF_DOWNLOAD_REPORT}`, {
                serverId: fParams.serverId,
                receiveDate: fParams.receiveDate,
                login: fParams.login ? fParams.login : "",
            })
            .then(response => {
                if (response.data === 0) {
                    Modal.success({
                        width: "30%",
                        title: "Successfully added batch",
                        content: "Please download the report from 【Report Listing】 once it is completed",
                    });
                } else {
                    Modal.error({
                        icon: <ExclamationCircleOutlined />,
                        title: "Failed to add batch",
                        content: "Please try again later",
                    });
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Failed to add download batch`, SUCCESS_FAILED.OTHERS_FAILED, err))
            )
            .finally(() => setIsExporting(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            setRunRefetchDataList(false);
            getAdminFeeProcessData();
        }
        return () => { };
    }, [runRefetchDataList]);

    useEffect(() => {
        plainAxiosInstance
            .get(`${APIs.RC_ADMINFEE.GET_SERVERS}`)
            .then((res: any) => {
                if (res.status === 200) {
                    setServers(res.data.map((x: any) => ({ value: x.id, text: x.name })));
                    setRunRefetchDataList(true);
                } else {
                    setServers([]);
                };
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setServers([]);
            }))
            .finally(() => setIsFilterLoaded(true));
        return () => { };
    }, []);

    return (
        <div className="config-diff-container">
            <CardBox title="AdminFee Process">
                <LoadingComponent tip="Loading filters..." spinning={!isFilterLoaded}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="key"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={data ?? []}
                        callback={componentCallback}
                        loading={isLoading}
                        serverSide={true}
                        pagination={{
                            ...pagination,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                        }}
                        filterInitialValue={{ server: 0, receiveDate: moment() }}
                        exporting={isExporting}
                    />
                </LoadingComponent>
                <ViewTradesModal
                    currTradeView={currTradeView}
                    onClose={() => setCurrTradeView(null)}
                />
                <ReportBatchModal
                    isModalVisible={isReportModalVisible}
                    modalTitle={"Download Report"}
                    callback={(type: number) => {
                        switch (type) {
                            case ReportBatchModalCallbackKey.Close:
                                setIsReportModalVisible(false);
                                break;
                            default:
                                break;
                        }
                    }}
                    filterCallback={(record: any) => record.batchName === "AdminFeeTrades"}
                />
            </CardBox>
        </div>
    );
};

export default AdminFeeProcess;