import { useLocation, useNavigate } from "react-router-dom";
import { Menu, Layout, MenuProps, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import StyledKbar from "@/services/kbar/StyledKbar";
import { useKBar } from "kbar";
import { DefaultIfEmpty } from "@/utils/object";
import { isEmptyOrNull } from "@/utils/string";
import { routeProps } from "@/constants/type";
import RouteHelper from "@/helpers/routeHelper";
import { auth_route } from "@/routes";
import { useAppSelector } from "@/store/hook";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import brandLogo from "@/assets/images/vantage-full-logo-RGB-teal.png";

const { Sider } = Layout;

export interface NavigationBarProps {}

const getMenuItem = (props: routeProps, excludeKeys: string[]): ItemType => {
    let subMenuList =
        props.submenu && props.submenu.length > 0
            ? props.submenu
                  .filter(x => !excludeKeys.includes(x.key))
                  .map(x => getMenuItem(x, excludeKeys))
                  .filter((x: any) => !(DefaultIfEmpty(x, "type", "") === "group" && (isEmptyOrNull(x.children) ? [] : x.children).length === 0)) // remove empty group
            : null;
    return {
        key: props.key as string,
        icon: props.icon,
        // label: (
        //     <Tooltip placement="right" title={props.title} className="highest-z-index">
        //         {props.title}
        //     </Tooltip>
        // ),
        label: props.title,
        title: props.title,
        children: subMenuList,
        ...(props.type && { type: props.type }),
    } as ItemType;
};

const NavigationBar = (): JSX.Element => {
    let navigate = useNavigate();
    let location = useLocation();
    const systemState = useAppSelector(state => state.system);
    const { query: kbarQuery } = useKBar();

    const [current, setCurrent] = useState<string>(location.pathname);
    const [openKeys, setOpenKeys] = useState<string[]>([]);

    const rootRouteKeys = auth_route.map(x => x.key);

    const menuList = useMemo(() => {
        const routeHelper = new RouteHelper(auth_route);

        return (
            [
                {
                    className: "logo-container",
                    key: "/",
                    title: "Insight",
                    label: (
                        // <Tooltip title={"Insight"}>
                        <Space>
                            <span>Insight</span>
                            <div className="kbarshortcut">
                                <span>{navigator.userAgent.indexOf("Mac") > -1 ? "⌘" : "Ctrl"}</span>+<span>K</span>
                            </div>
                        </Space>
                        // </Tooltip>
                    ),
                    icon: (
                        <div style={{ display: "inline-flex", justifyContent: "center", alignItems: "center", height: 20 }}>
                            <img alt={"Insight"} src={brandLogo} style={{ width: "auto", height: "100%" }} />
                        </div>
                    ),
                },
                {
                    type: "divider",
                    style: { borderColor: "#00615d" },
                },
            ] as ItemType[]
        ).concat(routeHelper.getNavigationMenu().map(x => getMenuItem(x, [])));
    }, []);

    const onMenuClick = (e: { key: any }) => {
        if (e.key === "/") {
            kbarQuery.toggle();
            return;
        }
        switch (e.key) {
            default:
                navigate(e.key);
                setCurrent(e.key);
                // scroll to the top after navigate with behavior smooth
                window.scrollTo({ top: 0, behavior: "smooth" });
                break;
        }
    };

    const onOpenChange: MenuProps["onOpenChange"] = keys => {
        const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
        if (rootRouteKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    useEffect(() => {
        if (location) {
            if (current !== location.pathname && location.pathname.indexOf(current) === -1) {
                setCurrent(location.pathname);
            }
        }
        return () => {};
    }, [location, current]);

    useEffect(() => {
        let currentPathSplited: string[] = location.pathname.split("/");
        if (currentPathSplited.length > 1) {
            let pPath = [currentPathSplited[0], currentPathSplited[1]].join("/");
            if (rootRouteKeys.indexOf(pPath) > -1) {
                setOpenKeys([pPath]);
            }
        }
        if (currentPathSplited.length > 3) {
            let pPath = [currentPathSplited[0], currentPathSplited[1], currentPathSplited[2]].join("/");
            if (
                pPath.indexOf("/riskscore") > -1 ||
                pPath.indexOf("/serverbrand") > -1 ||
                pPath.indexOf("/brandserver") > -1 ||
                pPath.indexOf("/symbolconfig") > -1
            ) {
                pPath = `${pPath}/${currentPathSplited[3]}`;
            }
            if (current !== pPath) {
                setCurrent(pPath);
            }
        }
        return () => {};
    }, []);

    return (
        <>
            <StyledKbar />
            <Sider collapsed={systemState.sideBarCollapsed} className="siderbar-container">
                <Menu
                    theme="dark"
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    onClick={onMenuClick}
                    selectedKeys={current === "/" ? [] : [current]}
                    items={menuList}
                />
                <div className="versioning-panel">
                    <span>v.1.0.1</span>
                </div>
            </Sider>
        </>
    );
};

export default NavigationBar;
