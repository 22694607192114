import { Modal } from "antd";
import { AlarmItemProps } from "../List";
import { useEffect, useMemo, useState } from "react";
import { apiRequest, APIs } from "../../../../services/apiConfig";
import { DateTimeUtil } from "../../../../utils/datetime";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { DimensionLevelObjectConfig, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { SUCCESS_FAILED } from "../../../../constants";
import { ToObjectWithKey } from "../../../../utils/array";
import { DefaultIfEmpty } from "../../../../utils/object";
import { Link } from "react-router-dom";
import { getNavigationUrl, ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileParams } from "../../../Analysis/AccountProfileHelper";

export enum UserPNLListModalCallbackKey {
    Close = 0,
}

export interface UserPNLListModalProps {
    selectedData: AlarmItemProps | undefined;
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
}

interface UserPNLItemProps {
    keyId?: string;
    account_id: number;
    brand_id: number | string;
    server_id: number;
    server_text?: string;
    total_pnl: number;
}

const UserPNLListModal = (props: UserPNLListModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [userList, setUserList] = useState<UserPNLItemProps[]>([]);
    const [filterOptList, setFilterOptList] = useState<any>({
        Server: [],
    });

    const markupData = useMemo(() => {
        return userList.map((x: UserPNLItemProps) => ({
            ...x,
            keyId: `${x.server_id}-${x.account_id}`,
            server_text: DefaultIfEmpty(filterOptList.Server, x.server_id, `${x.server_id}`),
        }));
    }, [userList, filterOptList]);

    const columns = [
        DTColProps.Small({
            title: "Server",
            dataIndex: "server_text",
            key: "server_text",
        }),
        DTColProps.Small({
            title: "Account ID",
            dataIndex: "account_id",
            key: "account_id",
            // render: (accountId: number, rowData: UserPNLItemProps) => {
            //     return (
            //         <Link
            //             to={getNavigationUrl(
            //                 ACCOUNT_PROFILE_FROM_MODULE.INTRADAY_MONITOR_ALARM,
            //                 getAccountProfileParams(
            //                     rowData.server_id,
            //                     rowData.account_id,
            //                     ACCOUNT_PROFILE_FROM_MODULE.INTRADAY_MONITOR_ALARM,
            //                     "",
            //                     "",
            //                     "",
            //                     "",
            //                     ""
            //                 )
            //             )}
            //             target="_blank"
            //             style={{ color: "#0e65cc" }}
            //         >
            //             {accountId}
            //         </Link>
            //     );
            // },
        }),
        DTColProps.SCurrency({
            title: "Daily P&L",
            dataIndex: "total_pnl",
            key: "total_pnl",
            sorter: (a: any, b: any) => a.total_pnl - b.total_pnl,
        }),
    ];

    const getUserList = (record: AlarmItemProps) => {
        setIsLoading(true);
        let severDate = DateTimeUtil.getCurrentServerTime().format("YYYY-MM-DD"),
            dimensionByKey = ToObjectWithKey(DimensionLevelObjectConfig("IntradayDimensionArr"), "value", "dLevel"),
            params: any = {
                dimensionSelectors: [1],
                dimensionFilters: [],
                metricPaginator: { offset: 0, limit: 100 },
                metricSelectors: [{ metricName: "total_pnl", columnOrder: 1 }],
                metricSorters: [{ metricName: "total_pnl", sortOrder: 1, sortDirection: 1 }],
                ...(severDate !== record.eventDate.split("T")[0] && {
                    eodDate: record.eventDate,
                }),
            };

        try {
            let tmp = JSON.parse(record.eventHeader);
            params.dimensionFilters = Object.keys(tmp).reduce((tmpArr: any[], key: string) => {
                if (key !== "rebate_server_id") {
                    if (key === "rebate_account_id") {
                        tmpArr.push({
                            dimensionLevel: dimensionByKey[key],
                            filterIn: [`${tmp[key]}${tmp?.hasOwnProperty("rebate_server_id") ? `|${tmp["rebate_server_id"]}` : ""}`],
                        });
                    } else {
                        tmpArr.push({
                            dimensionLevel: dimensionByKey[key],
                            filterIn:
                                key === "account_id" ? [`${tmp[key]}${tmp?.hasOwnProperty("server_id") ? `|${tmp["server_id"]}` : ""}`] : [tmp[key]],
                        });
                    }
                }

                return tmpArr;
            }, []);
        } catch (error) { }

        apiRequest(APIs.GET_MIM_METRIC_RANKING, params)
            .then(res => {
                setUserList(res.length > 0 ? res : []);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("user PNL list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server"] })
            .then(res => {
                setFilterOptList({
                    Server: ToObjectWithKey(res.servers, "id", "server"),
                });
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config info", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (props.selectedData !== undefined) {
            getUserList(props.selectedData);
        } else {
            setUserList([]);
        }
    }, [props.selectedData]);

    useEffect(() => {
        getConfigList();
    }, []);

    return (
        <Modal
            style={{ top: 10 }}
            width={"30vw"}
            bodyStyle={{ padding: "0.64vw 1.41vh" }}
            title={props.modalTitle}
            open={props.isModalVisible}
            okButtonProps={{ style: { display: "none" } }}
            cancelText="Close"
            onCancel={() => props.callback(UserPNLListModalCallbackKey.Close, null)}
        >
            <FlexiDataTable
                bordered
                rowKeyProperty="keyId"
                title={false}
                loading={isLoading}
                columns={columns}
                dataSource={markupData}
                options={{
                    enableFilter: false,
                    showHideColumns: false,
                }}
                scroll={{ x: true, y: "70vh" }}
                pagination={false}
            />
        </Modal>
    );
};

export default UserPNLListModal;
